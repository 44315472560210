import React, {createContext, ReactNode, useContext} from 'react'

interface BigFigureContextType {
  isBigFigure: boolean
  isMustSip?: boolean
  isFromHome?: boolean
}

const BigFigureContext = createContext<BigFigureContextType | undefined>(
  undefined,
)

interface BigFigureProviderProps {
  children: ReactNode
  isBigFigure: boolean
  isMustSip?: boolean
  isFromHome?: boolean
}

export const BigFigureProvider: React.FC<BigFigureProviderProps> = ({
  children,
  isBigFigure,
  isMustSip,
  isFromHome = true,
}) => {
  return (
    <BigFigureContext.Provider value={{isBigFigure, isMustSip, isFromHome}}>
      {children}
    </BigFigureContext.Provider>
  )
}

export const useBigFigure = (): boolean => {
  const context = useContext(BigFigureContext)
  if (!context) {
    return false
  }
  return context.isBigFigure
}

export const useIsMustSip = (): boolean => {
  const context = useContext(BigFigureContext)
  if (!context) {
    return false
  }
  return context.isMustSip ?? false
}

export const useBigFigureFromHome = (): boolean => {
  const context = useContext(BigFigureContext)
  if (!context) {
    return false
  }
  return context.isFromHome ?? true
}
