import React, {useState} from 'react'
import {
  Dimensions,
  Linking,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import {
  BarcodeScanningResult,
  CameraType,
  CameraView,
  // CameraType,
  useCameraPermissions,
} from 'expo-camera'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

import {usePalette} from '#/lib/hooks/usePalette'
import {isIOS} from '#/platform/detection'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'

export default function CameraScannerView({
  onCodeScanned,
  onCloseScanner,
}: {
  onCodeScanned: (result: BarcodeScanningResult) => void
  onCloseScanner?: () => void
}) {
  const [facing] = useState<CameraType>('back')
  const [permission] = useCameraPermissions()
  const windowHeight = Dimensions.get('window').height
  const pal = usePalette('default')
  const t = useTheme()

  if (!permission) {
    return <View />
  }

  if (!permission?.canAskAgain) {
    // Camera permissions are still loading.
    return (
      <View style={[styles.container, {height: windowHeight}]}>
        <Pressable
          accessibilityRole="button"
          style={{
            position: 'absolute',
            top: isIOS ? 0 : 10,
            right: 10,
            padding: 10,
            zIndex: 100,
            backgroundColor: pal.text.color,
            borderRadius: 100,
          }}
          onPress={onCloseScanner}>
          <FontAwesomeIcon
            icon="x"
            size={12}
            style={{
              color: String(pal.textInverted.color),
            }}
          />
        </Pressable>
        <View>
          <Text style={[styles.message, t.atoms.text]}>
            <Trans>Camera Permission Not Enabled</Trans>
          </Text>
        </View>
        <View style={[a.m_2xl]}>
          <Text style={[t.atoms.text]}>
            <Trans>
              Please go to “Settings {'>'} Sipz” on your iPhone and allow Sipz
              to access your camera
            </Trans>
          </Text>
        </View>
        <Button
          style={[a.m_2xl]}
          variant="solid"
          color="primary"
          size="medium"
          label="Go to Settings"
          onPress={() => {
            Linking.openSettings()
            onCloseScanner?.()
          }}>
          <Text>
            <Trans>Settings</Trans>
          </Text>
        </Button>
      </View>
    )
  }

  // const onContinue = async () => {
  //   requestPermission()
  //     .then(permission => {
  //       setCanAskAgain(permission.canAskAgain)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  // if (!permission.granted) {
  //   // Camera permissions are not granted yet.
  //   // requestPermission()
  //   return (
  //     <View style={[styles.container, {height: windowHeight}]}>
  //        <Pressable
  //         accessibilityRole="button"
  //         style={{
  //           position: 'absolute',
  //           top: isIOS ? 0 : 10,
  //           right: 10,
  //           padding: 10,
  //           zIndex: 100,
  //           backgroundColor: pal.text.color,
  //           borderRadius: 100,
  //         }}
  //         onPress={onCloseScanner}>
  //         <FontAwesomeIcon
  //           icon="x"
  //           size={12}
  //           style={{
  //             color: String(pal.textInverted.color),
  //           }}
  //         />
  //       </Pressable>
  //       <Text style={[styles.message, t.atoms.text]}>
  //         'Sipz' would like to access the camera.
  //       </Text>
  //       <View>
  //         <Button
  //           style={[a.m_2xl]}
  //           color="primary"
  //           variant="solid"
  //           size="medium"
  //           label="Continue"
  //           onPress={onContinue}>
  //           <Text style={[t.atoms.text_inverted]}>Continue</Text>
  //         </Button>
  //         {/* <Button
  //           style={[a.ml_2xl, a.mr_2xl]}
  //           color="secondary"
  //           variant="solid"
  //           size="medium"
  //           label="Allow"
  //           onPress={onCloseScanner}>
  //           <Text style={[t.atoms.text]}>Cancel</Text>
  //         </Button> */}
  //       </View>
  //     </View>
  //   )
  // }

  // function toggleCameraFacing() {
  //   setFacing(current => (current === 'back' ? 'front' : 'back'))
  // }

  return (
    <SafeAreaView style={{flex: 1, height: windowHeight}}>
      <CameraView
        style={styles.camera}
        facing={facing}
        onBarcodeScanned={onCodeScanned}
        barcodeScannerSettings={{
          barcodeTypes: ['qr'],
        }}>
        <Pressable
          accessibilityRole="button"
          style={{
            position: 'absolute',
            top: isIOS ? 0 : 10,
            right: 10,
            padding: 10,
            zIndex: 100,
            backgroundColor: pal.text.color,
            borderRadius: 100,
          }}
          onPress={onCloseScanner}>
          <FontAwesomeIcon
            icon="x"
            size={12}
            style={{
              color: String(pal.textInverted.color),
            }}
          />
        </Pressable>
        {/* <View style={styles.buttonContainer}>
          <TouchableOpacity
            accessibilityRole="button"
            style={styles.button}
            onPress={toggleCameraFacing}>
            <Text style={styles.text}>Flip Camera</Text>
          </TouchableOpacity>
        </View> */}
      </CameraView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  message: {
    textAlign: 'center',
    fontSize: 16,
    paddingBottom: 10,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 64,
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
})
