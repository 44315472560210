import React, {useEffect} from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isNative} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {Tag} from '#/view/com/tags/TagItem'
import {FriendsIcon, RightIcon, TagIcon} from '#/view/icons/ModalIcons'
import {CircleItemProps, ContactItemProps} from '#/view/screens/Circle'
import {
  useSipzContactList,
  useTelegramContactList,
} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {Circle_Left_Nav_Fill as CircleIcon} from '#/components/icons/Circle'
import {Button} from '../../util/forms/Button'
import {usePostStepStore} from '../../util/sdlStore/PostStepStore'
import {TVisible} from './Visible'

export interface CircleListType extends CircleItemProps {
  isSelected?: boolean
}

export type VisibleRangeType = {
  visibleType: number //  1: All (1st,2nd Friends) 2: 1st Friends 3: Select(Friends+Circles) 4: Except(Tags+Friends)
  visibleCircles: CircleListType[]
  visibleFriends: ContactItemProps[]
  unVisibleTags: Tag[]
  unVisibleFriends: ContactItemProps[]
}
export type PostSelectCircleModalProps = {
  visibleRange: VisibleRangeType
  setVisibleRange: (value: VisibleRangeType) => void
  setVisible: (value: TVisible) => void
}
export const SELET_TYPE = [
  {
    value: 1,
    name: 'All (1st,2nd Friends)',
    desc: 'All Friends, Circles ,Friends of Friends',
  },
  {
    value: 2,
    name: '1st Friends',
    desc: 'All Telegram mutual friends and Sipz friends',
  },
  {
    value: 3,
    name: 'Select',
  },
  {
    value: 4,
    name: 'Excluded',
  },
]
export const snapPoints = ['fullscreen']
export const Component = function PostSelectCircleModalComponent(
  props: PostSelectCircleModalProps,
) {
  const t = useTheme()
  const {_} = useLingui()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {closeModal, closeAllModals, openModal} = useModalControls()
  const {data: teleList = []} = useTelegramContactList({circleId: -1})
  const {data: sipzList = []} = useSipzContactList({circleId: -1})

  const {
    stepStore: {postVisibleRange},
    setPostVisibleRange,
  } = usePostStepStore()

  const selectType = postVisibleRange.visibleType

  const [isVisibleExpand, setIsVisibleExpand] = React.useState<boolean>(
    postVisibleRange.visibleType === 3 ? true : false,
  )
  const [isUnvisibleExpand, setIsUnvisibleExpand] = React.useState<boolean>(
    postVisibleRange.visibleType === 4 ? true : false,
  )
  const [firstFriends, setFirstFriends] = React.useState<ContactItemProps[]>([])
  const [error, setError] = React.useState<string | undefined>(undefined)

  function onConfirm() {
    if (
      selectType === 3 &&
      postVisibleRange?.visibleFriends?.length === 0 &&
      postVisibleRange?.visibleCircles.filter(item => item.isSelected)
        ?.length === 0
    ) {
      // Select(Friends+Circles)
      setError(_(msg`Please select at least one friend or circle.`))
      return
    }
    if (
      selectType === 4 &&
      postVisibleRange?.unVisibleTags?.length === 0 &&
      postVisibleRange?.unVisibleFriends?.length === 0
    ) {
      // Except(Tags+Friends)
      setError(_(msg`Please select at least one tag or friend.`))
      return
    }
    props?.setVisible('Circle')
    props?.setVisibleRange(postVisibleRange)

    closeAllModals()
  }

  useEffect(() => {
    setError(undefined)
  }, [selectType])

  useEffect(() => {
    if (selectType === 2) {
      setFirstFriends(
        teleList
          ?.filter(o => o?.degree === 1 && (o?.isAdded || o?.isInvite))
          ?.concat(sipzList?.filter(o => o?.degree === 1)),
      )
    }
  }, [selectType, teleList, sipzList])

  // console.log('visibleRange', props?.visibleRange, postVisibleRange, selectType)

  return (
    <View
      testID="postSelectCircleModal"
      style={[
        a.w_full,
        a.h_full,
        isMobile && {paddingHorizontal: 18},
        isNative && [a.py_xl],
      ]}>
      <View style={[a.w_full, a.flex_1]}>
        {/* All */}
        <Pressable
          style={[a.flex_row, a.align_center]}
          accessibilityRole="button"
          onPress={() => {
            setPostVisibleRange({
              ...postVisibleRange,
              visibleType: 1,
            })
            setIsVisibleExpand(false)
            setIsUnvisibleExpand(false)
            setFirstFriends([])
          }}>
          <View style={[{width: 28}]}>
            {selectType === 1 && (
              <FontAwesomeIcon icon={'check'} color={t.palette.green_11} />
            )}
          </View>
          <View
            style={[
              a.flex_1,
              a.flex_row,
              a.align_center,
              a.pt_md,
              a.gap_md,
              {borderBottomWidth: 1, borderColor: '#97979740'},
            ]}>
            <View style={[a.flex_col, a.align_start, a.gap_xs]}>
              <Text style={[a.text_md, a.font_heavy, t.atoms.text]}>
                <Trans>{SELET_TYPE?.[0]?.name}</Trans>
              </Text>
              <View style={[a.py_md, a.gap_sm, a.flex_row, a.align_center]}>
                <FriendsIcon color={t.palette.gray_16} />
                <Text style={[{color: t.palette.gray_10}]}>
                  <Trans>{SELET_TYPE?.[0]?.desc}</Trans>
                </Text>
              </View>
            </View>
          </View>
        </Pressable>
        {/* 1st Friends */}
        <Pressable
          style={[a.flex_row, a.align_center]}
          accessibilityRole="button"
          onPress={() => {
            setPostVisibleRange({
              ...postVisibleRange,
              visibleType: 2,
            })
            setIsVisibleExpand(false)
            setIsUnvisibleExpand(false)
            if (firstFriends?.length > 0) {
              openModal({
                name: 'post-select-tag-friend',
                type: 'friend',
                friends: firstFriends,
                setFriends: setFirstFriends,
                show1stFriends: true,
              })
            } else {
              setFirstFriends(
                teleList
                  ?.filter(o => o?.degree === 1 && (o?.isAdded || o?.isInvite))
                  ?.concat(sipzList?.filter(o => o?.degree === 1)),
              )
            }
          }}>
          <View style={[{width: 28}]}>
            {selectType === 2 && (
              <FontAwesomeIcon icon={'check'} color={t.palette.green_11} />
            )}
          </View>
          <View
            style={[
              a.flex_1,
              a.flex_row,
              a.align_center,
              a.pt_2xl,
              a.pb_md,
              {borderBottomWidth: 1, borderColor: '#97979740'},
            ]}>
            <View style={[a.flex_1, a.flex_col, a.align_start, a.gap_xs]}>
              <Text style={[a.text_md, a.font_heavy, t.atoms.text]}>
                <Trans>{SELET_TYPE?.[1]?.name}</Trans>
              </Text>
              <View style={[a.py_md, a.gap_sm, a.flex_row, a.align_center]}>
                <FriendsIcon color={t.palette.gray_16} />
                <Text style={[{color: t.palette.gray_10}]}>
                  <Trans>{SELET_TYPE?.[1]?.desc}</Trans>
                </Text>
              </View>
              {selectType === 2 && (
                <Text
                  style={[a.text_sm, {color: t.palette.primary_active}]}
                  numberOfLines={2}>
                  {firstFriends?.map(item => `@${item.nickname} `)}
                </Text>
              )}
            </View>
          </View>
        </Pressable>
        {/* Select */}
        <View style={[a.flex_col]}>
          <View style={[a.flex_row, a.align_center]}>
            <View style={[{width: 28}]}>
              {selectType === 3 && (
                <FontAwesomeIcon icon={'check'} color={t.palette.green_11} />
              )}
            </View>
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                setPostVisibleRange({
                  ...postVisibleRange,
                  visibleType: 3,
                })
                setIsVisibleExpand(!isVisibleExpand)
                setIsUnvisibleExpand(false)
                setFirstFriends([])
              }}
              style={[
                a.flex_1,
                a.flex_row,
                a.align_center,
                a.justify_between,
                a.py_xl,
                {
                  borderBottomWidth: !isVisibleExpand ? 1 : 0,
                  borderColor: '#97979740',
                },
              ]}>
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <Text style={[a.text_md, a.font_heavy, t.atoms.text]}>
                  <Trans>{SELET_TYPE?.[2]?.name}</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <RightIcon
                  style={[
                    !isVisibleExpand
                      ? {transform: 'rotate(90deg)', marginTop: 6}
                      : {transform: 'rotate(270deg)', marginBottom: 3},
                  ]}
                  color={t.palette.gray_10}
                />
              </View>
            </Pressable>
          </View>
          <View style={[a.pl_3xl]}>
            {isVisibleExpand && (
              <View style={[a.gap_md]}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    openModal({
                      name: 'post-select-tag-friend',
                      type: 'friend',
                      friends: postVisibleRange.visibleFriends,
                      setFriends: value => {
                        setPostVisibleRange({
                          ...postVisibleRange,
                          visibleType: 3,
                          visibleFriends: value,
                        })
                      },
                    })
                  }}
                  style={[
                    a.gap_xs,
                    a.pb_md,
                    {borderBottomWidth: 1, borderColor: '#97979740'},
                  ]}>
                  <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                    <FriendsIcon color={t.palette.gray_16} />
                    <Text
                      style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                      <Trans>Select Friends</Trans>
                    </Text>
                  </View>
                  <Text
                    style={[
                      a.text_sm,
                      a.mb_sm,
                      {color: t.palette.primary_active},
                    ]}
                    numberOfLines={2}>
                    {postVisibleRange.visibleFriends?.map(
                      item => `@${item.nickname} `,
                    )}
                  </Text>
                </Pressable>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    openModal({
                      name: 'select-circle-list',
                      list: postVisibleRange.visibleCircles,
                      setList: value => {
                        setPostVisibleRange({
                          ...postVisibleRange,
                          visibleType: 3,
                          visibleCircles: value,
                        })
                      },
                      visibleRange: postVisibleRange,
                    })
                  }}
                  style={[a.gap_xs, a.pt_md]}>
                  <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                    <CircleIcon width={16} style={{color: t.palette.gray_16}} />
                    <Text
                      style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                      <Trans>Select Circles</Trans>
                    </Text>
                  </View>
                  <Text
                    style={[
                      a.text_sm,
                      a.mt_sm,
                      {color: t.palette.primary_active},
                    ]}
                    numberOfLines={2}>
                    {postVisibleRange.visibleCircles
                      .filter(item => item.isSelected)
                      .map(item => `#${item.name}`)
                      .join(' ')}
                  </Text>
                </Pressable>
              </View>
            )}
          </View>
        </View>

        {/* Except */}
        <View style={[a.flex_col]}>
          <View style={[a.flex_row, a.align_center]}>
            <View style={[{width: 28}]}>
              {selectType === 4 && (
                <FontAwesomeIcon icon={'check'} color="#FF453A" />
              )}
            </View>
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                setPostVisibleRange({
                  ...postVisibleRange,
                  visibleType: 4,
                })
                setIsVisibleExpand(false)
                setIsUnvisibleExpand(!isUnvisibleExpand)
                setFirstFriends([])
              }}
              style={[
                a.flex_1,
                a.flex_row,
                a.align_center,
                a.justify_between,
                a.py_xl,
              ]}>
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <Text style={[a.text_md, a.font_heavy, t.atoms.text]}>
                  <Trans>{SELET_TYPE?.[3]?.name}</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <RightIcon
                  style={[
                    !isUnvisibleExpand
                      ? {transform: 'rotate(90deg)', marginTop: 6}
                      : {transform: 'rotate(270deg)', marginBottom: 3},
                  ]}
                  color={t.palette.gray_10}
                />
              </View>
            </Pressable>
          </View>
          <View style={[a.pl_3xl]}>
            {isUnvisibleExpand && (
              <View style={[a.gap_md]}>
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    openModal({
                      name: 'post-select-tag-friend',
                      type: 'tag',
                      tags: postVisibleRange?.unVisibleTags,
                      setTags: value => {
                        setPostVisibleRange({
                          ...postVisibleRange,
                          visibleType: 4,
                          unVisibleTags: value,
                        })
                      },
                    })
                  }}
                  style={[
                    a.gap_xs,
                    a.pb_md,
                    {borderBottomWidth: 1, borderColor: '#97979740'},
                  ]}>
                  <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                    <TagIcon color={t.palette.gray_16} />
                    <Text
                      style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                      <Trans>Select Tags</Trans>
                    </Text>
                  </View>
                  <Text style={[a.text_sm, {color: '#FF543D'}]}>
                    {postVisibleRange?.unVisibleTags
                      ?.map(item => item.name)
                      .join(', ')}
                  </Text>
                </Pressable>

                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    openModal({
                      name: 'post-select-tag-friend',
                      type: 'friend',
                      friends: postVisibleRange?.unVisibleFriends,
                      setFriends: value => {
                        setPostVisibleRange({
                          ...postVisibleRange,
                          visibleType: 4,
                          unVisibleFriends: value,
                        })
                      },
                    })
                  }}
                  style={[a.gap_xs, a.py_md]}>
                  <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                    <FriendsIcon color={t.palette.gray_16} />
                    <Text
                      style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                      <Trans>Select Friends</Trans>
                    </Text>
                  </View>
                  <Text style={[a.text_sm, {color: '#FF543D'}]}>
                    {postVisibleRange?.unVisibleFriends?.map(
                      item => `@${item.nickname} `,
                    )}
                  </Text>
                </Pressable>
              </View>
            )}
          </View>
        </View>
      </View>
      {error && error !== '' && (
        <View style={[a.flex_row, a.align_center, a.gap_sm, a.pl_3xl, a.mt_sm]}>
          <FontAwesomeIcon
            icon={'circle-exclamation'}
            color="#FF543D"
            size={16}
          />
          <Text style={[t.atoms.text, a.text_sm]}>{error}</Text>
        </View>
      )}
      <View style={[a.mt_4xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="primary"
          accessibilityLabel={_(msg`Spill`)}
          accessibilityHint=""
          label={_(msg`Spill`)}
          onPress={onConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Back`)}
          accessibilityHint=""
          label={_(msg`Back`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}
