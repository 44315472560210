import React, {useMemo} from 'react'
import {StyleSheet, View, ViewStyle} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'

import {UserAvatar} from '#/view/com/util/UserAvatar'
import {getAvatartConfig} from '#/view/com/util/UserTextAvatar'
import {SvgComponent as Mine} from '#/view/icons/Mine'
import {atoms as a, useTheme} from '#/alf'
import {Lock_Stroke2_Corner0_Rounded_Fill as Lock} from '#/components/icons/LockFill'
import {CircleItemProps} from '.'

type Props = {
  circle?: CircleItemProps
  size: number
  showFlag?: boolean
  style?: ViewStyle
}
export function CircleAvatar({circle, size, showFlag = true, style}: Props) {
  const t = useTheme()

  const isSystem = useMemo(() => {
    return circle?.createBy === 'system'
  }, [circle?.createBy])

  const filter: any = useMemo(() => {
    return {filter: isSystem ? 'blur(1px)' : 'none'}
  }, [isSystem])
  const avatarBg = getAvatartConfig(circle?.id || 'sipzdid')

  return (
    <View
      style={[
        styles.container,
        {width: size, height: size, borderRadius: size / 5},
        style,
      ]}>
      <LinearGradient
        colors={avatarBg}
        start={{x: 0.5, y: 0}}
        end={{x: 0.5, y: 1}}>
        {showFlag && circle?.isOwner && (
          <Mine style={[size <= 30 ? styles.smallMine : styles.mine]} />
        )}
        {showFlag && isSystem && (
          <Lock style={[styles.lock, t.atoms.text_inverted]} />
        )}
        <UserAvatar
          style={[filter, a.align_center, a.justify_center]}
          type="circle"
          avatar={circle?.avatar}
          size={size}
        />
      </LinearGradient>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    // borderRadius: 8,
    overflow: 'hidden',
  },
  mine: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  smallMine: {
    position: 'absolute',
    zIndex: 1,
    transform: 'scale(0.6)',
    right: -7,
    top: -7,
  },
  lock: {
    position: 'absolute',
    margin: 'auto',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
})
