import React, {memo, useCallback, useMemo} from 'react'
import {Image, Pressable, StyleSheet, View} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'
import {
  AppBskyEmbedImages,
  AppBskyEmbedRecord,
  AppBskyEmbedRecordWithMedia,
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {POST_TOMBSTONE, Shadow, usePostShadow} from '#/state/cache/post-shadow'
import {emitter} from '#/state/events'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useRequireAuth} from '#/state/session'
import {useThemePrefs} from '#/state/shell'
import {useComposerControls} from '#/state/shell/composer'
import {makeAnonymousProfileLink, makeProfileLink} from 'lib/routes/links'
import {sanitizeHandle} from 'lib/strings/handles'
import {precacheProfile} from 'state/queries/profile'
import {BigFigureHotIcon} from '#/view/icons/FeedIcons'
import {CircleMemberStackAvatar} from '#/view/screens/Circle/CircleMemberStackAvatar'
import {FONT_SIZE_CONFIG} from '#/screens/Settings/TextSizeSettings'
import {atoms as a, useTheme} from '#/alf'
import {RichText} from '#/components/RichText'
import {IGuarantors} from '../../post-thread/PostStakeDonateItem'
import {getIsOpreration} from '../../util/access/OperationUtil'
import {Link} from '../../util/Link'
import {BigFigurePostCtrls} from '../../util/post-ctrls/BigFigurePostCtrls'
import {Text} from '../../util/text/Text'
import {BigFigurePostEmbeds} from '../BigFigure/BigFigurePostEmbed'
import {FeedItemProps} from '../FeedItem'
import {TipIcon} from '../OperationFeedItem'
import {useSwipeKey} from '../SwipeProvider'
import {TargetDisplay} from '../TargetDisplay'
import {useTranslationWidget} from '../TranslationWidget/TranslationWidget'
import {BigFigurePostMeta} from './BigFigurePostMeta'
import {useIsMustSip} from './BigFigureProvider'

export type BigFigureSizeMode = 'larger' | 'middle' | 'small'
export function BigFigureCardItem({
  post,
  record,
  reason,
  feedContext,
  moderation,
  parentAuthor,
  showReplyTo,
  isThreadChild,
  isThreadLastChild,
  isThreadParent,
  isParentBlocked,
  isOperation,
  index,
}: FeedItemProps & {
  post: AppBskyFeedDefs.PostView
  index?: number
}): React.ReactNode {
  const postShadowed = usePostShadow(post)
  const {translateTitle, richText, hasMore, showAutoTranslate} =
    useTranslationWidget({
      record,
      shouldTrimArticle: true,
      post,
    })

  if (postShadowed === POST_TOMBSTONE) {
    return null
  }
  if (record.operation) {
  }
  if (richText && moderation) {
    return (
      <BigFigureCard
        key={postShadowed.uri}
        post={postShadowed}
        record={{...record, title: translateTitle || record.title}}
        reason={reason}
        feedContext={feedContext}
        richText={richText}
        parentAuthor={parentAuthor}
        showReplyTo={showReplyTo}
        moderation={moderation}
        isThreadChild={isThreadChild}
        isThreadLastChild={isThreadLastChild}
        isThreadParent={isThreadParent}
        isParentBlocked={isParentBlocked}
        hasMore={hasMore}
        isOperation={isOperation}
        showAutoTranslate={showAutoTranslate}
        index={index}
      />
    )
  }
  return null
}

export function BigFigureCard({
  post,
  record,
  feedContext,
  richText,
  moderation,
  // parentAuthor,
  // showReplyTo,
  // isThreadChild,
  // isThreadLastChild,
  // isThreadParent,
  // isParentBlocked,
  // hasMore,
  isOperation,
  showAutoTranslate,
  index,
}: FeedItemProps & {
  richText: RichTextAPI
  hasMore: boolean
  showAutoTranslate: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
  index?: number
}) {
  const queryClient = useQueryClient()
  const {openComposer} = useComposerControls()
  const t = useTheme()
  const {isDesktop} = useWebMediaQueries()
  const isMustSip = useIsMustSip()

  // @ts-ignore
  const isAnonymousPost = post?.record?.hideAuthor === 'true' ? true : false
  const href = useMemo(() => {
    const urip = new AtUri(post.uri)
    if (isAnonymousPost) {
      return makeAnonymousProfileLink(post.author, 'post', urip.rkey)
    } else {
      return makeProfileLink(post.author, 'post', urip.rkey)
    }
  }, [post.uri, post.author, isAnonymousPost])
  const {sendInteraction} = useFeedFeedbackContext()

  const onPressReply = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionReply',
      feedContext,
    })
    openComposer({
      replyTo: {
        uri: post.uri,
        cid: post.cid,
        text: record.text || '',
        author: post.author,
        embed: post.embed,
        moderation,
      },
    })
  }, [post, record, openComposer, moderation, sendInteraction, feedContext])

  const onBeforePress = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughItem',
      feedContext,
    })
    precacheProfile(queryClient, post.author)
  }, [queryClient, post, sendInteraction, feedContext])

  const outerStyles = [
    isDesktop ? styles.outer : a.px_lg,
    // {
    //   paddingBottom:
    //     isThreadLastChild || (!isThreadChild && !isThreadParent)
    //       ? 8
    //       : undefined,
    // },
  ]

  let sizeMode: BigFigureSizeMode = 'small'
  const maxLikeNum = Number(post?.maxLikeNumThreshold ?? 0)
  const minLikeNum = Number(post?.minLikeNumThreshold ?? 0)
  if (post.likeCount) {
    if (post.likeCount >= maxLikeNum) {
      sizeMode = 'larger'
    } else if (post.likeCount < maxLikeNum && post.likeCount >= minLikeNum) {
      sizeMode = 'middle'
    }
  }

  const requireAuth = useRequireAuth()
  const navigation = useNavigation<NavigationProp>()
  const guarantors = React.useMemo(
    () => (post?.guarantors as Array<IGuarantors>) || [],
    [post?.guarantors],
  )
  const onPressGuarantors = React.useCallback(() => {
    requireAuth(() => {
      navigation.navigate('BetList', {
        did: post.author.did,
        dataType: 'guarantors',
        rkey: new AtUri(post.uri).rkey,
      })
    })
  }, [navigation, requireAuth, post?.uri, post?.author?.did])

  const isOperater = getIsOpreration(post.author.did)

  const onOpenEmbed = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughEmbed',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])
  const targets = record?.finEntities || []

  const mustSipBgStyles =
    t.name === 'dark'
      ? ['#191B2F', '#282A3FB2', '#15162170', '#20222F']
      : ['#fff', '#fff']

  const swipeKey = useSwipeKey()
  const onPressLink = useCallback(() => {
    const urip = new AtUri(post.uri)
    const postUri = makeRecordUri(
      post.author.handle,
      'app.bsky.feed.post',
      urip.rkey,
    )
    emitter.emit('setSwipeList', {
      from: swipeKey,
      postUri,
    })
    navigation.navigate('PostThread', {
      name: post.author.handle,
      rkey: urip.rkey,
    })
  }, [navigation, post.author.handle, post.uri, swipeKey])

  return (
    <Link
      testID={`feedItem-by-${post.author.handle}`}
      style={[outerStyles, {paddingTop: 16, flexShrink: 1}]}
      href={href}
      noFeedback
      accessible={false}
      onPress={onPressLink}
      onBeforePress={onBeforePress}
      dataSet={{feedContext}}>
      <LinearGradient
        colors={
          isMustSip
            ? mustSipBgStyles
            : [
                t.atoms.big_figure_bg.backgroundColor,
                t.atoms.big_figure_bg.backgroundColor,
              ]
        }
        start={{x: 0, y: 0}}
        end={{x: 1, y: 1}}
        style={{borderRadius: 30}}>
        <View
          style={[
            a.flex_col,
            a.p_lg,
            t.atoms.input_border,
            {borderWidth: 0, borderRadius: 30},
          ]}>
          <BigFigurePostHeaderContent
            post={post}
            record={record}
            moderation={moderation}
            sizeMode={sizeMode}
            index={index}
          />
          <View style={[styles.layout]}>
            <View style={styles.layoutContent}>
              {/* content */}
              <BigFigurePostContent
                moderation={moderation}
                richText={richText}
                postEmbed={post.embed}
                postAuthor={post.author}
                onOpenEmbed={onOpenEmbed}
                sizeMode={sizeMode}
                record={record}
              />
              <View
                style={[
                  a.flex_row,
                  a.align_center,
                  a.justify_between,
                  a.mb_sm,
                ]}>
                {/* Spill about */}
                {targets.length > 0 && (
                  <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                    <TargetDisplay
                      targets={[targets[0]]}
                      isFromFeed
                      previewMode
                    />
                    {targets.length > 1 ? (
                      <Text
                        style={[
                          a.text_sm,
                          a.font_heavy,
                          t.atoms.text,
                          {color: '#696969'},
                        ]}>
                        +{targets.length - 1}
                      </Text>
                    ) : (
                      ''
                    )}
                  </View>
                )}
                {/* guarantors */}
                {!!guarantors.length && (
                  <Pressable
                    accessibilityRole="button"
                    style={[a.flex_row, a.align_center, a.gap_xs]}
                    onPress={onPressGuarantors}>
                    <CircleMemberStackAvatar
                      data={
                        guarantors?.map(m => {
                          return {
                            id: m?.did,
                            avatar: m?.avatar,
                          }
                        }) ?? []
                      }
                      size={24}
                      emptyIcon={<></>}
                      bgColor={t.atoms.bg.backgroundColor}
                      type="user"
                    />
                    <Text style={[a.text_xs, a.font_heavy, {color: '#696969'}]}>
                      {guarantors.length} <Trans>Guarantors</Trans>
                    </Text>
                  </Pressable>
                )}
              </View>

              {(isOperation || isOperater) && (
                <View
                  style={[
                    a.flex_row,
                    a.align_center,
                    a.gap_sm,
                    !isOperation && isOperater ? a.mb_lg : {},
                  ]}>
                  <View
                    style={[
                      a.flex_row,
                      a.align_center,
                      a.justify_center,
                      {width: 24, height: 24, backgroundColor: '#C28C00'},
                      a.rounded_full,
                    ]}>
                    <TipIcon {...t.atoms.text} size={14} />
                  </View>
                  <Text style={[t.atoms.text, a.font_heavy]}>
                    <Trans>Topic</Trans>
                  </Text>
                </View>
              )}

              {isOperation ? null : (
                <BigFigurePostCtrls
                  post={post}
                  record={record}
                  richText={richText}
                  onPressReply={onPressReply}
                  logContext="FeedItem"
                  feedContext={feedContext}
                  showAutoTranslate={showAutoTranslate}
                  isFromFeed={true}
                />
              )}
            </View>
          </View>
        </View>
      </LinearGradient>
    </Link>
  )
}

let BigFigurePostHeaderContent = ({
  post,
  record,
  moderation,
  sizeMode,
  index,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  moderation: ModerationDecision
  sizeMode: BigFigureSizeMode
  index?: number
}): React.ReactNode => {
  const t = useTheme()
  const isAnonymousPost = record?.hideAuthor === 'true' ? true : false
  const isMustSip = useIsMustSip()

  const {fontSizeMode} = useThemePrefs()
  const cardTitleFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.feed].feed.public.title
  }, [fontSizeMode])

  return (
    <View style={[a.relative]}>
      <BigFigurePostMeta
        author={post.author}
        moderation={moderation}
        showAvatar
        authorHasWarning={false}
        isQuoted={AppBskyEmbedRecord.isView(post.embed)}
        isAnonymous={isAnonymousPost}
      />
      {/* Hot Icon */}
      {!isMustSip && sizeMode === 'larger' && (
        <View style={[a.absolute, {right: -16, top: -16}]}>
          <BigFigureHotIcon />
        </View>
      )}
      {/* Top1 */}
      {isMustSip && index === 0 && (
        <View style={[a.absolute, {right: 0, top: -16}]}>
          <Image
            source={require('../../../../../assets/imgs/must_sip_top_1.png')}
            style={{width: 90, height: 40}}
            accessibilityIgnoresInvertColors
          />
        </View>
      )}
      {/* Top2 */}
      {isMustSip && index === 1 && (
        <View style={[a.absolute, {right: 0, top: -16}]}>
          <Image
            source={require('../../../../../assets/imgs/must_sip_top_2.png')}
            style={{width: 90, height: 40}}
            accessibilityIgnoresInvertColors
          />
        </View>
      )}
      {/* Top3 */}
      {isMustSip && index === 2 && (
        <View style={[a.absolute, {right: 0, top: -16}]}>
          <Image
            source={require('../../../../../assets/imgs/must_sip_top_3.png')}
            style={{width: 90, height: 40}}
            accessibilityIgnoresInvertColors
          />
        </View>
      )}
      {/* Title */}
      {record.title && (
        <Text
          style={[
            a.mt_lg,
            t.atoms.text,
            sizeMode === 'larger' && styles.largerTitle,
            sizeMode === 'middle' && styles.middleTitle,
            sizeMode === 'small' && [
              styles.smallTitle,
              {fontSize: cardTitleFontSize},
            ],
            {backgroundColor: 'transparent', fontFamily: 'BigFigure'},
          ]}
          numberOfLines={2}>
          {record.title}
        </Text>
      )}
    </View>
  )
}
BigFigurePostHeaderContent = memo(BigFigurePostHeaderContent)
export {BigFigurePostHeaderContent}

let BigFigurePostContent = ({
  moderation,
  richText,
  postEmbed,
  postAuthor,
  onOpenEmbed,
  sizeMode,
  record,
}: {
  moderation?: ModerationDecision
  richText?: RichTextAPI
  postEmbed: AppBskyFeedDefs.PostView['embed']
  postAuthor: AppBskyFeedDefs.PostView['author']
  onOpenEmbed?: () => void
  sizeMode: BigFigureSizeMode
  record?: AppBskyFeedPost.Record
}): React.ReactNode => {
  const t = useTheme()
  const hasImage = AppBskyEmbedImages.isView(postEmbed)
  const isQuote =
    AppBskyEmbedRecord.isView(postEmbed) ||
    AppBskyEmbedRecordWithMedia.isView(postEmbed)

  const {fontSizeMode} = useThemePrefs()
  const cardTextFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.feed].feed.public.text
  }, [fontSizeMode])

  return (
    <View style={[a.flex_col]}>
      <View
        style={[
          sizeMode === 'larger'
            ? [a.flex_col]
            : [a.align_center, a.flex_row_reverse, a.gap_md, a.justify_between],
        ]}>
        {/* Image */}
        {postEmbed && hasImage ? (
          <View
            style={[
              sizeMode === 'larger'
                ? [a.pt_lg, a.flex_1, {maxHeight: 350, overflow: 'hidden'}]
                : [a.flex_1, {maxHeight: 60, overflow: 'hidden'}],
            ]}>
            <BigFigurePostEmbeds
              embed={postEmbed}
              record={record}
              moderation={moderation}
              onOpen={onOpenEmbed}
            />
          </View>
        ) : postEmbed && AppBskyEmbedRecordWithMedia.isView(postEmbed) ? (
          <View style={[a.flex_1, a.mb_md]}>
            <BigFigurePostEmbeds
              embed={postEmbed?.media}
              moderation={moderation}
              onOpen={onOpenEmbed}
              sizeMode={sizeMode}
            />
          </View>
        ) : null}

        {richText && richText.text ? (
          <View
            style={[
              styles.postTextContainer,
              sizeMode === 'larger' ? {} : {flex: 3},
            ]}>
            <RichText
              enableTags
              testID="postText"
              value={richText}
              numberOfLines={3}
              style={[
                a.flex_1,
                // a.text_md,
                t.atoms.post_content_feed,
                {fontSize: cardTextFontSize},
              ]}
              authorHandle={sanitizeHandle(postAuthor.handle)}
            />
          </View>
        ) : undefined}
      </View>
      {/* quote post */}
      {postEmbed && isQuote ? (
        <View style={[a.w_full, a.overflow_hidden, a.mb_md]}>
          <BigFigurePostEmbeds
            embed={postEmbed}
            moderation={moderation}
            onOpen={onOpenEmbed}
            sizeMode={sizeMode}
          />
        </View>
      ) : null}
    </View>
  )
}
BigFigurePostContent = memo(BigFigurePostContent)
export {BigFigurePostContent}

const styles = StyleSheet.create({
  outer: {
    paddingHorizontal: 8,
    // @ts-ignore web only -prf
    cursor: 'pointer',
  },
  largerTitle: {
    // fontSize: 32,
    fontSize: 26,
    fontWeight: '900',
  },
  middleTitle: {
    fontSize: 26,
    fontWeight: '900',
  },
  smallTitle: {
    fontSize: 20,
    fontWeight: '900',
  },
  layout: {
    flexDirection: 'row',
    // marginTop: 1,
    // gap: 10,
  },
  layoutAvi: {
    position: 'relative',
    zIndex: 999,
  },
  layoutContent: {
    position: 'relative',
    flex: 1,
    zIndex: 0,
  },
  postTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 16,
    overflow: 'hidden',
  },
  embed: {
    marginBottom: 6,
  },
  includeReason: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 2,
    marginLeft: -18,
  },
})
