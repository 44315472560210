import React, {useCallback} from 'react'
import {LayoutAnimation} from 'react-native'
import {
  ComAtprotoServerCreateAccount,
  ComAtprotoServerDescribeServer,
} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
// import * as EmailValidator from 'email-validator'
import {ethers} from 'ethers'

import {DEFAULT_SERVICE, SDN_SERVICE} from '#/lib/constants'
import {decrementRefCount} from '#/lib/hooks/useWebBodyScrollLock'
import {cleanError} from '#/lib/strings/errors'
// import {createFullHandle} from '#/lib/strings/handles'
import {getAge} from '#/lib/strings/time'
import {logger} from '#/logger'
import {isWeb} from '#/platform/detection'
import {useListMetadataMutation} from '#/state/queries/list'
import {useSessionApi} from '#/state/session'
import {SendingNetworkAgent} from '#/state/session/sdn-agent'
// import { server } from '../../../metro.config'

export type ServiceDescription = ComAtprotoServerDescribeServer.OutputSchema

const DEFAULT_DATE = new Date(Date.now() - 60e3 * 60 * 24 * 365 * 20) // default to 20 years ago

export enum SignupStep {
  INFO,
  SAVE,
  CAPTCHA,
}

export type SignupState = {
  hasPrev: boolean
  activeStep: SignupStep

  serviceUrl: string
  serviceDescription?: ServiceDescription
  userDomain: string
  dateOfBirth: Date
  email: string
  password: string
  inviteCode: string
  nickname: string
  handle: string
  wallet: ethers.HDNodeWallet
  address: string
  tgAuthResult?: string

  error: string
  isLoading: boolean
}

export type SignupAction =
  | {type: 'prev'}
  | {type: 'next'}
  | {type: 'finish'}
  | {type: 'setStep'; value: SignupStep}
  | {type: 'setWallet'; mnemonic?: string}
  | {type: 'setServiceUrl'; value: string}
  | {type: 'setServiceDescription'; value: ServiceDescription | undefined}
  | {type: 'setEmail'; value: string}
  | {type: 'setAddress'; value: string}
  | {type: 'setPassword'; value: string}
  | {type: 'setDateOfBirth'; value: Date}
  | {type: 'setInviteCode'; value: string}
  | {type: 'setNickname'; value: string}
  | {type: 'setHandle'; value: string}
  | {type: 'setVerificationCode'; value: string}
  | {type: 'setError'; value: string}
  | {type: 'setIsLoading'; value: boolean}

export const initialState: SignupState = {
  hasPrev: false,
  activeStep: SignupStep.INFO,

  serviceUrl: DEFAULT_SERVICE,
  serviceDescription: undefined,
  userDomain: '',
  dateOfBirth: DEFAULT_DATE,
  email: 'gangls@test.com',
  password: '123123',
  nickname: '',
  handle: '',
  inviteCode: '',
  wallet: ethers.Wallet.createRandom(),
  address: '',

  error: '',
  isLoading: false,
}

export function is13(date: Date) {
  return getAge(date) >= 13
}

export function is18(date: Date) {
  return getAge(date) >= 18
}

export function reducer(s: SignupState, a: SignupAction): SignupState {
  let next = {...s}

  switch (a.type) {
    case 'prev': {
      if (s.activeStep !== SignupStep.INFO) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        next.activeStep--
        next.error = ''
      }
      break
    }
    case 'next': {
      if (s.activeStep !== SignupStep.CAPTCHA) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        next.activeStep++
        next.error = ''
      }
      break
    }
    case 'setStep': {
      next.activeStep = a.value
      break
    }
    case 'setWallet': {
      if (a.mnemonic) {
        next.wallet = ethers.Wallet.fromPhrase(a.mnemonic)
      } else {
        next.wallet = ethers.Wallet.createRandom()
      }
      break
    }
    case 'setServiceUrl': {
      next.serviceUrl = a.value
      break
    }
    case 'setServiceDescription': {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)

      next.serviceDescription = a.value
      next.userDomain = a.value?.availableUserDomains[0] ?? ''
      next.isLoading = false
      break
    }

    case 'setAddress': {
      next.address = a.value
      break
    }
    case 'setEmail': {
      next.email = a.value
      break
    }
    case 'setPassword': {
      next.password = a.value
      break
    }
    case 'setDateOfBirth': {
      next.dateOfBirth = a.value
      break
    }
    case 'setInviteCode': {
      next.inviteCode = a.value
      break
    }
    case 'setNickname': {
      next.nickname = a.value
      break
    }
    case 'setHandle': {
      next.handle = a.value
      break
    }
    case 'setIsLoading': {
      next.isLoading = a.value
      break
    }
    case 'setError': {
      next.error = a.value
      break
    }
  }

  next.hasPrev = next.activeStep !== SignupStep.INFO

  logger.debug('signup', next)

  if (s.activeStep !== next.activeStep) {
    logger.debug('signup: step changed', {activeStep: next.activeStep})
  }

  return next
}

interface IContext {
  state: SignupState
  dispatch: React.Dispatch<SignupAction>
}
export const SignupContext = React.createContext<IContext>({} as IContext)
export const useSignupContext = () => React.useContext(SignupContext)

export function useSubmitSignup({
  state,
  dispatch,
}: {
  state: SignupState
  dispatch: (action: SignupAction) => void
}) {
  const {_} = useLingui()
  const {signIn} = useSessionApi()
  const listMetadataMutation = useListMetadataMutation()
  // const agent = useAgent();

  return useCallback(
    async (_verificationCode?: string) => {
      if (!state.handle) {
        dispatch({type: 'setStep', value: SignupStep.INFO})
        dispatch({
          type: 'setError',
          value: _(msg`Please choose your handle.`),
        })
        return false
      }
      // if (!state.inviteCode) {
      //   dispatch({type: 'setStep', value: SignupStep.INFO})
      //   dispatch({
      //     type: 'setError',
      //     value: _(msg`Please enter your invite code.`),
      //   })
      //   return false
      // }
      dispatch({type: 'setIsLoading', value: true})
      dispatch({type: 'setError', value: ''})

      try {
        const agent = new SendingNetworkAgent({
          state,
          sdnServerUrl: SDN_SERVICE,
        })
        let data = await agent.login()
        data = {
          ...data,
          name: state.nickname,
          inviteCode: state.inviteCode,
          mnemonic: state.wallet.mnemonic?.phrase,
        }
        if (state.tgAuthResult) {
          data.tgLoginType = 1
          data.tgUserData = state.tgAuthResult
        }
        await signIn(
          {
            service: state.serviceUrl,
            data,
          },
          'LoginForm',
        )

        await listMetadataMutation.mutateAsync({
          name: state.nickname,
          uri: '',
          description: '',
          descriptionFacets: undefined,
          avatar: undefined,
        })

        return true
      } catch (e: any) {
        let errMsg = e.toString()
        if (e instanceof ComAtprotoServerCreateAccount.InvalidInviteCodeError) {
          dispatch({
            type: 'setError',
            value: _(
              msg`Invite code not accepted. Check that you input it correctly and try again.`,
            ),
          })
          dispatch({type: 'setStep', value: SignupStep.INFO})
          return false
        }

        const error = cleanError(errMsg)
        const isHandleError = error.toLowerCase().includes('handle')

        dispatch({type: 'setIsLoading', value: false})
        dispatch({type: 'setError', value: error})
        dispatch({type: 'setStep', value: isHandleError ? 0 : 1})

        logger.error('Signup Flow Error', {
          errorMessage: error,
          registrationHandle: state.handle,
        })
      } finally {
        dispatch({type: 'setIsLoading', value: false})
        if (isWeb) {
          setTimeout(() => {
            decrementRefCount(true)
          }, 3000)
        }
      }
    },
    [state, dispatch, _, signIn, listMetadataMutation],
  )
}
