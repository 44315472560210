import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Pressable, View} from 'react-native'
import {ScrollView} from 'react-native-gesture-handler'
import * as Progress from 'react-native-progress'
import {AppBskyFeedDefs, AtUri} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {logEvent} from '#/lib/statsig/statsig'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {useModerationOpts} from '#/state/preferences/moderation-opts'
import {useGetPopularFeedsQuery} from '#/state/queries/feed'
import {FeedDescriptor, RQKEY} from '#/state/queries/post-feed'
import {
  checkSyncTelegramContacts,
  useProfilesQuery,
} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {useProgressGuide} from '#/state/shell/progress-guide'
import * as userActionHistory from '#/state/userActionHistory'
import {SeenPost} from '#/state/userActionHistory'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {
  useTelegramClient,
  useTelegramLink,
} from '#/view/com/modals/TelegramModal/hooks'
import {Bevel} from '#/view/com/posts/MemoCard'
import {useTgStore} from '#/view/com/util/sdlStore/TgStore'
import {LogoYellow} from '#/view/icons/LogoYellow'
import {InviteFriendIcon} from '#/view/icons/SettingIcons'
import {atoms as a, useBreakpoints, useTheme, ViewStyleProp, web} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {Button, ButtonIcon} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import * as FeedCard from '#/components/FeedCard'
import {ArrowRight_Stroke2_Corner0_Rounded as Arrow} from '#/components/icons/Arrow'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {DotGrid_Stroke2_Corner0_Rounded as DotsHorizontal} from '#/components/icons/DotGrid'
import {Hashtag_Stroke2_Corner0_Rounded as Hashtag} from '#/components/icons/Hashtag'
import {PersonPlus_Stroke2_Corner0_Rounded as Person} from '#/components/icons/Person'
import {Telegram} from '#/components/icons/Telegram'
import {WarninCircle} from '#/components/icons/Warning'
import {InlineLinkText} from '#/components/Link'
import {Loader} from '#/components/Loader'
import * as ProfileCard from '#/components/ProfileCard'
import {Text} from '#/components/Typography'
import {ProgressGuideList} from './ProgressGuide/List'

const MOBILE_CARD_WIDTH = 300

function CardOuter({
  children,
  style,
}: {children: React.ReactNode | React.ReactNode[]} & ViewStyleProp) {
  const t = useTheme()
  const {gtMobile} = useBreakpoints()
  return (
    <View
      style={[
        a.w_full,
        a.p_lg,
        a.rounded_md,
        a.border,
        t.atoms.bg,
        t.atoms.border_contrast_low,
        !gtMobile && {
          width: MOBILE_CARD_WIDTH,
        },
        style,
      ]}>
      {children}
    </View>
  )
}

export function SuggestedFollowPlaceholder() {
  const t = useTheme()
  return (
    <CardOuter style={[a.gap_sm, t.atoms.border_contrast_low]}>
      <ProfileCard.Header>
        <ProfileCard.AvatarPlaceholder />
      </ProfileCard.Header>

      <View style={[a.py_xs]}>
        <ProfileCard.NameAndHandlePlaceholder />
      </View>

      <ProfileCard.DescriptionPlaceholder />
    </CardOuter>
  )
}

export function SuggestedFeedsCardPlaceholder() {
  const t = useTheme()
  return (
    <CardOuter style={[a.gap_sm, t.atoms.border_contrast_low]}>
      <FeedCard.Header>
        <FeedCard.AvatarPlaceholder />
        <FeedCard.TitleAndBylinePlaceholder creator />
      </FeedCard.Header>

      <FeedCard.DescriptionPlaceholder />
    </CardOuter>
  )
}

function getRank(seenPost: SeenPost): string {
  let tier: string
  if (seenPost.feedContext === 'popfriends') {
    tier = 'a'
  } else if (seenPost.feedContext?.startsWith('cluster')) {
    tier = 'b'
  } else if (seenPost.feedContext === 'popcluster') {
    tier = 'c'
  } else if (seenPost.feedContext?.startsWith('ntpc')) {
    tier = 'd'
  } else if (seenPost.feedContext?.startsWith('t-')) {
    tier = 'e'
  } else if (seenPost.feedContext === 'nettop') {
    tier = 'f'
  } else {
    tier = 'g'
  }
  let score = Math.round(
    Math.log(
      1 + seenPost.likeCount + seenPost.repostCount + seenPost.replyCount,
    ),
  )
  if (seenPost.isFollowedBy || Math.random() > 0.9) {
    score *= 2
  }
  const rank = 100 - score
  return `${tier}-${rank}`
}

function sortSeenPosts(postA: SeenPost, postB: SeenPost): 0 | 1 | -1 {
  const rankA = getRank(postA)
  const rankB = getRank(postB)
  // Yes, we're comparing strings here.
  // The "larger" string means a worse rank.
  if (rankA > rankB) {
    return 1
  } else if (rankA < rankB) {
    return -1
  } else {
    return 0
  }
}

function useExperimentalSuggestedUsersQuery() {
  const {currentAccount} = useSession()
  const userActionSnapshot = userActionHistory.useActionHistorySnapshot()
  const dids = React.useMemo(() => {
    const {likes, follows, followSuggestions, seen} = userActionSnapshot
    const likeDids = likes
      .map(l => new AtUri(l))
      .map(uri => uri.host)
      .filter(did => !follows.includes(did))
    let suggestedDids: string[] = []
    if (followSuggestions.length > 0) {
      suggestedDids = [
        // It's ok if these will pick the same item (weighed by its frequency)
        followSuggestions[Math.floor(Math.random() * followSuggestions.length)],
        followSuggestions[Math.floor(Math.random() * followSuggestions.length)],
        followSuggestions[Math.floor(Math.random() * followSuggestions.length)],
        followSuggestions[Math.floor(Math.random() * followSuggestions.length)],
      ]
    }
    const seenDids = seen
      .sort(sortSeenPosts)
      .map(l => new AtUri(l.uri))
      .map(uri => uri.host)
    return [...new Set([...suggestedDids, ...likeDids, ...seenDids])].filter(
      did => did !== currentAccount?.did,
    )
  }, [userActionSnapshot, currentAccount])
  const {data, isLoading, error} = useProfilesQuery({
    handles: dids.slice(0, 16),
  })

  const profiles = data
    ? data.profiles.filter(profile => {
        return !profile.viewer?.following
      })
    : []

  return {
    isLoading,
    error,
    profiles: profiles.slice(0, 6),
  }
}

export function SuggestedFollows() {
  const t = useTheme()
  const {_} = useLingui()
  const {
    isLoading: isSuggestionsLoading,
    profiles,
    error,
  } = useExperimentalSuggestedUsersQuery()
  const moderationOpts = useModerationOpts()
  const navigation = useNavigation<NavigationProp>()
  const {gtMobile} = useBreakpoints()
  const isLoading = isSuggestionsLoading || !moderationOpts
  const maxLength = gtMobile ? 4 : 6

  const content = isLoading ? (
    Array(maxLength)
      .fill(0)
      .map((_, i) => (
        <View
          key={i}
          style={[gtMobile && web([a.flex_0, {width: 'calc(50% - 6px)'}])]}>
          <SuggestedFollowPlaceholder />
        </View>
      ))
  ) : error || !profiles.length ? null : (
    <>
      {profiles.slice(0, maxLength).map(profile => (
        <ProfileCard.Link
          key={profile.did}
          profile={profile}
          onPress={() => {
            logEvent('feed:interstitial:profileCard:press', {})
          }}
          style={[
            a.flex_1,
            gtMobile && web([a.flex_0, {width: 'calc(50% - 6px)'}]),
          ]}>
          {({hovered, pressed}) => (
            <CardOuter
              style={[
                a.flex_1,
                (hovered || pressed) && t.atoms.border_contrast_high,
              ]}>
              <ProfileCard.Outer>
                <ProfileCard.Header>
                  <ProfileCard.Avatar
                    profile={profile}
                    moderationOpts={moderationOpts}
                  />
                  <ProfileCard.NameAndHandle
                    profile={profile}
                    moderationOpts={moderationOpts}
                  />
                  <ProfileCard.FollowButton
                    profile={profile}
                    moderationOpts={moderationOpts}
                    logContext="FeedInterstitial"
                    color="secondary_inverted"
                    shape="round"
                  />
                </ProfileCard.Header>
                <ProfileCard.Description profile={profile} />
              </ProfileCard.Outer>
            </CardOuter>
          )}
        </ProfileCard.Link>
      ))}
    </>
  )

  if (error || (!isLoading && profiles.length < 4)) {
    logger.debug(`Not enough profiles to show suggested follows`)
    return null
  }

  return (
    <View
      style={[a.border_t, t.atoms.border_contrast_low, t.atoms.bg_contrast_25]}>
      <View style={[a.pt_2xl, a.px_lg, a.flex_row, a.pb_xs]}>
        <Text
          style={[
            a.flex_1,
            a.text_lg,
            a.font_bold,
            t.atoms.text_contrast_medium,
          ]}>
          <Trans>Suggested for you</Trans>
        </Text>
        <Person fill={t.atoms.text_contrast_low.color} />
      </View>

      {gtMobile ? (
        <View style={[a.flex_1, a.px_lg, a.pt_md, a.pb_xl, a.gap_md]}>
          <View style={[a.flex_1, a.flex_row, a.flex_wrap, a.gap_md]}>
            {content}
          </View>

          <View
            style={[
              a.flex_row,
              a.justify_end,
              a.align_center,
              a.pt_xs,
              a.gap_md,
            ]}>
            <InlineLinkText
              label={_(msg`Browse more suggestions`)}
              to="/search"
              style={[t.atoms.text_contrast_medium]}>
              <Trans>Browse more suggestions</Trans>
            </InlineLinkText>
            <Arrow size="sm" fill={t.atoms.text_contrast_medium.color} />
          </View>
        </View>
      ) : (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          snapToInterval={MOBILE_CARD_WIDTH + a.gap_md.gap}
          decelerationRate="fast">
          <View style={[a.px_lg, a.pt_md, a.pb_xl, a.flex_row, a.gap_md]}>
            {content}

            <Button
              label={_(msg`Browse more accounts on the Explore page`)}
              onPress={() => {
                navigation.navigate('SearchTab')
              }}>
              <CardOuter style={[a.flex_1, {borderWidth: 0}]}>
                <View style={[a.flex_1, a.justify_center]}>
                  <View style={[a.flex_row, a.px_lg]}>
                    <Text style={[a.pr_xl, a.flex_1, a.leading_snug]}>
                      <Trans>Browse more suggestions on the Explore page</Trans>
                    </Text>

                    <Arrow size="xl" />
                  </View>
                </View>
              </CardOuter>
            </Button>
          </View>
        </ScrollView>
      )}
    </View>
  )
}

export function SuggestedFeeds() {
  const numFeedsToDisplay = 3
  const t = useTheme()
  const {_} = useLingui()
  const {data, isLoading, error} = useGetPopularFeedsQuery({
    limit: numFeedsToDisplay,
  })
  const navigation = useNavigation<NavigationProp>()
  const {gtMobile} = useBreakpoints()

  const feeds = React.useMemo(() => {
    const items: AppBskyFeedDefs.GeneratorView[] = []

    if (!data) return items

    for (const page of data.pages) {
      for (const feed of page.feeds) {
        items.push(feed)
      }
    }

    return items
  }, [data])

  const content = isLoading ? (
    Array(numFeedsToDisplay)
      .fill(0)
      .map((_, i) => <SuggestedFeedsCardPlaceholder key={i} />)
  ) : error || !feeds ? null : (
    <>
      {feeds.slice(0, numFeedsToDisplay).map(feed => (
        <FeedCard.Link
          key={feed.uri}
          view={feed}
          onPress={() => {
            logEvent('feed:interstitial:feedCard:press', {})
          }}>
          {({hovered, pressed}) => (
            <CardOuter
              style={[
                a.flex_1,
                (hovered || pressed) && t.atoms.border_contrast_high,
              ]}>
              <FeedCard.Outer>
                <FeedCard.Header>
                  <FeedCard.Avatar src={feed.avatar} />
                  <FeedCard.TitleAndByline
                    title={feed.displayName}
                    creator={feed.creator}
                  />
                </FeedCard.Header>
                <FeedCard.Description
                  description={feed.description}
                  numberOfLines={3}
                />
              </FeedCard.Outer>
            </CardOuter>
          )}
        </FeedCard.Link>
      ))}
    </>
  )

  return error ? null : (
    <View
      style={[a.border_t, t.atoms.border_contrast_low, t.atoms.bg_contrast_25]}>
      <View style={[a.pt_2xl, a.px_lg, a.flex_row, a.pb_xs]}>
        <Text
          style={[
            a.flex_1,
            a.text_lg,
            a.font_bold,
            t.atoms.text_contrast_medium,
          ]}>
          <Trans>Some other feeds you might like</Trans>
        </Text>
        <Hashtag fill={t.atoms.text_contrast_low.color} />
      </View>

      {gtMobile ? (
        <View style={[a.flex_1, a.px_lg, a.pt_md, a.pb_xl, a.gap_md]}>
          {content}

          <View
            style={[
              a.flex_row,
              a.justify_end,
              a.align_center,
              a.pt_xs,
              a.gap_md,
            ]}>
            <InlineLinkText
              label={_(msg`Browse more suggestions`)}
              to="/search"
              style={[t.atoms.text_contrast_medium]}>
              <Trans>Browse more suggestions</Trans>
            </InlineLinkText>
            <Arrow size="sm" fill={t.atoms.text_contrast_medium.color} />
          </View>
        </View>
      ) : (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          snapToInterval={MOBILE_CARD_WIDTH + a.gap_md.gap}
          decelerationRate="fast">
          <View style={[a.px_lg, a.pt_md, a.pb_xl, a.flex_row, a.gap_md]}>
            {content}

            <Button
              label={_(msg`Browse more feeds on the Explore page`)}
              onPress={() => {
                navigation.navigate('SearchTab')
              }}
              style={[a.flex_col]}>
              <CardOuter style={[a.flex_1]}>
                <View style={[a.flex_1, a.justify_center]}>
                  <View style={[a.flex_row, a.px_lg]}>
                    <Text style={[a.pr_xl, a.flex_1, a.leading_snug]}>
                      <Trans>Browse more suggestions on the Explore page</Trans>
                    </Text>

                    <Arrow size="xl" />
                  </View>
                </View>
              </CardOuter>
            </Button>
          </View>
        </ScrollView>
      )}
    </View>
  )
}

export function ProgressGuide() {
  const t = useTheme()
  const {isDesktop} = useWebMediaQueries()
  const guide = useProgressGuide('like-10-and-follow-7')

  if (isDesktop) {
    return null
  }

  return guide ? (
    <View
      style={[
        a.border_t,
        t.atoms.border_contrast_low,
        a.px_lg,
        a.py_lg,
        a.pb_lg,
      ]}>
      <ProgressGuideList />
    </View>
  ) : null
}

export function InviteFriendsFeeds({isMemoCard}: {isMemoCard?: boolean}) {
  const pal = usePalette('default')
  const {bind: hasBindTelegram} = useTgStore(),
    {openModal} = useModalControls()
  const theme = useThemeName()
  const memoCardBg = useMemo(() => {
    if (theme === 'light') {
      return '#FFF7D5'
    }
    return '#FFFC56'
  }, [theme])

  return (
    <View style={!isMemoCard && {paddingHorizontal: 16}}>
      <Pressable
        accessibilityRole="button"
        onPress={() => {
          if (hasBindTelegram) {
            openModal({
              name: 'telegram',
              currentStep: TelegramBindStep.SelectContact,
            })
          } else {
            openModal({name: 'telegram'})
          }
        }}
        style={[
          pal.border,
          a.flex_1,
          a.flex_row,
          a.align_center,
          a.justify_between,
          {
            marginTop: 16,
            maxHeight: 175,
            backgroundColor: memoCardBg,
            borderRadius: 16,
            padding: 16,
          },
          isMemoCard && {
            marginHorizontal: 6,
            width: '100%',
          },
        ]}>
        {isMemoCard && <Bevel />}
        <View style={[a.flex_1]}>
          <Text style={[a.text_md, a.font_bold, a.mb_xs, {color: 'black'}]}>
            Invite Friends
          </Text>
          <Text style={[a.text_sm, {color: 'black', lineHeight: 20}]}>
            Share your stories and moments with friends.
          </Text>
        </View>
        <View
          style={[
            a.align_center,
            a.justify_center,
            {
              marginLeft: 10,
              width: 40,
              height: 40,
              borderRadius: 8,
              backgroundColor: 'rgba(0,0,0,0.1)',
            },
          ]}>
          <InviteFriendIcon isMemoCard />
        </View>
      </Pressable>
    </View>
  )
}

const initailProgressState = {timestamp: 0, progressWidth: 10}

export function SyncTelegramContactsFeeds({
  feed,
  isMemoCard,
}: {
  feed: FeedDescriptor
  isMemoCard?: boolean
}) {
  const pal = usePalette('default')
  const interval = useRef<any>(0)
  const duration = 60000
  const intervalStep = 1000
  const PROGRESS_WIDTH = 600

  const queryClient = useQueryClient()
  const t = useTheme()
  const {openModal} = useModalControls()
  const basic = Dialog.useDialogControl()
  const {client} = useTelegramClient()
  const {saveContacts, syncContactStep} = useTelegramLink()
  const [error, setError] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)
  const [progress, setProgress] = useState<{
    timestamp: number
    progressWidth: number
  }>(initailProgressState)
  const loading = syncContactStep === 'syncing'
  const busy = syncContactStep !== 'saved'

  const percent = useMemo(() => {
    if (progress.progressWidth > PROGRESS_WIDTH) {
      return '100%'
    }
    return `${((progress.progressWidth / PROGRESS_WIDTH) * 100).toFixed(2)}%`
  }, [progress.progressWidth])

  useEffect(() => {
    basic.open()
  }, [basic])

  // const saveTeleInfo = useCallback(() => {
  //   try {
  //     // saveChannels()
  //     saveChannelParticipants()
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }, [saveChannels, saveChannelParticipants])

  const saveTelegramInfon = useCallback(async () => {
    try {
      setError(false)
      await saveContacts(client)
    } catch (err: any) {
      if (err?.code === 401) {
        openModal({name: 'telegram', currentStep: TelegramBindStep.Init})
      }
      console.log('save-info-error')
      setError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveContacts])

  const retry = () => {
    setError(false)
    setProgress(initailProgressState)
    saveTelegramInfon()
    // saveTeleInfo()
  }

  useEffect(() => {
    saveTelegramInfon()
    // saveTeleInfo()
  }, [saveTelegramInfon])

  useEffect(() => {
    if (progress.timestamp < duration) {
      clearTimeout(interval.current)
      const step = Math.round(Math.random() * 6 + 8)
      interval.current = setTimeout(() => {
        setProgress(p => ({
          timestamp: p.timestamp + intervalStep,
          progressWidth:
            p.progressWidth > PROGRESS_WIDTH - 20
              ? p.progressWidth
              : p.progressWidth + step,
        }))
      }, intervalStep)
    } else {
      !success && setError(true)
      clearTimeout(interval.current)
    }

    return () => clearTimeout(interval.current)
  }, [progress, success])

  useEffect(() => {
    if (syncContactStep === 'syncing') {
      setProgress(initailProgressState)
    } else if (syncContactStep === 'synced') {
      setProgress(p => ({
        timestamp: p.timestamp + intervalStep,
        progressWidth:
          p.progressWidth > PROGRESS_WIDTH - 120
            ? p.progressWidth
            : PROGRESS_WIDTH - 120,
      }))
    } else if (syncContactStep === 'saved') {
      clearTimeout(interval.current)
      setSuccess(true)
      setProgress({timestamp: duration, progressWidth: PROGRESS_WIDTH})
    }
  }, [syncContactStep])

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        queryClient.invalidateQueries({queryKey: RQKEY(feed)})
        queryClient.invalidateQueries({queryKey: checkSyncTelegramContacts})
      }, 5000)
    }
  }, [feed, queryClient, success])

  const theme = useThemeName()

  const memoCardBg = useMemo(() => {
    if (theme === 'light') {
      return '#FFF7D5'
    }
    return '#FFFC56'
  }, [theme])

  return (
    <>
      <Pressable
        accessibilityRole="button"
        onPress={() => basic.open()}
        style={[
          pal.border,
          a.flex_1,
          a.flex_row,
          a.align_center,
          a.justify_between,
          {
            marginTop: 16,
            maxHeight: 175,
            backgroundColor: isMemoCard ? memoCardBg : '#FF5736',
            borderRadius: 16,
            padding: 16,
          },
          isMemoCard && {
            marginHorizontal: 6,
          },
        ]}>
        {isMemoCard && <Bevel />}
        <View style={[a.flex_1, a.gap_sm]}>
          <Text
            style={[
              a.text_md,
              a.font_bold,
              a.mb_xs,
              {color: isMemoCard ? 'black' : '#fff'},
            ]}>
            <Trans>Authorization: {percent}</Trans>
          </Text>
          <View>
            <Progress.Bar
              progress={progress.progressWidth / PROGRESS_WIDTH}
              width={null}
              style={[a.w_full]}
              borderColor="#979797"
              unfilledColor="#ade8f9"
              color="#f3b0ae"
              borderRadius={9999}
              height={8}
            />
          </View>
          <Text
            style={[
              a.text_sm,
              {color: isMemoCard ? 'black' : '#fff', lineHeight: 20},
            ]}>
            {error ? (
              <Trans>Failed to access your contacts. Please try again.</Trans>
            ) : (
              <Trans>SipZ requests access of getting more friends.</Trans>
            )}
          </Text>
        </View>
        <View
          style={[
            a.align_center,
            a.justify_center,
            {
              marginLeft: 10,
              width: 40,
              height: 40,
              borderRadius: 8,
              backgroundColor: isMemoCard
                ? 'rgba(0,0,0,0.15)'
                : error
                ? '#00000080'
                : '#FFFFFF4D',
            },
          ]}>
          {error ? (
            <WarninCircle />
          ) : (
            <Telegram style={{color: isMemoCard ? 'black' : '#fff'}} />
          )}
        </View>
      </Pressable>
      <Dialog.Outer control={basic}>
        <Dialog.Inner
          label="sync contacts"
          style={{minHeight: 360, maxWidth: 420}}>
          <View style={[a.w_full, a.flex_1, a.py_xl, a.gap_2xl]}>
            <Text
              style={[
                a.text_xl,
                a.text_center,
                a.font_bold,
                a.mt_2xl,
                t.atoms.text,
              ]}>
              <Trans>Authorization</Trans>
            </Text>
            <View
              style={[a.flex_row, a.align_center, a.justify_center, {gap: 24}]}>
              <View
                style={[
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#2AABEE',
                    width: 48,
                    height: 48,
                  },
                  a.p_lg,
                  a.rounded_full,
                ]}>
                <Telegram
                  size="lg"
                  style={{
                    color: '#fff',
                    left: -2,
                  }}
                />
              </View>

              <DotsHorizontal size="lg" style={{color: '#D9D9D9'}} />
              <View
                style={[
                  a.align_center,
                  a.self_center,
                  a.justify_center,
                  t.atoms.bg_primary,
                  {
                    width: 48,
                    height: 48,
                  },
                  a.rounded_full,
                ]}>
                <LogoYellow />
              </View>
            </View>
            <Text style={[a.text_center, a.mb_4xl, t.atoms.text]}>
              <Trans>SipZ.io requests access via TelegramBot</Trans>
            </Text>
            <View style={[{gap: 12}]}>
              <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                <Trans>The website will obtain the following：</Trans>
              </Text>
              <View style={[a.flex_row, a.align_center, {gap: 6}]}>
                <SaveStatus
                  loading={loading}
                  status={
                    success || syncContactStep !== 'syncing'
                      ? 'success'
                      : 'error'
                  }
                />
                <Text style={[t.atoms.text_sub]}>
                  <Trans>User name</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.align_center, {gap: 6}]}>
                <SaveStatus
                  loading={loading}
                  status={
                    success || syncContactStep !== 'syncing'
                      ? 'success'
                      : 'error'
                  }
                />
                <Text style={[t.atoms.text_sub]}>
                  <Trans>Avatar</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.align_center, {gap: 12}]}>
                <SaveStatus
                  loading={busy}
                  status={
                    success || syncContactStep === 'saved' ? 'success' : 'error'
                  }
                />
                <Text style={[t.atoms.text_sub]}>
                  <Trans>Contacts</Trans>
                </Text>
              </View>
              {error && (
                <Button
                  color="primary"
                  variant="solid"
                  size="medium"
                  label="retry"
                  onPress={() => retry()}>
                  <Text style={[a.font_bold, a.text_md, t.atoms.text]}>
                    <Trans>Try again</Trans>
                  </Text>
                  {loading && <ButtonIcon size="md" icon={Loader} />}
                </Button>
              )}
            </View>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
    </>
  )
}

function SaveStatus({
  loading,
  status,
}: {
  loading: boolean
  status?: 'error' | 'success'
}) {
  if (loading) {
    return <Loader />
  }

  return (
    <View
      style={[
        {
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          backgroundColor: status === 'success' ? '#0ABF52' : '#FF4444',
          width: 20,
          height: 20,
        },
        a.rounded_full,
      ]}>
      {status === 'success' && <Check size="xs" fill={'#ffffff'} />}
      {status === 'error' && <Close size="xs" fill={'#ffffff'} />}
    </View>
  )
}
