import {useQuery} from '@tanstack/react-query'

import {entityAgent} from '#/state/session/entityAgent'

export const Entity = {
  Detail: 'entity.detail',
}

export const ENTITY_TYPE: {[key: string]: number} = {
  project: 1,
  organization: 2,
  person: 3,
}

export const ENTITY_TYPE_NAME: {[key: number]: string} = {
  1: 'project',
  2: 'organization',
  3: 'person',
}

export const useEntityItem = (id: number, type: string) => {
  return useQuery({
    queryKey: [Entity.Detail, id, type],
    queryFn: async () => {
      const res: any = await entityAgent.getEntityDetail(id, ENTITY_TYPE[type])
      if (res) {
        if (type === 'project' || res?.project_id) {
          let links = getLinksFromDetail(res?.social_media)
          if (res?.rootdataurl && res?.rootdataurl !== '') {
            links.push({key: 'Rootdata', value: res?.rootdataurl})
          }
          return {
            type: ENTITY_TYPE[type],
            id: res?.project_id,
            name: res?.project_name,
            desc: res?.description,
            avatar: res?.logo,
            links,
          }
        } else if (type === 'organization') {
          const orgRes = res?.[0]
          let links = getLinksFromDetail(orgRes?.social_media)
          if (orgRes?.rootdataurl && orgRes?.rootdataurl !== '') {
            links.push({key: 'Rootdata', value: orgRes?.rootdataurl})
          }
          return {
            type: ENTITY_TYPE[type],
            id: orgRes?.org_id,
            name: orgRes?.org_name,
            desc: orgRes?.description,
            avatar: orgRes?.logo,
            links,
          }
        } else {
          const itemRes = res?.[0]
          let links: {key: string; value: string}[] = []
          if (itemRes?.X) {
            links.push({key: 'X', value: itemRes?.X})
          }
          if (itemRes?.rootdataurl && itemRes?.rootdataurl !== '') {
            links.push({key: 'Rootdata', value: itemRes?.rootdataurl})
          }
          return {
            type: ENTITY_TYPE[type],
            id: itemRes?.people_id,
            name: itemRes?.people_name,
            desc: itemRes?.introduce,
            avatar: itemRes?.head_img,
            links,
          }
        }
      }
    },
  })
}

function getLinksFromDetail(socialMedia: {[key: string]: string}) {
  let links: {key: string; value: string}[] = []
  Object.keys(socialMedia)?.forEach(key => {
    if (socialMedia[key] && socialMedia[key] !== '') {
      links.push({
        key: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
        value: socialMedia[key],
      })
    }
  })

  return links
}
