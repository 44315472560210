import React from 'react'
import {Pressable, Text, TextInput, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {useFriendAddReqMutation} from '#/state/queries/connections'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {RightIcon} from '#/view/icons/ModalIcons'
import {useTagList} from '#/view/screens/Circle/hooks'
import {RecommendItemProps} from '#/view/screens/Circle/RecommendItem'
import * as Toast from 'view/com/util/Toast'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import {CircleCreateTagDialog} from '#/components/dialogs/CircleCreateTag'
import {CircleUserInfoDialog} from '#/components/dialogs/CircleUserInfo'
import {Button} from '../../util/forms/Button'

export type SendFriendsRequestFrom = 'recommend' | 'contact' | 'profile'
export interface SendFriendsRequestModalProps {
  selectedUser: RecommendItemProps
  from?: SendFriendsRequestFrom
  onCallBack?: () => void
}

export const snapPoints = ['56%']
export function Component({selectedUser, from}: SendFriendsRequestModalProps) {
  const t = useTheme()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const pal = usePalette('default')
  const {isMobile, isDesktop} = useWebMediaQueries()
  const control = useDialogControl()
  const tagUpdateControl = useDialogControl()

  const {currentAccount} = useSession()
  const {data: profile} = useProfileQuery({
    did: currentAccount?.did,
  })
  const {data: tags = []} = useTagList()
  const friendAddMutation = useFriendAddReqMutation()

  const [message, setMessage] = React.useState<string>()
  const [error, setError] = React.useState<string>('')
  const [sending, setSending] = React.useState<boolean>(false)

  const onSend = React.useCallback(async () => {
    if (!sending && message) {
      try {
        setSending(true)
        await friendAddMutation.mutateAsync({
          recipientDid: selectedUser.id,
          source: selectedUser?.source ?? '',
          reason: message,
          from,
        })
        Toast.show('Friend Request Sent Successfully.')
        closeModal()
      } catch (err: any) {
        setError(
          err?.errorMessage ||
            err?.message ||
            _(msg`cause some error, please try again later.`),
        )
      } finally {
        setSending(false)
      }
    }
  }, [
    _,
    sending,
    message,
    closeModal,
    selectedUser.id,
    selectedUser.source,
    friendAddMutation,
    from,
  ])

  React.useEffect(() => {
    if (error) {
      control.open()
    }
  }, [error, control])

  React.useEffect(() => {
    const userName =
      profile?.displayName && profile?.displayName !== ''
        ? profile?.displayName
        : formatHandler(profile?.handle ?? '')
    if (userName) {
      setMessage(`I'm ${userName}`)
    }
  }, [profile?.displayName, profile?.handle])

  return (
    <View style={[isMobile && {paddingHorizontal: 18}]}>
      <View style={[a.w_full, a.align_center, a.justify_center, a.mb_lg]}>
        <Text style={[t.atoms.text, a.font_bold, {fontSize: 19}]}>
          <Trans>Send Friends Request</Trans>
        </Text>
      </View>
      <Text style={[t.atoms.text_sub, a.text_sm, a.mb_md]}>
        <Trans>Send Friends Request</Trans>
      </Text>
      <View
        style={[
          pal.border,
          a.p_sm,
          a.rounded_md,
          a.px_md,
          t.atoms.input_border,
          t.atoms.input_bg,
          {borderWidth: 1},
        ]}>
        <TextInput
          testID="sendFriendsMessage"
          style={[t.atoms.text, {height: 80, fontSize: 16}]}
          placeholderTextColor={t.atoms.text_sub.color}
          multiline
          value={message}
          onChangeText={v => {
            setMessage(v)
            setError('')
          }}
          accessible={true}
        />
      </View>
      <Text style={[t.atoms.text_sub, a.text_sm, a.my_md]}>
        <Trans>Add tags</Trans>
      </Text>
      <Pressable
        accessibilityRole="button"
        role="button"
        onPress={() => {
          control.open()
        }}
        style={[
          pal.border,
          t.atoms.input_border,
          t.atoms.input_bg,
          a.flex_row,
          a.align_center,
          a.justify_between,
          a.px_md,
          {height: 44, borderRadius: 10, borderWidth: 1},
        ]}>
        <Text style={[t.atoms.text, a.font_semibold]}>
          <Trans>Tags</Trans>
        </Text>
        <RightIcon color={t.palette.black_25} />
      </Pressable>
      {error && (
        <Text style={[error ? t.atoms.text_danger : t.atoms.text, a.m_md]}>
          <Trans>{error}</Trans>
        </Text>
      )}
      <View style={[a.mt_5xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          disabled={sending || !message}
          withLoading={true}
          accessibilityLabel={_(msg`${'Send'}`)}
          accessibilityHint=""
          label={_(msg`Send`)}
          onPress={onSend}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>

      <CircleUserInfoDialog
        control={control}
        createTagControl={tagUpdateControl}
        params={{
          selected: {...selectedUser, isAdded: true},
          selectedTags: selectedUser.tags,
          tags,
          fromType: 'sipz',
        }}
      />
      <CircleCreateTagDialog control={tagUpdateControl} />
    </View>
  )
}
