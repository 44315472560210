import React, {useMemo} from 'react'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {AppBskyFeedDefs, moderatePost} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

import {usePalette} from '#/lib/hooks/usePalette'
import {useModerationOpts} from '#/state/preferences/moderation-opts'
import {s} from 'lib/styles'
import {atoms as a, useTheme} from '#/alf'
// import {AviFollowButton} from '../posts/AviFollowButton'
import {PostMeta} from '../util/PostMeta'
import {Text} from '../util/text/Text'
import {PreviewableUserAvatar, UserAvatar} from '../util/UserAvatar'
import {AdditionalPostText} from './FeedItem'

export function AdditionalPost({
  itemHref,
  post,
  style,
  hideTopBorder,
}: {
  itemHref: string
  post: AppBskyFeedDefs.PostView
  style?: StyleProp<ViewStyle>
  hideTopBorder?: boolean
}) {
  const moderationOpts = useModerationOpts()
  const t = useTheme()
  const pal = usePalette('default')

  const moderation = useMemo(
    () => (moderationOpts ? moderatePost(post, moderationOpts) : undefined),
    [moderationOpts, post],
  )

  return (
    <View
      style={[
        {padding: 10, paddingRight: 15},
        a.flex_row,
        pal.border,
        style,
        {borderTopWidth: hideTopBorder ? 0 : StyleSheet.hairlineWidth},
        a.overflow_hidden,
      ]}>
      <View style={[a.align_end, a.pr_sm, a.ml_lg, {paddingTop: 2}]}>
        {moderation ? (
          (post.author?.isAnonymous as boolean) ? (
            <UserAvatar
              avatar={post.author.avatar}
              moderation={moderation.ui('avatar')}
              size={24}
              type={post.author.associated?.labeler ? 'labeler' : 'user'}
            />
          ) : (
            // <AviFollowButton author={post.author} moderation={moderation}>
            <PreviewableUserAvatar
              size={24}
              profile={post.author}
              moderation={moderation.ui('avatar')}
              type={post.author.associated?.labeler ? 'labeler' : 'user'}
            />
            // </AviFollowButton>
          )
        ) : (
          ''
        )}
      </View>

      <View style={[a.flex_1]}>
        <PostMeta
          author={post.author}
          moderation={moderation}
          authorHasWarning={!!post.author.labels?.length}
          timestamp={post.indexedAt}
          postHref={itemHref}
          isAnonymous={(post.author?.isAnonymous ?? false) as boolean}
        />
        <View style={[s.flexRow, s.mb2, s.alignCenter]}>
          <FontAwesomeIcon
            icon="reply"
            size={9}
            color={t.atoms.text_sub.color}
            style={[pal.textLight, s.mr5]}
          />
          <Text
            type="sm"
            style={[pal.textLight, s.mr2, t.atoms.text_sub]}
            lineHeight={1.2}
            numberOfLines={1}>
            <Trans context="description">Reply to you</Trans>
          </Text>
        </View>
        <AdditionalPostText post={post} />
      </View>
    </View>
  )
}
