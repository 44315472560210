import React from 'react'
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {BACK_HITSLOP, MUST_SIP_UTI} from '#/lib/constants'
import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {isNative} from '#/platform/detection'
import {FeedDescriptor} from '#/state/queries/post-feed'
import {FeedPage} from 'view/com/feeds/FeedPage'
import {atoms as a, useTheme} from '#/alf'
import {HomeHeaderLayout} from '../com/home/HomeHeaderLayout'
import {BigFigureProvider} from '../com/posts/BigFigure/BigFigureProvider'
import {EmptyBox} from '../com/util/EmptyBox'
import {CenteredView} from '../com/util/Views'

const lightPng = require('../../../assets/imgs/must_sip_banner_light.png')
const darkPng = require('../../../assets/imgs/must_sip_banner_dark.png')
const mobileLightPng = require('../../../assets/imgs/must_sip_mobile_light.png')
const mobileDarkPng = require('../../../assets/imgs/must_sip_mobile_dark.png')

export function MustSipTeaScreen() {
  const mustSipFeedDescriptor = `feedgen|${MUST_SIP_UTI}` as FeedDescriptor
  const {isDesktop, isTablet, isMobile} = useWebMediaQueries()
  const pal = usePalette('default')
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {_} = useLingui()

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  const headerContent = (
    <View
      style={[
        t.name === 'light' ? {borderColor: '#DAD4E2'} : pal.border,
        isDesktop && styles.desktopContainer,
        isTablet && styles.tabletContainer,
        isNative && {marginBottom: 10},
      ]}>
      <Image
        source={
          t.name === 'light'
            ? isMobile
              ? mobileLightPng
              : lightPng
            : isMobile
            ? mobileDarkPng
            : darkPng
        }
        style={[
          {height: 200},
          isDesktop && {marginTop: 16},
          isNative && {width: '100%'},
          isMobile && {marginBottom: -16},
        ]}
        resizeMode="cover"
        accessibilityIgnoresInvertColors
      />
      {!isDesktop && (
        <TouchableOpacity
          testID="mustSipBackBtn"
          onPress={onPressBack}
          hitSlop={BACK_HITSLOP}
          style={[
            styles.backBtn,
            {
              justifyContent: 'center',
              position: 'absolute',
              left: 12,
              top: 12,
            },
          ]}
          accessibilityRole="button"
          accessibilityLabel={_(msg`Back`)}
          accessibilityHint={''}>
          <FontAwesomeIcon
            size={18}
            icon="angle-left"
            style={{color: 'black'}}
          />
        </TouchableOpacity>
      )}
    </View>
  )

  const feedContent = (
    <View style={[a.flex_1, a.w_full, a.h_full]}>
      <BigFigureProvider isBigFigure={true} isMustSip={true}>
        <FeedPage
          testID="mustSipTeaFeedPage"
          isPageFocused={true}
          feed={mustSipFeedDescriptor}
          renderEmptyState={() => (
            <CenteredView isWidescreen>
              <EmptyBox
                icon="tea"
                message={'Oops...look like your feed is a bit empty!'}
              />
            </CenteredView>
          )}
        />
      </BigFigureProvider>
    </View>
  )

  if (isNative) {
    return (
      <View
        style={[a.flex_1, a.w_full, a.h_full, a.flex_col, a.justify_between]}>
        <HomeHeaderLayout tabBarAnchor={null}>{headerContent}</HomeHeaderLayout>
        {feedContent}
      </View>
    )
  }

  return (
    <ScrollView
      style={[
        a.w_full,
        a.h_full,
        {backgroundColor: t.name === 'light' ? '#FFEAB5' : 'transparent'},
      ]}>
      <View
        style={[a.flex_1, a.w_full, a.h_full, a.flex_col, a.justify_between]}>
        {headerContent}
        {feedContent}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  desktopContainer: {
    maxWidth: 828,
    // @ts-ignore
    left: 'calc(50vw - 312px)',
    borderLeftWidth: 1,
    paddingHorizontal: 8,
  },
  tabletContainer: {
    width: '100%',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  backBtn: {
    width: 30,
    height: 30,
  },
})
