import React, {memo, useCallback, useId, useMemo, useState} from 'react'
import {StyleSheet, View} from 'react-native'
import {
  AppBskyActorDefs,
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {POST_CTRL_HITSLOP} from '#/lib/constants'
import {useIsMe} from '#/lib/hooks/useIsMe'
import {NavigationProp} from '#/lib/routes/types'
import {useGate} from '#/lib/statsig/statsig'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {POST_TOMBSTONE, Shadow, usePostShadow} from '#/state/cache/post-shadow'
import {emitter} from '#/state/events'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell/composer'
import {isReasonFeedSource, ReasonFeedSource} from 'lib/api/feed/types'
import {MAX_POST_LINES} from 'lib/constants'
import {usePalette} from 'lib/hooks/usePalette'
import {makeAnonymousProfileLink, makeProfileLink} from 'lib/routes/links'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {sanitizeHandle} from 'lib/strings/handles'
import {s} from 'lib/styles'
import {precacheProfile} from 'state/queries/profile'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Repost_Stroke2_Corner2_Rounded as Repost} from '#/components/icons/Repost'
import {ContentHider} from '#/components/moderation/ContentHider'
import {ProfileHoverCard} from '#/components/ProfileHoverCard'
import {RichText} from '#/components/RichText'
// import {LabelsOnMyPost} from '../../../components/moderation/LabelsOnMe'
import {PostAlerts} from '../../../components/moderation/PostAlerts'
import {PostStakeDonateItem} from '../post-thread/PostStakeDonateItem'
import {BetProgressFeed} from '../post-transaction/BetProgress'
import {FeedNameText} from '../util/FeedInfoText'
import {PostDropdownBtn} from '../util/forms/PostDropdownBtn'
import {Link, TextLink, TextLinkOnWebOnly} from '../util/Link'
import {CirclePostCtrls} from '../util/post-ctrls/CirclePostCtrls'
import {PostCtrls} from '../util/post-ctrls/PostCtrls'
import {PostEmbeds} from '../util/post-embeds'
import {VideoEmbed} from '../util/post-embeds/VideoEmbed'
import {PostMeta} from '../util/PostMeta'
import {Text} from '../util/text/Text'
import {AnonSpillerAvatar, PreviewableUserAvatar} from '../util/UserAvatar'
import {AviFollowButton} from './AviFollowButton'
import {useCircle} from './CircleProvider'
import {useSwipeKey} from './SwipeProvider'
import {TargetDisplay} from './TargetDisplay'
import {
  AutoTranslate,
  useTranslationWidget,
} from './TranslationWidget/TranslationWidget'

export interface FeedItemProps {
  record: AppBskyFeedPost.Record
  reason:
    | AppBskyFeedDefs.ReasonRepost
    | ReasonFeedSource
    | {[k: string]: unknown; $type: string}
    | undefined
  moderation: ModerationDecision
  parentAuthor: AppBskyActorDefs.ProfileViewBasic | undefined
  showReplyTo: boolean
  isThreadChild?: boolean
  isThreadLastChild?: boolean
  isThreadParent?: boolean
  feedContext: string | undefined
  hideTopBorder?: boolean
  isParentBlocked?: boolean
  isOperation?: boolean
}

export function FeedItem({
  post,
  record,
  reason,
  feedContext,
  moderation,
  parentAuthor,
  showReplyTo,
  isThreadChild,
  isThreadLastChild,
  isThreadParent,
  hideTopBorder,
  isParentBlocked,
  isOperation,
}: FeedItemProps & {
  post: AppBskyFeedDefs.PostView
}): React.ReactNode {
  const postShadowed = usePostShadow(post)
  const {translateTitle, richText, hasMore, showAutoTranslate} =
    useTranslationWidget({
      record,
      shouldTrimArticle: true,
      post,
    })

  if (postShadowed === POST_TOMBSTONE) {
    return null
  }
  if (richText && moderation) {
    return (
      <FeedItemInner
        // Safeguard from clobbering per-post state below:
        key={postShadowed.uri}
        post={postShadowed}
        record={{...record, title: translateTitle || record.title}}
        reason={reason}
        feedContext={feedContext}
        richText={richText}
        parentAuthor={parentAuthor}
        showReplyTo={showReplyTo}
        moderation={moderation}
        isThreadChild={isThreadChild}
        isThreadLastChild={isThreadLastChild}
        isThreadParent={isThreadParent}
        hideTopBorder={hideTopBorder}
        isParentBlocked={isParentBlocked}
        hasMore={hasMore}
        isOperation={isOperation}
        showAutoTranslate={showAutoTranslate}
      />
    )
  }
  return null
}

let FeedItemInner = ({
  post,
  record,
  reason,
  feedContext,
  richText,
  moderation,
  parentAuthor,
  showReplyTo,
  isThreadChild,
  isThreadLastChild,
  isThreadParent,
  hideTopBorder,
  isParentBlocked,
  hasMore,
  isOperation,
  showAutoTranslate,
}: FeedItemProps & {
  richText: RichTextAPI
  hasMore: boolean
  showAutoTranslate: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
}): React.ReactNode => {
  const queryClient = useQueryClient()
  const {openComposer} = useComposerControls()
  const pal = usePalette('default')
  const {_} = useLingui()
  const isCircle = useCircle()

  // @ts-ignore
  const isAnonymousPost = post?.record?.hideAuthor === 'true' ? true : false
  const href = useMemo(() => {
    const urip = new AtUri(post.uri)
    if (isAnonymousPost) {
      return makeAnonymousProfileLink(post.author, 'post', urip.rkey)
    } else {
      return makeProfileLink(post.author, 'post', urip.rkey)
    }
  }, [post.uri, post.author, isAnonymousPost])
  const {sendInteraction} = useFeedFeedbackContext()

  const onPressReply = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionReply',
      feedContext,
    })
    openComposer({
      replyTo: {
        uri: post.uri,
        cid: post.cid,
        text: record.text || '',
        author: post.author,
        embed: post.embed,
        moderation,
      },
    })
  }, [post, record, openComposer, moderation, sendInteraction, feedContext])

  const onOpenAuthor = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughAuthor',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])

  const onOpenReposter = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughReposter',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])

  const onOpenEmbed = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughEmbed',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])

  const onBeforePress = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughItem',
      feedContext,
    })
    precacheProfile(queryClient, post.author)
  }, [queryClient, post, sendInteraction, feedContext])

  const outerStyles = [
    styles.outer,
    {
      borderColor: pal.colors.border,
      paddingBottom:
        isThreadLastChild || (!isThreadChild && !isThreadParent)
          ? 8
          : undefined,
      borderTopWidth:
        hideTopBorder || isThreadChild || isCircle
          ? 0
          : StyleSheet.hairlineWidth,
    },
  ]

  const {currentAccount} = useSession()
  const isOwner =
    AppBskyFeedDefs.isReasonRepost(reason) &&
    reason.by.did === currentAccount?.did

  const targets = record?.finEntities || []

  const navigation = useNavigation<NavigationProp>()
  const swipeKey = useSwipeKey()
  const onPressLink = useCallback(() => {
    const urip = new AtUri(post.uri)
    const postUri = makeRecordUri(
      post.author.handle,
      'app.bsky.feed.post',
      urip.rkey,
    )
    emitter.emit('setSwipeList', {
      from: swipeKey,
      postUri,
    })
    navigation.navigate('PostThread', {
      name: post.author.handle,
      rkey: urip.rkey,
    })
  }, [navigation, post.author.handle, post.uri, swipeKey])

  return (
    <Link
      testID={`feedItem-by-${post.author.handle}`}
      style={outerStyles}
      href={href}
      onPress={onPressLink}
      noFeedback
      accessible={false}
      onBeforePress={onBeforePress}
      dataSet={{feedContext}}>
      <View style={{flexDirection: 'row', gap: 10, paddingLeft: 8}}>
        <View style={{width: 52}}>
          {isThreadChild && (
            <View
              style={[
                styles.replyLine,
                {
                  flexGrow: 1,
                  backgroundColor: pal.colors.replyLine,
                  marginBottom: 4,
                  transform: [{translateX: -4}],
                },
              ]}
            />
          )}
        </View>

        <View style={{paddingTop: 12, flexShrink: 1}}>
          {isReasonFeedSource(reason) ? (
            <Link href={reason.href}>
              <Text
                type="sm-bold"
                style={pal.textLight}
                lineHeight={1.2}
                numberOfLines={1}>
                <Trans context="from-feed">
                  From{' '}
                  <FeedNameText
                    type="sm-bold"
                    uri={reason.uri}
                    href={reason.href}
                    lineHeight={1.2}
                    numberOfLines={1}
                    style={pal.textLight}
                  />
                </Trans>
              </Text>
            </Link>
          ) : AppBskyFeedDefs.isReasonRepost(reason) ? (
            <Link
              style={styles.includeReason}
              href={makeProfileLink(reason.by)}
              title={
                isOwner
                  ? _(msg`Reposted by you`)
                  : _(
                      msg`Reposted by ${sanitizeDisplayName(
                        reason.by.displayName ||
                          sanitizeHandle(reason.by.handle),
                      )}`,
                    )
              }
              onBeforePress={onOpenReposter}>
              <Repost
                style={{color: pal.colors.textLight, marginRight: 3}}
                width={14}
                height={14}
              />
              <Text
                type="sm-bold"
                style={pal.textLight}
                lineHeight={1.2}
                numberOfLines={1}>
                {isOwner ? (
                  <Trans>Reposted by you</Trans>
                ) : (
                  <Trans>
                    Reposted by{' '}
                    <ProfileHoverCard inline did={reason.by.did}>
                      <TextLinkOnWebOnly
                        type="sm-bold"
                        style={pal.textLight}
                        lineHeight={1.2}
                        numberOfLines={1}
                        text={sanitizeDisplayName(
                          reason.by.displayName ||
                            formatHandler(sanitizeHandle(reason.by.handle)),
                          moderation.ui('displayName'),
                        )}
                        href={makeProfileLink(reason.by)}
                        onBeforePress={onOpenReposter}
                      />
                    </ProfileHoverCard>
                  </Trans>
                )}
              </Text>
            </Link>
          ) : null}
        </View>
      </View>

      <View style={[styles.layout, isCircle && styles.layoutCircle]}>
        <View style={[styles.layoutAvi, {position: 'relative'}]}>
          {isAnonymousPost ? (
            <AnonSpillerAvatar size={44} profile={post.author} />
          ) : (
            <AviFollowButton author={post.author} moderation={moderation}>
              <PreviewableUserAvatar
                size={44}
                profile={post.author}
                moderation={moderation.ui('avatar')}
                type={post.author.associated?.labeler ? 'labeler' : 'user'}
                onBeforePress={onOpenAuthor}
              />
            </AviFollowButton>
          )}
          {isThreadParent && (
            <View
              style={[
                styles.replyLine,
                {
                  flexGrow: 1,
                  backgroundColor: pal.colors.replyLine,
                  marginTop: 4,
                },
              ]}
            />
          )}
        </View>

        <View style={styles.layoutContent}>
          {/* firstLine */}
          <PostMeta
            isAnonymous={isAnonymousPost}
            author={post.author}
            moderation={moderation}
            authorHasWarning={!!post.author.labels?.length}
            timestamp={post.indexedAt}
            postHref={href}
            onOpenAuthor={onOpenAuthor}
            // eslint-disable-next-line react/no-unstable-nested-components
            rightButton={() => {
              return (
                <PostDropdownBtn
                  testID="postDropdownBtn"
                  post={post}
                  postFeedContext={feedContext}
                  record={record}
                  richText={richText}
                  style={[{padding: 2}]}
                  hitSlop={POST_CTRL_HITSLOP}
                  timestamp={post.indexedAt}
                />
              )
            }}
          />
          <View
            style={{
              height: 5,
              width: 1,
            }}
          />
          {showReplyTo && (parentAuthor || isParentBlocked) && (
            <ReplyToLabel blocked={isParentBlocked} profile={parentAuthor} />
          )}
          {/* <LabelsOnMyPost post={post} /> */}
          {targets.length > 0 && (
            <TargetDisplay targets={targets} previewMode />
          )}

          <PostContent
            title={record.title}
            moderation={moderation}
            richText={richText}
            postEmbed={post.embed}
            postAuthor={post.author}
            onOpenEmbed={onOpenEmbed}
            hasMore={hasMore}
          />
          <VideoDebug />
          <View style={{height: 10, width: 1}} />
          <PostStakeDonateItem post={post} outside />
          {/* @ts-ignore */}
          {false && post?.betCount > 0 && <BetProgressFeed post={post} />}

          {showAutoTranslate && <AutoTranslate />}

          {isOperation ? null : record?.visibleCircles &&
            record.visibleCircles.length > 0 &&
            record.visibleCircles[0] === 'Circle' ? (
            <CirclePostCtrls
              post={post}
              record={record}
              richText={richText}
              onPressReply={onPressReply}
              logContext="FeedItem"
              feedContext={feedContext}
              // eslint-disable-next-line react/no-unstable-nested-components
              rightButton={() => {
                return (
                  <PostDropdownBtn
                    testID="postDropdownBtn"
                    post={post}
                    postFeedContext={feedContext}
                    record={record}
                    richText={richText}
                    style={{padding: 2}}
                    hitSlop={POST_CTRL_HITSLOP}
                    timestamp={post.indexedAt}
                  />
                )
              }}
            />
          ) : (
            <View style={{paddingRight: 25}}>
              <PostCtrls
                post={post}
                record={record}
                richText={richText}
                onPressReply={onPressReply}
                logContext="FeedItem"
                feedContext={feedContext}
              />
            </View>
          )}
        </View>
      </View>
    </Link>
  )
}
FeedItemInner = memo(FeedItemInner)

let PostContent = ({
  moderation,
  richText,
  postEmbed,
  postAuthor,
  onOpenEmbed,
  title,
  hasMore,
}: {
  moderation: ModerationDecision
  richText: RichTextAPI
  postEmbed: AppBskyFeedDefs.PostView['embed']
  postAuthor: AppBskyFeedDefs.PostView['author']
  onOpenEmbed: () => void
  title?: string
  hasMore?: boolean
}): React.ReactNode => {
  const pal = usePalette('default')
  const {_} = useLingui()
  const [limitLines, setLimitLines] = useState(
    // () => countLines(richText.text) >= MAX_POST_LINES,
    false,
  )

  const onPressShowMore = React.useCallback(() => {
    setLimitLines(false)
  }, [setLimitLines])

  const t = useTheme()

  const isCircle = useCircle()

  return (
    <ContentHider
      testID="contentHider-post"
      modui={moderation.ui('contentList')}
      ignoreMute
      childContainerStyle={styles.contentHiderChild}>
      {title && (
        <Text
          style={[
            {
              fontSize: 16,
              fontWeight: '700',
              marginVertical: 5,
            },
            t.atoms.text,
            isCircle && {color: 'white'},
          ]}>
          {title}
        </Text>
      )}
      <PostAlerts modui={moderation.ui('contentList')} style={[a.py_2xs]} />
      {richText.text ? (
        <View style={[styles.postTextContainer]}>
          <RichText
            enableTags
            testID="postText"
            value={richText}
            // numberOfLines={limitLines ? MAX_POST_LINES : undefined}
            numberOfLines={MAX_POST_LINES}
            style={[a.flex_1, a.text_md, isCircle && {color: 'white'}]}
            authorHandle={sanitizeHandle(postAuthor.handle)}
          />
        </View>
      ) : undefined}
      {limitLines ? (
        <TextLink
          text={_(msg`Show More`)}
          style={pal.linkActive}
          onPress={onPressShowMore}
          href="#"
        />
      ) : undefined}
      {hasMore && (
        <Text style={{color: t.palette.primary}}>
          <Trans>Show More</Trans>
        </Text>
      )}
      {postEmbed ? (
        <View style={[a.pb_xs]}>
          <PostEmbeds
            embed={postEmbed}
            moderation={moderation}
            onOpen={onOpenEmbed}
          />
        </View>
      ) : null}
    </ContentHider>
  )
}
PostContent = memo(PostContent)

function ReplyToLabel({
  profile,
  blocked,
}: {
  profile: AppBskyActorDefs.ProfileViewBasic | undefined
  blocked?: boolean
}) {
  const pal = usePalette('default')
  const isMe = useIsMe(profile?.did || '')

  let label
  if (blocked) {
    label = <Trans context="description">Reply to a blocked post</Trans>
  } else if (profile != null) {
    if (isMe) {
      label = <Trans context="description">Reply to you</Trans>
    } else {
      label = (
        <Trans context="description">
          Reply to{' '}
          <ProfileHoverCard inline did={profile.did}>
            <TextLinkOnWebOnly
              type="md"
              style={pal.textLight}
              lineHeight={1.2}
              numberOfLines={1}
              href={makeProfileLink(profile)}
              text={
                profile.displayName
                  ? sanitizeDisplayName(profile.displayName)
                  : sanitizeHandle(profile.handle)
              }
            />
          </ProfileHoverCard>
        </Trans>
      )
    }
  }

  if (!label) {
    // Should not happen.
    return null
  }

  return (
    <View style={[s.flexRow, s.mb2, s.alignCenter]}>
      <FontAwesomeIcon
        icon="reply"
        size={9}
        style={[{color: pal.colors.textLight} as FontAwesomeIconStyle, s.mr5]}
      />
      <Text
        type="md"
        style={[pal.textLight, s.mr2]}
        lineHeight={1.2}
        numberOfLines={1}>
        {label}
      </Text>
    </View>
  )
}

function VideoDebug() {
  const gate = useGate()
  const id = useId()

  if (!gate('video_debug')) return null

  return (
    <VideoEmbed
      source={`https://lumi.jazco.dev/watch/did:plc:q6gjnaw2blty4crticxkmujt/Qmc8w93UpTa2adJHg4ZhnDPrBs1EsbzrekzPcqF5SwusuZ/playlist.m3u8?ignore_me_just_testing_frontend_stuff=${id}`}
    />
  )
}

const styles = StyleSheet.create({
  outer: {
    paddingLeft: 10,
    paddingRight: 15,
    // @ts-ignore web only -prf
    cursor: 'pointer',
  },
  replyLine: {
    width: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  includeReason: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 2,
    marginLeft: -18,
  },
  layout: {
    flexDirection: 'row',
    marginTop: 1,
    gap: 10,
  },
  layoutCircle: {
    padding: 16,
    position: 'relative',
    marginLeft: 16,
    paddingBottom: 5,
  },
  circleBg: {
    height: '100%',
    width: '100%',
    backgroundColor: 'pink',
    borderRadius: 16,
    borderBottomLeftRadius: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  layoutAvi: {
    paddingLeft: 8,
    position: 'relative',
    zIndex: 999,
  },
  layoutContent: {
    position: 'relative',
    flex: 1,
    zIndex: 0,
  },
  alert: {
    marginTop: 6,
    marginBottom: 6,
  },
  postTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: 2,
    overflow: 'hidden',
  },
  contentHiderChild: {
    marginTop: 6,
  },
  embed: {
    marginBottom: 6,
  },
  translateLink: {
    marginBottom: 6,
  },
})
