import React from 'react'
import {StyleSheet, Text, View} from 'react-native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isNative} from '#/platform/detection'
import {useTagListQuery} from '#/state/queries/post-feed'
import {atoms as a, useTheme} from '#/alf'
import {FeedTabBar} from '../../pager/FeedTabBar'
import {CenteredView} from '../../util/Views'

export type BigFigureTab = {
  id: string
  name: string
}
export default function BigFigureTabs({
  selectTab,
  onSelectTab,
}: {
  selectTab: BigFigureTab
  onSelectTab: (value: BigFigureTab) => void
}) {
  const t = useTheme()
  const pal = usePalette('default')
  const {isTabletOrDesktop, isMobile} = useWebMediaQueries()
  const {data: tagList = []} = useTagListQuery()

  const tags = React.useMemo(() => {
    return [{id: '-1', name: '❤️ For you'}].concat(tagList)
  }, [tagList])

  const renderItem = ({item}: {item: BigFigureTab}) => {
    const isSelected = selectTab?.id === item.id
    return (
      <View
        style={[
          a.flex_row,
          a.gap_xs,
          {
            backgroundColor: isSelected
              ? t.name === 'light'
                ? 'rgba(252, 208, 16, 0.2)'
                : '#322a03'
              : t.atoms.feed_tab_bg.backgroundColor,
            borderRadius: 8,
            padding: 10,
            borderWidth: 1,
            borderColor: isSelected ? '#FCD010' : 'transparent',
            marginRight: 10,
          },
        ]}>
        <Text
          style={[
            styles.tabText,
            t.atoms.feed_tab_text,
            isSelected && {
              color: t.name === 'light' ? '#000000' : '#FCD010',
            },
          ]}>
          {item?.name === 'Classic' ? '👍 ' : Number(item?.id) > 0 ? '# ' : ''}
          {item?.name}
        </Text>
      </View>
    )
  }

  return (
    <CenteredView
      isWidescreen={true}
      style={[
        styles.container,
        isTabletOrDesktop && {borderLeftWidth: 1},
        pal.border,
        isMobile && !isNative && {marginTop: 85},
      ]}>
      <FeedTabBar
        testID={'circles-tab-bar'}
        items={tags}
        selectedPage={tags?.findIndex(o => o?.id === selectTab?.id)}
        onSelect={index => {
          onSelectTab(tags[index])
        }}
        renderItem={renderItem}
        onPressSelected={index => {
          onSelectTab(tags[index])
        }}
      />
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 16,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientBorder: {
    padding: 1,
    borderRadius: 8,
  },
  tabText: {
    fontSize: 14,
    fontWeight: '500',
  },
})
