import React from 'react'
import {View} from 'react-native'
import {Trans} from '@lingui/macro'

import {Tag} from '#/view/com/tags/TagItem'
import {useTagUpdateMutation} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import * as Dialog from '#/components/Dialog'
import {Button} from '../Button'
import {Loader} from '../Loader'
import {Text} from '../Typography'

export function CircleCreateTagDialog({
  control,
}: {
  control: Dialog.DialogControlProps
}) {
  const t = useTheme()
  const tagUpdate = useTagUpdateMutation()

  const [editTag, setEditTag] = React.useState<Tag>({id: -1, name: ''})

  const onTagCreateAndUpdate = async () => {
    if (!editTag?.name) {
      return
    }
    await tagUpdate.mutateAsync({
      id: editTag?.id,
      name: editTag?.name,
    })
    control.close()
  }

  return (
    <Dialog.Outer control={control}>
      <Dialog.Handle />

      <Dialog.Inner label="Create tags">
        <View style={[a.p_md, a.justify_center, a.align_center]}>
          <Text style={[{fontSize: 19, fontWeight: 600}, t.atoms.text]}>
            <Trans>Create tag</Trans>
          </Text>
        </View>
        <View style={[{height: 48}, a.my_2xl]}>
          <InputShadow publishEdiTag={setEditTag} />
        </View>
        <View>
          <Button
            color="primary"
            variant="solid"
            size="medium"
            label="Confirm"
            onPress={onTagCreateAndUpdate}
            style={[a.mt_3xl]}>
            <Text style={[a.text_md, a.font_bold, {color: t.palette.black}]}>
              <Trans>Confirm</Trans>
            </Text>
            {tagUpdate.isPending && <Loader size="sm" style={[t.atoms.text]} />}
          </Button>
          <Button
            color="secondary"
            variant="solid"
            size="medium"
            label="Cancel"
            style={[a.mt_md]}
            onPress={() => control.close()}>
            <Text style={[t.atoms.text_sub, a.text_md, a.font_bold]}>
              <Trans>Cancel</Trans>
            </Text>
          </Button>
        </View>
        <Dialog.Close />
      </Dialog.Inner>
    </Dialog.Outer>
  )
}

const InputShadow = ({
  publishEdiTag,
}: {
  publishEdiTag: React.Dispatch<React.SetStateAction<Tag>>
}) => {
  const [editTag, setEditTag] = React.useState<Tag>({id: -1, name: ''})
  React.useEffect(() => {
    publishEdiTag(editTag)
  }, [editTag, publishEdiTag])

  return (
    <Dialog.Input
      value={editTag?.name}
      onChangeText={(value: string) => {
        setEditTag({id: editTag?.id, name: value})
      }}
      label="Type here"
      numberOfLines={2}
    />
  )
}
