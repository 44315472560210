import React, {useCallback} from 'react'
import {Image, Text, View} from 'react-native'
import Svg, {Defs, LinearGradient, Path, Rect, Stop} from 'react-native-svg'
import {Trans} from '@lingui/macro'

import * as persisted from '#/state/persisted'
import {SdlButton} from '../com/util/SdlButton'
import {CenteredView} from '../com/util/Views'

export function PostGuide() {
  const [display, setDisplay] = React.useState(persisted.get('postGuide'))

  const close = useCallback(() => {
    setDisplay(false)
    persisted.write('postGuide', false)
  }, [])
  if (!display) {
    return null
  }
  return (
    <CenteredView
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Svg width="224" height="14" viewBox="0 0 224 14" fill="none">
        <Defs>
          <LinearGradient
            id="paint0_linear_2061_96505"
            x1="5"
            y1="7"
            x2="106"
            y2="7"
            gradientUnits="userSpaceOnUse">
            <Stop offset="0" stopColor="white" />
            <Stop offset="1" stopColor="white" stopOpacity="0" />
          </LinearGradient>
          <LinearGradient
            id="paint1_linear_2061_96505"
            x1="0"
            y1="4"
            x2="101"
            y2="4"
            gradientUnits="userSpaceOnUse">
            <Stop offset="0" stopColor="white" />
            <Stop offset="1" stopColor="white" stopOpacity="0" />
          </LinearGradient>
        </Defs>
        <Rect
          x="5"
          y="3"
          width="101"
          height="8"
          rx="4"
          fill="url(#paint0_linear_2061_96505)"
        />
        <Path
          d="M10 12.0793V1.92066C10 1.11163 9.08931 0.63748 8.42654 1.10142L2.34066 5.36154C1.20323 6.15774 1.20323 7.84226 2.34067 8.63847L8.42654 12.8986C9.08932 13.3625 10 12.8884 10 12.0793Z"
          fill="white"
        />
        <Path
          d="M214 12.0793V1.92066C214 1.11163 214.911 0.63748 215.573 1.10142L221.659 5.36154C222.797 6.15774 222.797 7.84226 221.659 8.63847L215.573 12.8986C214.911 13.3625 214 12.8884 214 12.0793Z"
          fill="white"
        />
        <Rect
          width="101"
          height="8"
          rx="4"
          transform="matrix(-1 0 0 1 219 3)"
          fill="url(#paint1_linear_2061_96505)"
        />
      </Svg>
      <Image
        style={[{height: 77, width: 77, marginTop: -10, marginLeft: 77}]}
        source={require('../../../assets/imgs/guideGesture.png')}
        accessibilityIgnoresInvertColors={true}
      />
      <Text
        style={{
          fontSize: 20,
          lineHeight: 26,
          fontWeight: 'bold',
          color: 'white',
          marginTop: 20,
          width: 246,
          textAlign: 'center',
        }}>
        <Trans>Swipe left and right to switch content</Trans>
      </Text>
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: 120,
          bottom: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <SdlButton
          text="No longer shown"
          style={{width: 180}}
          onPress={close}
        />
      </View>
    </CenteredView>
  )
}
