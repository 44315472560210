import React from 'react'
import {
  InteractionManager,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'
import {Image} from 'expo-image'
import {
  AppBskyEmbedExternal,
  AppBskyEmbedImages,
  AppBskyEmbedRecord,
  AppBskyEmbedRecordWithMedia,
  AppBskyFeedPost,
  ModerationDecision,
} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {ImagesLightbox, useLightboxControls} from '#/state/lightbox'
import {useLargeAltBadgeEnabled} from '#/state/preferences/large-alt-badge'
import * as imageSizes from 'lib/media/image-sizes'
import {Dimensions} from 'lib/media/types'
import {clamp} from 'lib/numbers'
import {atoms as a} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {MaybeQuoteEmbed} from '../../util/post-embeds/QuoteEmbed'
import {BigFigureSizeMode} from './BigFigureCard'

type Embed =
  | AppBskyEmbedRecord.View
  | AppBskyEmbedImages.View
  | AppBskyEmbedExternal.View
  | AppBskyEmbedRecordWithMedia.View
  | {$type: string; [k: string]: unknown}

export function BigFigurePostEmbeds({
  embed,
  // moderation,
  style,
  onOpen,
  allowNestedQuotes,
}: // record
// sizeMode,
{
  embed?: Embed
  moderation?: ModerationDecision
  onOpen?: () => void
  style?: StyleProp<ViewStyle>
  allowNestedQuotes?: boolean
  record?: AppBskyFeedPost.Record
  sizeMode?: BigFigureSizeMode
}) {
  // quote post with media
  // =
  if (AppBskyEmbedRecordWithMedia.isView(embed)) {
    return (
      <View style={[style, a.gap_lg]}>
        {/* <BigFigurePostEmbeds
          embed={embed.media}
          moderation={moderation}
          onOpen={onOpen}
        /> */}
        <MaybeQuoteEmbed embed={embed.record} onOpen={onOpen} />
      </View>
    )
  }

  if (AppBskyEmbedRecord.isView(embed)) {
    // custom feed embed (i.e. generator view)
    // if (AppBskyFeedDefs.isGeneratorView(embed.record)) {
    //   return <MaybeFeedCard view={embed.record} />
    // }

    // // list embed
    // if (AppBskyGraphDefs.isListView(embed.record)) {
    //   return <MaybeListCard view={embed.record} />
    // }

    // if (AppBskyGraphDefs.isStarterPackViewBasic(embed.record)) {
    //   return <StarterPackCard starterPack={embed.record} />
    // }

    // quote post
    // =
    return (
      <MaybeQuoteEmbed
        embed={embed}
        style={style}
        onOpen={onOpen}
        allowNestedQuotes={allowNestedQuotes}
      />
    )
  }

  // image embed
  // =
  if (AppBskyEmbedImages.isView(embed)) {
    return (
      <ImageEmbed
        images={embed.images}
        style={[
          // sizeMode === 'larger'
          //   ? [a.w_full, {maxHeight: 350, overflow: 'hidden'}]
          //   : [a.flex_1, {height: 60, overflow: 'hidden'}],
          [a.w_full, {maxHeight: 350, overflow: 'hidden'}],
        ]}
      />
    )
  }

  return <View />
}

export const ImageEmbed = ({
  images,
  style,
}: {
  images: AppBskyEmbedImages.ViewImage[]
  style?: StyleProp<ViewStyle>
}) => {
  const {_} = useLingui()
  const theme = useThemeName()
  const {openLightbox} = useLightboxControls()
  const largeAltBadge = useLargeAltBadgeEnabled()

  const items = images.map(img => ({
    uri: img.fullsize,
    alt: img.alt,
    aspectRatio: img.aspectRatio,
  }))
  const firstImageThumb = images[0]?.thumb
  const _openLightbox = (index: number) => {
    openLightbox(new ImagesLightbox(items, index))
  }
  const onPressIn = (_: number) => {
    InteractionManager.runAfterInteractions(() => {
      Image.prefetch(items.map(i => i.uri))
    })
  }

  const [dim, setDim] = React.useState<Dimensions | undefined>(
    images[0]?.aspectRatio || imageSizes.get(firstImageThumb),
  )

  const [aspectRatio, setAspectRatio] = React.useState<number>(
    dim ? calc(dim) : 1,
  )

  React.useEffect(() => {
    let aborted = false
    if (dim) {
      return
    }
    imageSizes.fetch(firstImageThumb).then(newDim => {
      if (aborted) {
        return
      }
      setDim(newDim)
      setAspectRatio(calc(newDim))
    })
  }, [dim, setDim, setAspectRatio, firstImageThumb])

  if (images.length > 0) {
    const {alt} = images[0]

    return (
      <Pressable
        accessibilityRole="button"
        onPress={() => _openLightbox(0)}
        onPressIn={() => onPressIn(0)}
        style={[
          style,
          styles.image,
          // {aspectRatio},
          theme !== 'light' && {borderWidth: 1, borderColor: '#262627'},
        ]}>
        <Image
          style={[styles.image, {aspectRatio}]}
          source={firstImageThumb}
          accessible={true}
          accessibilityIgnoresInvertColors
          accessibilityLabel={alt}
          accessibilityHint={_(msg`Tap to view fully`)}
        />

        {alt === '' ? null : (
          <View style={styles.altContainer}>
            <Text
              style={[styles.alt, largeAltBadge && a.text_xs]}
              accessible={false}>
              ALT
            </Text>
          </View>
        )}
      </Pressable>
    )
  }
}

function calc(dim: Dimensions) {
  if (dim.width === 0 || dim.height === 0) {
    return 1
  }
  return clamp(dim.width / dim.height, 0.33, 10)
}

const styles = StyleSheet.create({
  altContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderRadius: 6,
    paddingHorizontal: 6,
    paddingVertical: 3,
    position: 'absolute',
    right: 6,
    bottom: 6,
  },
  alt: {
    color: 'white',
    fontSize: 7,
    fontWeight: 'bold',
  },
  customFeedOuter: {
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 8,
    marginTop: 4,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  image: {
    resizeMode: 'contain',
    borderRadius: 10,
  },
})
