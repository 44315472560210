import React from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {Image} from 'expo-image'
import {Trans} from '@lingui/macro'

import {usePhotoLibraryPermission} from '#/lib/hooks/usePermissions'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {openCropper, openPicker} from '#/lib/media/picker'
import {validateCircleName} from '#/lib/strings/handles'
import {logger} from '#/logger'
import * as Toast from '#/view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as TextField from '#/components/forms/TextField'
import * as Toggle from '#/components/forms/Toggle'
import {IconCircle} from '#/components/IconCircle'
import {Camera_Filled_Stroke2_Corner0_Rounded as Camera} from '#/components/icons/Camera'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
import {TimesLarge_Stroke2_Corner0_Rounded as Times} from '#/components/icons/Times'
import {Loader} from '#/components/Loader'

type Props = {
  avatarUri?: string
  bio?: string
  name?: string
  isPrivate?: boolean
  isCreate?: boolean
  isPending?: boolean
  onChangePrivate?: (value: boolean) => void
  onChangeCircleName?: (value: string) => void
  onChangeCircleBio?: (value: string) => void
  onSelectBanner?: (img: RNImage | null) => void
  onNext?: () => void
}

export function CreateForm({
  avatarUri,
  bio,
  name = '',
  isPrivate,
  isCreate,
  isPending,
  onChangePrivate,
  onChangeCircleBio,
  onChangeCircleName,
  onSelectBanner,
  onNext,
}: Props) {
  const {isMobile} = useWebMediaQueries()
  const {requestPhotoAccessIfNeeded} = usePhotoLibraryPermission()
  const t = useTheme()
  const [error] = React.useState('')
  const [values, setPrivate] = React.useState<string[]>(() => {
    return isPrivate ? ['private'] : []
  })

  const validCheck = React.useMemo(() => {
    if (name) {
      return validateCircleName(name ?? '')
    }
    return {
      frontLength: false,
      handleChars: false,
      hyphenStartOrEnd: false,
      overall: false,
      totalLength: false,
    }
  }, [name])

  const onOpenLibrary = React.useCallback(async () => {
    if (!(await requestPhotoAccessIfNeeded())) {
      return
    }
    const items = await openPicker()
    if (!items[0]) {
      return
    }

    let [item] = items

    try {
      onSelectBanner?.(
        await openCropper({
          mediaType: 'photo',
          path: item.path,
          width: 1000,
          height: 1000,
          cropperCircleOverlay: true,
        }),
      )
      //   onSelectNewWallet?.(item)
      console.log(item)
    } catch (e: any) {
      if (!String(e).includes('Canceled')) {
        logger.error('Failed to crop banner', {error: e})
      }
    }
  }, [onSelectBanner, requestPhotoAccessIfNeeded])

  const onPrivate = () => {
    if (!values.includes('private')) {
      onChangePrivate?.(true)
      setPrivate(['private'])
    } else {
      onChangePrivate?.(false)
      setPrivate([])
    }
  }

  const handleUpdate = () => {
    if (!name) {
      Toast.show('Circle name is required', 'xmark')
      return
    }
    if (
      !validCheck.frontLength ||
      !validCheck.handleChars ||
      !validCheck.hyphenStartOrEnd ||
      !validCheck.totalLength
    ) {
      return
    }

    onNext?.()
  }

  return (
    <View style={[a.h_full, a.flex_col, a.gap_3xl]}>
      <View style={[a.p_lg]}>
        <View style={[a.align_center]}>
          {avatarUri ? (
            <Image
              testID="userBannerImage"
              style={styles.bannerImage}
              source={{uri: avatarUri}}
              accessible={true}
              accessibilityIgnoresInvertColors
            />
          ) : (
            <View testID="userBannerFallback" style={[styles.bannerImage]} />
          )}
          <Button
            label="Take a photo"
            style={[
              styles.editButtonContainer,
              a.flex_1,
              a.justify_center,
              a.align_center,
              a.p_2xl,
              // t.atoms.input_border,
              {
                width: 80,
                height: 80,
                borderRadius: 20,
              },
              !avatarUri && t.atoms.modalBg2,
            ]}
            onPress={onOpenLibrary}>
            <IconCircle
              icon={Camera}
              size="xl"
              iconStyle={[t.atoms.text]}
              style={[
                {
                  // width: 80,
                  // height: 80,
                  // borderRadius: 20,
                  backgroundColor: 'transparent',
                },
              ]}
            />
          </Button>
        </View>
        <View style={[a.mt_xl, {height: 69}]}>
          <TextField.LabelText style={[t.atoms.text]}>
            <Trans>Circle Name</Trans>
          </TextField.LabelText>
          <TextField.Root>
            <TextField.Input
              testID="handleInput"
              onChangeText={onChangeCircleName}
              // label={_(msg`Input your user handle`)}
              label=""
              value={name}
              autoCapitalize="none"
              autoCorrect={false}
              //   autoFocus
              autoComplete="off"
            />
          </TextField.Root>
        </View>
        {name !== '' && (
          <View
            style={[
              a.w_full,
              a.rounded_sm,
              a.border,
              a.p_md,
              a.gap_sm,
              t.atoms.border_contrast_low,
            ]}>
            {error ? (
              <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                <IsValidIcon valid={false} />
                <Text style={[a.text_md, a.flex_1]}>{error}</Text>
              </View>
            ) : undefined}
            {validCheck.hyphenStartOrEnd ? (
              <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                <IsValidIcon valid={validCheck.handleChars} />
                <Text style={[a.text_md, a.flex_1, t.atoms.text]}>
                  <Trans>Only supports letters, numbers, and hyphens.</Trans>
                </Text>
              </View>
            ) : (
              <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                <IsValidIcon valid={validCheck.hyphenStartOrEnd} />
                <Text style={[a.text_md, a.flex_1, t.atoms.text]}>
                  <Trans>Doesn't begin or end with a hyphen</Trans>
                </Text>
              </View>
            )}
            <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
              <IsValidIcon
                valid={validCheck.frontLength && validCheck.totalLength}
              />
              {!validCheck.totalLength ? (
                <Text style={[a.text_md, a.flex_1, t.atoms.text]}>
                  <Trans>No longer than 20 characters</Trans>
                </Text>
              ) : (
                <Text style={[a.text_md, a.flex_1, t.atoms.text]}>
                  <Trans>Contains at least 3 characters.</Trans>
                </Text>
              )}
            </View>
          </View>
        )}
        <View style={[a.mt_xl, {height: 146}]}>
          <TextField.LabelText style={[t.atoms.text]}>
            <Trans>Circle Description</Trans>
          </TextField.LabelText>
          <TextField.Root>
            <TextField.Input
              testID="circle bio input"
              onChangeText={onChangeCircleBio}
              label=""
              multiline
              maxLength={100}
              style={{height: 120}}
              value={bio}
              autoCapitalize="none"
              autoCorrect={false}
              //   autoFocus
              autoComplete="off"
            />
          </TextField.Root>
        </View>
        <View style={[a.mt_xl]}>
          <TextField.LabelText style={[t.atoms.text]}>
            <Trans>Private Circle</Trans>
          </TextField.LabelText>
          <TextField.SubLabelView
            style={[a.justify_between, a.align_center, a.flex_row, a.gap_md]}>
            <View style={[a.flex_1]}>
              <Text style={[a.text_sm, t.atoms.text_sub]}>
                <Trans>When a circle is private, only you can see it.</Trans>
              </Text>
            </View>
            <Toggle.Group
              // disabled={isCurrent}
              type="checkbox"
              label="Private"
              values={values}
              onChange={onPrivate}>
              <Toggle.Item name="private" label="Switch Mask">
                <Toggle.Switch />
              </Toggle.Item>
            </Toggle.Group>
          </TextField.SubLabelView>
        </View>
      </View>
      {!isMobile && (
        <View style={[a.justify_center, a.align_center, a.gap_md]}>
          {/* <Link to={'/circle/create/fee'} label={''}>
          <Text>
            <Trans>Create a fee based circle?</Trans>
          </Text>
        </Link> */}
          <Button
            style={{width: '80%'}}
            color="primary"
            variant="solid"
            size="medium"
            label="create circle"
            onPress={handleUpdate}>
            <Text style={[a.font_bold, a.text_md]}>
              {isCreate ? (
                <Trans>Create Circle</Trans>
              ) : (
                <Trans>Save Changes</Trans>
              )}
            </Text>
            {isPending && <Loader />}
          </Button>
        </View>
      )}
    </View>
  )
}

function IsValidIcon({valid}: {valid: boolean}) {
  const t = useTheme()
  if (!valid) {
    return <Times size="md" style={{color: t.palette.negative_500}} />
  }
  return <Check size="md" style={{color: t.palette.positive_700}} />
}

const styles = StyleSheet.create({
  editButtonContainer: {
    position: 'absolute',
    width: 34,
    height: 34,
    // bottom: 8,
    // right: 24,
    top: '50%',
    left: '50%',
    transform: [{translateX: -40}, {translateY: -40}],
    borderRadius: 17,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'rgba(0,0,0,0.5)',
    borderWidth: 1,
  },
  bannerImage: {
    width: 81,
    height: 81,
    borderRadius: 20,
    overflow: 'hidden',
  },
})
