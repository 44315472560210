import React, {useCallback, useEffect, useRef, useState} from 'react'
import {LayoutChangeEvent, ScrollView} from 'react-native'

import {isNative} from '#/platform/detection'
import {CircleItemProps} from '#/view/screens/Circle'
import {PressableWithHover} from '../util/PressableWithHover'
import {DraggableScrollView} from './DraggableScrollView'

export interface FeedTabBarProps {
  testID?: string
  selectedPage: number
  items: CircleItemProps[]
  onSelect?: (index: number) => void
  onPressSelected?: (index: number) => void
  renderItem?: (item: any) => JSX.Element
}

// How much of the previous/next item we're showing
// to give the user a hint there's more to scroll.
const OFFSCREEN_ITEM_WIDTH = 20

export function FeedTabBar({
  testID,
  selectedPage,
  items,
  onSelect,
  onPressSelected,
  renderItem,
}: FeedTabBarProps) {
  const scrollElRef = useRef<ScrollView>(null)
  const itemRefs = useRef<Array<Element>>([])
  const [itemXs, setItemXs] = useState<number[]>([])

  useEffect(() => {
    if (isNative) {
      // On native, the primary interaction is swiping.
      // We adjust the scroll little by little on every tab change.
      // Scroll into view but keep the end of the previous item visible.
      let x = itemXs[selectedPage] || 0
      x = Math.max(0, x - OFFSCREEN_ITEM_WIDTH)
      scrollElRef.current?.scrollTo({x})
    } else {
      // On the web, the primary interaction is tapping.
      // Scrolling under tap feels disorienting so only adjust the scroll offset
      // when tapping on an item out of view--and we adjust by almost an entire page.
      const parent = scrollElRef?.current?.getScrollableNode?.()
      if (!parent) {
        return
      }
      const parentRect = parent.getBoundingClientRect()
      if (!parentRect) {
        return
      }
      const {
        left: parentLeft,
        right: parentRight,
        width: parentWidth,
      } = parentRect
      const child = itemRefs.current[selectedPage]
      if (!child) {
        return
      }
      const childRect = child.getBoundingClientRect?.()
      if (!childRect) {
        return
      }
      const {left: childLeft, right: childRight, width: childWidth} = childRect
      let dx = 0
      if (childRight >= parentRight) {
        dx += childRight - parentRight
        dx += parentWidth - childWidth - OFFSCREEN_ITEM_WIDTH
      } else if (childLeft <= parentLeft) {
        dx -= parentLeft - childLeft
        dx -= parentWidth - childWidth - OFFSCREEN_ITEM_WIDTH
      }
      let x = parent.scrollLeft + dx
      x = Math.max(0, x)
      x = Math.min(x, parent.scrollWidth - parentWidth)
      if (dx !== 0) {
        parent.scroll({
          left: x,
          behavior: 'smooth',
        })
      }
    }
  }, [scrollElRef, itemXs, selectedPage])

  const onPressItem = useCallback(
    (index: number) => {
      onSelect?.(index)
      if (index === selectedPage) {
        onPressSelected?.(index)
      }
    },
    [onSelect, selectedPage, onPressSelected],
  )

  // calculates the x position of each item on mount and on layout change
  const onItemLayout = React.useCallback(
    (e: LayoutChangeEvent, index: number) => {
      const x = e.nativeEvent.layout.x
      setItemXs(prev => {
        const Xs = [...prev]
        Xs[index] = x
        return Xs
      })
    },
    [],
  )

  return (
    <DraggableScrollView
      testID={`${testID}-selector`}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      ref={scrollElRef}>
      {items.map((item, i) => {
        return (
          <PressableWithHover
            testID={`${testID}-selector-${i}`}
            key={`${item}-${i}`}
            ref={node => (itemRefs.current[i] = node)}
            onLayout={e => onItemLayout(e, i)}
            hoverStyle={[]}
            onPress={() => onPressItem(i)}>
            {renderItem?.({item})}
          </PressableWithHover>
        )
      })}
    </DraggableScrollView>
  )
}
