import {StyleSheet} from 'react-native'

import {colors} from 'lib/styles'

export const styles = StyleSheet.create({
  navText: {
    marginTop: 5,
    fontSize: 10,
  },
  navItemBox: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    // paddingLeft: 5,
    height: 71,
    width: '100%',
  },
  bottomBarWeb: {
    // @ts-ignore web-only
    position: 'fixed',
  },
  ctrl: {
    // flex: 1,
    width: '20%',
    paddingTop: 13,
    paddingBottom: 0,
    position: 'relative',
  },
  postBox: {
    position: 'absolute',
    left: '50%',
    top: -0,
    transform: [{translateX: -25}],
  },
  tabItem: {
    flex: 1,
  },
  notificationCount: {
    position: 'absolute',
    left: '52%',
    top: 8,
    paddingHorizontal: 4,
    borderRadius: 6,
    zIndex: 1,
  },
  notificationCountLight: {
    borderColor: colors.white,
  },
  notificationCountDark: {
    borderColor: colors.gray8,
  },
  notificationCountLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.white,
    fontVariant: ['tabular-nums'],
  },
  ctrlIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  ctrlIconSizingWrapper: {},
  homeIcon: {},
  feedsIcon: {},
  searchIcon: {
    top: -1,
  },
  bellIcon: {},
  profileIcon: {
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  messagesIcon: {},
  onProfile: {
    borderWidth: 1,
    borderRadius: 100,
  },
  webBoxShadow: {
    // @ts-ignore web-only
    boxShadow: '0px 4px 20px 0px rgba(252, 208, 16, 0.4)',
  },
})
