import React from 'react'
import {Image, ImageBackground, View} from 'react-native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useThemePrefs} from '#/state/shell'
import {BigFigureCardItem} from '#/view/com/posts/BigFigure/BigFigureCard'
import {Bevel} from '#/view/com/posts/MemoCard'
import {colors} from '#/view/com/util/CircleUtil'
import {Text} from '#/view/com/util/text/Text'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {atoms as a, useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {FONT_SIZE_CONFIG} from '../TextSizeSettings'

const POST_RECORD_DEMO_DATA = {
  text: 'You said you are an unmanaged wallet, but your private keys are all in plain text without any encryption.',
  $type: 'app.bsky.feed.post',
  langs: ['zh'],
  title:
    "DEXX's boss was exposed, with a junior high school education of 1994, and he was caught stealing from himself?",
  createdAt: '2024-10-10T09:39:01.199Z',
}

const POST_DEMO_DATA: any = {
  post: {
    author: {
      displayName: 'Nereidaevangej',
    },
    record: POST_RECORD_DEMO_DATA,
    replyCount: 0,
    repostCount: 0,
    likeCount: 0,
    opposeCount: 0,
    viewCount: 0,
  },
  record: POST_RECORD_DEMO_DATA,
  moderation: {
    did: '',
    isMe: false,
    causes: [],
  },
}

export function CardDemo({cardType}: {cardType: string}) {
  const theme = useThemeName()
  const {fontSizeMode} = useThemePrefs()
  const cardFontSize = FONT_SIZE_CONFIG[fontSizeMode.feed].feed

  return (
    <View style={[a.w_full]} pointerEvents="none">
      {cardType === 'Explore' ? (
        <BigFigureCardItem
          post={POST_DEMO_DATA.post}
          reason={undefined}
          feedContext={undefined}
          record={POST_DEMO_DATA.record}
          moderation={POST_DEMO_DATA?.moderation}
          parentAuthor={undefined}
          showReplyTo={false}
          isThreadParent={true}
        />
      ) : (
        <View style={[a.px_lg]}>
          <ImageBackground
            resizeMode="stretch"
            style={[
              a.relative,
              a.w_full,
              a.mt_md,
              a.px_xl,
              {
                height: 178,
                paddingVertical: 10,
                borderRadius: 16,
                backgroundColor:
                  colors[0]?.[theme === 'light' ? 'light' : 'dark'],
              },
            ]}>
            <Bevel />
            <View
              style={[a.w_full, a.flex_1, a.align_center, a.justify_center]}>
              <Text
                style={[
                  a.w_full,
                  a.font_semibold,
                  {
                    fontSize: cardFontSize.circle.text,
                    lineHeight: 28,
                    color: 'black',
                  },
                ]}
                numberOfLines={3}>
                {POST_RECORD_DEMO_DATA.text.replaceAll('\n', ' ')}
              </Text>
            </View>
          </ImageBackground>
        </View>
      )}
    </View>
  )
}

export function DetailDemo({cardType}: {cardType: string}) {
  const pal = usePalette('default')
  const t = useTheme()
  const {fontSizeMode} = useThemePrefs()
  const detailFontSize = FONT_SIZE_CONFIG[fontSizeMode.detail].detail

  return (
    <View style={[a.w_full, a.px_lg]}>
      {cardType === 'Explore' ? (
        <View style={[a.p_lg, t.atoms.big_figure_bg, {borderRadius: 4}]}>
          <View style={[a.flex_row, a.align_center, a.gap_md]}>
            <UserAvatar size={34} />
            <View>
              <Text
                type="xl-bold"
                style={[pal.text, a.self_start, a.text_xs, a.font_heavy]}
                numberOfLines={1}
                lineHeight={1}>
                {POST_DEMO_DATA.post.author.displayName}
              </Text>
              <Text style={[a.text_xs, {color: t.palette.gray_16}]}>
                @{POST_DEMO_DATA.post.author.displayName}
              </Text>
            </View>
          </View>
          <Text
            style={[
              pal.text,
              t.atoms.post_thread_text,
              {
                fontSize: detailFontSize.public.title,
                fontFamily: 'BigFigure',
                fontWeight: '900',
                marginBottom: 16,
                marginTop: 20,
              },
            ]}>
            {POST_DEMO_DATA.record.title}
          </Text>
          <Text
            style={[
              pal.text,
              t.atoms.post_thread_text,
              {
                fontSize: detailFontSize.public.text,
                marginBottom: 16,
              },
            ]}>
            {POST_DEMO_DATA.record.text}
          </Text>
        </View>
      ) : (
        <View style={[a.p_lg, {backgroundColor: '#FFE371', borderRadius: 16}]}>
          <View style={[a.flex_row, a.align_center, a.gap_md]}>
            <UserAvatar size={34} />
            <View>
              <Text
                type="xl-bold"
                style={[
                  pal.text,
                  a.self_start,
                  a.text_xs,
                  a.font_heavy,
                  {color: 'black'},
                ]}
                numberOfLines={1}
                lineHeight={1}>
                {POST_DEMO_DATA.post.author.displayName}
              </Text>
              <Text style={[a.text_xs, {color: t.palette.gray_16}]}>
                @{POST_DEMO_DATA.post.author.displayName}
              </Text>
            </View>
          </View>
          <View>
            <Text
              style={[
                a.my_md,
                a.font_semibold,
                {
                  fontSize: detailFontSize.circle.text,
                  color: 'black',
                },
              ]}>
              {POST_DEMO_DATA.record.text}
            </Text>
            <View
              style={[
                {opacity: 0.3, marginTop: 50},
                a.align_end,
                a.mb_2xl,
                a.pr_md,
              ]}>
              <Image
                source={require('../../../../assets/logo.png')}
                style={{width: 76, height: 52}}
                accessibilityIgnoresInvertColors
              />
            </View>
          </View>
        </View>
      )}
    </View>
  )
}
