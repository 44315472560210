import React, {useState} from 'react'
import {ImageBackground, Pressable, StyleSheet, Text, View} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

// import {trimArticle} from '#/view/com/posts/TranslationWidget/TranslationWidget'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {atoms as a, useTheme} from '#/alf'
import {AssetsScanIcon} from '#/components/icons/StakeIcons'
import {InlineLinkText} from '#/components/Link'
import {EntityItemProps} from './types'

type Props = {
  data: EntityItemProps | undefined
}

export default function EntityHeader({data}: Props) {
  const t = useTheme()
  const [limitLines, setLimitLines] = useState<boolean>(true)
  const [hasMore, setHasMore] = useState<boolean>(false)

  // useEffect(() => {
  //   if (data?.desc) {
  //     const {hasMoreText} = trimArticle(data?.desc, 240)
  //     if (hasMoreText) {
  //       setHasMore(true)
  //     }
  //   }
  // }, [data?.desc])

  return (
    <ImageBackground source={{uri: data?.avatar}}>
      <LinearGradient
        colors={
          t.name === 'dark'
            ? ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)']
            : ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 1)']
        }
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        style={styles.entityHeader}>
        <ViewHeader
          canGoBack
          alwaysWhite
          title={' '}
          style={[a.bg_transparent]}
          showOnDesktop
          showBorder={false}
        />
        {/* avatar & name & desc */}
        <View
          style={[
            a.flex_row,
            a.align_center,
            a.gap_lg,
            a.px_xl,
            a.mb_lg,
            a.pt_sm,
          ]}>
          <UserAvatar
            avatar={data?.avatar}
            size={64}
            type={
              data?.avatar && data?.avatar !== ''
                ? 'user'
                : data?.type === 3
                ? 'person'
                : 'organization'
            }
          />
          <View style={[a.gap_sm, a.flex_1]}>
            <Text
              style={[
                t.atoms.text,
                a.text_xl,
                a.font_bold,
                {fontFamily: 'BigFigure'},
              ]}
              numberOfLines={1}>
              {data?.name}
            </Text>
          </View>
        </View>
        {/* desc */}
        <View style={[a.px_xl, a.mb_sm, a.gap_xs]}>
          <Text
            style={[a.text_sm, t.atoms.post_thread_text, {lineHeight: 17}]}
            numberOfLines={limitLines ? 3 : undefined}
            onLayout={e => {
              const {height} = e.nativeEvent.layout
              if (height >= 51) {
                setHasMore(true)
              } else {
                setHasMore(false)
              }
            }}>
            {data?.desc}
          </Text>
          {hasMore && (
            <Pressable
              accessibilityRole="button"
              style={[a.flex_row, a.align_center, a.gap_xs, a.justify_end]}
              onPress={() => {
                setLimitLines(!limitLines)
              }}>
              <Text
                style={[
                  a.text_xs,
                  a.font_semibold,
                  {color: t.palette.primary_active},
                ]}>
                <Trans>{limitLines ? 'Read more' : 'Fold up'}</Trans>
              </Text>
              <FontAwesomeIcon
                icon={limitLines ? 'angle-down' : 'angle-up'}
                size={12}
                style={{color: t.palette.primary_active, marginTop: 2}}
              />
            </Pressable>
          )}
        </View>
        {/* links */}
        <View style={[a.px_xl, a.pb_lg, a.mb_sm]}>
          <View
            style={[
              a.w_full,
              a.relative,
              a.flex_row,
              a.align_center,
              a.gap_md,
            ]}>
            {data?.links?.map(item => {
              return (
                <InlineLinkText
                  to={item?.value}
                  label=""
                  key={item?.key}
                  style={[a.flex_row, a.align_center, a.justify_center]}>
                  <View
                    key={item?.key}
                    style={[
                      a.py_xs,
                      a.flex_row,
                      a.align_center,
                      a.justify_center,
                      {
                        backgroundColor:
                          t.name === 'dark'
                            ? 'rgba(255, 255, 255, 0.15)'
                            : 'rgba(0, 0, 0, 0.3)',
                        borderRadius: 4,
                        paddingHorizontal: 6,
                      },
                    ]}>
                    <Text
                      style={[
                        a.text_xs,
                        {
                          color:
                            t.name === 'dark'
                              ? t.palette.gray_16
                              : t.atoms.text_inverted.color,
                        },
                        ,
                        a.mr_xs,
                      ]}>
                      {item?.key}
                    </Text>
                    <AssetsScanIcon
                      primaryColor={
                        t.name === 'dark'
                          ? t.palette.gray_16
                          : t.atoms.text_inverted.color
                      }
                    />
                  </View>
                </InlineLinkText>
              )
            })}
          </View>
        </View>
      </LinearGradient>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  entityHeader: {
    // @ts-ignore
    backdropFilter: 'blur(40px)',
  },
})
