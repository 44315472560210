/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import React, {useEffect, useState} from 'react'
import {TextInput, View} from 'react-native'
import {StyleSheet, Text} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

// import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {InfoIcon} from '#/lib/icons'
import {capitalize} from '#/lib/strings/capitalize'
import {isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon, ButtonText} from '#/components/Button'
import {Telegram} from '#/components/icons/Telegram'
import {Loader} from '#/components/Loader'
import {apiHash, apiId} from './config'
import {CountryDropdown} from './CountryDropdown'
import {useTelegramClient} from './hooks'
// import {CountryDropdown} from './CountryDropdown'
import {ApiCountryCode} from './types'
import {normalizePhone} from './util'

interface IProps {
  // client: any
  updateLoginParams: (p: string) => void
  isExpired?: boolean
}
export function LoginBase(props: IProps) {
  const {updateLoginParams} = props
  const t = useTheme()
  const {_} = useLingui()
  const [phone, setPhone] = React.useState('')
  const {closeModal} = useModalControls()
  const [marginTop, setMarginTop] = React.useState(0)
  // const {isDesktop} = useWebMediaQueries()
  const {client} = useTelegramClient()
  const [country, setCountry] = React.useState<ApiCountryCode | null>({
    defaultName: 'China',
    name: null,
    iso2: 'CN',
    hidden: false,
    countryCode: '86',
    patterns: ['XXX XXXX XXXX'],
    prefixes: null,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    client?.connect()
  }, [client])

  const getCode = React.useCallback(async () => {
    try {
      if (client) {
        setLoading(true)
        await client.connect()
        await client.sendCode(
          {
            apiId,
            apiHash,
          },
          normalizePhone(phone),
        )
        setLoading(false)
        updateLoginParams(phone)
      }
    } catch (err: any) {
      setError(err?.errorMessage || err.message || 'Get phone code error')
      setLoading(false)
    }
  }, [client, phone, updateLoginParams])

  const formatPhone = (val: string) => {
    if (`+${country?.countryCode}`.indexOf(val) === 0) {
      setPhone(val)
      return
    }
    if (country?.patterns) {
      const _phone = val
        .replace(/[^\d]/g, '')
        .replace(`${country.countryCode}`, '')
        .split('')
      const pattern = country.patterns[0].split(' ')
      const len = pattern.length
      let result = []
      for (let i = 0; i < len; i++) {
        if (_phone.length > pattern[i].length) {
          result.push(_phone.splice(0, pattern[i].length).join(''))
        } else {
          result.push(_phone.join(''))
          break
        }
      }
      setPhone(
        [`+${country.countryCode}`, ...result.filter(Boolean)]
          .filter(Boolean)
          .join(' '),
      )
    } else {
      setPhone(val)
    }
  }
  const onPhoneFocus = () => {
    if (isWeb) {
      const url = new URL(location.href)
      const isFrame = url.searchParams.get('is_frame')
      if (isFrame && isFrame === '1') {
        setMarginTop(-60)
      }
    }
  }

  const onPhoneBlur = () => {
    if (isWeb) {
      const url = new URL(location.href)
      const isFrame = url.searchParams.get('is_frame')
      if (isFrame && isFrame === '1') {
        setMarginTop(0)
      }
    }
  }

  return (
    <View style={[styles.page, a.flex_1, a.py_5xl, {marginTop}]}>
      <View>
        <View
          style={[
            {
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              backgroundColor: '#2AABEE',
              width: 120,
              height: 120,
            },
            a.p_lg,
            a.rounded_full,
          ]}>
          <Telegram
            width={70}
            height={70}
            style={{color: '#fff', position: 'relative', left: -4}}
          />
        </View>
        <Text style={[styles.title, a.mt_2xl, t.atoms.text]}>
          <Trans>Telegram</Trans>
        </Text>
        {props?.isExpired ? (
          <View
            style={[
              a.flex_row,
              a.align_center,
              a.justify_center,
              a.gap_sm,
              a.mb_4xl,
            ]}>
            <InfoIcon />
            <Text
              style={[
                a.text_center,
                {color: t.palette.black_25, fontSize: 13},
              ]}>
              <Trans>Authorization expired, please try again.</Trans>
            </Text>
          </View>
        ) : (
          <Text style={[a.text_center, a.mb_4xl, {color: t.palette.black_25}]}>
            <Trans>
              Please confirm your country code and enter your phone number.
            </Trans>
          </Text>
        )}
        <View style={a.mb_md}>
          <Text style={[a.mb_sm, t.atoms.text]}>
            <Trans>Your Country</Trans>
          </Text>
          <CountryDropdown
            onFocus={onPhoneFocus}
            onBlur={onPhoneBlur}
            updateCountry={val => {
              setCountry(val)
              setPhone(`+${val.countryCode}`)
            }}
          />
        </View>
        <View style={[a.mb_md]}>
          <Text style={[a.mb_sm, error ? t.atoms.text_danger : t.atoms.text]}>
            {error ? (
              <Trans>
                {error === 'PHONE_PASSWORD_FLOOD'
                  ? 'Limit exceeded. Please try again later.'
                  : capitalize(error, '_', ' ')}
              </Trans>
            ) : (
              <Trans>Your Phone Number</Trans>
            )}
          </Text>

          <TextInput
            style={[
              a.border,
              error !== ''
                ? {borderColor: t.atoms.text_danger.color}
                : t.atoms.input_border,
              t.atoms.input_bg,
              a.py_md,
              a.px_sm,
              a.rounded_sm,
              t.atoms.text,
              {lineHeight: 20},
            ]}
            value={phone}
            placeholder={_(msg`Your phone number`)}
            placeholderTextColor={t.atoms.text_sub.color}
            onChangeText={val => {
              formatPhone(val)
              setError('')
            }}
            onFocus={onPhoneFocus}
            onBlur={onPhoneBlur}
            clearButtonMode="while-editing"
            // isInvalid={error !== ''}
          />
        </View>
      </View>

      <View
        style={[
          {gap: 24, marginTop: 16, padding: 0},
          // isDesktop ? a.flex_row_reverse : {},
        ]}>
        <Button
          style={[a.p_0, {gap: 24, backgroundColor: '#3390ED'}]}
          variant="solid"
          size="large"
          label="Next"
          disabled={loading || !phone}
          onPress={getCode}>
          <ButtonText
            style={{fontSize: 16, fontWeight: '600', color: '#ffffff'}}>
            <Trans>{loading ? 'PLEASE WAIT...' : 'Next'}</Trans>
          </ButtonText>
          {loading && <ButtonIcon size="md" icon={Loader} />}
        </Button>
        <Button
          style={[t.atoms.bg_btn_default]}
          onPress={() => {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'close_modal',
                }),
              )
            } else {
              closeModal()
            }
          }}
          label="Cancel"
          variant="solid"
          size="large">
          <ButtonText style={[a.font_bold, t.atoms.text_sub, a.text_md]}>
            <Trans>Cancel</Trans>
          </ButtonText>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    // position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    flex: 1,
    // padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
