import React from 'react'
import {FlatList, Pressable, ScrollView, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {fuzzyMatch} from '#/lib/hooks/Tools'
import {isMobileWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useFriendQueryRecommendsQuery} from '#/state/queries/connections'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {Telegram} from '#/components/icons/Telegram'
import {Loader} from '#/components/Loader'
import RecommendItem from './RecommendItem'

export const AddFriendPanel = () => {
  const t = useTheme()
  const {openModal} = useModalControls()

  const [query, setQuery] = React.useState('')

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const {status, data, error} = useFriendQueryRecommendsQuery({
    includeUnbindTgUser: false,
  })

  let content
  if (status === 'pending') {
    content = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (error) {
    content = (
      <View>
        <Text style={[t.atoms.text]}>{error?.message}</Text>
      </View>
    )
  } else if (data?.length === 0) {
    content = (
      <EmptyBox
        icon="heart"
        message={''}
        viewStyles={{paddingTop: 20, paddingBottom: 20}}
      />
    )
  } else {
    content = (
      <FlatList
        data={data?.filter(o => {
          if (query && o?.nickname) {
            return fuzzyMatch(o?.nickname, query) ||
              fuzzyMatch(o?.nickname, query)
              ? o
              : null
          } else {
            return o
          }
        })}
        renderItem={({item}) => <RecommendItem {...item} key={item?.id} />}
        keyExtractor={item => item.id.toString()}
      />
    )
  }

  return (
    <CenteredView
      style={[
        a.h_full,
        a.justify_between,
        {paddingBottom: isMobileWeb ? 90 : 0},
      ]}>
      <ViewHeader
        title={`Add Friends`}
        canGoBack
        showOnDesktop
        showBorder={false}
      />
      <View style={[a.my_md]}>
        <SearchInput
          query={query}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
          onSubmitQuery={() => {}}
          style={[
            a.border,
            t.atoms.input_bg,
            t.atoms.input_border,
            a.mx_lg,
            {borderRadius: 10},
          ]}
        />
      </View>
      <View style={[a.flex_1, a.px_lg]}>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            openModal({
              name: 'telegram',
              currentStep: TelegramBindStep.SelectContact,
            })
          }}
          style={[a.flex_row, a.align_center, a.gap_md, a.my_sm]}>
          <IconCircle
            style={[{width: 56, height: 56, backgroundColor: '#2aabee'}]}
            icon={Telegram}
            iconStyle={{color: t.palette.white}}
            size="xl"
          />
          <Text style={[a.text_md, a.font_semibold, {color: '#2BABEE'}]}>
            <Trans>Invite Telegram Friends</Trans>
          </Text>
        </Pressable>
        <ScrollView showsVerticalScrollIndicator={false}>{content}</ScrollView>
      </View>
    </CenteredView>
  )
}
