import React, {createContext, ReactNode, useContext} from 'react'

interface ISwipeContext {
  swipeKey: string
}

const SwipeContext = createContext<ISwipeContext | undefined>(undefined)

interface SwipeProviderProps {
  children: ReactNode
  swipeKey: string
}

export const SwipeProvider: React.FC<SwipeProviderProps> = ({
  children,
  swipeKey,
}) => {
  return (
    <SwipeContext.Provider value={{swipeKey}}>{children}</SwipeContext.Provider>
  )
}

export const useSwipeKey = (): string => {
  const context = useContext(SwipeContext)
  if (!context) {
    return ''
  }
  return context.swipeKey
}
