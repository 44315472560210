import React, {useEffect, useSyncExternalStore} from 'react'
import {StyleSheet, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {FEED_CIRCLE_URI, SQUARE_FEED_URI} from '#/lib/constants'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isMobileWeb} from '#/platform/detection'
import {FeedParams} from '#/state/queries/post-feed'
import {FeedPage} from '#/view/com/feeds/CircleFeedPage'
import {BigFigureProvider} from '#/view/com/posts/BigFigure/BigFigureProvider'
import {CircleProvider} from '#/view/com/posts/CircleProvider'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {atoms as a} from '#/alf'
import * as ToggleButton from '#/components/forms/ToggleButton'
import {createStore} from '../util/sdlStore/SdlStore'

export default function DetailFeedPage({feedParams}: {feedParams: FeedParams}) {
  const {_} = useLingui()
  const {isDesktop} = useWebMediaQueries()
  const {selectedTabType} = useDetailFeedStore()

  const [cardType, setCardType] = React.useState<string[]>(['Explore'])

  useEffect(() => {
    if (selectedTabType && selectedTabType !== '') {
      setCardType([selectedTabType])
    }
  }, [selectedTabType])

  return (
    <View style={[a.flex_1, a.w_full, a.h_full, {paddingBottom: 30}]}>
      <View style={[{width: 290}, a.pb_xs, a.px_md]}>
        <ToggleButton.Group
          label="PostType"
          values={cardType}
          onChange={setCardType}
          style={{
            paddingVertical: 4,
            paddingHorizontal: 4,
            borderRadius: 12,
          }}>
          <ToggleButton.Button name="Circle" label="From your circle">
            <ToggleButton.ButtonText>From your circle</ToggleButton.ButtonText>
          </ToggleButton.Button>
          <ToggleButton.Button name="Explore" label="Public tea">
            <ToggleButton.ButtonText>Public tea</ToggleButton.ButtonText>
          </ToggleButton.Button>
        </ToggleButton.Group>
      </View>
      {/* Circle Feed */}
      <View
        style={[
          {display: cardType[0] === 'Circle' ? 'flex' : 'none'},
          isMobileWeb && {paddingHorizontal: 10},
        ]}>
        <CircleProvider isCircle={true} isFromHome={false}>
          <FeedPage
            testID="circleDetailFeedPage"
            isPageFocused={true}
            feed={`feedgen|${FEED_CIRCLE_URI}`}
            feedParams={feedParams}
            renderEndOfFeed={() => (
              <View style={styles.emptyBox}>
                <EmptyBox
                  isMe
                  icon={'tea'}
                  message={_(
                    msg`Oops! No More tea yet.Invite your Telegram contacts or spill some tea now.`,
                  )}
                />
              </View>
            )}
            renderEmptyState={() => (
              <View style={styles.emptyBox}>
                <EmptyBox
                  isMe
                  icon={'tea'}
                  message={_(
                    msg`Oops! No tea yet.Invite your Telegram contacts or spill some tea now.`,
                  )}
                />
              </View>
            )}
            loadLatestBtnBottomPos={isMobileWeb && !isDesktop ? -30 : undefined}
          />
        </CircleProvider>
      </View>
      {/* Explore Feed */}
      <View
        style={[
          a.w_full,
          a.relative,
          {display: cardType[0] !== 'Circle' ? 'flex' : 'none'},
        ]}>
        <BigFigureProvider isBigFigure={true} isFromHome={false}>
          <FeedPage
            testID="exloreDetailFeedPage"
            isPageFocused={true}
            feed={`feedgen|${SQUARE_FEED_URI}`}
            feedParams={feedParams}
            renderEndOfFeed={() => (
              <View style={styles.emptyBox}>
                <EmptyBox
                  isMe
                  icon={'tea'}
                  message={_(msg`Oops! No More tea yet.Spill some tea now.`)}
                />
              </View>
            )}
            renderEmptyState={() => (
              <View style={styles.emptyBox}>
                <EmptyBox
                  isMe
                  icon={'tea'}
                  message={_(msg`Oops! No tea yet.Spill some tea now.`)}
                />
              </View>
            )}
            loadLatestBtnBottomPos={isMobileWeb && !isDesktop ? -30 : undefined}
          />
        </BigFigureProvider>
      </View>
    </View>
  )
}

const {subscribe, getState, setState} = createStore({selectedTabType: ''})
export function useDetailFeedStore() {
  const dataStore = useSyncExternalStore(subscribe, getState)
  const setSelectdCardType = (type: string) => {
    setState({selectedTabType: type})
  }

  return {
    selectedTabType: dataStore?.selectedTabType,
    setSelectdCardType,
  }
}

const styles = StyleSheet.create({
  emptyBox: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
})
