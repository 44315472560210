import React from 'react'
import {RouteProp, useRoute} from '@react-navigation/native'

import {handleReFresh} from '#/lib/api/feed/custom'
import {CommonNavigatorParams} from '#/lib/routes/types'
import DetailFeedPage from '#/view/com/feeds/DetailFeedPage'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a} from '#/alf'
import EntityHeader from './EntityHeader'
import {useEntityItem} from './hooks'

export function EntityDetailScreen() {
  const route = useRoute<RouteProp<CommonNavigatorParams, 'EntityDetail'>>()
  const {data} = useEntityItem(+route.params.id, route.params.type)
  handleReFresh(true)

  return (
    <CenteredView
      style={[a.w_full, a.h_full, a.justify_between, a.relative]}
      sideBorders
      isWidescreen={true}>
      <EntityHeader data={data} />
      <DetailFeedPage
        feedParams={{
          labelid: route.params.id,
          labeltype: route.params.type,
        }}
      />
    </CenteredView>
  )
}
