import React from 'react'
import {
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import Animated from 'react-native-reanimated'
// import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {Trans} from '@lingui/macro'

import {HITSLOP_20} from '#/lib/constants'
import {useCheckVersion} from '#/lib/hooks/Tools'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isWeb} from '#/platform/detection'
import {Text} from '#/components/Typography'

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity)

export function UpdateVersionBtn({hide}: {hide?: boolean}) {
  const {isDesktop} = useWebMediaQueries()
  const {isVersionOutdated, refreshPage} = useCheckVersion()
  // const insets = useSafeAreaInsets()
  const [leftOffset, setLeftOffset] = React.useState(0)
  const handleLayout = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout
    setLeftOffset(-width / 2)
  }

  if (hide) return null

  return (
    <>
      {isVersionOutdated ? (
        <View
          style={[
            styles.pos,
            isDesktop
              ? {top: 105, left: '50%'}
              : {
                  top: 100,
                  left: '50%',
                  transform: [{translateX: leftOffset}],
                },
          ]}
          onLayout={handleLayout}>
          <AnimatedTouchableOpacity
            style={[styles.loadLatest]}
            onPress={refreshPage}
            hitSlop={HITSLOP_20}
            accessibilityRole="button"
            accessibilityLabel={'update version'}
            accessibilityHint="">
            <Text style={{color: '#000'}}>
              <Trans>Update App</Trans>
            </Text>
          </AnimatedTouchableOpacity>
        </View>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  pos: {
    // @ts-ignore web only
    position: isWeb ? 'fixed' : 'absolute',
    // @ts-ignore web only
    left: 'calc(50vw - 60px)',
    flex: 1,
  },
  loadLatest: {
    width: 120,
    height: 32,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: '#FCD010',
    // @ts-ignore web only
    boxShadow: '0px 2px 10px 0px #00000033',
  },
})
