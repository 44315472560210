import React, {useEffect, useMemo, useSyncExternalStore} from 'react'

import {remove} from '#/lib/storage'
import {emitter} from '#/state/events'
import {useAgent} from '#/state/session'
import {TELEGRAM_SESSION_KEY} from '../../modals/TelegramModal/config'
import {useTelegramClient} from '../../modals/TelegramModal/hooks'
import {createStore} from './SdlStore'

const store = createStore({
  telegramId: '',
  userName: '',
  hasConnected: false,
})

const {subscribe, getState, setState: setTgStoreState} = store

export function useTgStore() {
  const agent = useAgent()
  const {client} = useTelegramClient()
  const tgStore = useSyncExternalStore(subscribe, getState)

  const resetTgStore = React.useCallback(async () => {
    await client?.disconnect()
    setTgStoreState({
      telegramId: '',
      userName: '',
      hasConnected: false,
    })
  }, [client])

  useEffect(() => {
    emitter.on('resetTgStore', resetTgStore)
    return () => {
      emitter.off('resetTgStore', resetTgStore)
    }
  }, [resetTgStore])
  const getTelegramInfo = () => {
    agent.com.atproto.server.getTelegramUserBasicInfo().then(async res => {
      if (res.data.telegramId && res.data.userName) {
        setTgStoreState({
          telegramId: res.data.telegramId,
          userName: res.data.userName,
          hasConnected: res.data.hasConnected,
        })
      }
    })
  }

  const unlinkTg = () => {
    agent.com.atproto.server.unlinkTg().then(() => {
      resetTgStore()
      remove(TELEGRAM_SESSION_KEY)
    })
  }

  const bind = useMemo(() => {
    return tgStore.hasConnected
  }, [tgStore])

  return {
    tgStore,
    getTelegramInfo,
    resetTgStore,
    unlinkTg,
    setTgStoreState,
    bind,
  }
}
