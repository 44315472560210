import React, {
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BackHandler,
  Keyboard,
  KeyboardAvoidingView,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import Animated from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {
  AppBskyFeedDefs,
  AppBskyFeedGetPostThread,
  AtUri,
  BskyAgent,
} from '@atproto/api'
import {RichText} from '@atproto/api'
import {
  FinEntity,
  ForbidEntity,
} from '@atproto/api/dist/client/types/app/bsky/feed/post'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'
import {observer} from 'mobx-react-lite'

import {until} from '#/lib/async/until'
import {
  createGIFDescription,
  parseAltFromGIFDescription,
} from '#/lib/gif-alt-text'
// import {DetectLanguage} from '#/lib/hooks/Tools'
import {NavigationProp} from '#/lib/routes/types'
import {logEvent} from '#/lib/statsig/statsig'
import {logger} from '#/logger'
import {emitPostCreated, emitter} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useModals} from '#/state/modals'
import * as persisted from '#/state/persisted'
import {useRequireAltTextEnabled} from '#/state/preferences'
import {
  toPostLanguages,
  useLanguagePrefs,
  useLanguagePrefsApi,
} from '#/state/preferences/languages'
import {usePayWalletQuery} from '#/state/queries/link-wallet'
import {useProfileQuery} from '#/state/queries/profile'
import {Gif} from '#/state/queries/tenor'
import {ThreadgateSetting} from '#/state/queries/threadgate'
import {useUploadVideo} from '#/state/queries/video/video'
import {useAgent, useSession} from '#/state/session'
import {useComposerControls} from '#/state/shell/composer'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {useBet} from '#/state/transaction/bet'
import {useStake} from '#/state/transaction/stake'
import {useAnalytics} from 'lib/analytics/analytics'
import * as apilib from 'lib/api/index'
import {MAX_GRAPHEME_LENGTH} from 'lib/constants'
import {useIsKeyboardVisible} from 'lib/hooks/useIsKeyboardVisible'
import {cleanError} from 'lib/strings/errors'
import {insertMentionAt} from 'lib/strings/mention-manip'
import {shortenLinks} from 'lib/strings/rich-text-manip'
import {s} from 'lib/styles'
import {isAndroid, isIOS, isNative, isWeb} from 'platform/detection'
import {useDialogStateControlContext} from 'state/dialogs'
import {GalleryModel} from 'state/models/media/gallery'
import {ComposerOpts} from 'state/shell/composer'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {ArrowTriangleBottom_Stroke2_Corner1 as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
import * as Prompt from '#/components/Prompt'
import {
  SELET_TYPE,
  VisibleRangeType,
} from '../modals/PostModal/PostSelectCircle'
import {TVisible} from '../modals/PostModal/Visible'
import {QuoteEmbed, QuoteX} from '../util/post-embeds/QuoteEmbed'
import {usePostMask} from '../util/post-mask'
import {usePostStepStore} from '../util/sdlStore/PostStepStore'
import {usePostTargetStore} from '../util/sdlStore/PostTargetStore'
import {useTgStore} from '../util/sdlStore/TgStore'
import {Text} from '../util/text/Text'
import * as Toast from '../util/Toast'
import ComposerFooter from './ComposerFooter'
import ComposerTopBar, {useAnimatedBorders} from './ComposerTopBar'
import {ExternalEmbed} from './ExternalEmbed'
import {GifAltText} from './GifAltText'
import {Gallery} from './photos/Gallery'
import {Credibility} from './postBox/Credibility'
import {TargetPreview} from './postBox/TargetPreview'
import {SdlTextInput} from './text-input/SdlTextInput'
import {TextInput, TextInputRef} from './text-input/TextInput'
import {useExternalLinkFetch} from './useExternalLinkFetch'
import {VideoPreview} from './videos/VideoPreview'
import {VideoTranscodeProgress} from './videos/VideoTranscodeProgress'

type CancelRef = {
  onPressCancel: () => void
}

type Props = ComposerOpts
export const ComposePost = observer(function ComposePost({
  replyTo,
  onPost,
  quote: initQuote,
  mention: initMention,
  openPicker,
  text: initText,
  imageUris: initImageUris,
  cancelRef,
  placeholderText,
}: Props & {
  cancelRef?: React.RefObject<CancelRef>
}) {
  const queryClient = useQueryClient()
  const agent = useAgent()
  const {isModalActive} = useModals()
  const {closeComposer} = useComposerControls()
  const {track} = useAnalytics()
  const {_} = useLingui()
  const requireAltTextEnabled = useRequireAltTextEnabled()
  const langPrefs = useLanguagePrefs()
  const setLangPrefs = useLanguagePrefsApi()
  const textInput = useRef<TextInputRef>(null)
  const discardPromptControl = Prompt.usePromptControl()
  const {closeAllDialogs} = useDialogStateControlContext()
  const {closeAllModals, openModal} = useModalControls()
  const {currentAccount} = useSession()
  const {data: payWalletAddress} = usePayWalletQuery(
    currentAccount?.did ?? '',
    true,
    0,
  )
  const {data: currentProfile} = useProfileQuery({did: currentAccount?.did})
  const t = useTheme()
  const [title, setTitle] = useState('')
  const [targets, setTargets] = useState<FinEntity[]>([])
  const {
    targetStore: {selectedArray},
    setSelectedTargetStore,
  } = usePostTargetStore()
  const {
    stepStore: {guarantorParams},
  } = usePostStepStore()
  const selectedFeed = useSelectedFeed()

  const {bind: hasBindTelegram} = useTgStore()
  const lastSelectedHomeFeedTab = persisted.get('lastSelectedHomeFeedTab')

  const [visible, setVisible] = useState<TVisible>(
    selectedFeed?.includes('circle') ? 'Circle' : 'Public',
  )
  const isSltCircle =
    selectedFeed?.includes('circle') &&
    lastSelectedHomeFeedTab?.circle?.id !== '-1'
  const [visibleRange, setVisibleRange] = useState<VisibleRangeType>({
    visibleType: isSltCircle ? 3 : 1,
    visibleCircles: isSltCircle
      ? [
          {
            id: lastSelectedHomeFeedTab?.circle?.id,
            name: lastSelectedHomeFeedTab?.circle?.name,
            isSelected: true,
          },
        ]
      : [],
    visibleFriends: [],
    unVisibleTags: [],
    unVisibleFriends: [],
  })
  const [operation, setOperation] = useState('0')

  const {
    finishStake,
    finishBet,
    setStakeParams,
    setBetParams,
    setPostInfo,
    nextStep,
  } = usePostStepStore()

  const {showStakeModal, amount, response, onStake} = useStake({
    type: 'create-post-stake',
  })
  const {
    showBetModal,
    amount: betAmount,
    response: betResponse,
    onCreateBet,
  } = useBet({type: 'create-bet'})
  const {sltAccount, markAsControl, makeAsDialog, isAnonymous} = usePostMask({
    visible,
    replyTo,
  })

  useEffect(() => {
    if (response) {
      finishStake()
    }
  }, [response, finishStake])

  useEffect(() => {
    if (betResponse) {
      finishBet()
    }
  }, [betResponse, finishBet])

  useEffect(() => {
    setTargets(
      selectedArray.map(tar => {
        return {
          id: tar.id,
          name: tar.name,
          type: tar.type,
          avatar: tar.logo,
        }
      }),
    )
  }, [selectedArray])

  const [isKeyboardVisible] = useIsKeyboardVisible({iosUseWillEvents: true})
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState('')

  const [richtext, setRichText] = useState(
    new RichText({
      text: initText
        ? initText
        : initMention
        ? insertMentionAt(
            `@${initMention}`,
            initMention.length + 1,
            `${initMention}`,
          ) // insert mention if passed in
        : '',
    }),
  )
  const graphemeLength = useMemo(() => {
    return shortenLinks(richtext).graphemeLength
  }, [richtext])
  const [quote, setQuote] = useState<ComposerOpts['quote'] | undefined>(
    initQuote,
  )

  const {clearVideo, state: videoUploadState} = useUploadVideo({
    setStatus: () => {},
    onSuccess: () => {
      if (publishOnUpload) {
        onPressPublish(true)
      }
    },
  })
  const [publishOnUpload, setPublishOnUpload] = useState(false)

  const {extLink, setExtLink} = useExternalLinkFetch({setQuote})
  const [extGif, setExtGif] = useState<Gif>()
  const [labels] = useState<string[]>([])
  const [threadgate] = useState<ThreadgateSetting[]>([])

  const gallery = useMemo(
    () => new GalleryModel(initImageUris),
    [initImageUris],
  )
  const onClose = useCallback(() => {
    closeComposer()
  }, [closeComposer])

  const insets = useSafeAreaInsets()
  const viewStyles = useMemo(
    () => ({
      paddingTop: isAndroid ? insets.top : 0,
      paddingBottom:
        isAndroid || (isIOS && !isKeyboardVisible) ? insets.bottom : 0,
    }),
    [insets, isKeyboardVisible],
  )

  const onPressCancel = useCallback(() => {
    if (graphemeLength > 0 || !gallery.isEmpty || extGif) {
      closeAllDialogs()
      Keyboard.dismiss()
      discardPromptControl.open()
    } else {
      onClose()
    }
    // delete mention node
    if (isWeb) {
      const elements =
        document && document?.querySelectorAll('[data-tippy-root]')
      elements.forEach(element => {
        element.remove()
      })
    }
  }, [
    extGif,
    graphemeLength,
    gallery.isEmpty,
    closeAllDialogs,
    discardPromptControl,
    onClose,
  ])

  useImperativeHandle(cancelRef, () => ({onPressCancel}))

  // On Android, pressing Back should ask confirmation.
  useEffect(() => {
    if (!isAndroid) {
      return
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        if (closeAllDialogs() || closeAllModals()) {
          return true
        }
        onPressCancel()
        return true
      },
    )
    return () => {
      backHandler.remove()
    }
  }, [onPressCancel, closeAllDialogs, closeAllModals])

  // listen to escape key on desktop web
  const onEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onPressCancel()
      }
    },
    [onPressCancel],
  )
  useEffect(() => {
    if (isWeb && !isModalActive) {
      window.addEventListener('keydown', onEscape)
      return () => window.removeEventListener('keydown', onEscape)
    }
  }, [onEscape, isModalActive])

  const onNewLink = useCallback(
    (uri: string) => {
      if (extLink != null) return
      setExtLink({uri, isLoading: true})
    },
    [extLink, setExtLink],
  )

  const onPhotoPasted = useCallback(
    async (uri: string) => {
      track('Composer:PastedPhotos')
      await gallery.paste(uri)
    },
    [gallery, track],
  )

  const isAltTextRequiredAndMissing = useMemo(() => {
    if (!requireAltTextEnabled) return false

    if (gallery.needsAltText) return true
    if (extGif) {
      if (!extLink?.meta?.description) return true

      const parsedAlt = parseAltFromGIFDescription(extLink.meta.description)
      if (!parsedAlt.isPreferred) return true
    }
    return false
  }, [gallery.needsAltText, extLink, extGif, requireAltTextEnabled])

  const onPressVisible = () => {
    if (hasBindTelegram) {
      openModal({
        name: 'visible',
        setVisible: setVisible,
        visible: visible,
        visibleRange,
        setVisibleRange,
      })
    } else {
      Toast.show(_(msg`Please link your Telegram to enable Circle`), 'comment')
    }
  }

  const navigation = useNavigation<NavigationProp>()

  const onPostUri = (resultUri: string, createDid: string) => {
    const urip = new AtUri(resultUri)

    const viewText = (
      <Text style={{color: '#C28C00', fontWeight: 600, marginLeft: 4}}>
        <Trans>View</Trans>
      </Text>
    )
    const onPressView = () => {
      navigation.navigate('PostThread', {name: createDid, rkey: urip.rkey})
      Toast.hide()
    }
    const extra = () => {
      return (
        <TouchableOpacity accessibilityRole="button" onPress={onPressView}>
          {viewText}
        </TouchableOpacity>
      )
    }
    Toast.show(_(msg`Your tea was spilt.`), 'check', 5000, extra())
  }

  const onPressPublish = async (finishedUploading?: boolean) => {
    if (!canPost) return
    if (isProcessing || graphemeLength > MAX_GRAPHEME_LENGTH) {
      return
    }

    if (isAltTextRequiredAndMissing) {
      return
    }

    if (
      !finishedUploading &&
      videoUploadState.status !== 'idle' &&
      videoUploadState.asset
    ) {
      setPublishOnUpload(true)
      return
    }

    setError('')

    if (
      richtext.text.trim().length === 0 &&
      gallery.isEmpty &&
      !extLink &&
      !quote
    ) {
      setError(_(msg`Did you want to say anything?`))
      return
    }
    if (extLink?.isLoading) {
      setError(_(msg`Please wait for your link card to finish loading`))
      return
    }

    setIsProcessing(true)
    if (amount || betAmount || guarantorParams) {
      openModal({
        name: 'post-step',
      })
    }

    let postUri, createDid, postCid
    // detectLanguage = await DetectLanguage(title + richtext.text)

    try {
      const requestParams: apilib.PostOpts = {
        rawText: richtext.text,
        replyTo: replyTo?.uri,
        images: gallery.images,
        quote,
        extLink,
        labels,
        threadgate,
        title: title,
        targets: targets.length > 0 ? targets : undefined,
        // onStateChange: setProcessingState,
        onStateChange: () => {},
        langs: toPostLanguages(langPrefs.postLanguage),
        // langs: false
        //   ? [detectLanguage]
        //   : toPostLanguages(langPrefs.postLanguage),
      }

      if (!quote && !replyTo) {
        let visibleCircles: string[] = [visible as string]
        let forbidIds: ForbidEntity[] = []
        let visibleIds: ForbidEntity[] = []
        if (visibleRange?.visibleType === 2) {
          visibleIds = [{type: 'CF', value: 'NA'}]
        } else if (visibleRange?.visibleType === 3) {
          visibleIds = visibleRange.visibleCircles
            ?.filter(o => o?.isSelected)
            ?.map(o => {
              return {type: 'CIRCLE', value: o?.id}
            })
            .concat(
              visibleRange.visibleFriends?.map(o => {
                if (o?.from === 'telegram') {
                  return {type: 'TELE', value: o?.id}
                } else if (o?.from === 'twitter') {
                  return {type: 'TWITTER', value: o?.id}
                } else {
                  return {type: 'SIPZ', value: o?.id}
                }
              }),
            )
        } else if (visibleRange?.visibleType === 4) {
          forbidIds = visibleRange.unVisibleTags
            ?.map(o => {
              return {type: 'TAG', value: o?.id + ''}
            })
            .concat(
              visibleRange.unVisibleFriends?.map(o => {
                if (o?.from === 'telegram') {
                  return {type: 'TELE', value: o?.id}
                } else if (o?.from === 'twitter') {
                  return {type: 'TWITTER', value: o?.id}
                } else {
                  return {type: 'SIPZ', value: o?.id}
                }
              }),
            )
        }
        requestParams.visibleCircles = visibleCircles
        requestParams.forbidIds = forbidIds
        requestParams.visibleIds = visibleIds
      }
      requestParams.createDid = sltAccount?.did
      createDid = sltAccount?.handle
      // isAnonymous
      requestParams.hideAuthor =
        visible === 'Public' ? 'false' : isAnonymous?.toString()
      if (requestParams.hideAuthor === 'true' && sltAccount?.did) {
        requestParams.createDid = await apilib.createOrGetAnonAccount(
          agent,
          sltAccount?.did,
        )
        createDid = requestParams.createDid
      }
      if (operation && operation !== '0') {
        requestParams.operation = true
        requestParams.operationType = operation
        requestParams.hideAuthor = 'false'
      }
      const result = await apilib.post(agent, requestParams)

      if (!quote && !replyTo) {
        emitter.emit('addNewPost', result.uri)
      }
      postUri = result.uri
      postCid = result.cid
      try {
        await whenAppViewReady(agent, postUri, res => {
          const thread = res.data.thread
          return AppBskyFeedDefs.isThreadViewPost(thread)
        })
      } catch (waitErr: any) {
        logger.error(waitErr, {
          message: `Waiting for app view failed`,
        })
        // Keep going because the post *was* published.
      }
    } catch (e: any) {
      logger.error(e, {
        message: `Composer: create post failed`,
        hasImages: gallery.size > 0,
      })

      if (extLink) {
        setExtLink({
          ...extLink,
          isLoading: true,
          localThumb: undefined,
        } as apilib.ExternalEmbedDraft)
      }
      let err = cleanError(e.message)
      if (err.includes('not locate record')) {
        err = _(
          msg`We're sorry! The post you are replying to has been deleted.`,
        )
      }
      setError(err)
      setIsProcessing(false)
      return
    } finally {
      if (postUri) {
        logEvent('post:create', {
          imageCount: gallery.size,
          isReply: replyTo != null,
          hasLink: extLink != null,
          hasQuote: quote != null,
          langs: langPrefs.postLanguage,
          logContext: 'Composer',
        })
      }
      track('Create Post', {
        imageCount: gallery.size,
      })
      setSelectedTargetStore([])
      if (replyTo && replyTo.uri) track('Post:Reply')
    }
    setLangPrefs.savePostLanguageToHistory()
    onPost?.(postUri)
    if (replyTo) {
      Toast.show(_(msg`Your reply has been published`))
    } else if (createDid) {
      onPostUri(postUri, createDid)
    }

    if (postUri && !replyTo) {
      queryClient.invalidateQueries({
        queryKey: [
          'post-feed',
          `author|${currentProfile?.did}|posts_and_author_threads`,
          {},
        ],
      })
      emitPostCreated(postUri)
      if (amount || betAmount || guarantorParams) {
        if (amount) {
          setStakeParams({
            rKey: new AtUri(postUri).rkey,
            transPostAuthor: {
              did: currentAccount?.did || '',
              payWallet: payWalletAddress,
              avatar: sltAccount?.avatar ?? currentProfile?.avatar ?? '',
              displayName:
                currentProfile?.displayName ??
                formatHandler(currentProfile?.handle || ''),
              createDid: sltAccount?.did ?? (currentAccount?.did || ''),
            },
          })
        }
        if (betAmount) {
          setBetParams({
            rKey: new AtUri(postUri).rkey,
            transPostAuthor: {
              did: currentAccount?.did || '',
              payWallet: payWalletAddress,
              avatar: sltAccount?.avatar ?? currentProfile?.avatar ?? '',
              displayName:
                currentProfile?.displayName ??
                formatHandler(currentProfile?.handle || ''),
              createDid: sltAccount?.did ?? (currentAccount?.did || ''),
            },
          })
        }
        if (guarantorParams) {
          setPostInfo({
            uri: postUri || '',
            cid: postCid,
            did: currentAccount?.did || '',
          })
        }
        nextStep()
      } else {
        // detect linked wallet
        const hideRemind = persisted.get('hideLinkWalletRemind')
        if (
          false &&
          !hideRemind &&
          !replyTo &&
          !quote &&
          (!payWalletAddress || payWalletAddress === '')
        ) {
          openModal({name: 'detect-link-wallet'})
        }
        onClose()
      }
    } else {
      onClose()
    }
  }

  const canPost = useMemo(
    () =>
      graphemeLength <= MAX_GRAPHEME_LENGTH &&
      !isAltTextRequiredAndMissing &&
      graphemeLength > 0,
    [graphemeLength, isAltTextRequiredAndMissing],
  )
  const selectTextInputPlaceholder = replyTo
    ? _(msg`Write your reply`)
    : placeholderText
    ? placeholderText
    : _(msg`Spill the tea! What’s on your mind?...`)

  const canSelectImages =
    gallery.size < 4 &&
    !extLink &&
    videoUploadState.status === 'idle' &&
    !videoUploadState.video

  const handleChangeGifAltText = useCallback(
    (altText: string) => {
      setExtLink(ext =>
        ext && ext.meta
          ? {
              ...ext,
              meta: {
                ...ext.meta,
                description: createGIFDescription(
                  ext.meta.title ?? '',
                  altText,
                ),
              },
            }
          : ext,
      )
    },
    [setExtLink],
  )

  const {scrollHandler, onScrollViewContentSizeChange, onScrollViewLayout} =
    useAnimatedBorders()

  const keyboardVerticalOffset = useKeyboardVerticalOffset()
  const isReply = !!replyTo

  console.log('composer', error, viewStyles, keyboardVerticalOffset)

  return (
    <KeyboardAvoidingView
      testID="composePostView"
      behavior={isIOS ? 'padding' : 'height'}
      // keyboardVerticalOffset={keyboardVerticalOffset}
      style={[a.flex_1]}>
      <View
        style={[a.flex_1 /*viewStyles*/]}
        aria-modal
        accessibilityViewIsModal>
        <ComposerTopBar
          sltAccount={sltAccount}
          markAsControl={markAsControl}
          onPressCancel={onPressCancel}
          quote={quote}
          visible={visible}
          isAnonymous={isAnonymous}
          isProcessing={isProcessing}
          onPressPublish={onPressPublish}
          canPost={canPost}
          isReply={isReply}
        />
        <TouchableWithoutFeedback
          accessibilityRole="combobox"
          onPress={() => {
            !isWeb && Keyboard.dismiss()
          }}>
          <View style={[a.flex_1, t.atoms.modalBg2]}>
            <View style={[a.flex_1]} aria-modal accessibilityViewIsModal>
              {/* Visible to */}
              {!isReply && !quote && (
                <View style={[{paddingHorizontal: 12, paddingTop: 16}]}>
                  <TouchableOpacity
                    accessibilityRole="button"
                    style={{flexDirection: 'row', alignItems: 'center'}}
                    onPress={onPressVisible}>
                    <Text style={[t.atoms.text, a.font_bold, {fontSize: 14}]}>
                      <Trans>Visible To</Trans>
                    </Text>
                    <View style={{flex: 1}} />
                    <View style={[a.flex_row, a.align_center]}>
                      <Text
                        style={[t.atoms.text, {fontSize: 14, marginRight: 6}]}>
                        {visible}
                        {visible === 'Circle' ? ' : ' : ''}
                      </Text>
                      {visible === 'Circle' && (
                        <>
                          {visibleRange.visibleType === 1 && (
                            <Text style={[t.atoms.text]}>
                              {SELET_TYPE?.[0]?.name}
                            </Text>
                          )}
                          {visibleRange.visibleType === 2 && (
                            <Text style={[t.atoms.text]}>
                              {SELET_TYPE?.[1]?.name}
                            </Text>
                          )}
                          {visibleRange.visibleType === 3 && (
                            <Text style={[t.atoms.text]}>
                              {SELET_TYPE?.[2]?.name} (
                              {visibleRange.visibleFriends?.length > 0
                                ? `${visibleRange.visibleFriends?.length} Friends`
                                : ''}
                              {visibleRange.visibleFriends?.length > 0 &&
                              visibleRange.visibleCircles?.length > 0
                                ? ' and '
                                : ''}
                              {visibleRange.visibleCircles?.filter(
                                o => o?.isSelected,
                              )?.length > 0
                                ? `${
                                    visibleRange.visibleCircles?.filter(
                                      o => o?.isSelected,
                                    )?.length
                                  } Circles`
                                : ''}
                              )
                            </Text>
                          )}
                          {visibleRange.visibleType === 4 && (
                            <Text style={[t.atoms.text]}>
                              <Text style={{color: '#FF543D'}}>
                                {SELET_TYPE?.[3]?.name}{' '}
                              </Text>
                              (
                              {visibleRange.unVisibleTags?.length > 0
                                ? `${visibleRange.unVisibleTags?.length} Tags`
                                : ''}
                              {visibleRange.unVisibleFriends?.length > 0 &&
                              visibleRange.unVisibleTags?.length > 0
                                ? ' and '
                                : ''}
                              {visibleRange.unVisibleFriends?.length > 0
                                ? `${visibleRange.unVisibleFriends?.length} Friends`
                                : ''}
                              )
                            </Text>
                          )}
                        </>
                      )}
                    </View>
                    <ArrowTriangleBottom
                      style={{
                        color: t.palette.contrast_975,
                        width: 16,
                        height: 16,
                        marginLeft: 6,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
              {/* Title & Content*/}
              <View style={[styles.textInputLayout, isNative && [a.flex_1]]}>
                {/* Title */}
                {!isReply && !quote && visible !== 'Circle' && (
                  <View style={{paddingHorizontal: 12}}>
                    <SdlTextInput
                      text={title}
                      setText={setTitle}
                      style={styles.sdlInput}
                      placeholder="Title (Optional)"
                      amxLength={100}
                    />
                  </View>
                )}
                {/* Content */}
                <Animated.ScrollView
                  onScroll={scrollHandler}
                  keyboardShouldPersistTaps="always"
                  onContentSizeChange={onScrollViewContentSizeChange}
                  onLayout={onScrollViewLayout}
                  contentContainerStyle={[a.flex_1, {flexGrow: 1}]}>
                  <TextInput
                    ref={textInput}
                    richtext={richtext}
                    placeholder={selectTextInputPlaceholder}
                    autoFocus
                    setRichText={setRichText}
                    onPhotoPasted={onPhotoPasted}
                    onPressPublish={() => onPressPublish()}
                    onNewLink={onNewLink}
                    onError={setError}
                    accessible={true}
                    accessibilityLabel={_(msg`Write post`)}
                    accessibilityHint={_(
                      msg`Compose posts up to ${MAX_GRAPHEME_LENGTH} characters in length`,
                    )}
                  />
                </Animated.ScrollView>
              </View>
              {!isReply && (
                <>
                  {gallery.isEmpty && extLink && (
                    <View style={[a.relative, a.px_md]}>
                      <ExternalEmbed
                        link={extLink}
                        gif={extGif}
                        onRemove={() => {
                          setExtLink(undefined)
                          setExtGif(undefined)
                        }}
                      />
                      <GifAltText
                        link={extLink}
                        gif={extGif}
                        onSubmit={handleChangeGifAltText}
                      />
                    </View>
                  )}

                  <View style={[a.mt_md]}>
                    {quote ? (
                      <View style={[s.mt5, s.mb2, a.px_md, isWeb && s.mb10]}>
                        <View style={{pointerEvents: 'none'}}>
                          <QuoteEmbed quote={quote} />
                        </View>
                        {quote.uri !== initQuote?.uri && (
                          <QuoteX onRemove={() => setQuote(undefined)} />
                        )}
                      </View>
                    ) : null}
                    {videoUploadState.status === 'compressing' &&
                    videoUploadState.asset ? (
                      <VideoTranscodeProgress
                        asset={videoUploadState.asset}
                        progress={videoUploadState.progress}
                      />
                    ) : videoUploadState.video ? (
                      // remove suspense when we get rid of lazy
                      <Suspense fallback={null}>
                        <VideoPreview
                          video={videoUploadState.video}
                          clear={clearVideo}
                        />
                      </Suspense>
                    ) : null}
                  </View>
                  {/* <SuggestedLanguage text={richtext.text} /> */}
                </>
              )}
            </View>

            {/* preview image and target========================================================================= */}
            <View style={[{paddingHorizontal: 12}]}>
              <Gallery gallery={gallery} />
              <TargetPreview previewArray={selectedArray} />
            </View>

            {/* bottom control img and target ==================================================================== */}
            <ComposerFooter
              videoUploadState={videoUploadState}
              canSelectImages={canSelectImages}
              gallery={gallery}
              quote={quote}
              operation={operation}
              setOperation={setOperation}
              textInput={textInput}
              isReply={isReply}
              richtext={richtext}
              setRichText={setRichText}
              openPicker={openPicker}
            />
            {/* More options */}
            {!isReply && !quote && visible === 'Public' && (
              <View style={[{borderTopWidth: 1}, t.atoms.line]}>
                <Credibility
                  showStakeModal={showStakeModal}
                  showBetModal={showBetModal}
                  amount={amount}
                  onStake={onStake}
                  betAmount={betAmount}
                  onCreateBet={onCreateBet}
                />
                <View style={{height: 3, width: 3}} />
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>

      <Prompt.Basic
        control={discardPromptControl}
        title={_(msg`Discard draft?`)}
        description={_(msg`Are you sure you'd like to discard this draft?`)}
        onConfirm={onClose}
        confirmButtonCta={_(msg`Discard`)}
        confirmButtonColor="negative"
      />
      {makeAsDialog}
    </KeyboardAvoidingView>
  )
})

export function useComposerCancelRef() {
  return useRef<CancelRef>(null)
}

function useKeyboardVerticalOffset() {
  const {top} = useSafeAreaInsets()

  // Android etc
  if (!isIOS) return 0

  // iPhone SE
  if (top === 20) return 40

  // all other iPhones
  return top + 10
}

async function whenAppViewReady(
  agent: BskyAgent,
  uri: string,
  fn: (res: AppBskyFeedGetPostThread.Response) => boolean,
) {
  await until(
    5, // 5 tries
    1e3, // 1s delay between tries
    fn,
    () =>
      agent.app.bsky.feed.getPostThread({
        uri,
        depth: 0,
      }),
  )
}

const styles = StyleSheet.create({
  textInputLayout: {
    paddingTop: 4,
  },
  sdlInput: {
    borderBottomWidth: 1,
    // borderBottomColor: '#eaeaea',
    marginBottom: 10,
  },
})
