import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  Animated,
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  PanResponder,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'
import {useFocusEffect} from '@react-navigation/native'

import {CommonNavigatorParams, NativeStackScreenProps} from '#/lib/routes/types'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {isMobile as MobileDevice} from '#/platform/detection'
import {emitter} from '#/state/events'
import {useSetMinimalShellMode} from '#/state/shell'
import {useTheme} from '#/alf'
import {PostThread as PostThreadComponent} from '../com/post-thread/PostThread'
import {useSwipeListStore} from '../com/util/sdlStore/SwiperListStore'
import {CenteredView} from '../com/util/Views'
import {useHideBottomBar} from '../shell/bottom-bar/BottomBar'
import {PostGuide} from './PostGuide'
import {vh} from './Task'
const {width: VW, height: VH} = Dimensions.get('window')

type TActionKey = 'setCurrentUri' | 'setPreviousUri' | 'setNextUri'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'PostThread'>

export function PostThreadScreen({route}: Props) {
  const t = useTheme()
  useHideBottomBar()
  const setMinimalShellMode = useSetMinimalShellMode()
  const {name, rkey, did} = route.params
  const uri = makeRecordUri(name, 'app.bsky.feed.post', rkey)

  const [previousOffset, setPreviousOffset] = useState(0)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [nextOffset, setNextOffset] = useState(0)

  const handleScroll = useCallback((pos: string, y: number) => {
    switch (pos) {
      case 'previous':
        setPreviousOffset(y)
        break
      case 'current':
        setCurrentOffset(y)
        break
      case 'next':
        setNextOffset(y)
        break
    }
  }, [])

  const {
    panResponder,
    currentStyle,
    previousStyle,
    nextStyle,
    currentUri,
    previousUri,
    nextUri,
    movingPage,
    hasNextPage,
  } = useSwipeHandler(uri)

  useFocusEffect(
    React.useCallback(() => {
      setMinimalShellMode(false)
    }, [setMinimalShellMode]),
  )
  //#region  view
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <CenteredView
        {...panResponder.panHandlers}
        style={{overflow: 'hidden', height: '100%', width: '100%'}}>
        {[
          {
            animationStyle: previousStyle,
            postUri: previousUri,
            pos: 'previous',
          },
          {animationStyle: currentStyle, postUri: currentUri, pos: 'current'},
          {animationStyle: nextStyle, postUri: nextUri, pos: 'next'},
        ].map(item => {
          return (
            <Animated.View
              key={item.postUri}
              style={[
                styles.animationBox,
                t.atoms.bg,
                item.animationStyle,
                {zIndex: movingPage === item.pos ? 1 : 0},
              ]}>
              <ScrollView
                style={styles.insideBox}
                onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
                  const offsetY = event.nativeEvent.contentOffset.y
                  handleScroll(item.pos, offsetY)
                }}
                scrollEventThrottle={16}>
                {item.postUri && (
                  <PostThreadComponent
                    uri={item.postUri}
                    notifyDid={did}
                    scrollTop={
                      item.pos === 'current'
                        ? currentOffset
                        : item.pos === 'previous'
                        ? previousOffset
                        : nextOffset
                    }
                  />
                )}
              </ScrollView>
            </Animated.View>
          )
        })}
      </CenteredView>
      {hasNextPage && MobileDevice && <PostGuide />}
    </View>
  )
}

//#region handle
function useSwipeHandler(initUri: string) {
  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: (_, gestureState) => {
        if (Math.abs(gestureState.dx) > Math.abs(gestureState.dy)) {
          if (gestureState.dx > 5) {
            emitter.emit('goBackward')
          } else if (gestureState.dx < -5) {
            emitter.emit('goForward')
          }
        }
        return true
      },
      onPanResponderRelease: (_, gestureState) => {
        if (Math.abs(gestureState.dx) > Math.abs(gestureState.dy)) {
          if (gestureState.dx > 5) {
            emitter.emit('goBackward')
          } else if (gestureState.dx < -5) {
            emitter.emit('goForward')
          }
        }
      },
    }),
  ).current
  const {swipeList, setCurrentSwipeUri} = useSwipeListStore()
  const [currentUri, setCurrentUri] = useState(initUri)
  const [previousUri, setPreviousUri] = useState('')
  const [nextUri, setNextUri] = useState(swipeList[1] || '')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentAnimation] = useState(new Animated.Value(1))
  const [previousAnimation] = useState(new Animated.Value(0))
  const [nextAnimation] = useState(new Animated.Value(2))
  const currentStyle = getAnimatedStyle(currentAnimation)
  const previousStyle = getAnimatedStyle(previousAnimation)
  const nextStyle = getAnimatedStyle(nextAnimation)
  const [currentPosition, setCurrentPosition] = useState({
    left: {
      ref: previousAnimation,
      key: 'setPreviousUri',
    },
    middle: {
      ref: currentAnimation,
      key: 'setCurrentUri',
    },
    right: {
      ref: nextAnimation,
      key: 'setNextUri',
    },
  })
  const [movingPage, setMovingPage] = useState('next')

  const hasNextPage = useMemo(() => {
    return currentIndex + 1 < swipeList.length
  }, [currentIndex, swipeList.length])

  const updateRightUri = useCallback(
    (actionKey: TActionKey) => {
      const resultUri = currentIndex - 2 >= 0 ? swipeList[currentIndex - 2] : ''
      switch (actionKey) {
        case 'setCurrentUri':
          setCurrentUri(resultUri)
          break
        case 'setPreviousUri':
          setPreviousUri(resultUri)
          break
        case 'setNextUri':
          setNextUri(resultUri)
      }
      setCurrentIndex(pre => pre - 1)
    },
    [currentIndex, swipeList],
  )

  const updateLeftUri = useCallback(
    (actionKey: TActionKey) => {
      const resultUri = swipeList[currentIndex + 2] || ''
      switch (actionKey) {
        case 'setCurrentUri':
          setCurrentUri(resultUri)
          break
        case 'setPreviousUri':
          setPreviousUri(resultUri)
          break
        case 'setNextUri':
          setNextUri(resultUri)
      }
      setCurrentIndex(pre => pre + 1)
    },
    [currentIndex, swipeList],
  )

  const goBackward = useMemo(() => {
    return throttle(() => {
      if (currentIndex === 0) return
      switch (currentPosition.left.key) {
        case 'setNextUri':
          setMovingPage('next')
          break
        case 'setPreviousUri':
          setMovingPage('previous')
          break
        case 'setCurrentUri':
          setMovingPage('current')
          break
      }
      Animated.timing(currentPosition.left.ref, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        currentPosition.middle.ref.setValue(2)
        currentPosition.right.ref.setValue(0)
        setCurrentPosition(pre => {
          const temp = pre.left
          updateRightUri(pre.right.key as TActionKey)
          return {
            left: pre.right,
            right: pre.middle,
            middle: temp,
          }
        })
      })
    }, 500)
  }, [
    currentIndex,
    currentPosition.left.key,
    currentPosition.left.ref,
    currentPosition.middle.ref,
    currentPosition.right.ref,
    updateRightUri,
  ])

  const goForward = useMemo(() => {
    return throttle(() => {
      if (currentIndex + 2 > swipeList.length) return
      switch (currentPosition.right.key) {
        case 'setNextUri':
          setMovingPage('next')
          break
        case 'setPreviousUri':
          setMovingPage('previous')
          break
        case 'setCurrentUri':
          setMovingPage('current')
          break
      }
      Animated.timing(currentPosition.right.ref, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        currentPosition.middle.ref.setValue(0)
        currentPosition.left.ref.setValue(2)
        setCurrentPosition(pre => {
          const temp = pre.left
          updateLeftUri(pre.left.key as TActionKey)
          return {
            left: pre.middle,
            middle: pre.right,
            right: temp,
          }
        })
      })
    }, 500)
  }, [
    currentIndex,
    currentPosition.left.ref,
    currentPosition.middle.ref,
    currentPosition.right.key,
    currentPosition.right.ref,
    swipeList.length,
    updateLeftUri,
  ])

  useEffect(() => {
    emitter.on('goForward', goForward)
    return () => {
      emitter.off('goForward', goForward)
    }
  }, [goForward])

  useEffect(() => {
    emitter.on('goBackward', goBackward)
    return () => {
      emitter.off('goBackward', goBackward)
    }
  }, [goBackward])

  useEffect(() => {
    setCurrentUri(swipeList[0])
    setPreviousUri(swipeList[1])
  }, [swipeList])

  useFocusEffect(
    useCallback(() => {
      setCurrentSwipeUri(initUri)
      setCurrentUri(initUri)
    }, [setCurrentSwipeUri, initUri]),
  )
  return {
    panResponder,
    goForward,
    goBackward,
    currentStyle,
    previousStyle,
    nextStyle,
    currentUri,
    previousUri,
    nextUri,
    currentIndex,
    movingPage,
    hasNextPage,
  }
}

function throttle<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let lastTime = 0

  return (...args: Parameters<T>) => {
    const now = Date.now()
    if (now - lastTime >= delay) {
      lastTime = now
      func(...args)
    }
  }
}

//#region  style
function getAnimatedStyle(animation: Animated.Value) {
  return {
    transform: [
      {
        rotateZ: animation.interpolate({
          inputRange: [0, 1, 2],
          outputRange: ['-90deg', '0deg', '90deg'],
        }),
      },
      {
        scale: animation.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [0.5, 1, 0.5],
        }),
      },
      {
        translateX: animation.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [-2 * VW, 0, 2 * VW],
        }),
      },
      {
        translateY: animation.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [VH / 2, 0, VH / 2],
        }),
      },
    ],
    opacity: animation.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [0, 1, 0],
    }),
  }
}

const styles = StyleSheet.create({
  bt: {
    height: 32,
    width: 32,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    position: 'absolute',
    top: vh / 2,
    zIndex: 3,
  },
  animationBox: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  insideBox: {
    height: '100%',
    width: '100%',
  },
})
