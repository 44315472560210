import React, {useCallback} from 'react'
// import {Button} from '#/components/Button'
import {TouchableOpacity} from 'react-native'

// import {msg} from '@lingui/macro'
// import {useLingui} from '@lingui/react'
import {useAnalytics} from '#/lib/analytics/analytics'
import {usePhotoLibraryPermission} from '#/lib/hooks/usePermissions'
import {isNative} from '#/platform/detection'
import {GalleryModel} from '#/state/models/media/gallery'
import {ComposerImageIcon /*ImageIcon*/} from '#/view/icons/ModalIcons'
import {
  // atoms as a,
  useTheme,
} from '#/alf'

type Props = {
  gallery: GalleryModel
  disabled?: boolean
}

export function SelectPhotoBtn({gallery, disabled}: Props) {
  const t = useTheme()
  const {track} = useAnalytics()
  // const {_} = useLingui()
  const {requestPhotoAccessIfNeeded} = usePhotoLibraryPermission()

  const [isHovered, setIsHovered] = React.useState(false)

  const onPressSelectPhotos = useCallback(async () => {
    track('Composer:GalleryOpened')

    if (isNative && !(await requestPhotoAccessIfNeeded())) {
      return
    }

    gallery.pick()
  }, [track, requestPhotoAccessIfNeeded, gallery])

  return (
    // <Button
    //   testID="openGalleryBtn"
    //   onPress={onPressSelectPhotos}
    //   label={_(msg`Gallery`)}
    //   accessibilityHint={_(msg`Opens device photo gallery`)}
    //   style={a.p_sm}
    //   // variant="ghost"
    //   shape="round"
    //   color="primary"
    //   disabled={disabled}>
    //   <ImageIcon active={true}/>
    // </Button>

    <TouchableOpacity
      testID="openGalleryBtn"
      accessibilityRole="button"
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={onPressSelectPhotos}
      style={[
        {borderRadius: 9, padding: 2, marginVertical: 8, marginRight: 6},
        isHovered && t.atoms.bg_primary,
      ]}
      disabled={disabled}>
      {/* <ImageIcon active={isHovered} /> */}
      <ComposerImageIcon />
    </TouchableOpacity>
  )
}
