import React, {useMemo} from 'react'
import {Pressable, Text, TouchableOpacity, View} from 'react-native'
import {FinEntity} from '@atproto/api/dist/client/types/app/bsky/feed/post'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {useRequireAuth} from '#/state/session'
import {TeaLogoIcon} from '#/view/icons/FeedIcons'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {ENTITY_TYPE} from '#/view/screens/Entity/hooks'
import {atoms as a, useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {EllipsisBox} from '../composer/postBox/EllipsisContainer'
import {ITargetType} from '../util/sdlStore/targetDate'
import {UserAvatar} from '../util/UserAvatar'

export function TargetDisplay({
  targets,
  previewMode = false,
  isFromFeed,
  isFromShare,
}: {
  targets: FinEntity[]
  previewMode?: boolean
  isFromFeed?: boolean
  isFromShare?: boolean
}) {
  if (previewMode) {
    return (
      <>
        <EllipsisBox
          array={targets}
          keyName="did"
          height={60}
          gap={2}
          isTarget
          isFromFeed={isFromFeed}
          renderItem={tar => (
            <Capsule
              id={tar?.id}
              name={tar.name}
              avatar={tar.avatar}
              type={tar.type}
              isFromFeed={isFromFeed}
              key={tar.id}
              isFromShare={isFromShare}
            />
          )}
        />
        <View style={{height: 5, width: 1}} />
      </>
    )
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'hidden',
      }}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 5,
          marginRight: 10,
        }}>
        <Text style={{color: '#979797', fontSize: 14, fontWeight: '800'}}>
          <Trans>Spill about:</Trans>
        </Text>
      </View>
      {targets.map((tar, index) => {
        return (
          <Capsule
            id={tar?.id}
            key={String(index)}
            name={tar.name || ''}
            avatar={tar.avatar || ''}
            type={tar.type as ITargetType}
          />
        )
      })}
    </View>
  )
}

interface ICapsuleProps {
  id?: number
  name: string
  avatar: string
  key?: string
  handle?: string
  type?: ITargetType
  onDelete?: () => void
  isFromFeed?: boolean
  isFromShare?: boolean
}

export function Capsule({
  id,
  key,
  name,
  type,
  avatar,
  onDelete,
  handle,
  isFromFeed,
  isFromShare,
}: ICapsuleProps) {
  const t = useTheme()
  const themeName = useThemeName()
  const requireAuth = useRequireAuth()
  const navigation = useNavigation<NavigationProp>()

  const config = useMemo(() => {
    const styleConfig = {
      background: '#F0DDFF',
      height: 32,
      borderWidth: 1,
      avatarSize: 24,
    }
    if (type && Object.keys(ENTITY_TYPE).indexOf(type) < 0) return styleConfig

    styleConfig.avatarSize = 16
    styleConfig.height = 24
    styleConfig.borderWidth = 0
    styleConfig.background = type === 'organization' ? '#E8E8E8' : '#FFF3BD'
    return styleConfig
  }, [type])

  return (
    <Pressable
      accessibilityRole="button"
      onPress={() => {
        requireAuth(() => {
          navigation.navigate('EntityDetail', {
            id: id + '',
            type: type ?? 'person',
          })
        })
      }}>
      <View
        key={key}
        style={[
          {
            flexDirection: 'row',
            height: config.height,
            padding: 2,
            paddingRight: onDelete ? 5 : 10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isFromFeed
              ? 'transparent'
              : t.atoms.bg_capsule.backgroundColor, //config.background,
            borderRadius: 18,
            marginRight: 10,
            marginBottom: 5,
            borderWidth: isFromFeed ? 1 : config.borderWidth,
            borderColor: isFromFeed
              ? isFromShare || themeName === 'light'
                ? t.palette.black
                : '#98989F'
              : t.palette.primary,
            maxWidth: '100%',
          },
        ]}>
        <UserAvatar
          avatar={avatar}
          type={avatar ? 'user' : (type as 'organization')}
          // size={config.avatarSize}
          size={20}
        />
        <View>
          <Text
            style={{
              color: isFromFeed
                ? isFromShare || themeName === 'light'
                  ? t.palette.black
                  : '#98989F'
                : t.atoms.text_capsule.color, //'#5E6272',
              fontSize: 12,
              marginLeft: 6,
            }}>
            {name}
          </Text>
          {handle && (
            <Text
              style={{
                color: 'white',
                fontSize: 12,
                marginLeft: 6,
              }}>
              {handle}
            </Text>
          )}
        </View>
        {onDelete && (
          <TouchableOpacity
            accessibilityRole="button"
            style={{
              marginLeft: 5,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={onDelete}>
            <CloseIcon circleColor={'#000'} />
          </TouchableOpacity>
        )}
        {isFromFeed && (
          <View
            style={[
              a.flex_row,
              a.align_center,
              a.justify_between,
              isFromShare
                ? {backgroundColor: '#EFE20E'}
                : t.atoms.big_figure_bg,
              {
                position: 'absolute',
                right: -10,
                top: -10,
                borderRadius: 10,
              },
            ]}>
            <TeaLogoIcon
              fixedColor={
                isFromShare || themeName === 'light'
                  ? t.palette.black
                  : undefined
              }
            />
          </View>
        )}
      </View>
    </Pressable>
  )
}
