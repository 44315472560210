import React from 'react'
import {View} from 'react-native'
import Animated, {FadeIn, LayoutAnimationConfig} from 'react-native-reanimated'
import {AppBskyGraphStarterpack} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useAnalytics} from '#/lib/analytics/analytics'
// import {FEEDBACK_FORM_URL} from '#/lib/constants'
import {useServiceQuery} from '#/state/queries/service'
import {useStarterPackQuery} from 'state/queries/starter-packs'
import {useActiveStarterPack} from 'state/shell/starter-pack'
import {LoggedOutLayout} from '#/view/com/util/layouts/LoggedOutLayout'
import {
  initialState,
  reducer,
  SignupContext,
  SignupStep,
} from '#/screens/Signup/state'
// import {StepCaptcha} from '#/screens/Signup/StepCaptcha'
// import {StepHandle} from '#/screens/Signup/StepHandle'
import {StepInfo} from '#/screens/Signup/StepInfo'
import {atoms as a, useTheme} from '#/alf'
// import {AppLanguageDropdown} from '#/components/AppLanguageDropdown'
// import {Divider} from '#/components/Divider'
import {LinearGradientBackground} from '#/components/LinearGradientBackground'
// import {InlineLinkText} from '#/components/Link'
import {Text} from '#/components/Typography'
import {StepSave} from './StepSave'
// import { ethers } from 'ethers'

export function Signup({
  onPressBack,
  nickname = '',
  handle = '',
  // mnemonic,
  tgAuthResult,
}: {
  onPressBack: () => void
  nickname?: string
  handle?: string
  // mnemonic?: string
  tgAuthResult?: string
}) {
  const {_} = useLingui()
  const t = useTheme()
  const {screen} = useAnalytics()
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    nickname,
    handle,
    // wallet: mnemonic ? ethers.Wallet.fromPhrase(mnemonic) : initialState.wallet,
    tgAuthResult,
  })
  // const {gtMobile} = useBreakpoints()

  const activeStarterPack = useActiveStarterPack()
  const {
    data: starterPack,
    isFetching: isFetchingStarterPack,
    isError: isErrorStarterPack,
  } = useStarterPackQuery({
    uri: activeStarterPack?.uri,
  })

  const [isFetchedAtMount] = React.useState(starterPack != null)
  const showStarterPackCard =
    activeStarterPack?.uri && !isFetchingStarterPack && starterPack

  const {
    data: serviceInfo,
    isFetching,
    isError,
    refetch,
  } = useServiceQuery(state.serviceUrl)

  React.useEffect(() => {
    screen('CreateAccount')
  }, [screen])

  React.useEffect(() => {
    if (isFetching) {
      dispatch({type: 'setIsLoading', value: true})
    } else if (!isFetching) {
      dispatch({type: 'setIsLoading', value: false})
    }
  }, [isFetching])

  React.useEffect(() => {
    setTimeout(() => {
      dispatch({type: 'setWallet'})
    }, 200)
  }, [])

  React.useEffect(() => {
    if (isError) {
      dispatch({type: 'setServiceDescription', value: undefined})
      dispatch({
        type: 'setError',
        value: _(
          msg`Unable to contact your service. Please check your Internet connection.`,
        ),
      })
    } else if (serviceInfo) {
      dispatch({type: 'setServiceDescription', value: serviceInfo})
      dispatch({type: 'setError', value: ''})
    }
  }, [_, serviceInfo, isError])

  return (
    <SignupContext.Provider value={{state, dispatch}}>
      <LoggedOutLayout
        leadin=""
        title={_(msg`Create Account`)}
        description={_(msg`We're so excited to have you join us!`)}
        isSaveStep={state.activeStep === SignupStep.SAVE}
        scrollable>
        <View
          testID="createAccount"
          style={[
            a.flex_1,
            a.flex_col,
            a.h_full,
            // state.activeStep === SignupStep.SAVE ? t.atoms.bg_gray : {},
          ]}>
          {showStarterPackCard &&
          AppBskyGraphStarterpack.isRecord(starterPack.record) ? (
            <Animated.View entering={!isFetchedAtMount ? FadeIn : undefined}>
              <LinearGradientBackground
                style={[a.mx_lg, a.p_lg, a.gap_sm, a.rounded_sm]}>
                <Text style={[a.font_bold, a.text_xl, {color: 'white'}]}>
                  {starterPack.record.name}
                </Text>
                <Text style={[{color: 'white'}]}>
                  {starterPack.feeds?.length ? (
                    <Trans>
                      You'll follow the suggested users and feeds once you
                      finish creating your account!
                    </Trans>
                  ) : (
                    <Trans>
                      You'll follow the suggested users once you finish creating
                      your account!
                    </Trans>
                  )}
                </Text>
              </LinearGradientBackground>
            </Animated.View>
          ) : null}
          <View
            style={[
              a.flex_1,
              a.px_xl,
              a.h_full,
              // a.pt_2xl,
            ]}>
            <View style={[a.gap_sm]}>
              <Text style={[a.font_semibold, t.atoms.text_contrast_medium]}>
                {/* <Trans>
                  Step {state.activeStep + 1} of{' '}
                  {state.serviceDescription &&
                  !state.serviceDescription.phoneVerificationRequired
                    ? '2'
                    : '3'}
                </Trans> */}
              </Text>
              {/* <Text style={[a.text_3xl, a.font_bold, a.text_center]}>
                
                {state.activeStep === SignupStep.SAVE && (
                  <Trans>Save your account</Trans>
                )}
              </Text> */}
            </View>

            <LayoutAnimationConfig skipEntering skipExiting>
              {state.activeStep === SignupStep.INFO && (
                <StepInfo
                  onPressBack={onPressBack}
                  isLoadingStarterPack={
                    isFetchingStarterPack && !isErrorStarterPack
                  }
                  isServerError={isError}
                  refetchServer={refetch}
                />
              )}
              {state.activeStep === SignupStep.SAVE && <StepSave />}
            </LayoutAnimationConfig>

            {/* <Divider /> */}

            {/* <View
              style={[a.w_full, a.py_lg, a.flex_row, a.gap_lg, a.align_center]}>
              <AppLanguageDropdown />
              <Text style={[t.atoms.text, !gtMobile && a.text_md]}>
                <Trans>Having trouble?</Trans>{' '}
                <InlineLinkText
                  label={_(msg`Contact support`)}
                  to={FEEDBACK_FORM_URL({email: state.email})}
                  style={[!gtMobile && a.text_md]}>
                  <Trans>Contact support</Trans>
                </InlineLinkText>
              </Text>
            </View> */}
          </View>
        </View>
      </LoggedOutLayout>
    </SignupContext.Provider>
  )
}
