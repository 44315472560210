import React from 'react'
import {StyleProp, Text, View, ViewStyle} from 'react-native'
import {Trans} from '@lingui/macro'

import {EllipsisBox} from '#/view/com/composer/postBox/EllipsisContainer'
import {SimpleTag, Tag} from '#/view/com/tags/TagItem'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Toggle from '#/components/forms/Toggle'
import {IconCircle} from '#/components/IconCircle'
import {ChevronRight_Stroke2_Corner0_Rounded as Chevron} from '#/components/icons/Chevron'
import {DegreeIcon} from '#/components/icons/Degree'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {Telegram} from '#/components/icons/Telegram'
import {ContactItemProps} from '../Circle'

type Props = {
  selected?: boolean
  showCheckbox?: boolean
  showTag?: boolean
  user: ContactItemProps
  disabled?: boolean
  maxTags?: number
  itemType?: 'menu' | 'normal'
  filterTags?: Tag[]
  showDegree?: boolean
  showInvite?: boolean
  onPress?: (item: ContactItemProps) => void
  onSelected?: (selected: boolean, item: ContactItemProps) => void
  style?: StyleProp<ViewStyle>
}

export const degrees = [
  {
    name: <Text style={{color: 'hsla(43, 100%, 38%, 1)'}}>1st</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(43, 100%, 38%, 1)'}}
      />
    ),
  },
  {
    name: <Text style={{color: 'hsla(86, 100%, 35%, 1)'}}>2nd</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(86, 100%, 35%, 1)'}}
      />
    ),
  },
  {
    name: <Text style={{color: 'hsla(205, 100%, 45%, 1)'}}>3rd</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(205, 100%, 45%, 1)'}}
      />
    ),
  },
]

export default function ContactItem({
  selected,
  showCheckbox,
  user,
  itemType,
  disabled,
  showTag = true,
  // maxTags = 4,
  showDegree = true,
  filterTags,
  onSelected,
  onPress,
}: Props) {
  const t = useTheme()

  const [values, setSelected] = React.useState<string[]>(() => {
    return selected ? [`${user.id}`] : []
  })

  const tags = React.useMemo(() => {
    if (!filterTags || filterTags.length === 0) {
      return user.tags
    }
    const includes = user.tags
      ?.filter(tag => {
        return filterTags?.find(_tag => _tag.id === tag.id)
      })
      .map(_tag => {
        _tag.selected = true
        return _tag
      })
    const exclusives = user.tags?.filter(tag => {
      return !includes?.find(_tag => _tag.id === tag.id)
    })
    return [...(includes || []), ...(exclusives || [])]
  }, [filterTags, user.tags])

  React.useEffect(() => {
    setSelected(selected ? [`${user.id}`] : [])
  }, [selected, user.id])

  const handleSelected = (_user: ContactItemProps) => {
    if (disabled) return
    let list = []
    if (selected) {
      list = values.filter(item => item !== _user.id)
    } else {
      list = [...values, _user.id]
    }
    setSelected(list)
    onSelected && onSelected(!selected, _user)
  }
  const degree = user.degree
  const isOpacity =
    user?.from === 'telegram'
      ? !user?.isAdded && user?.isInvite
      : !user?.isAdded

  return (
    <Button
      style={[
        a.flex_1,
        a.flex_row,
        a.align_center,
        a.justify_between,
        a.gap_md,
        // a.pr_2xl,
      ]}
      label="follower"
      onPress={() => (showCheckbox ? handleSelected(user) : onPress?.(user))}>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.p_md,
          a.gap_md,
          a.flex_1,
          {paddingHorizontal: 4},
        ]}>
        <View>
          <UserAvatar
            size={44}
            avatar={user.avatar}
            name={
              user.from === 'telegram'
                ? user.nickname || user.username
                : undefined
            }
            style={isOpacity ? {opacity: 0.4} : {}}
          />
          {user?.isBindTg && (
            <IconCircle
              icon={Telegram}
              size="xs"
              iconStyle={[
                {color: '#fff', padding: 2, marginLeft: -1, marginBottom: -1},
              ]}
              style={[
                {
                  width: 16,
                  height: 16,
                  backgroundColor: '#25a3e1',
                  right: -4,
                  bottom: 0,
                  position: 'absolute',
                },
              ]}
            />
          )}
        </View>
        <View style={[a.flex_1, a.flex_col, a.gap_xs]}>
          <View style={[a.gap_sm]}>
            <View style={[a.flex_row, a.align_center, a.gap_xs]}>
              <Text style={[a.font_bold, t.atoms.text]} numberOfLines={1}>
                {user.nickname}
              </Text>
              <Text style={[a.text_xs, t.atoms.text_sub]}>
                {user.username ? `@${formatHandler(user.username)}` : ''}
              </Text>
              {user.isMutual && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <IconCircle
                    style={[
                      {width: 16, height: 16, backgroundColor: '#2aabee'},
                    ]}
                    icon={Telegram}
                    iconStyle={{color: t.palette.white}}
                    size="xxs"
                  />
                  <Text style={[a.text_xs, t.atoms.text]}>
                    <Trans>Mutual</Trans>
                  </Text>
                </View>
              )}
              {showDegree && degree && degrees[degree - 1] && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  {degrees[degree - 1].icon}
                  {degrees[degree - 1].name}
                </View>
              )}
            </View>
          </View>
          {showTag && user.tags && tags && (
            <EllipsisBox
              array={tags}
              keyName="name"
              height={22}
              gap={8}
              renderItem={tag => (
                <SimpleTag
                  key={tag.name}
                  tag={tag}
                  isFilter={tag.selected}
                  style={{height: 16}}
                />
              )}
              moreContent={
                <SimpleTag
                  key="..."
                  tag={{name: '....', id: -1}}
                  style={{height: 16}}
                />
              }
            />
          )}
        </View>
      </View>
      <View style={[a.flex_row, a.align_center, a.justify_end, a.gap_md]}>
        {showCheckbox && (
          <Toggle.Group
            disabled={disabled}
            type="checkbox"
            label="select contact"
            values={values}
            onChange={() => handleSelected(user)}>
            <Toggle.Item name={`${user.id}`} label="contact">
              <Toggle.Checkbox
                style={[{borderWidth: 1.2}, t.atoms.checkbox_border]}
              />
            </Toggle.Item>
          </Toggle.Group>
        )}
        {itemType && <Chevron fill={t.atoms.text_sub.color} />}
        {!showCheckbox &&
          !user?.isAdded &&
          (user?.from === 'telegram' ? user?.isInvite : true) && (
            <View style={[a.flex_row, a.gap_xs]}>
              <ArrowUpLeftIcon style={{marginTop: 2}} />
              <Text
                style={[
                  a.text_xs,
                  a.font_semibold,
                  {color: t.palette.gray_10},
                ]}>
                Request sent
              </Text>
            </View>
          )}
      </View>
    </Button>
  )
}

export const SmallContact = ({user, disabled, onSelected, style}: Props) => {
  const t = useTheme()
  return (
    <Button
      style={[
        a.flex_row,
        a.align_center,
        a.justify_between,
        {
          backgroundColor: 'hsla(49, 100%, 89%, 1)',
          height: 32,
          borderWidth: 1,
          borderColor: 'hsla(43, 100%, 38%, 1)',
          maxWidth: '100%',
        },
        a.rounded_full,
        // a.pr_2xl,
        style,
      ]}
      label="follower"
      onPress={() => {
        if (disabled) return
        onSelected?.(false, user)
      }}>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.p_xs,
          a.gap_xs,
          a.justify_between,
        ]}>
        <UserAvatar
          type="user"
          size={24}
          avatar={user.avatar}
          name={user.nickname || user.username}
        />
        <View style={[a.flex_col]}>
          <Text
            style={[{fontSize: 12, color: t.palette.black}, a.font_bold]}
            numberOfLines={1}>
            {user.nickname}
          </Text>
          {user.username && (
            <Text
              style={[
                {fontSize: 10},
                a.text_xs,
                t.atoms.text_contrast_low,
                {color: 'hsla(0, 0%, 40%, 1)'},
              ]}>
              {user.username ? `@${user.username}` : ''}
            </Text>
          )}
        </View>
        {!disabled && <CloseIcon circleColor={t.palette.black} />}
      </View>
    </Button>
  )
}
