import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  FlatList,
  Image,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isIOS, isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {entityAgent} from '#/state/session/entityAgent'
import {TargetIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {TabBar} from '../pager/TabBar'
import {Capsule} from '../posts/TargetDisplay'
import {usePostTargetStore} from '../util/sdlStore/PostTargetStore'
import {ITargetItem} from '../util/sdlStore/targetDate'
import * as Toast from '../util/Toast'
import {UserAvatar} from '../util/UserAvatar'

interface ISelectTargetProps {
  init: () => void
}

export function SelectTarget({init}: ISelectTargetProps) {
  const {openModal} = useModalControls()

  const [isHovered, setIsHovered] = useState(false)

  const onPressIcon = React.useCallback(() => {
    init()
    openModal({
      name: 'post-target',
    })
  }, [openModal, init])

  return (
    <TouchableOpacity
      accessibilityRole="button"
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={onPressIcon}>
      <TargetIcon active={isHovered} />
    </TouchableOpacity>
  )
}

export const snapPoints = ['90%']

interface ITargetData {
  company: ITargetItem[]
  people: ITargetItem[]
}

export const Component = function DetectLinkedWalletModalComponent() {
  const [currentItem, setCurrentItem] = useState<number>(0)
  const pal = usePalette('default')
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const items = ['Project', 'Individual']
  const flatListRef = useRef<FlatList<any>>(null)
  const flatListRef2 = useRef<FlatList<any>>(null)
  const selectedScrollViewRef = useRef<ScrollView>(null)
  const [targets, setTarget] = useState<ITargetData>({
    company: [],
    people: [],
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false)
  const {
    targetStore: {selectedArray},
    setSelectedTargetStore,
  } = usePostTargetStore()
  const [localSelectedArray, setLocalSelectedArray] =
    useState<ITargetItem[]>(selectedArray)

  function useKeyboardVerticalOffset() {
    const {top} = useSafeAreaInsets()
    if (!isIOS) return 0
    if (top === 20) return 40
    return top + 10
  }
  const keyboardVerticalOffset = useKeyboardVerticalOffset()

  const selectedIds = useMemo(() => {
    return localSelectedArray.map(item => item.id)
  }, [localSelectedArray])

  const onPressItem = useCallback(
    (targetItem: ITargetItem) => {
      setLocalSelectedArray(pre => {
        const index = pre.findIndex(item => item.id === targetItem.id)
        if (index === -1) {
          return [...pre, targetItem]
        } else {
          return [...pre.slice(0, index), ...pre.slice(index + 1)]
        }
      })
    },
    [setLocalSelectedArray],
  )

  const renderItem = useCallback(
    ({item}: {item: ITargetItem}) => {
      const active = selectedIds.includes(item.id)
      return <TargetBox item={item} onPressItem={onPressItem} active={active} />
    },
    [selectedIds, onPressItem],
  )

  useEffect(() => {
    selectedScrollViewRef.current?.scrollToEnd({animated: true})
  }, [localSelectedArray])

  useEffect(() => {
    flatListRef.current?.scrollToOffset({offset: 0, animated: false})
    flatListRef2.current?.scrollToOffset({offset: 0, animated: false})
  }, [currentItem])

  const onPressDone = useCallback(() => {
    setSelectedTargetStore(localSelectedArray)
    closeModal()
  }, [setSelectedTargetStore, localSelectedArray, closeModal])

  const t = useTheme()

  return (
    <KeyboardAvoidingView
      behavior={isIOS ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={[
        a.flex_1,
        {minHeight: isMobile ? 300 : 500, paddingHorizontal: isMobile ? 10 : 0},
        isDesktop && {marginHorizontal: -10, marginTop: -10},
      ]}>
      <View style={styles.top}>
        <View style={styles.head}>
          <TouchableOpacity
            accessibilityRole="button"
            style={{}}
            onPress={() => closeModal()}>
            <Text style={[t.atoms.text, {fontSize: 14}]}>
              <Trans>Cancel</Trans>
            </Text>
          </TouchableOpacity>
          <View style={[a.flex_1, {alignItems: 'center'}]}>
            <Text style={[{fontSize: 16, fontWeight: 'bold'}, t.atoms.text]}>
              <Trans>Spill about</Trans>
            </Text>
          </View>
          <TouchableOpacity
            accessibilityRole="button"
            style={{
              borderRadius: 25,
              backgroundColor: t.palette.primary,
              paddingHorizontal: 10,
              paddingVertical: 8,
              opacity: localSelectedArray.length > 0 ? 1 : 0.5,
            }}
            onPress={onPressDone}>
            <Text
              style={{
                fontSize: 14,
                color: t.palette.black,
                fontWeight: 'bold',
              }}>
              <Trans context="action">Done</Trans>
            </Text>
          </TouchableOpacity>
        </View>

        <Search
          setArray={setTarget}
          isPerson={currentItem === 1}
          setLoading={setLoading}
        />

        {localSelectedArray.length > 0 && (
          <View
            style={[
              styles.selectedContainer,
              {flexDirection: 'row', flexWrap: 'wrap'},
            ]}>
            {localSelectedArray.map(item => {
              return (
                <Capsule
                  onDelete={() => onPressItem(item)}
                  key={String(item.id)}
                  name={item.name}
                  avatar={item.logo}
                  type={item.type}
                />
              )
            })}
          </View>
        )}

        <View style={{marginTop: 10}}>
          <TabBar
            key={items?.join(',')}
            onPressSelected={setCurrentItem}
            selectedPage={currentItem}
            onSelect={setCurrentItem}
            testID={'stakeDonateModalTab'}
            items={items}
            indicatorColor={pal.colors.link}
            isCentered={true}
            style={t.atoms.modalBg}
            selectedTextStyle={{color: pal.colors.text}}
            textStyle={{color: t.palette.black_25, fontWeight: '400'}}
          />
        </View>
      </View>

      <View style={styles.list}>
        <FlatList
          ref={flatListRef2}
          style={{height: 300}}
          data={targets[currentItem === 1 ? 'people' : 'company']}
          renderItem={renderItem}
          keyExtractor={item => item.id + ''}
          initialNumToRender={10}
          maxToRenderPerBatch={10}
          windowSize={5}
        />
      </View>
      <View
        style={{
          marginBottom: isWeb ? -15 : 10,
          alignItems: 'center',
          paddingTop: 10,
        }}>
        <Image
          style={[{width: 185, height: 24, marginRight: 20}]}
          source={require('#/../assets/imgs/rootdata.png')}
          accessibilityIgnoresInvertColors={true}
        />
      </View>
    </KeyboardAvoidingView>
  )
}

interface ISearch {
  setArray: (data: ITargetData) => void
  isPerson: boolean
  setLoading: (loading: boolean) => void
}

let timer: NodeJS.Timeout | null = null

function Search({setArray, setLoading}: ISearch) {
  const t = useTheme()
  const [searchValue, setSearchValue] = React.useState('')
  const [isFocused, setIsFocused] = React.useState(false)

  const searchTarget = useCallback(() => {
    timer && clearTimeout(timer)
    setArray({
      company: [],
      people: [],
    })
    setLoading(true)
    timer = setTimeout(() => {
      entityAgent
        .tarListRequest(searchValue)
        .then(data => {
          const company: ITargetItem[] = [],
            people: ITargetItem[] = []
          let array = data
          if (data?.data) {
            array = data.data
          }
          // 1: project 2: organization 3: person
          for (const obj of array) {
            if (obj.type === 3) {
              people.push({
                id: obj.id,
                name: obj.name,
                logo: obj.logo,
                type: 'person',
              })
            } else {
              company.push({
                id: obj.id,
                name: obj.name,
                logo: obj.logo,
                type: obj.type === 2 ? 'organization' : 'project',
              })
            }
          }
          setArray({
            company: [...company],
            people: [...people],
          })

          setLoading(false)
        })
        .catch(err => {
          Toast.show(err, 'xmark')
          setLoading(false)
        })
    }, 1000)
  }, [searchValue, setArray, setLoading])

  useEffect(() => {
    if (!searchValue) {
      setArray({
        company: [],
        people: [],
      })
    } else {
      searchTarget()
    }
  }, [searchValue, setArray, searchTarget])

  return (
    <TextInput
      value={searchValue}
      onChangeText={setSearchValue}
      accessibilityLabel="Text input field"
      accessibilityHint="Enter text to search"
      placeholder="Enter..."
      placeholderTextColor="#979797"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      style={[
        {borderWidth: 1},
        a.relative,
        a.p_xs,
        t.atoms.text,
        a.text_md,
        a.pl_sm,
        a.pr_md,
        t.atoms.input_border,
        t.atoms.input_bg,
        {height: 42, borderRadius: 10},
        isFocused && {borderColor: t.palette.primary},
      ]}
    />
  )
}

function TargetBox({
  item,
  onPressItem,
  active,
}: {
  item: ITargetItem
  onPressItem: (data: ITargetItem) => void
  active: boolean
}) {
  const t = useTheme()
  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={[a.flex_row, a.align_center, a.justify_between]}
      onPress={() => onPressItem(item)}>
      <View
        style={[
          styles.itemContainer,
          // active && {backgroundColor: 'rgba(128, 73, 170, 0.05)'},
        ]}>
        <UserAvatar
          avatar={item.logo}
          type={
            item.logo
              ? 'user'
              : item.type === 'person'
              ? 'person'
              : 'organization'
          }
          size={32}
        />
        <Text
          style={[
            t.atoms.text,
            active && {
              fontWeight: 'bold',
            },
            {marginLeft: 10},
          ]}>
          {item.name}
        </Text>
      </View>
      {active && (
        <FontAwesomeIcon
          icon={'check'}
          color={t.palette.green_11}
          style={[a.mr_lg]}
        />
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  top: {
    width: '100%',
    // paddingTop: 20,
  },
  head: {
    // height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  list: {
    width: '100%',
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  item: {
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: 3,
    padding: 10,
  },
  image: {
    width: 32,
    height: 32,
    marginRight: 10,
    borderRadius: 32,
  },
  selectedContainer: {
    marginVertical: 20,
    flexDirection: 'row',
  },
})
