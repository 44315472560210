import React, {useCallback, useEffect, useMemo} from 'react'
import {Pressable, StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import Svg, {Path} from 'react-native-svg'
import {Image} from 'expo-image'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useMediaQuery} from 'react-responsive'

import {useGetTimeAgo} from '#/lib/hooks/useTimeAgo'
import {makeProfileLink} from '#/lib/routes/links'
import {NavigationProp} from '#/lib/routes/types'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {POST_TOMBSTONE, Shadow, usePostShadow} from '#/state/cache/post-shadow'
import {emitter} from '#/state/events'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useModalControls} from '#/state/modals'
import {useAgent} from '#/state/session'
import {
  useComposerControls,
  useThemePrefs,
  useTickEveryMinute,
} from '#/state/shell'
import {UserIcon} from '#/view/icons/FeedIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {FONT_SIZE_CONFIG} from '#/screens/Settings/TextSizeSettings'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {RichText} from '#/components/RichText'
import {TelegramBindStep} from '../modals/TelegramModal/config'
import {getIsOpreration} from '../util/access/OperationUtil'
import {FromOfficialCard, getCircleItemCard} from '../util/CircleUtil'
import {TextLinkOnWebOnly} from '../util/Link'
import {CirclePostCtrls} from '../util/post-ctrls/CirclePostCtrls'
import {FriendTag, getRelationship} from '../util/sdlStore/Relationship'
import {FeedItemProps} from './FeedItem'
import MemoOperationCard from './MemoOperationCard'
import {useSwipeKey} from './SwipeProvider'
import {
  // AutoTranslate,
  useTranslationWidget,
} from './TranslationWidget/TranslationWidget'

interface IMemoCard {
  post: AppBskyFeedDefs.PostView
  style?: any
  index?: number
  isLocal?: boolean
}

export const MemoCard = ({
  style,
  index,
  post,
  record,
  moderation,
  isLocal,
}: IMemoCard & FeedItemProps) => {
  const postShadowed = usePostShadow(post)

  if (postShadowed === POST_TOMBSTONE) {
    return null
  }
  return (
    <MemoCardInner
      style={style}
      index={index}
      post={post}
      postShadowed={postShadowed}
      record={record}
      isLocal={isLocal}
      moderation={moderation}
    />
  )
}

function MemoCardInner({
  style,
  // index,
  post,
  record,
  postShadowed,
  moderation,
  isLocal,
}: {
  post: AppBskyFeedDefs.PostView
  postShadowed: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  style?: any
  index?: number
  moderation: ModerationDecision
  isLocal?: boolean
}) {
  const theme = useThemeName()
  const {openModal} = useModalControls()
  const hideRelation = useMediaQuery({minWidth: 500, maxWidth: 560})
  const swipeKey = useSwipeKey()

  const FromOfficial = useMemo(() => {
    return record.operation
  }, [record.operation])
  const isOfficialOperater = getIsOpreration(post.author.did)

  const circleItemCard = useMemo(() => {
    let cards = FromOfficialCard
    if (!FromOfficial) {
      cards = getCircleItemCard(post.author.did)
    }
    return cards[theme === 'light' ? 'light' : 'dark']
  }, [FromOfficial, post.author.did, theme])
  const [relations, setRelations] = React.useState<FriendTag | ''>('')
  const agent = useAgent()
  const {
    richText,
    // showAutoTranslate
  } = useTranslationWidget({
    record,
    shouldTrimArticle: true,
    post,
  })
  const ago = useGetTimeAgo()
  const tick = useTickEveryMinute()

  const time = ago(record.createdAt, tick)

  const onPressRelation = React.useCallback(() => {
    openModal({
      name: 'tea-visible',
      id: post.author.did,
      hideAuthor: record.hideAuthor === 'true',
    })
  }, [openModal, post.author.did, record.hideAuthor])

  useEffect(() => {
    if (post.author.did) {
      getRelationship(post.author.did, agent).then(res => {
        setRelations(res)
      })
    }
  }, [agent, post.author.did])

  const profileLink = makeProfileLink(post.author)

  const name = useMemo(() => {
    const temp = post.author.displayName || formatHandler(post.author.handle)
    return formatName(temp)
  }, [post.author.displayName, post.author.handle])

  const {sendInteraction} = useFeedFeedbackContext()
  const {openComposer} = useComposerControls()

  const onPressReply = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionReply',
      feedContext: record.text,
    })
    openComposer({
      replyTo: {
        uri: post.uri,
        cid: post.cid,
        text: record.text || '',
        author: post.author,
        embed: post.embed,
        moderation,
      },
    })
  }, [post, record, openComposer, moderation, sendInteraction])

  const navigation = useNavigation<NavigationProp>()

  const onPressSpill = React.useCallback(async () => {
    openComposer({})
  }, [openComposer])

  const onPressInvitation = React.useCallback(() => {
    openModal({name: 'telegram', currentStep: TelegramBindStep.SelectContact})
  }, [openModal])

  const onPressOperation = useCallback(() => {
    if (record.operationType === '1') {
      onPressSpill()
    } else if (record.operationType === '2') {
      onPressInvitation()
    } else if (record.operationType === '3') {
      navigation.navigate('CircleCreate')
    }
  }, [navigation, onPressInvitation, onPressSpill, record])

  const onPressLink = useCallback(() => {
    if (FromOfficial) {
      onPressOperation()
    } else {
      const urip = new AtUri(post.uri)
      const postUri = makeRecordUri(
        post.author.handle,
        'app.bsky.feed.post',
        urip.rkey,
      )
      emitter.emit('setSwipeList', {
        from: swipeKey,
        postUri,
      })
      navigation.navigate('PostThread', {
        name: post.author.handle,
        rkey: urip.rkey,
      })
    }
  }, [
    FromOfficial,
    navigation,
    onPressOperation,
    post.author.handle,
    post.uri,
    swipeKey,
  ])

  const {_} = useLingui()

  const AnonymousName = useMemo(() => {
    return formatName(_(msg`Anonymous`))
  }, [_])

  const {fontSizeMode} = useThemePrefs()
  const cardTextFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.feed].feed.circle.text
  }, [fontSizeMode])

  if (FromOfficial) {
    return <MemoOperationCard style={style} record={record} />
  }

  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPressLink}
      style={[style, {paddingHorizontal: 6}]}>
      <View>
        <View style={[styles.page, {backgroundColor: circleItemCard.color}]}>
          <Bevel />
          {(FromOfficial || isOfficialOperater) && (
            <Stamp type={record.operationType || ''} />
          )}
          <View style={styles.inside}>
            {/* <Text style={[styles.article]} numberOfLines={3}>
              {richText.text.replaceAll('\n', ' ')}
            </Text> */}
            <RichText
              testID="circlePostText"
              value={richText}
              numberOfLines={3}
              style={[
                styles.article,
                {color: 'black', fontSize: cardTextFontSize},
              ]}
            />
          </View>

          <View style={styles.ctrl}>
            <Text
              style={[
                styles.name,
                FromOfficial && {transform: [{translateY: -2.5}]},
              ]}>
              {FromOfficial && <VIcon />}
              {record.hideAuthor === 'true' ? (
                AnonymousName
              ) : (
                <TextLinkOnWebOnly
                  href={profileLink}
                  text={name}
                  style={{fontSize: 12}}
                />
              )}
              {` ·${time}`}
            </Text>

            {!hideRelation && (
              <TouchableOpacity
                accessibilityRole="button"
                onPress={onPressRelation}
                style={{
                  flexDirection: 'row',
                }}>
                <UserIcon style={{marginLeft: 10}} fixedColor={'black'} />
                <Text
                  style={[styles.name, {marginLeft: 3, fontWeight: 'bold'}]}>
                  {relations}
                </Text>
              </TouchableOpacity>
            )}

            <View style={{flex: 1}} />
            <CirclePostCtrls
              post={postShadowed}
              record={record}
              // richText={richText}
              onPressReply={onPressReply}
              logContext="FeedItem"
              isMemoCard
              isLocal={isLocal}
              feedContext={record.text}
            />
          </View>
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  page: {
    height: 178,
    width: '100%',
    marginTop: 12,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 16,
    position: 'relative',
  },
  inside: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  article: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 28,
    width: '100%',
    // textAlign: 'center',
  },
  ctrl: {
    height: 16,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  name: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  handle: {
    width: 50,
    height: 16,
    flexDirection: 'row',
  },
  iconText: {
    color: 'rgba(0, 0, 0, 0.8)',
    marginLeft: 5,
  },
})

export function Bevel() {
  const theme = useThemeName()

  const sdlTest = () => {
    // aboutCacheArray()
  }

  if (theme === 'light') {
    return (
      <Pressable
        accessibilityRole="button"
        onPress={sdlTest}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}>
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <Path
            d="M20 8C20 3.58172 16.4183 0 12 0H0L9.58333 9.58333L20 20V8Z"
            fill="white"
          />
          <Path
            d="M0 8C0 14.6274 5.37258 20 12 20H20L10.4167 10.4167L0 0V8Z"
            fill="black"
            fillOpacity="0.15"
          />
        </Svg>
      </Pressable>
    )
  }
  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}>
      <Path
        d="M20 8C20 3.58172 16.4183 0 12 0H0L9.58333 9.58333L20 20V8Z"
        fill="black"
      />
      <Path
        opacity="0.15"
        d="M0 8C0 14.6274 5.37258 20 12 20H20L10.4167 10.4167L0 0V8Z"
        fill="black"
      />
    </Svg>
  )
}

function VIcon() {
  const size = 14
  return (
    <Svg
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
      fill="none"
      style={{
        transform: [{translateY: 2.5}],
        marginRight: 3,
      }}>
      <Path
        d="M8 0L9.8635 1.04533L12 1.0718L13.0912 2.90883L14.9282 4L14.9547 6.1365L16 8L14.9547 9.8635L14.9282 12L13.0912 13.0912L12 14.9282L9.8635 14.9547L8 16L6.1365 14.9547L4 14.9282L2.90883 13.0912L1.0718 12L1.04533 9.8635L0 8L1.04533 6.1365L1.0718 4L2.90883 2.90883L4 1.0718L6.1365 1.04533L8 0Z"
        fill="black"
      />
      <Path
        d="M11.3585 5.06565L9.47084 5.2475C9.35098 5.25902 9.22167 5.36057 9.18211 5.47423L7.97407 8.94277L6.76013 5.47394C6.72057 5.36057 6.59097 5.25902 6.47141 5.2475L4.58377 5.06565C4.46421 5.05414 4.40014 5.1365 4.44059 5.24987L6.81534 11.853C6.85608 11.9661 6.98686 12.0582 7.10701 12.0582H8.95568C8.97723 12.0582 8.99524 12.0523 9.00852 12.042C9.04539 12.0244 9.07434 11.9936 9.08971 11.9558L9.10122 11.9239L11.5014 5.24987C11.5421 5.1365 11.478 5.05414 11.3585 5.06565Z"
        fill="#FCD010"
      />
    </Svg>
  )
}

function Stamp({type}: {type: string}) {
  const StampSource = useMemo(() => {
    switch (type) {
      case '1': // spill
        return require('../../../../assets/icons/stamp1.png')
      case '2': // invitation
        return require('../../../../assets/icons/stamp2.png')
      case '3': // circle
        return require('../../../../assets/icons/stamp3.png')
      default:
        return require('../../../../assets/icons/stamp4.png')
    }
  }, [type])
  return (
    <Image
      style={{
        height: 80,
        width: 80,
        position: 'absolute',
        top: 0,
        right: 5,
        opacity: 0.6,
      }}
      source={StampSource}
      accessibilityIgnoresInvertColors={true}
    />
  )
}

function formatName(name: string) {
  if (name.length > 8) {
    return name.substring(0, 5) + '...'
  } else {
    return name
  }
}
