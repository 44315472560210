import React from 'react'
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '../util/forms/Button'

type ActionItem = {
  name: string
  action: () => void
}
export interface ActionMenuModalProps {
  actionMenus: ActionItem[]
}

export const snapPoints = ['35%']
export function Component({actionMenus}: ActionMenuModalProps) {
  const t = useTheme()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()

  return (
    <View style={[styles.page, isMobile && {paddingHorizontal: 18}]}>
      {actionMenus?.map(item => {
        return (
          <TouchableWithoutFeedback
            accessibilityRole="button"
            key={item.name}
            onPress={() => {
              item?.action()
            }}>
            <View style={[styles.item]}>
              <Text style={[styles.itemLabel, t.atoms.text]}>
                <Trans>{item?.name}</Trans>
              </Text>
            </View>
          </TouchableWithoutFeedback>
        )
      })}

      <View
        style={[
          styles.ctrl,
          a.gap_md,
          isDesktop ? [a.flex_row_reverse, a.gap_md] : {},
        ]}>
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 0,
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
  },
  item: {
    height: 56,
    borderWidth: 1,
    borderColor: '#97979733',
    borderRadius: 8,
    marginBottom: 12,
    padding: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemLabel: {
    fontSize: 16,
    fontWeight: '500',
  },
  ctrl: {
    marginTop: 20,
  },
})
