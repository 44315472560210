import React, {useEffect} from 'react'
import {
  FlatList as RNFlatList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import * as persisted from '#/state/persisted'
import {useAgent, useSession, useSessionApi} from '#/state/session'
import {maskToSessionAccount} from '#/state/session/agent'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import * as Toast from '#/view/com/util/Toast'
import {MaskEmptyIcon} from '#/view/icons/EmptyIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {CheckedIcon} from '#/components/icons/StakeIcons'
import {Button} from '../util/forms/Button'
import {PostAccountType} from '../util/post-mask'
import {UserAvatar} from '../util/UserAvatar'

export const snapPoints = ['fullscreen']
export interface SwitchMaskModalProps {
  title?: string
}
export const Component = function SwitchMaskModalComponent(
  props: SwitchMaskModalProps,
) {
  const {_} = useLingui()
  const t = useTheme()
  const {isMobile} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const {currentAccount} = useSession()
  const {accounts} = usePostPreState()
  const navigation = useNavigation<NavigationProp>()
  const agent = useAgent()
  const {resumeSession} = useSessionApi()

  const [sltAccount, setSltAccount] = React.useState<PostAccountType>()
  const isCurrentMain =
    currentAccount?.did === sltAccount?.did &&
    (sltAccount?.parentDid === '' || sltAccount?.parentDid === undefined)

  const onChangeMask = React.useCallback(
    async (item: PostAccountType) => {
      const res = await agent.com.atproto.server.createSubAccountSession({
        did: item.did,
      })
      const account = maskToSessionAccount(res.data, agent)
      const handle = account.handle
      await resumeSession(account, true)
      if (handle) {
        persisted.write('postMakeDid', account.did)
        persisted.write('postIsAnonymous', true)
        Toast.show(_(`You are now acting as ${formatHandler(handle)}`))
      }
    },
    [_, agent, resumeSession],
  )

  const renderItemInner = React.useCallback(
    ({item}: {item: PostAccountType}) => {
      const isSelected = sltAccount?.did === item?.did
      const isMain = !item?.parentDid || item?.parentDid === ''
      return (
        <TouchableOpacity
          accessibilityRole="button"
          key={item?.did}
          onPress={() => {
            closeModal()
            onChangeMask(item)
          }}
          style={[
            a.p_md,
            a.border,
            a.rounded_lg,
            a.flex_row,
            a.justify_between,
            a.align_center,
            a.mt_md,
            {
              borderWidth: isSelected ? 2 : 1,
              borderColor: isSelected ? t.palette.green_11 : '#97979733',
            },
          ]}>
          <View style={[a.flex_1, a.flex_row, a.align_center]}>
            <UserAvatar avatar={item?.avatar} size={44} />
            <View style={[a.flex_1, a.flex_col, a.ml_sm]}>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <Text
                  style={[a.text_sm, a.font_bold, t.atoms.text]}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {item?.name && item?.name !== ''
                    ? item?.name
                    : formatHandler(item?.handle)}
                </Text>
                {isMain && (
                  <View
                    style={[
                      t.atoms.bg_primary,
                      a.rounded_xs,
                      {paddingHorizontal: 4, paddingVertical: 2},
                    ]}>
                    <Text style={[a.text_xs, a.font_semibold]}>
                      Main account
                    </Text>
                  </View>
                )}
              </View>
              <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                @{formatHandler(item?.handle)}
              </Text>
            </View>
          </View>
          <View style={[a.px_lg]}>
            {isSelected && <CheckedIcon size={24} />}
          </View>
        </TouchableOpacity>
      )
    },
    [
      t.palette.gray_10,
      sltAccount?.did,
      closeModal,
      onChangeMask,
      t.palette.green_11,
      t.atoms.bg_primary,
      t.atoms.text,
    ],
  )

  useEffect(() => {
    const obj = accounts.find(o => o?.did === currentAccount?.did)
    if (obj) {
      setSltAccount(obj)
    }
  }, [currentAccount, accounts])

  return (
    <View
      testID="stakeDonateModal"
      style={[a.flex_1, isMobile && {paddingHorizontal: 18}]}>
      <View style={[a.flex_row, a.align_center, a.justify_center, a.pb_lg]}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={closeModal}
          style={[a.absolute, {left: -10}]}>
          <FontAwesomeIcon
            icon={'xmark'}
            size={24}
            color={t.atoms.text.color}
          />
        </TouchableOpacity>
        <Text
          style={[
            a.text_xl,
            a.font_bold,
            a.flex,
            a.justify_center,
            a.text_center,
            t.atoms.text,
          ]}>
          <Trans>{props?.title ?? 'Switch to'}</Trans>
        </Text>
      </View>
      <RNFlatList
        testID={'transassetsflatlist'}
        data={accounts}
        renderItem={renderItemInner}
        removeClippedSubviews={true}
        // @ts-ignore our .web version only -prf
        desktopFixedHeight
      />
      {isCurrentMain && (
        <View style={[a.mt_md]}>
          {accounts?.length <= 1 ? (
            <View
              style={[
                a.flex_row,
                a.gap_md,
                a.border,
                a.rounded_md,
                a.p_lg,
                isMobile ? a.pb_md : a.pb_0,
                {borderColor: '#97979733'},
              ]}>
              <View style={[a.flex_1]}>
                <Text style={[a.text_lg, a.font_bold, a.pb_xs, t.atoms.text]}>
                  <Trans>Create a mask and express yourself freely.</Trans>
                </Text>
                <Button
                  testID="goCreateMaskBtn"
                  type="default"
                  onPress={() => {
                    closeModal()
                    // navigation?.navigate('Mask', {time: new Date().getTime()})
                    navigation.navigate('SavedMask')
                  }}
                  accessibilityLabel={_(msg`Create`)}
                  accessibilityHint=""
                  label={_(msg`Create`)}
                  labelContainerStyle={{justifyContent: 'center', padding: 4}}
                  labelStyle={[a.text_md, {color: t.palette.black}]}
                  style={[
                    a.mt_lg,
                    {backgroundColor: t.palette.primary, width: 95},
                  ]}
                />
              </View>
              <View>
                <MaskEmptyIcon size={136} />
              </View>
            </View>
          ) : (
            <TouchableOpacity
              accessibilityRole="button"
              onPress={() => {
                closeModal()
                navigation.navigate('SavedMask')
              }}
              style={[
                a.p_md,
                a.border,
                a.rounded_lg,
                a.flex_row,
                a.align_center,
                a.mb_md,
                a.gap_md,
                {
                  borderWidth: 1,
                  borderColor: '#97979733',
                },
              ]}>
              <View
                style={{
                  marginLeft: 3,
                  borderWidth: 1,
                  borderStyle: 'dashed',
                  borderColor: t.palette.gray_10,
                  width: 40,
                  height: 40,
                  borderRadius: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#E8E8E8',
                }}>
                <FontAwesomeIcon
                  icon={'plus'}
                  size={20}
                  color={t.palette.gray_10}
                />
              </View>
              <Text
                style={[
                  a.text_lg,
                  a.font_semibold,
                  {color: t.palette.black_25},
                ]}>
                <Trans>Create mask</Trans>
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  )
}
