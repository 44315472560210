import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {StyleSheet, Text, TextInput, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {SHARE_URL} from '#/lib/constants'
import {usePalette} from '#/lib/hooks/usePalette'
import {useModalControls} from '#/state/modals'
import {useProfileQuery} from '#/state/queries/profile'
import {useAgent, useSession} from '#/state/session'
import {useTelegramBotUrl} from '#/view/screens/Activity/hooks'
import * as Toast from 'view/com/util/Toast'
// import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon, ButtonText} from '#/components/Button'
import {Loader} from '#/components/Loader'
import {telegramUserBaseInfo, useTelegramClient} from './hooks'

export function TelegramMessage({
  userIds,
  onCallBack,
  relogin,
  circleId,
}: {
  userIds: string[]
  onCallBack?: () => void
  relogin: () => void
  circleId?: string
}) {
  const pal = usePalette('default')
  const t = useTheme()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const {client} = useTelegramClient()
  const [sending, setSending] = useState<boolean>(false)
  const [message, setMessage] = useState<string>()
  const [error, setError] = useState<string>('')
  const queryClient = useQueryClient()
  const {currentAccount} = useSession()
  const agent = useAgent()
  const {data: profile} = useProfileQuery({
    did: currentAccount?.did,
  })
  const inviteLink = useTelegramBotUrl(2)

  const url = useMemo(() => {
    if (circleId) {
      return `${SHARE_URL}circle/detail/${circleId}`
    }
    if (currentAccount?.handle) {
      // return `${SHARE_URL}?spiz_handle=${formatHandler(currentAccount?.handle)}`
      return inviteLink
    }
    return SHARE_URL
  }, [circleId, currentAccount?.handle, inviteLink])

  const sendMessage = useCallback(async () => {
    if (!sending && message && client) {
      setSending(true)
      try {
        await client?.connect()
        await Promise.all(
          userIds.map(id => {
            return client?.sendMessage(id, {
              message: `${message}\n\n ${url} \n`,
            })
          }),
        )
        agent.com.atproto.server.inviteTelegramUsers({
          invited_user_ids: userIds,
        })

        await sleep()

        queryClient.invalidateQueries({
          queryKey: telegramUserBaseInfo,
        })
        Toast.show('Invitation sent successfully!')
        closeModal()
      } catch (err: any) {
        if (err?.code === 401) {
          relogin()
        }
        if (err?.error_code === 400) {
          setError(
            _(
              msg`Telegram server is busy. Please refresh and try again later.`,
            ),
          )
        } else {
          setError(_(msg`cause some error, please try again later.`))
        }
      } finally {
        setSending(false)
        onCallBack?.()
      }
    }
  }, [
    sending,
    message,
    client,
    userIds,
    agent.com.atproto.server,
    queryClient,
    closeModal,
    url,
    relogin,
    _,
    onCallBack,
  ])

  useEffect(() => {
    if (profile?.displayName) {
      setMessage(
        `"${profile.displayName}" is using Sipz - an anonymous and exclusive gossip app for a clean crypto community! Access now for the latest insights or spill the tea`,
      )
    } else {
      setMessage(
        `An anonymous and exclusive gossip app for a clean crypto community! Access now for the latest insights or spill the tea`,
      )
    }
  }, [profile?.displayName])

  return (
    <View style={[styles.page, a.pb_xl]}>
      <View style={[a.flex_row, a.align_center, a.justify_between, a.mb_3xl]}>
        <Button
          style={[a.p_md]}
          variant="solid"
          shape="square"
          size="medium"
          label="Cancel"
          onPress={() => closeModal()}>
          <Text style={[a.text_md, t.atoms.text]}>
            <Trans>Cancel</Trans>
          </Text>
        </Button>
        <Text style={[styles.title, a.m_0, t.atoms.text]}>
          <Trans>Invite</Trans>
        </Text>
        <Button
          style={[a.p_xs]}
          color="primary"
          onPress={() => sendMessage()}
          label="Send"
          variant="solid"
          shape="square"
          disabled={sending || !message}
          size="medium">
          <ButtonText style={[a.font_semibold, a.text_md]}>
            <Trans>Send</Trans>
          </ButtonText>
          <>{sending ? <ButtonIcon icon={Loader} /> : ''}</>
        </Button>
      </View>

      <Text style={[a.mb_md, t.atoms.text_sub]}>
        <Trans>The individual you've invited will receive this message:</Trans>
      </Text>
      {error && (
        <Text style={[error ? t.atoms.text_danger : t.atoms.text, a.m_md]}>
          <Trans>{error}</Trans>
        </Text>
      )}
      <View
        style={[
          styles.textArea,
          pal.border,
          a.p_sm,
          t.atoms.input_border,
          t.atoms.input_bg,
        ]}>
        <TextInput
          testID="sendTelegramMessage"
          style={[t.atoms.text, {height: 80, fontSize: 16}]}
          placeholderTextColor={t.atoms.text_sub.color}
          multiline
          value={message}
          onChangeText={v => {
            setMessage(v)
            setError('')
          }}
          accessible={true}
        />
        <Text style={[a.mt_sm, {color: '#C28C00'}]}>{url}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },

  textArea: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingTop: 10,
    fontSize: 16,
    textAlignVertical: 'top',
  },
})

function sleep(time: number = 500) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}
