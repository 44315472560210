import React from 'react'
import {ScrollView} from 'react-native'

import {isNative} from '#/platform/detection'
import {List} from '#/view/com/util/List'
import {CircleItemProps} from '.'
import {CircleItem} from './CircleItem'

type Props = {
  data?: CircleItemProps[]
  onEdit?: (circle: CircleItemProps) => void
  onInvite?: (circle: CircleItemProps) => void
}
export function CircleList({data, onEdit, onInvite}: Props) {
  const renderItem = ({item}: {item: CircleItemProps}) => {
    return <CircleItem circle={item} onEdit={onEdit} onInvite={onInvite} />
  }

  const keyExtractor = (item: CircleItemProps) => {
    return item.id
  }

  const content = (
    <List
      sideBorders={false}
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  )
  return isNative ? <ScrollView>{content}</ScrollView> : content
}
