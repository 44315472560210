import {useSyncExternalStore} from 'react'
import {ProfileViewBasic} from '@atproto/api/dist/client/types/app/bsky/actor/defs'

import {useModalControls} from '#/state/modals'
import {useComposerControls} from '#/state/shell'
import {IOnBet} from '#/state/transaction/bet'
import {IOnStake} from '#/state/transaction/stake'
import {VisibleRangeType} from '../../modals/PostModal/PostSelectCircle'
import {createStore} from './SdlStore'

type TStepValue = 'start' | 'stake' | 'bet' | 'guarantor' | 'finish'

interface IPostInfo {
  cid: string
  uri: string
  did: string
}
interface IPostStepStore {
  posting: boolean
  stake: {
    pending: boolean
    finish: boolean
  }
  bet: {
    pending: boolean
    finish: boolean
  }
  guarantor: {
    pending: boolean
    finish: boolean
  }
  stakeParams?: IOnStake
  step: TStepValue
  betParams?: IOnBet
  guarantorParams?: ProfileViewBasic[]
  postInfo: IPostInfo
  postVisibleRange: VisibleRangeType
}

const initStore = {
  posting: true,
  stake: {
    pending: false,
    finish: false,
  },
  step: 'start' as TStepValue,
  bet: {
    pending: false,
    finish: false,
  },
  guarantor: {
    pending: false,
    finish: false,
  },
  postInfo: {
    cid: '',
    uri: '',
    did: '',
  },
  postVisibleRange: {
    visibleType: 1,
    visibleCircles: [],
    visibleFriends: [],
    unVisibleTags: [],
    unVisibleFriends: [],
  },
}

const store = createStore<IPostStepStore>({...initStore})

const {subscribe, getState, setState: setStepState} = store

export function usePostStepStore() {
  const {closeModal} = useModalControls()
  const {closeComposer} = useComposerControls()
  const stepStore = useSyncExternalStore(subscribe, getState)

  const resetStore = () => {
    setStepState({...initStore})
  }

  const nextStep = () => {
    setStepState(pre => {
      let stepValue = pre.step
      switch (pre.step) {
        case 'start':
          stepValue = 'guarantor'
          break
        case 'guarantor':
          stepValue = 'stake'
          break
        case 'stake':
          stepValue = 'bet'
          break
        case 'bet':
          stepValue = 'finish'
          break
      }
      return {
        ...pre,
        posting: false,
        step: stepValue,
      }
    })
  }

  const setStakeParams = (stakeParams: IOnStake) => {
    setStepState(pre => ({
      ...pre,
      stakeParams,
    }))
  }

  const setBetParams = (betParams: IOnBet) => {
    setStepState(pre => ({
      ...pre,
      betParams,
    }))
  }

  const setGuarantorParams = (guarantorParams: ProfileViewBasic[]) => {
    setStepState(pre => ({
      ...pre,
      guarantorParams,
    }))
  }

  const finishAllStep = () => {
    resetStore()
    closeComposer()
    closeModal()
  }

  const startStake = () => {
    setStepState(pre => ({
      ...pre,
      stake: {
        pending: true,
        finish: false,
      },
    }))
  }

  const startBet = () => {
    setStepState(pre => ({
      ...pre,
      bet: {
        pending: true,
        finish: false,
      },
    }))
  }

  const startGuarantor = () => {
    setStepState(pre => ({
      ...pre,
      guarantor: {
        pending: true,
        finish: false,
      },
    }))
  }

  const finishGuarantor = () => {
    setStepState(pre => ({
      ...pre,
      step: 'stake',
      guarantor: {
        pending: false,
        finish: true,
      },
    }))
  }

  const finishStake = () => {
    setStepState(pre => ({
      ...pre,
      step: 'bet',
      stake: {
        pending: false,
        finish: true,
      },
    }))
  }

  const finishBet = () => {
    setStepState(pre => ({
      ...pre,
      step: 'finish',
      bet: {
        pending: false,
        finish: true,
      },
    }))
  }

  const setPostInfo = (postInfo: IPostInfo) => {
    setStepState(pre => ({
      ...pre,
      postInfo,
    }))
  }

  const setPostVisibleRange = (postVisibleRange: VisibleRangeType) => {
    setStepState(pre => ({
      ...pre,
      postVisibleRange,
    }))
  }

  return {
    stepStore,
    startStake,
    startBet,
    finishStake,
    finishGuarantor,
    finishBet,
    finishAllStep,
    setStakeParams,
    setBetParams,
    nextStep,
    setGuarantorParams,
    startGuarantor,
    setPostInfo,
    setPostVisibleRange,
  }
}
