import React, {useCallback, useState} from 'react'
import {
  StyleSheet,
  Text,
  // TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {Trans} from '@lingui/macro'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {IOperation, useModalControls} from '#/state/modals'
import {RadioIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '../../util/forms/Button'

export const snapPoints = ['fullscreen']

export function Component({operation, setOperation}: IOperation) {
  const t = useTheme()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const [scope, setScope] = useState<string>(operation)

  const onPressConfirm = useCallback(() => {
    setOperation(scope)
    closeModal()
  }, [closeModal, setOperation, scope])

  const getActive = useCallback(
    (str: string) => {
      return str === scope
    },
    [scope],
  )

  return (
    <View style={[styles.page, isMobile && {paddingHorizontal: 18}]}>
      <Text
        style={[
          {
            fontWeight: 'bold',
            fontSize: 19,
            textAlign: 'center',
            paddingBottom: 30,
          },
          t.atoms.text,
        ]}>
        <Trans>Operation Type</Trans>
      </Text>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setScope('0')}>
        <View
          style={[
            styles.item,
            getActive('0') && {borderColor: t.palette.green_11},
          ]}>
          <RadioIcon active={getActive('0')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}, t.atoms.text]}>
              <Trans>None</Trans>
            </Text>
            <Text style={[styles.itemValue, t.atoms.text_sub]}>
              <Trans>Spilling as a common post</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}}>
            {/* <RightIcon /> */}
          </View>
        </View>
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setScope('1')}>
        <View
          style={[
            styles.item,
            getActive('1') && {borderColor: t.palette.green_11},
          ]}>
          <RadioIcon active={getActive('1')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}, t.atoms.text]}>
              <Trans>Spilling</Trans>
            </Text>
            <Text style={[styles.itemValue, t.atoms.text_sub]}>
              <Trans>Inspire users to Spill</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}} />
        </View>
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setScope('2')}>
        <View
          style={[
            styles.item,
            getActive('2') && {borderColor: t.palette.green_11},
          ]}>
          <RadioIcon active={getActive('2')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}, t.atoms.text]}>
              <Trans>Invitation</Trans>
            </Text>
            <Text style={[styles.itemValue, t.atoms.text_sub]}>
              <Trans>Inspire users to invite friends</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}} />
        </View>
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setScope('3')}>
        <View
          style={[
            styles.item,
            getActive('3') && {borderColor: t.palette.green_11},
          ]}>
          <RadioIcon active={getActive('3')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}, t.atoms.text]}>
              <Trans>Circle</Trans>
            </Text>
            <Text style={[styles.itemValue, t.atoms.text_sub]}>
              <Trans>Inspire users to create circles</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}} />
        </View>
      </TouchableWithoutFeedback>

      <TouchableWithoutFeedback
        accessibilityRole="button"
        onPress={() => setScope('4')}>
        <View
          style={[
            styles.item,
            getActive('4') && {borderColor: t.palette.green_11},
          ]}>
          <RadioIcon active={getActive('4')} />
          <View style={styles.itemText}>
            <Text style={[styles.itemLabel, {fontWeight: '500'}, t.atoms.text]}>
              <Trans>Leaderboard</Trans>
            </Text>
            <Text style={[styles.itemValue, t.atoms.text_sub]}>
              <Trans>Show leaderboard entry</Trans>
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}} />
        </View>
      </TouchableWithoutFeedback>

      <View
        style={[
          styles.ctrl,
          a.gap_md,
          isDesktop ? [a.flex_row_reverse, a.gap_md] : {},
        ]}>
        {/* <TouchableOpacity
          accessibilityRole="button"
          style={[styles.button, {backgroundColor: t.palette.primary}]}
          onPress={onPressConfirm}>
          <Text
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'black',
            }}>
            <Trans>Confirm</Trans>
          </Text>
        </TouchableOpacity> */}

        {/* <TouchableOpacity
          accessibilityRole="button"
          style={[styles.button, t.atoms.bg_contrast_25]}
          onPress={closeModal}>
          <Text
            style={[
              t.atoms.text_sub,
              {
                fontSize: 16,
                fontWeight: 'bold',
                textAlign: 'center',
              },
            ]}>
            <Trans>Close</Trans>
          </Text>
        </TouchableOpacity> */}

        <Button
          testID="confirmBtn"
          type="default"
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint=""
          label={_(msg`Confirm`)}
          onPress={onPressConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Close`)}
          accessibilityHint=""
          label={_(msg`Close`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 0,
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
  },
  item: {
    borderWidth: 1,
    borderColor: '#97979733',
    borderRadius: 8,
    marginBottom: 12,
    padding: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  itemText: {},
  itemLabel: {
    fontSize: 16,
  },
  itemValue: {
    fontSize: 12,
    marginTop: 4,
  },
  ctrl: {
    marginTop: 20,
  },
  button: {
    flex: 1,
    height: 42,
    padding: 10,
    marginBottom: 10,
    borderRadius: 100,
    // backgroundColor: '#F1F3F5',
    justifyContent: 'center',
  },
})
