import React, {useMemo} from 'react'
import {Text, View} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useAnalytics} from '#/lib/analytics/analytics'
import {logEvent} from '#/lib/statsig/statsig'
import {logger} from '#/logger'
import {SessionAccount, useSession, useSessionApi} from '#/state/session'
import {useLoggedOutViewControls} from '#/state/shell/logged-out'
import {LoggedOutScreenState} from '#/view/com/auth/LoggedOut'
import * as Toast from '#/view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {AccountList} from '#/components/AccountList'
import {formatHandler} from '../Onboarding/util'
// import {Button, ButtonText} from '#/components/Button'
// import * as TextField from '#/components/forms/TextField'
import {FormContainer} from './FormContainer'
import {WalletImport} from './WalletImport'

export const ChooseAccountForm = ({
  onSelectAccount,
  onPressBack,
  onSelectNewWallet,
  onPressScanner,
  setScreenState,
}: {
  onSelectAccount: (account?: SessionAccount) => void
  onSelectNewWallet?: (img: RNImage | null) => void
  onPressBack: () => void
  onPressScanner: () => void
  setScreenState: (screen: LoggedOutScreenState) => void
}) => {
  const [pendingDid, setPendingDid] = React.useState<string | null>(null)
  const {track, screen} = useAnalytics()
  const {_} = useLingui()
  const {currentAccount} = useSession()
  const {resumeSession} = useSessionApi()
  const {setShowLoggedOut} = useLoggedOutViewControls()
  const t = useTheme()

  React.useEffect(() => {
    screen('Choose Account')
  }, [screen])

  const onSelect = React.useCallback(
    async (account: SessionAccount) => {
      if (pendingDid) {
        // The session API isn't resilient to race conditions so let's just ignore this.
        return
      }
      if (!account.accessJwt) {
        // Move to login form.
        onSelectAccount(account)
        return
      }
      if (account.did === currentAccount?.did) {
        setShowLoggedOut(false)
        Toast.show(
          _(msg`Already signed in as @${formatHandler(account.handle)}`),
        )
        return
      }
      try {
        setPendingDid(account.did)
        await resumeSession(account)
        logEvent('account:loggedIn', {
          logContext: 'ChooseAccountForm',
          withPassword: false,
        })
        track('Sign In', {resumedSession: true})
        Toast.show(_(msg`Signed in as @${formatHandler(account.handle)}`))
      } catch (e: any) {
        logger.error('choose account: initSession failed', {
          message: e.message,
        })
        // Move to login form.
        onSelectAccount(account)
      } finally {
        setPendingDid(null)
      }
    },
    [
      currentAccount,
      track,
      resumeSession,
      pendingDid,
      onSelectAccount,
      setShowLoggedOut,
      _,
    ],
  )

  const title = useMemo(() => {
    return (
      <>
        <View>
          <Text onPress={onPressBack}>
            <FontAwesomeIcon
              size={18}
              icon="angle-left"
              color={t.atoms.text.color}
              // style={[.text]}
            />
          </Text>
        </View>
      </>
    )
  }, [onPressBack, t.atoms.text.color])

  return (
    <FormContainer testID="chooseAccountForm">
      {title}
      <Text
        style={[
          a.pb_3xl,
          a.text_3xl,
          a.font_bold,
          a.text_center,
          t.atoms.text,
        ]}>
        <Trans>Login In</Trans>
      </Text>
      <View>
        {/* <TextField.LabelText>
          <Trans>Login as...</Trans>
        </TextField.LabelText> */}
        <AccountList
          onSelectAccount={onSelect}
          onSelectOther={() => onSelectAccount()}
          pendingDid={pendingDid}
        />
        <WalletImport
          onSelectNewWallet={onSelectNewWallet}
          onPressScanner={onPressScanner}
          setScreenState={setScreenState}
        />
      </View>
      {/* <View style={[a.flex_row]}>
        <Button
          label={_(msg`Back`)}
          variant="solid"
          color="secondary"
          size="medium"
          onPress={onPressBack}>
          <ButtonText>{_(msg`Back`)}</ButtonText>
        </Button>
        <View style={[a.flex_1]} />
      </View> */}
    </FormContainer>
  )
}
