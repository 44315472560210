/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { XrpcClient, FetchHandler, FetchHandlerOptions } from '@atproto/xrpc'
import { schemas } from './lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoAdminCreateLabelTag from './types/com/atproto/admin/createLabelTag'
import * as ComAtprotoAdminDefs from './types/com/atproto/admin/defs'
import * as ComAtprotoAdminDeleteAccount from './types/com/atproto/admin/deleteAccount'
import * as ComAtprotoAdminDeleteLabelTag from './types/com/atproto/admin/deleteLabelTag'
import * as ComAtprotoAdminDisableAccountInvites from './types/com/atproto/admin/disableAccountInvites'
import * as ComAtprotoAdminDisableInviteCodes from './types/com/atproto/admin/disableInviteCodes'
import * as ComAtprotoAdminEnableAccountInvites from './types/com/atproto/admin/enableAccountInvites'
import * as ComAtprotoAdminGetAccountInfo from './types/com/atproto/admin/getAccountInfo'
import * as ComAtprotoAdminGetAccountInfos from './types/com/atproto/admin/getAccountInfos'
import * as ComAtprotoAdminGetActorStatistics from './types/com/atproto/admin/getActorStatistics'
import * as ComAtprotoAdminGetInviteCodes from './types/com/atproto/admin/getInviteCodes'
import * as AppBskyFeedGetPostByTime from './types/app/bsky/feed/getPostByTime'
import * as ComAtprotoAdminGetSubjectStatus from './types/com/atproto/admin/getSubjectStatus'
import * as ComAtprotoAdminModifyLabelTagActive from './types/com/atproto/admin/modifyLabelTagActive'
import * as ComAtprotoAdminModifyPostLabels from './types/com/atproto/admin/modifyPostLabels'
import * as ComAtprotoAdminQueryLabelTags from './types/com/atproto/admin/queryLabelTags'
import * as AppBskyFeedQueryPostByLabel from './types/app/bsky/feed/queryPostByLabel'
import * as ComAtprotoAdminRenameLabelTag from './types/com/atproto/admin/renameLabelTag'
import * as ComAtprotoAdminSaveActorStatistics from './types/com/atproto/admin/saveActorStatistics'
import * as ComAtprotoAdminSaveAdminAddress from './types/com/atproto/admin/saveAdminAddress'
import * as ComAtprotoAdminSearchAccounts from './types/com/atproto/admin/searchAccounts'
import * as ComAtprotoAdminSearchLabelTags from './types/com/atproto/admin/searchLabelTags'
import * as ComAtprotoAdminSendEmail from './types/com/atproto/admin/sendEmail'
import * as ComAtprotoAdminUpdateAccountEmail from './types/com/atproto/admin/updateAccountEmail'
import * as ComAtprotoAdminUpdateAccountHandle from './types/com/atproto/admin/updateAccountHandle'
import * as ComAtprotoAdminUpdateAccountPassword from './types/com/atproto/admin/updateAccountPassword'
import * as ComAtprotoAdminUpdateSubjectStatus from './types/com/atproto/admin/updateSubjectStatus'
import * as ComAtprotoIdentityGetFavorites from './types/com/atproto/identity/getFavorites'
import * as ComAtprotoIdentityGetRecommendedDidCredentials from './types/com/atproto/identity/getRecommendedDidCredentials'
import * as ComAtprotoIdentityIsFavorite from './types/com/atproto/identity/isFavorite'
import * as ComAtprotoIdentityRemoveFavorite from './types/com/atproto/identity/removeFavorite'
import * as ComAtprotoIdentityRequestPlcOperationSignature from './types/com/atproto/identity/requestPlcOperationSignature'
import * as ComAtprotoIdentityResolveHandle from './types/com/atproto/identity/resolveHandle'
import * as ComAtprotoIdentitySetFavorite from './types/com/atproto/identity/setFavorite'
import * as ComAtprotoIdentitySignPlcOperation from './types/com/atproto/identity/signPlcOperation'
import * as ComAtprotoIdentitySubmitPlcOperation from './types/com/atproto/identity/submitPlcOperation'
import * as ComAtprotoIdentityUpdateHandle from './types/com/atproto/identity/updateHandle'
import * as ComAtprotoLabelDefs from './types/com/atproto/label/defs'
import * as ComAtprotoLabelQueryLabels from './types/com/atproto/label/queryLabels'
import * as ComAtprotoLabelSubscribeLabels from './types/com/atproto/label/subscribeLabels'
import * as ComAtprotoModerationCreateReport from './types/com/atproto/moderation/createReport'
import * as ComAtprotoModerationDefs from './types/com/atproto/moderation/defs'
import * as ComAtprotoRepoApplyWrites from './types/com/atproto/repo/applyWrites'
import * as ComAtprotoRepoCreateRecord from './types/com/atproto/repo/createRecord'
import * as ComAtprotoRepoCreateRecords from './types/com/atproto/repo/createRecords'
import * as ComAtprotoRepoDeleteRecord from './types/com/atproto/repo/deleteRecord'
import * as ComAtprotoRepoDescribeRepo from './types/com/atproto/repo/describeRepo'
import * as ComAtprotoRepoGetRecord from './types/com/atproto/repo/getRecord'
import * as ComAtprotoRepoImportRepo from './types/com/atproto/repo/importRepo'
import * as ComAtprotoRepoListMissingBlobs from './types/com/atproto/repo/listMissingBlobs'
import * as ComAtprotoRepoListRecords from './types/com/atproto/repo/listRecords'
import * as ComAtprotoRepoPutRecord from './types/com/atproto/repo/putRecord'
import * as ComAtprotoRepoStrongRef from './types/com/atproto/repo/strongRef'
import * as ComAtprotoRepoUploadBlob from './types/com/atproto/repo/uploadBlob'
import * as ComAtprotoServerActivateAccount from './types/com/atproto/server/activateAccount'
import * as ComAtprotoServerAddInviteeFriend from './types/com/atproto/server/addInviteeFriend'
import * as ComAtprotoServerBindTg from './types/com/atproto/server/bindTg'
import * as ComAtprotoServerBindTwitter from './types/com/atproto/server/bindTwitter'
import * as ComAtprotoServerBindWalletAddress from './types/com/atproto/server/bindWalletAddress'
import * as ComAtprotoServerBindWalletAddressList from './types/com/atproto/server/bindWalletAddressList'
import * as ComAtprotoServerCheckAccountStatus from './types/com/atproto/server/checkAccountStatus'
import * as ComAtprotoServerCheckUserEmailCode from './types/com/atproto/server/checkUserEmailCode'
import * as ComAtprotoServerCircleAcceptInvitation from './types/com/atproto/server/circleAcceptInvitation'
import * as ComAtprotoServerCircleAgreeOrReject from './types/com/atproto/server/circleAgreeOrReject'
import * as ComAtprotoServerCircleApply from './types/com/atproto/server/circleApply'
import * as ComAtprotoServerCircleDelete from './types/com/atproto/server/circleDelete'
import * as ComAtprotoServerCircleInfoAdd from './types/com/atproto/server/circleInfoAdd'
import * as ComAtprotoServerCircleInfoUpdate from './types/com/atproto/server/circleInfoUpdate'
import * as ComAtprotoServerCircleQueryApproveInfos from './types/com/atproto/server/circleQueryApproveInfos'
import * as ComAtprotoServerCircleQueryApproveNum from './types/com/atproto/server/circleQueryApproveNum'
import * as ComAtprotoServerCircleQueryInviteInfo from './types/com/atproto/server/circleQueryInviteInfo'
import * as ComAtprotoServerCircleUpdateUsersBackend from './types/com/atproto/server/circleUpdateUsersBackend'
import * as ComAtprotoServerCircleUserDeleteBatch from './types/com/atproto/server/circleUserDeleteBatch'
import * as ComAtprotoServerCircleUserFromHandles from './types/com/atproto/server/circleUserFromHandles'
import * as ComAtprotoServerCircleUserFromTelegramGroup from './types/com/atproto/server/circleUserFromTelegramGroup'
import * as ComAtprotoServerCircleUsersBatch from './types/com/atproto/server/circleUsersBatch'
import * as ComAtprotoServerConfirmBindTwitter from './types/com/atproto/server/confirmBindTwitter'
import * as ComAtprotoServerConfirmEmail from './types/com/atproto/server/confirmEmail'
import * as ComAtprotoServerCreateAccount from './types/com/atproto/server/createAccount'
import * as ComAtprotoServerCreateAppPassword from './types/com/atproto/server/createAppPassword'
import * as ComAtprotoServerCreateInviteCode from './types/com/atproto/server/createInviteCode'
import * as ComAtprotoServerCreateInviteCodes from './types/com/atproto/server/createInviteCodes'
import * as ComAtprotoServerCreateOrGetAnonAccount from './types/com/atproto/server/createOrGetAnonAccount'
import * as ComAtprotoServerCreatePostShareImg from './types/com/atproto/server/createPostShareImg'
import * as ComAtprotoServerCreateSession from './types/com/atproto/server/createSession'
import * as ComAtprotoServerCreateSubAccount from './types/com/atproto/server/createSubAccount'
import * as ComAtprotoServerCreateSubAccountSession from './types/com/atproto/server/createSubAccountSession'
import * as ComAtprotoServerDeactivateAccount from './types/com/atproto/server/deactivateAccount'
import * as ComAtprotoServerDefs from './types/com/atproto/server/defs'
import * as ComAtprotoServerDelBindWalletAddress from './types/com/atproto/server/delBindWalletAddress'
import * as ComAtprotoServerDeleteAccount from './types/com/atproto/server/deleteAccount'
import * as ComAtprotoServerDeleteSession from './types/com/atproto/server/deleteSession'
import * as ComAtprotoServerDeleteSubAccount from './types/com/atproto/server/deleteSubAccount'
import * as ComAtprotoServerDescribeServer from './types/com/atproto/server/describeServer'
import * as ComAtprotoServerFriendAddReq from './types/com/atproto/server/friendAddReq'
import * as ComAtprotoServerFriendAgreeReq from './types/com/atproto/server/friendAgreeReq'
import * as ComAtprotoServerFriendQueryIsFriend from './types/com/atproto/server/friendQueryIsFriend'
import * as ComAtprotoServerFriendQueryPendingReqNum from './types/com/atproto/server/friendQueryPendingReqNum'
import * as ComAtprotoServerFriendQueryRecommends from './types/com/atproto/server/friendQueryRecommends'
import * as ComAtprotoServerFriendQueryReqs from './types/com/atproto/server/friendQueryReqs'
import * as ComAtprotoServerGetAccountInviteCodes from './types/com/atproto/server/getAccountInviteCodes'
import * as ComAtprotoServerGetAccountList from './types/com/atproto/server/getAccountList'
import * as ComAtprotoServerGetAccountSettings from './types/com/atproto/server/getAccountSettings'
import * as ComAtprotoServerGetActorMagnification from './types/com/atproto/server/getActorMagnification'
import * as ComAtprotoServerGetBetTransAmountDetails from './types/com/atproto/server/getBetTransAmountDetails'
import * as ComAtprotoServerGetBetTransScoreDetails from './types/com/atproto/server/getBetTransScoreDetails'
import * as ComAtprotoServerGetBetTransSummary from './types/com/atproto/server/getBetTransSummary'
import * as ComAtprotoServerGetBindTg from './types/com/atproto/server/getBindTg'
import * as ComAtprotoServerGetBindTwitter from './types/com/atproto/server/getBindTwitter'
import * as ComAtprotoServerGetBindWalletAddressMsg from './types/com/atproto/server/getBindWalletAddressMsg'
import * as ComAtprotoServerGetCircleInfo from './types/com/atproto/server/getCircleInfo'
import * as ComAtprotoServerGetCircleList from './types/com/atproto/server/getCircleList'
import * as ComAtprotoServerGetCircleUsers from './types/com/atproto/server/getCircleUsers'
import * as ComAtprotoServerGetCirclesByName from './types/com/atproto/server/getCirclesByName'
import * as ComAtprotoServerGetConnections from './types/com/atproto/server/getConnections'
import * as ComAtprotoServerGetCredit from './types/com/atproto/server/getCredit'
import * as ComAtprotoServerGetCreditDetail from './types/com/atproto/server/getCreditDetail'
import * as ComAtprotoServerGetDailyTask from './types/com/atproto/server/getDailyTask'
import * as ComAtprotoServerGetDailyTaskMoney from './types/com/atproto/server/getDailyTaskMoney'
import * as ComAtprotoServerGetDonateTransDetails from './types/com/atproto/server/getDonateTransDetails'
import * as ComAtprotoServerGetDonateTransSummary from './types/com/atproto/server/getDonateTransSummary'
import * as ComAtprotoServerGetLabelTagActive from './types/com/atproto/server/getLabelTagActive'
import * as ComAtprotoServerGetNotificationCount from './types/com/atproto/server/getNotificationCount'
import * as ComAtprotoServerGetPaymentWalletAddress from './types/com/atproto/server/getPaymentWalletAddress'
import * as ComAtprotoServerGetPostShareImg from './types/com/atproto/server/getPostShareImg'
import * as ComAtprotoServerGetRandom from './types/com/atproto/server/getRandom'
import * as ComAtprotoServerGetReceiveAddress from './types/com/atproto/server/getReceiveAddress'
import * as ComAtprotoServerGetRelationShips from './types/com/atproto/server/getRelationShips'
import * as ComAtprotoServerGetServiceAuth from './types/com/atproto/server/getServiceAuth'
import * as ComAtprotoServerGetSession from './types/com/atproto/server/getSession'
import * as ComAtprotoServerGetSharedCircles from './types/com/atproto/server/getSharedCircles'
import * as ComAtprotoServerGetSharedFriends from './types/com/atproto/server/getSharedFriends'
import * as ComAtprotoServerGetSpizFriends from './types/com/atproto/server/getSpizFriends'
import * as ComAtprotoServerGetStakeTransDetails from './types/com/atproto/server/getStakeTransDetails'
import * as ComAtprotoServerGetStakeTransSummary from './types/com/atproto/server/getStakeTransSummary'
import * as ComAtprotoServerGetSubAccountLIst from './types/com/atproto/server/getSubAccountLIst'
import * as ComAtprotoServerGetSubscriptions from './types/com/atproto/server/getSubscriptions'
import * as ComAtprotoServerGetTags from './types/com/atproto/server/getTags'
import * as ComAtprotoServerGetTelegramAvatarBlob from './types/com/atproto/server/getTelegramAvatarBlob'
import * as ComAtprotoServerGetTelegramContactsInfo from './types/com/atproto/server/getTelegramContactsInfo'
import * as ComAtprotoServerGetTelegramContactsPage from './types/com/atproto/server/getTelegramContactsPage'
import * as ComAtprotoServerGetTelegramContactsWithSpizPage from './types/com/atproto/server/getTelegramContactsWithSpizPage'
import * as ComAtprotoServerGetTelegramGroupCircles from './types/com/atproto/server/getTelegramGroupCircles'
import * as ComAtprotoServerGetTelegramUserBasicInfo from './types/com/atproto/server/getTelegramUserBasicInfo'
import * as ComAtprotoServerGetTopPerformers from './types/com/atproto/server/getTopPerformers'
import * as ComAtprotoServerGetTransWalletAddress from './types/com/atproto/server/getTransWalletAddress'
import * as ComAtprotoServerGetTranslation from './types/com/atproto/server/getTranslation'
import * as ComAtprotoServerGetUserEmail from './types/com/atproto/server/getUserEmail'
import * as ComAtprotoServerGetUserEmails from './types/com/atproto/server/getUserEmails'
import * as ComAtprotoServerGetUserTags from './types/com/atproto/server/getUserTags'
import * as ComAtprotoServerGetUserTransRecords from './types/com/atproto/server/getUserTransRecords'
import * as ComAtprotoServerGetUsersByTags from './types/com/atproto/server/getUsersByTags'
import * as ComAtprotoServerGetWithdrawBalance from './types/com/atproto/server/getWithdrawBalance'
import * as ComAtprotoServerGetWithdrawRecord from './types/com/atproto/server/getWithdrawRecord'
import * as ComAtprotoServerGetWithdrawRecords from './types/com/atproto/server/getWithdrawRecords'
import * as ComAtprotoServerInviteTelegramUsers from './types/com/atproto/server/inviteTelegramUsers'
import * as ComAtprotoServerListAppPasswords from './types/com/atproto/server/listAppPasswords'
import * as ComAtprotoServerLogin from './types/com/atproto/server/login'
import * as ComAtprotoServerLoginUseTg from './types/com/atproto/server/loginUseTg'
import * as ComAtprotoServerPostVisibilityControl from './types/com/atproto/server/postVisibilityControl'
import * as ComAtprotoServerPrepareTransaction from './types/com/atproto/server/prepareTransaction'
import * as ComAtprotoServerQueryTgBindResult from './types/com/atproto/server/queryTgBindResult'
import * as ComAtprotoServerRefreshSession from './types/com/atproto/server/refreshSession'
import * as ComAtprotoServerRequestAccountDelete from './types/com/atproto/server/requestAccountDelete'
import * as ComAtprotoServerRequestEmailConfirmation from './types/com/atproto/server/requestEmailConfirmation'
import * as ComAtprotoServerRequestEmailUpdate from './types/com/atproto/server/requestEmailUpdate'
import * as ComAtprotoServerRequestPasswordReset from './types/com/atproto/server/requestPasswordReset'
import * as ComAtprotoServerReserveSigningKey from './types/com/atproto/server/reserveSigningKey'
import * as ComAtprotoServerResetPassword from './types/com/atproto/server/resetPassword'
import * as ComAtprotoServerRevokeAppPassword from './types/com/atproto/server/revokeAppPassword'
import * as ComAtprotoServerSaveMaskWalletAddress from './types/com/atproto/server/saveMaskWalletAddress'
import * as ComAtprotoServerSaveReceiveAddress from './types/com/atproto/server/saveReceiveAddress'
import * as ComAtprotoServerSaveTgLoginUser from './types/com/atproto/server/saveTgLoginUser'
import * as ComAtprotoServerSdnAppToken from './types/com/atproto/server/sdnAppToken'
import * as ComAtprotoServerSendBotCommMsg from './types/com/atproto/server/sendBotCommMsg'
import * as ComAtprotoServerSendEmailCodes from './types/com/atproto/server/sendEmailCodes'
import * as ComAtprotoServerSendSipzBotNotification from './types/com/atproto/server/sendSipzBotNotification'
import * as ComAtprotoServerSendUserEmailCode from './types/com/atproto/server/sendUserEmailCode'
import * as ComAtprotoServerSetDefaultAccount from './types/com/atproto/server/setDefaultAccount'
import * as ComAtprotoServerSetPaymentWallet from './types/com/atproto/server/setPaymentWallet'
import * as ComAtprotoServerStoreAccountSettings from './types/com/atproto/server/storeAccountSettings'
import * as ComAtprotoServerStoreCredit from './types/com/atproto/server/storeCredit'
import * as ComAtprotoServerStoreDailyTask from './types/com/atproto/server/storeDailyTask'
import * as ComAtprotoServerStoreDailyTaskStatus from './types/com/atproto/server/storeDailyTaskStatus'
import * as ComAtprotoServerStoreShareConnection from './types/com/atproto/server/storeShareConnection'
import * as ComAtprotoServerStoreShareCount from './types/com/atproto/server/storeShareCount'
import * as ComAtprotoServerStoreTranslation from './types/com/atproto/server/storeTranslation'
import * as ComAtprotoServerSyncParentId from './types/com/atproto/server/syncParentId'
import * as ComAtprotoServerTagAdd from './types/com/atproto/server/tagAdd'
import * as ComAtprotoServerTagDelete from './types/com/atproto/server/tagDelete'
import * as ComAtprotoServerTagUpdate from './types/com/atproto/server/tagUpdate'
import * as ComAtprotoServerTagUserAdd from './types/com/atproto/server/tagUserAdd'
import * as ComAtprotoServerTagUserAddTags from './types/com/atproto/server/tagUserAddTags'
import * as ComAtprotoServerTagUsersAdd from './types/com/atproto/server/tagUsersAdd'
import * as ComAtprotoServerTagUsersDelete from './types/com/atproto/server/tagUsersDelete'
import * as ComAtprotoServerTelegramBind from './types/com/atproto/server/telegramBind'
import * as ComAtprotoServerTelegramChannelUserForOperation from './types/com/atproto/server/telegramChannelUserForOperation'
import * as ComAtprotoServerTelegramChannels from './types/com/atproto/server/telegramChannels'
import * as ComAtprotoServerTelegramContacts from './types/com/atproto/server/telegramContacts'
import * as ComAtprotoServerTelegramMemberDetail from './types/com/atproto/server/telegramMemberDetail'
import * as ComAtprotoServerTelegramParticipants from './types/com/atproto/server/telegramParticipants'
import * as ComAtprotoServerUnlinkTg from './types/com/atproto/server/unlinkTg'
import * as ComAtprotoServerUnlinkTwitter from './types/com/atproto/server/unlinkTwitter'
import * as ComAtprotoServerUpdateEmail from './types/com/atproto/server/updateEmail'
import * as ComAtprotoServerUpdateEntityTagByAdmin from './types/com/atproto/server/updateEntityTagByAdmin'
import * as ComAtprotoServerUpdateUserEmailStatus from './types/com/atproto/server/updateUserEmailStatus'
import * as ComAtprotoServerUploadBlobImage from './types/com/atproto/server/uploadBlobImage'
import * as ComAtprotoServerValidateHandle from './types/com/atproto/server/validateHandle'
import * as ComAtprotoServerWithdrawReward from './types/com/atproto/server/withdrawReward'
import * as ComAtprotoSyncGetBlob from './types/com/atproto/sync/getBlob'
import * as ComAtprotoSyncGetBlocks from './types/com/atproto/sync/getBlocks'
import * as ComAtprotoSyncGetCheckout from './types/com/atproto/sync/getCheckout'
import * as ComAtprotoSyncGetHead from './types/com/atproto/sync/getHead'
import * as ComAtprotoSyncGetLatestCommit from './types/com/atproto/sync/getLatestCommit'
import * as ComAtprotoSyncGetRecord from './types/com/atproto/sync/getRecord'
import * as ComAtprotoSyncGetRepo from './types/com/atproto/sync/getRepo'
import * as ComAtprotoSyncGetRepoStatus from './types/com/atproto/sync/getRepoStatus'
import * as ComAtprotoSyncListBlobs from './types/com/atproto/sync/listBlobs'
import * as ComAtprotoSyncListRepos from './types/com/atproto/sync/listRepos'
import * as ComAtprotoSyncNotifyOfUpdate from './types/com/atproto/sync/notifyOfUpdate'
import * as ComAtprotoSyncRequestCrawl from './types/com/atproto/sync/requestCrawl'
import * as ComAtprotoSyncSubscribeRepos from './types/com/atproto/sync/subscribeRepos'
import * as ComAtprotoTempCheckSignupQueue from './types/com/atproto/temp/checkSignupQueue'
import * as ComAtprotoTempFetchLabels from './types/com/atproto/temp/fetchLabels'
import * as ComAtprotoTempRequestPhoneVerification from './types/com/atproto/temp/requestPhoneVerification'
import * as AppBskyActorDefs from './types/app/bsky/actor/defs'
import * as AppBskyActorGetPreferences from './types/app/bsky/actor/getPreferences'
import * as AppBskyActorGetProfile from './types/app/bsky/actor/getProfile'
import * as AppBskyActorGetProfiles from './types/app/bsky/actor/getProfiles'
import * as AppBskyActorGetSuggestions from './types/app/bsky/actor/getSuggestions'
import * as AppBskyActorProfile from './types/app/bsky/actor/profile'
import * as AppBskyActorPutPreferences from './types/app/bsky/actor/putPreferences'
import * as AppBskyActorSearchActors from './types/app/bsky/actor/searchActors'
import * as AppBskyActorSearchActorsTypeahead from './types/app/bsky/actor/searchActorsTypeahead'
import * as AppBskyActorSearchTelegramHandle from './types/app/bsky/actor/searchTelegramHandle'
import * as AppBskyActorSearchTelegrams from './types/app/bsky/actor/searchTelegrams'
import * as AppBskyEmbedExternal from './types/app/bsky/embed/external'
import * as AppBskyEmbedImages from './types/app/bsky/embed/images'
import * as AppBskyEmbedRecord from './types/app/bsky/embed/record'
import * as AppBskyEmbedRecordWithMedia from './types/app/bsky/embed/recordWithMedia'
import * as AppBskyFeedBet from './types/app/bsky/feed/bet'
import * as AppBskyFeedDefs from './types/app/bsky/feed/defs'
import * as AppBskyFeedDescribeFeedGenerator from './types/app/bsky/feed/describeFeedGenerator'
import * as AppBskyFeedDonate from './types/app/bsky/feed/donate'
import * as AppBskyFeedFriendAgreeRequest from './types/app/bsky/feed/friendAgreeRequest'
import * as AppBskyFeedFriendRequest from './types/app/bsky/feed/friendRequest'
import * as AppBskyFeedGenerator from './types/app/bsky/feed/generator'
import * as AppBskyFeedGetActorEngagements from './types/app/bsky/feed/getActorEngagements'
import * as AppBskyFeedGetActorFeeds from './types/app/bsky/feed/getActorFeeds'
import * as AppBskyFeedGetActorLikes from './types/app/bsky/feed/getActorLikes'
import * as AppBskyFeedGetActorOpposes from './types/app/bsky/feed/getActorOpposes'
import * as AppBskyFeedGetAuthorFeed from './types/app/bsky/feed/getAuthorFeed'
import * as AppBskyFeedGetFeed from './types/app/bsky/feed/getFeed'
import * as AppBskyFeedGetFeedByTag from './types/app/bsky/feed/getFeedByTag'
import * as AppBskyFeedGetFeedGenerator from './types/app/bsky/feed/getFeedGenerator'
import * as AppBskyFeedGetFeedGenerators from './types/app/bsky/feed/getFeedGenerators'
import * as AppBskyFeedGetFeedSkeleton from './types/app/bsky/feed/getFeedSkeleton'
import * as AppBskyFeedGetLikes from './types/app/bsky/feed/getLikes'
import * as AppBskyFeedGetListFeed from './types/app/bsky/feed/getListFeed'
import * as AppBskyFeedGetOpposes from './types/app/bsky/feed/getOpposes'
import * as AppBskyFeedGetPostThread from './types/app/bsky/feed/getPostThread'
import * as AppBskyFeedGetPosts from './types/app/bsky/feed/getPosts'
import * as AppBskyFeedGetRepostedBy from './types/app/bsky/feed/getRepostedBy'
import * as AppBskyFeedGetSuggestedFeeds from './types/app/bsky/feed/getSuggestedFeeds'
import * as AppBskyFeedGetTimeline from './types/app/bsky/feed/getTimeline'
import * as AppBskyFeedGetUnlock from './types/app/bsky/feed/getUnlock'
import * as AppBskyFeedLike from './types/app/bsky/feed/like'
import * as AppBskyFeedOppose from './types/app/bsky/feed/oppose'
import * as AppBskyFeedPost from './types/app/bsky/feed/post'
import * as AppBskyFeedRepost from './types/app/bsky/feed/repost'
import * as AppBskyFeedSearchPosts from './types/app/bsky/feed/searchPosts'
import * as AppBskyFeedSendInteractions from './types/app/bsky/feed/sendInteractions'
import * as AppBskyFeedStake from './types/app/bsky/feed/stake'
import * as AppBskyFeedTgFriendBind from './types/app/bsky/feed/tgFriendBind'
import * as AppBskyFeedThreadgate from './types/app/bsky/feed/threadgate'
import * as AppBskyFeedUnlock from './types/app/bsky/feed/unlock'
import * as AppBskyFeedView from './types/app/bsky/feed/view'
import * as AppBskyGraphBlock from './types/app/bsky/graph/block'
import * as AppBskyGraphDefs from './types/app/bsky/graph/defs'
import * as AppBskyGraphFollow from './types/app/bsky/graph/follow'
import * as AppBskyGraphGetActorStarterPacks from './types/app/bsky/graph/getActorStarterPacks'
import * as AppBskyGraphGetBlocks from './types/app/bsky/graph/getBlocks'
import * as AppBskyGraphGetFollowers from './types/app/bsky/graph/getFollowers'
import * as AppBskyGraphGetFollows from './types/app/bsky/graph/getFollows'
import * as AppBskyGraphGetGuarantee from './types/app/bsky/graph/getGuarantee'
import * as AppBskyGraphGetKnownFollowers from './types/app/bsky/graph/getKnownFollowers'
import * as AppBskyGraphGetList from './types/app/bsky/graph/getList'
import * as AppBskyGraphGetListBlocks from './types/app/bsky/graph/getListBlocks'
import * as AppBskyGraphGetListMutes from './types/app/bsky/graph/getListMutes'
import * as AppBskyGraphGetLists from './types/app/bsky/graph/getLists'
import * as AppBskyGraphGetMutes from './types/app/bsky/graph/getMutes'
import * as AppBskyGraphGetRelationships from './types/app/bsky/graph/getRelationships'
import * as AppBskyGraphGetStarterPack from './types/app/bsky/graph/getStarterPack'
import * as AppBskyGraphGetStarterPacks from './types/app/bsky/graph/getStarterPacks'
import * as AppBskyGraphGetSuggestedFollowsByActor from './types/app/bsky/graph/getSuggestedFollowsByActor'
import * as AppBskyGraphGuarantee from './types/app/bsky/graph/guarantee'
import * as AppBskyGraphGuaranteeJoin from './types/app/bsky/graph/guaranteeJoin'
import * as AppBskyGraphGuaranteeUpdate from './types/app/bsky/graph/guaranteeUpdate'
import * as AppBskyGraphList from './types/app/bsky/graph/list'
import * as AppBskyGraphListblock from './types/app/bsky/graph/listblock'
import * as AppBskyGraphListitem from './types/app/bsky/graph/listitem'
import * as AppBskyGraphMuteActor from './types/app/bsky/graph/muteActor'
import * as AppBskyGraphMuteActorList from './types/app/bsky/graph/muteActorList'
import * as AppBskyGraphMuteThread from './types/app/bsky/graph/muteThread'
import * as AppBskyGraphStarterpack from './types/app/bsky/graph/starterpack'
import * as AppBskyGraphUnmuteActor from './types/app/bsky/graph/unmuteActor'
import * as AppBskyGraphUnmuteActorList from './types/app/bsky/graph/unmuteActorList'
import * as AppBskyGraphUnmuteThread from './types/app/bsky/graph/unmuteThread'
import * as AppBskyLabelerDefs from './types/app/bsky/labeler/defs'
import * as AppBskyLabelerGetServices from './types/app/bsky/labeler/getServices'
import * as AppBskyLabelerService from './types/app/bsky/labeler/service'
import * as AppBskyNotificationGetUnreadCount from './types/app/bsky/notification/getUnreadCount'
import * as AppBskyNotificationListNotifications from './types/app/bsky/notification/listNotifications'
import * as AppBskyNotificationPushNotification from './types/app/bsky/notification/pushNotification'
import * as AppBskyNotificationPutPreferences from './types/app/bsky/notification/putPreferences'
import * as AppBskyNotificationRegisterPush from './types/app/bsky/notification/registerPush'
import * as AppBskyNotificationRegisterPushOt from './types/app/bsky/notification/registerPushOt'
import * as AppBskyNotificationUpdateSeen from './types/app/bsky/notification/updateSeen'
import * as AppBskyRichtextFacet from './types/app/bsky/richtext/facet'
import * as AppBskyUnspeccedDefs from './types/app/bsky/unspecced/defs'
import * as AppBskyUnspeccedGetPopularFeedGenerators from './types/app/bsky/unspecced/getPopularFeedGenerators'
import * as AppBskyUnspeccedGetSuggestionsSkeleton from './types/app/bsky/unspecced/getSuggestionsSkeleton'
import * as AppBskyUnspeccedGetTaggedSuggestions from './types/app/bsky/unspecced/getTaggedSuggestions'
import * as AppBskyUnspeccedSearchActorsSkeleton from './types/app/bsky/unspecced/searchActorsSkeleton'
import * as AppBskyUnspeccedSearchPostsSkeleton from './types/app/bsky/unspecced/searchPostsSkeleton'
import * as ChatBskyActorDeclaration from './types/chat/bsky/actor/declaration'
import * as ChatBskyActorDefs from './types/chat/bsky/actor/defs'
import * as ChatBskyActorDeleteAccount from './types/chat/bsky/actor/deleteAccount'
import * as ChatBskyActorExportAccountData from './types/chat/bsky/actor/exportAccountData'
import * as ChatBskyConvoDefs from './types/chat/bsky/convo/defs'
import * as ChatBskyConvoDeleteMessageForSelf from './types/chat/bsky/convo/deleteMessageForSelf'
import * as ChatBskyConvoGetConvo from './types/chat/bsky/convo/getConvo'
import * as ChatBskyConvoGetConvoForMembers from './types/chat/bsky/convo/getConvoForMembers'
import * as ChatBskyConvoGetLog from './types/chat/bsky/convo/getLog'
import * as ChatBskyConvoGetMessages from './types/chat/bsky/convo/getMessages'
import * as ChatBskyConvoLeaveConvo from './types/chat/bsky/convo/leaveConvo'
import * as ChatBskyConvoListConvos from './types/chat/bsky/convo/listConvos'
import * as ChatBskyConvoMuteConvo from './types/chat/bsky/convo/muteConvo'
import * as ChatBskyConvoSendMessage from './types/chat/bsky/convo/sendMessage'
import * as ChatBskyConvoSendMessageBatch from './types/chat/bsky/convo/sendMessageBatch'
import * as ChatBskyConvoUnmuteConvo from './types/chat/bsky/convo/unmuteConvo'
import * as ChatBskyConvoUpdateRead from './types/chat/bsky/convo/updateRead'
import * as ChatBskyModerationGetActorMetadata from './types/chat/bsky/moderation/getActorMetadata'
import * as ChatBskyModerationGetMessageContext from './types/chat/bsky/moderation/getMessageContext'
import * as ChatBskyModerationUpdateActorAccess from './types/chat/bsky/moderation/updateActorAccess'
import * as ToolsOzoneCommunicationCreateTemplate from './types/tools/ozone/communication/createTemplate'
import * as ToolsOzoneCommunicationDefs from './types/tools/ozone/communication/defs'
import * as ToolsOzoneCommunicationDeleteTemplate from './types/tools/ozone/communication/deleteTemplate'
import * as ToolsOzoneCommunicationListTemplates from './types/tools/ozone/communication/listTemplates'
import * as ToolsOzoneCommunicationUpdateTemplate from './types/tools/ozone/communication/updateTemplate'
import * as ToolsOzoneModerationDefs from './types/tools/ozone/moderation/defs'
import * as ToolsOzoneModerationEmitEvent from './types/tools/ozone/moderation/emitEvent'
import * as ToolsOzoneModerationGetEvent from './types/tools/ozone/moderation/getEvent'
import * as ToolsOzoneModerationGetRecord from './types/tools/ozone/moderation/getRecord'
import * as ToolsOzoneModerationGetRepo from './types/tools/ozone/moderation/getRepo'
import * as ToolsOzoneModerationQueryEvents from './types/tools/ozone/moderation/queryEvents'
import * as ToolsOzoneModerationQueryStatuses from './types/tools/ozone/moderation/queryStatuses'
import * as ToolsOzoneModerationSearchRepos from './types/tools/ozone/moderation/searchRepos'
import * as ToolsOzoneServerGetConfig from './types/tools/ozone/server/getConfig'
import * as ToolsOzoneTeamAddMember from './types/tools/ozone/team/addMember'
import * as ToolsOzoneTeamDefs from './types/tools/ozone/team/defs'
import * as ToolsOzoneTeamDeleteMember from './types/tools/ozone/team/deleteMember'
import * as ToolsOzoneTeamListMembers from './types/tools/ozone/team/listMembers'
import * as ToolsOzoneTeamUpdateMember from './types/tools/ozone/team/updateMember'

export * as ComAtprotoAdminCreateLabelTag from './types/com/atproto/admin/createLabelTag'
export * as ComAtprotoAdminDefs from './types/com/atproto/admin/defs'
export * as ComAtprotoAdminDeleteAccount from './types/com/atproto/admin/deleteAccount'
export * as ComAtprotoAdminDeleteLabelTag from './types/com/atproto/admin/deleteLabelTag'
export * as ComAtprotoAdminDisableAccountInvites from './types/com/atproto/admin/disableAccountInvites'
export * as ComAtprotoAdminDisableInviteCodes from './types/com/atproto/admin/disableInviteCodes'
export * as ComAtprotoAdminEnableAccountInvites from './types/com/atproto/admin/enableAccountInvites'
export * as ComAtprotoAdminGetAccountInfo from './types/com/atproto/admin/getAccountInfo'
export * as ComAtprotoAdminGetAccountInfos from './types/com/atproto/admin/getAccountInfos'
export * as ComAtprotoAdminGetActorStatistics from './types/com/atproto/admin/getActorStatistics'
export * as ComAtprotoAdminGetInviteCodes from './types/com/atproto/admin/getInviteCodes'
export * as AppBskyFeedGetPostByTime from './types/app/bsky/feed/getPostByTime'
export * as ComAtprotoAdminGetSubjectStatus from './types/com/atproto/admin/getSubjectStatus'
export * as ComAtprotoAdminModifyLabelTagActive from './types/com/atproto/admin/modifyLabelTagActive'
export * as ComAtprotoAdminModifyPostLabels from './types/com/atproto/admin/modifyPostLabels'
export * as ComAtprotoAdminQueryLabelTags from './types/com/atproto/admin/queryLabelTags'
export * as AppBskyFeedQueryPostByLabel from './types/app/bsky/feed/queryPostByLabel'
export * as ComAtprotoAdminRenameLabelTag from './types/com/atproto/admin/renameLabelTag'
export * as ComAtprotoAdminSaveActorStatistics from './types/com/atproto/admin/saveActorStatistics'
export * as ComAtprotoAdminSaveAdminAddress from './types/com/atproto/admin/saveAdminAddress'
export * as ComAtprotoAdminSearchAccounts from './types/com/atproto/admin/searchAccounts'
export * as ComAtprotoAdminSearchLabelTags from './types/com/atproto/admin/searchLabelTags'
export * as ComAtprotoAdminSendEmail from './types/com/atproto/admin/sendEmail'
export * as ComAtprotoAdminUpdateAccountEmail from './types/com/atproto/admin/updateAccountEmail'
export * as ComAtprotoAdminUpdateAccountHandle from './types/com/atproto/admin/updateAccountHandle'
export * as ComAtprotoAdminUpdateAccountPassword from './types/com/atproto/admin/updateAccountPassword'
export * as ComAtprotoAdminUpdateSubjectStatus from './types/com/atproto/admin/updateSubjectStatus'
export * as ComAtprotoIdentityGetFavorites from './types/com/atproto/identity/getFavorites'
export * as ComAtprotoIdentityGetRecommendedDidCredentials from './types/com/atproto/identity/getRecommendedDidCredentials'
export * as ComAtprotoIdentityIsFavorite from './types/com/atproto/identity/isFavorite'
export * as ComAtprotoIdentityRemoveFavorite from './types/com/atproto/identity/removeFavorite'
export * as ComAtprotoIdentityRequestPlcOperationSignature from './types/com/atproto/identity/requestPlcOperationSignature'
export * as ComAtprotoIdentityResolveHandle from './types/com/atproto/identity/resolveHandle'
export * as ComAtprotoIdentitySetFavorite from './types/com/atproto/identity/setFavorite'
export * as ComAtprotoIdentitySignPlcOperation from './types/com/atproto/identity/signPlcOperation'
export * as ComAtprotoIdentitySubmitPlcOperation from './types/com/atproto/identity/submitPlcOperation'
export * as ComAtprotoIdentityUpdateHandle from './types/com/atproto/identity/updateHandle'
export * as ComAtprotoLabelDefs from './types/com/atproto/label/defs'
export * as ComAtprotoLabelQueryLabels from './types/com/atproto/label/queryLabels'
export * as ComAtprotoLabelSubscribeLabels from './types/com/atproto/label/subscribeLabels'
export * as ComAtprotoModerationCreateReport from './types/com/atproto/moderation/createReport'
export * as ComAtprotoModerationDefs from './types/com/atproto/moderation/defs'
export * as ComAtprotoRepoApplyWrites from './types/com/atproto/repo/applyWrites'
export * as ComAtprotoRepoCreateRecord from './types/com/atproto/repo/createRecord'
export * as ComAtprotoRepoCreateRecords from './types/com/atproto/repo/createRecords'
export * as ComAtprotoRepoDeleteRecord from './types/com/atproto/repo/deleteRecord'
export * as ComAtprotoRepoDescribeRepo from './types/com/atproto/repo/describeRepo'
export * as ComAtprotoRepoGetRecord from './types/com/atproto/repo/getRecord'
export * as ComAtprotoRepoImportRepo from './types/com/atproto/repo/importRepo'
export * as ComAtprotoRepoListMissingBlobs from './types/com/atproto/repo/listMissingBlobs'
export * as ComAtprotoRepoListRecords from './types/com/atproto/repo/listRecords'
export * as ComAtprotoRepoPutRecord from './types/com/atproto/repo/putRecord'
export * as ComAtprotoRepoStrongRef from './types/com/atproto/repo/strongRef'
export * as ComAtprotoRepoUploadBlob from './types/com/atproto/repo/uploadBlob'
export * as ComAtprotoServerActivateAccount from './types/com/atproto/server/activateAccount'
export * as ComAtprotoServerAddInviteeFriend from './types/com/atproto/server/addInviteeFriend'
export * as ComAtprotoServerBindTg from './types/com/atproto/server/bindTg'
export * as ComAtprotoServerBindTwitter from './types/com/atproto/server/bindTwitter'
export * as ComAtprotoServerBindWalletAddress from './types/com/atproto/server/bindWalletAddress'
export * as ComAtprotoServerBindWalletAddressList from './types/com/atproto/server/bindWalletAddressList'
export * as ComAtprotoServerCheckAccountStatus from './types/com/atproto/server/checkAccountStatus'
export * as ComAtprotoServerCheckUserEmailCode from './types/com/atproto/server/checkUserEmailCode'
export * as ComAtprotoServerCircleAcceptInvitation from './types/com/atproto/server/circleAcceptInvitation'
export * as ComAtprotoServerCircleAgreeOrReject from './types/com/atproto/server/circleAgreeOrReject'
export * as ComAtprotoServerCircleApply from './types/com/atproto/server/circleApply'
export * as ComAtprotoServerCircleDelete from './types/com/atproto/server/circleDelete'
export * as ComAtprotoServerCircleInfoAdd from './types/com/atproto/server/circleInfoAdd'
export * as ComAtprotoServerCircleInfoUpdate from './types/com/atproto/server/circleInfoUpdate'
export * as ComAtprotoServerCircleQueryApproveInfos from './types/com/atproto/server/circleQueryApproveInfos'
export * as ComAtprotoServerCircleQueryApproveNum from './types/com/atproto/server/circleQueryApproveNum'
export * as ComAtprotoServerCircleQueryInviteInfo from './types/com/atproto/server/circleQueryInviteInfo'
export * as ComAtprotoServerCircleUpdateUsersBackend from './types/com/atproto/server/circleUpdateUsersBackend'
export * as ComAtprotoServerCircleUserDeleteBatch from './types/com/atproto/server/circleUserDeleteBatch'
export * as ComAtprotoServerCircleUserFromHandles from './types/com/atproto/server/circleUserFromHandles'
export * as ComAtprotoServerCircleUserFromTelegramGroup from './types/com/atproto/server/circleUserFromTelegramGroup'
export * as ComAtprotoServerCircleUsersBatch from './types/com/atproto/server/circleUsersBatch'
export * as ComAtprotoServerConfirmBindTwitter from './types/com/atproto/server/confirmBindTwitter'
export * as ComAtprotoServerConfirmEmail from './types/com/atproto/server/confirmEmail'
export * as ComAtprotoServerCreateAccount from './types/com/atproto/server/createAccount'
export * as ComAtprotoServerCreateAppPassword from './types/com/atproto/server/createAppPassword'
export * as ComAtprotoServerCreateInviteCode from './types/com/atproto/server/createInviteCode'
export * as ComAtprotoServerCreateInviteCodes from './types/com/atproto/server/createInviteCodes'
export * as ComAtprotoServerCreateOrGetAnonAccount from './types/com/atproto/server/createOrGetAnonAccount'
export * as ComAtprotoServerCreatePostShareImg from './types/com/atproto/server/createPostShareImg'
export * as ComAtprotoServerCreateSession from './types/com/atproto/server/createSession'
export * as ComAtprotoServerCreateSubAccount from './types/com/atproto/server/createSubAccount'
export * as ComAtprotoServerCreateSubAccountSession from './types/com/atproto/server/createSubAccountSession'
export * as ComAtprotoServerDeactivateAccount from './types/com/atproto/server/deactivateAccount'
export * as ComAtprotoServerDefs from './types/com/atproto/server/defs'
export * as ComAtprotoServerDelBindWalletAddress from './types/com/atproto/server/delBindWalletAddress'
export * as ComAtprotoServerDeleteAccount from './types/com/atproto/server/deleteAccount'
export * as ComAtprotoServerDeleteSession from './types/com/atproto/server/deleteSession'
export * as ComAtprotoServerDeleteSubAccount from './types/com/atproto/server/deleteSubAccount'
export * as ComAtprotoServerDescribeServer from './types/com/atproto/server/describeServer'
export * as ComAtprotoServerFriendAddReq from './types/com/atproto/server/friendAddReq'
export * as ComAtprotoServerFriendAgreeReq from './types/com/atproto/server/friendAgreeReq'
export * as ComAtprotoServerFriendQueryIsFriend from './types/com/atproto/server/friendQueryIsFriend'
export * as ComAtprotoServerFriendQueryPendingReqNum from './types/com/atproto/server/friendQueryPendingReqNum'
export * as ComAtprotoServerFriendQueryRecommends from './types/com/atproto/server/friendQueryRecommends'
export * as ComAtprotoServerFriendQueryReqs from './types/com/atproto/server/friendQueryReqs'
export * as ComAtprotoServerGetAccountInviteCodes from './types/com/atproto/server/getAccountInviteCodes'
export * as ComAtprotoServerGetAccountList from './types/com/atproto/server/getAccountList'
export * as ComAtprotoServerGetAccountSettings from './types/com/atproto/server/getAccountSettings'
export * as ComAtprotoServerGetActorMagnification from './types/com/atproto/server/getActorMagnification'
export * as ComAtprotoServerGetBetTransAmountDetails from './types/com/atproto/server/getBetTransAmountDetails'
export * as ComAtprotoServerGetBetTransScoreDetails from './types/com/atproto/server/getBetTransScoreDetails'
export * as ComAtprotoServerGetBetTransSummary from './types/com/atproto/server/getBetTransSummary'
export * as ComAtprotoServerGetBindTg from './types/com/atproto/server/getBindTg'
export * as ComAtprotoServerGetBindTwitter from './types/com/atproto/server/getBindTwitter'
export * as ComAtprotoServerGetBindWalletAddressMsg from './types/com/atproto/server/getBindWalletAddressMsg'
export * as ComAtprotoServerGetCircleInfo from './types/com/atproto/server/getCircleInfo'
export * as ComAtprotoServerGetCircleList from './types/com/atproto/server/getCircleList'
export * as ComAtprotoServerGetCircleUsers from './types/com/atproto/server/getCircleUsers'
export * as ComAtprotoServerGetCirclesByName from './types/com/atproto/server/getCirclesByName'
export * as ComAtprotoServerGetConnections from './types/com/atproto/server/getConnections'
export * as ComAtprotoServerGetCredit from './types/com/atproto/server/getCredit'
export * as ComAtprotoServerGetCreditDetail from './types/com/atproto/server/getCreditDetail'
export * as ComAtprotoServerGetDailyTask from './types/com/atproto/server/getDailyTask'
export * as ComAtprotoServerGetDailyTaskMoney from './types/com/atproto/server/getDailyTaskMoney'
export * as ComAtprotoServerGetDonateTransDetails from './types/com/atproto/server/getDonateTransDetails'
export * as ComAtprotoServerGetDonateTransSummary from './types/com/atproto/server/getDonateTransSummary'
export * as ComAtprotoServerGetLabelTagActive from './types/com/atproto/server/getLabelTagActive'
export * as ComAtprotoServerGetNotificationCount from './types/com/atproto/server/getNotificationCount'
export * as ComAtprotoServerGetPaymentWalletAddress from './types/com/atproto/server/getPaymentWalletAddress'
export * as ComAtprotoServerGetPostShareImg from './types/com/atproto/server/getPostShareImg'
export * as ComAtprotoServerGetRandom from './types/com/atproto/server/getRandom'
export * as ComAtprotoServerGetReceiveAddress from './types/com/atproto/server/getReceiveAddress'
export * as ComAtprotoServerGetRelationShips from './types/com/atproto/server/getRelationShips'
export * as ComAtprotoServerGetServiceAuth from './types/com/atproto/server/getServiceAuth'
export * as ComAtprotoServerGetSession from './types/com/atproto/server/getSession'
export * as ComAtprotoServerGetSharedCircles from './types/com/atproto/server/getSharedCircles'
export * as ComAtprotoServerGetSharedFriends from './types/com/atproto/server/getSharedFriends'
export * as ComAtprotoServerGetSpizFriends from './types/com/atproto/server/getSpizFriends'
export * as ComAtprotoServerGetStakeTransDetails from './types/com/atproto/server/getStakeTransDetails'
export * as ComAtprotoServerGetStakeTransSummary from './types/com/atproto/server/getStakeTransSummary'
export * as ComAtprotoServerGetSubAccountLIst from './types/com/atproto/server/getSubAccountLIst'
export * as ComAtprotoServerGetSubscriptions from './types/com/atproto/server/getSubscriptions'
export * as ComAtprotoServerGetTags from './types/com/atproto/server/getTags'
export * as ComAtprotoServerGetTelegramAvatarBlob from './types/com/atproto/server/getTelegramAvatarBlob'
export * as ComAtprotoServerGetTelegramContactsInfo from './types/com/atproto/server/getTelegramContactsInfo'
export * as ComAtprotoServerGetTelegramContactsPage from './types/com/atproto/server/getTelegramContactsPage'
export * as ComAtprotoServerGetTelegramContactsWithSpizPage from './types/com/atproto/server/getTelegramContactsWithSpizPage'
export * as ComAtprotoServerGetTelegramGroupCircles from './types/com/atproto/server/getTelegramGroupCircles'
export * as ComAtprotoServerGetTelegramUserBasicInfo from './types/com/atproto/server/getTelegramUserBasicInfo'
export * as ComAtprotoServerGetTopPerformers from './types/com/atproto/server/getTopPerformers'
export * as ComAtprotoServerGetTransWalletAddress from './types/com/atproto/server/getTransWalletAddress'
export * as ComAtprotoServerGetTranslation from './types/com/atproto/server/getTranslation'
export * as ComAtprotoServerGetUserEmail from './types/com/atproto/server/getUserEmail'
export * as ComAtprotoServerGetUserEmails from './types/com/atproto/server/getUserEmails'
export * as ComAtprotoServerGetUserTags from './types/com/atproto/server/getUserTags'
export * as ComAtprotoServerGetUserTransRecords from './types/com/atproto/server/getUserTransRecords'
export * as ComAtprotoServerGetUsersByTags from './types/com/atproto/server/getUsersByTags'
export * as ComAtprotoServerGetWithdrawBalance from './types/com/atproto/server/getWithdrawBalance'
export * as ComAtprotoServerGetWithdrawRecord from './types/com/atproto/server/getWithdrawRecord'
export * as ComAtprotoServerGetWithdrawRecords from './types/com/atproto/server/getWithdrawRecords'
export * as ComAtprotoServerInviteTelegramUsers from './types/com/atproto/server/inviteTelegramUsers'
export * as ComAtprotoServerListAppPasswords from './types/com/atproto/server/listAppPasswords'
export * as ComAtprotoServerLogin from './types/com/atproto/server/login'
export * as ComAtprotoServerLoginUseTg from './types/com/atproto/server/loginUseTg'
export * as ComAtprotoServerPostVisibilityControl from './types/com/atproto/server/postVisibilityControl'
export * as ComAtprotoServerPrepareTransaction from './types/com/atproto/server/prepareTransaction'
export * as ComAtprotoServerQueryTgBindResult from './types/com/atproto/server/queryTgBindResult'
export * as ComAtprotoServerRefreshSession from './types/com/atproto/server/refreshSession'
export * as ComAtprotoServerRequestAccountDelete from './types/com/atproto/server/requestAccountDelete'
export * as ComAtprotoServerRequestEmailConfirmation from './types/com/atproto/server/requestEmailConfirmation'
export * as ComAtprotoServerRequestEmailUpdate from './types/com/atproto/server/requestEmailUpdate'
export * as ComAtprotoServerRequestPasswordReset from './types/com/atproto/server/requestPasswordReset'
export * as ComAtprotoServerReserveSigningKey from './types/com/atproto/server/reserveSigningKey'
export * as ComAtprotoServerResetPassword from './types/com/atproto/server/resetPassword'
export * as ComAtprotoServerRevokeAppPassword from './types/com/atproto/server/revokeAppPassword'
export * as ComAtprotoServerSaveMaskWalletAddress from './types/com/atproto/server/saveMaskWalletAddress'
export * as ComAtprotoServerSaveReceiveAddress from './types/com/atproto/server/saveReceiveAddress'
export * as ComAtprotoServerSaveTgLoginUser from './types/com/atproto/server/saveTgLoginUser'
export * as ComAtprotoServerSdnAppToken from './types/com/atproto/server/sdnAppToken'
export * as ComAtprotoServerSendBotCommMsg from './types/com/atproto/server/sendBotCommMsg'
export * as ComAtprotoServerSendEmailCodes from './types/com/atproto/server/sendEmailCodes'
export * as ComAtprotoServerSendSipzBotNotification from './types/com/atproto/server/sendSipzBotNotification'
export * as ComAtprotoServerSendUserEmailCode from './types/com/atproto/server/sendUserEmailCode'
export * as ComAtprotoServerSetDefaultAccount from './types/com/atproto/server/setDefaultAccount'
export * as ComAtprotoServerSetPaymentWallet from './types/com/atproto/server/setPaymentWallet'
export * as ComAtprotoServerStoreAccountSettings from './types/com/atproto/server/storeAccountSettings'
export * as ComAtprotoServerStoreCredit from './types/com/atproto/server/storeCredit'
export * as ComAtprotoServerStoreDailyTask from './types/com/atproto/server/storeDailyTask'
export * as ComAtprotoServerStoreDailyTaskStatus from './types/com/atproto/server/storeDailyTaskStatus'
export * as ComAtprotoServerStoreShareConnection from './types/com/atproto/server/storeShareConnection'
export * as ComAtprotoServerStoreShareCount from './types/com/atproto/server/storeShareCount'
export * as ComAtprotoServerStoreTranslation from './types/com/atproto/server/storeTranslation'
export * as ComAtprotoServerSyncParentId from './types/com/atproto/server/syncParentId'
export * as ComAtprotoServerTagAdd from './types/com/atproto/server/tagAdd'
export * as ComAtprotoServerTagDelete from './types/com/atproto/server/tagDelete'
export * as ComAtprotoServerTagUpdate from './types/com/atproto/server/tagUpdate'
export * as ComAtprotoServerTagUserAdd from './types/com/atproto/server/tagUserAdd'
export * as ComAtprotoServerTagUserAddTags from './types/com/atproto/server/tagUserAddTags'
export * as ComAtprotoServerTagUsersAdd from './types/com/atproto/server/tagUsersAdd'
export * as ComAtprotoServerTagUsersDelete from './types/com/atproto/server/tagUsersDelete'
export * as ComAtprotoServerTelegramBind from './types/com/atproto/server/telegramBind'
export * as ComAtprotoServerTelegramChannelUserForOperation from './types/com/atproto/server/telegramChannelUserForOperation'
export * as ComAtprotoServerTelegramChannels from './types/com/atproto/server/telegramChannels'
export * as ComAtprotoServerTelegramContacts from './types/com/atproto/server/telegramContacts'
export * as ComAtprotoServerTelegramMemberDetail from './types/com/atproto/server/telegramMemberDetail'
export * as ComAtprotoServerTelegramParticipants from './types/com/atproto/server/telegramParticipants'
export * as ComAtprotoServerUnlinkTg from './types/com/atproto/server/unlinkTg'
export * as ComAtprotoServerUnlinkTwitter from './types/com/atproto/server/unlinkTwitter'
export * as ComAtprotoServerUpdateEmail from './types/com/atproto/server/updateEmail'
export * as ComAtprotoServerUpdateEntityTagByAdmin from './types/com/atproto/server/updateEntityTagByAdmin'
export * as ComAtprotoServerUpdateUserEmailStatus from './types/com/atproto/server/updateUserEmailStatus'
export * as ComAtprotoServerUploadBlobImage from './types/com/atproto/server/uploadBlobImage'
export * as ComAtprotoServerValidateHandle from './types/com/atproto/server/validateHandle'
export * as ComAtprotoServerWithdrawReward from './types/com/atproto/server/withdrawReward'
export * as ComAtprotoSyncGetBlob from './types/com/atproto/sync/getBlob'
export * as ComAtprotoSyncGetBlocks from './types/com/atproto/sync/getBlocks'
export * as ComAtprotoSyncGetCheckout from './types/com/atproto/sync/getCheckout'
export * as ComAtprotoSyncGetHead from './types/com/atproto/sync/getHead'
export * as ComAtprotoSyncGetLatestCommit from './types/com/atproto/sync/getLatestCommit'
export * as ComAtprotoSyncGetRecord from './types/com/atproto/sync/getRecord'
export * as ComAtprotoSyncGetRepo from './types/com/atproto/sync/getRepo'
export * as ComAtprotoSyncGetRepoStatus from './types/com/atproto/sync/getRepoStatus'
export * as ComAtprotoSyncListBlobs from './types/com/atproto/sync/listBlobs'
export * as ComAtprotoSyncListRepos from './types/com/atproto/sync/listRepos'
export * as ComAtprotoSyncNotifyOfUpdate from './types/com/atproto/sync/notifyOfUpdate'
export * as ComAtprotoSyncRequestCrawl from './types/com/atproto/sync/requestCrawl'
export * as ComAtprotoSyncSubscribeRepos from './types/com/atproto/sync/subscribeRepos'
export * as ComAtprotoTempCheckSignupQueue from './types/com/atproto/temp/checkSignupQueue'
export * as ComAtprotoTempFetchLabels from './types/com/atproto/temp/fetchLabels'
export * as ComAtprotoTempRequestPhoneVerification from './types/com/atproto/temp/requestPhoneVerification'
export * as AppBskyActorDefs from './types/app/bsky/actor/defs'
export * as AppBskyActorGetPreferences from './types/app/bsky/actor/getPreferences'
export * as AppBskyActorGetProfile from './types/app/bsky/actor/getProfile'
export * as AppBskyActorGetProfiles from './types/app/bsky/actor/getProfiles'
export * as AppBskyActorGetSuggestions from './types/app/bsky/actor/getSuggestions'
export * as AppBskyActorProfile from './types/app/bsky/actor/profile'
export * as AppBskyActorPutPreferences from './types/app/bsky/actor/putPreferences'
export * as AppBskyActorSearchActors from './types/app/bsky/actor/searchActors'
export * as AppBskyActorSearchActorsTypeahead from './types/app/bsky/actor/searchActorsTypeahead'
export * as AppBskyActorSearchTelegramHandle from './types/app/bsky/actor/searchTelegramHandle'
export * as AppBskyActorSearchTelegrams from './types/app/bsky/actor/searchTelegrams'
export * as AppBskyEmbedExternal from './types/app/bsky/embed/external'
export * as AppBskyEmbedImages from './types/app/bsky/embed/images'
export * as AppBskyEmbedRecord from './types/app/bsky/embed/record'
export * as AppBskyEmbedRecordWithMedia from './types/app/bsky/embed/recordWithMedia'
export * as AppBskyFeedBet from './types/app/bsky/feed/bet'
export * as AppBskyFeedDefs from './types/app/bsky/feed/defs'
export * as AppBskyFeedDescribeFeedGenerator from './types/app/bsky/feed/describeFeedGenerator'
export * as AppBskyFeedDonate from './types/app/bsky/feed/donate'
export * as AppBskyFeedFriendAgreeRequest from './types/app/bsky/feed/friendAgreeRequest'
export * as AppBskyFeedFriendRequest from './types/app/bsky/feed/friendRequest'
export * as AppBskyFeedGenerator from './types/app/bsky/feed/generator'
export * as AppBskyFeedGetActorEngagements from './types/app/bsky/feed/getActorEngagements'
export * as AppBskyFeedGetActorFeeds from './types/app/bsky/feed/getActorFeeds'
export * as AppBskyFeedGetActorLikes from './types/app/bsky/feed/getActorLikes'
export * as AppBskyFeedGetActorOpposes from './types/app/bsky/feed/getActorOpposes'
export * as AppBskyFeedGetAuthorFeed from './types/app/bsky/feed/getAuthorFeed'
export * as AppBskyFeedGetFeed from './types/app/bsky/feed/getFeed'
export * as AppBskyFeedGetFeedByTag from './types/app/bsky/feed/getFeedByTag'
export * as AppBskyFeedGetFeedGenerator from './types/app/bsky/feed/getFeedGenerator'
export * as AppBskyFeedGetFeedGenerators from './types/app/bsky/feed/getFeedGenerators'
export * as AppBskyFeedGetFeedSkeleton from './types/app/bsky/feed/getFeedSkeleton'
export * as AppBskyFeedGetLikes from './types/app/bsky/feed/getLikes'
export * as AppBskyFeedGetListFeed from './types/app/bsky/feed/getListFeed'
export * as AppBskyFeedGetOpposes from './types/app/bsky/feed/getOpposes'
export * as AppBskyFeedGetPostThread from './types/app/bsky/feed/getPostThread'
export * as AppBskyFeedGetPosts from './types/app/bsky/feed/getPosts'
export * as AppBskyFeedGetRepostedBy from './types/app/bsky/feed/getRepostedBy'
export * as AppBskyFeedGetSuggestedFeeds from './types/app/bsky/feed/getSuggestedFeeds'
export * as AppBskyFeedGetTimeline from './types/app/bsky/feed/getTimeline'
export * as AppBskyFeedGetUnlock from './types/app/bsky/feed/getUnlock'
export * as AppBskyFeedLike from './types/app/bsky/feed/like'
export * as AppBskyFeedOppose from './types/app/bsky/feed/oppose'
export * as AppBskyFeedPost from './types/app/bsky/feed/post'
export * as AppBskyFeedRepost from './types/app/bsky/feed/repost'
export * as AppBskyFeedSearchPosts from './types/app/bsky/feed/searchPosts'
export * as AppBskyFeedSendInteractions from './types/app/bsky/feed/sendInteractions'
export * as AppBskyFeedStake from './types/app/bsky/feed/stake'
export * as AppBskyFeedTgFriendBind from './types/app/bsky/feed/tgFriendBind'
export * as AppBskyFeedThreadgate from './types/app/bsky/feed/threadgate'
export * as AppBskyFeedUnlock from './types/app/bsky/feed/unlock'
export * as AppBskyFeedView from './types/app/bsky/feed/view'
export * as AppBskyGraphBlock from './types/app/bsky/graph/block'
export * as AppBskyGraphDefs from './types/app/bsky/graph/defs'
export * as AppBskyGraphFollow from './types/app/bsky/graph/follow'
export * as AppBskyGraphGetActorStarterPacks from './types/app/bsky/graph/getActorStarterPacks'
export * as AppBskyGraphGetBlocks from './types/app/bsky/graph/getBlocks'
export * as AppBskyGraphGetFollowers from './types/app/bsky/graph/getFollowers'
export * as AppBskyGraphGetFollows from './types/app/bsky/graph/getFollows'
export * as AppBskyGraphGetGuarantee from './types/app/bsky/graph/getGuarantee'
export * as AppBskyGraphGetKnownFollowers from './types/app/bsky/graph/getKnownFollowers'
export * as AppBskyGraphGetList from './types/app/bsky/graph/getList'
export * as AppBskyGraphGetListBlocks from './types/app/bsky/graph/getListBlocks'
export * as AppBskyGraphGetListMutes from './types/app/bsky/graph/getListMutes'
export * as AppBskyGraphGetLists from './types/app/bsky/graph/getLists'
export * as AppBskyGraphGetMutes from './types/app/bsky/graph/getMutes'
export * as AppBskyGraphGetRelationships from './types/app/bsky/graph/getRelationships'
export * as AppBskyGraphGetStarterPack from './types/app/bsky/graph/getStarterPack'
export * as AppBskyGraphGetStarterPacks from './types/app/bsky/graph/getStarterPacks'
export * as AppBskyGraphGetSuggestedFollowsByActor from './types/app/bsky/graph/getSuggestedFollowsByActor'
export * as AppBskyGraphGuarantee from './types/app/bsky/graph/guarantee'
export * as AppBskyGraphGuaranteeJoin from './types/app/bsky/graph/guaranteeJoin'
export * as AppBskyGraphGuaranteeUpdate from './types/app/bsky/graph/guaranteeUpdate'
export * as AppBskyGraphList from './types/app/bsky/graph/list'
export * as AppBskyGraphListblock from './types/app/bsky/graph/listblock'
export * as AppBskyGraphListitem from './types/app/bsky/graph/listitem'
export * as AppBskyGraphMuteActor from './types/app/bsky/graph/muteActor'
export * as AppBskyGraphMuteActorList from './types/app/bsky/graph/muteActorList'
export * as AppBskyGraphMuteThread from './types/app/bsky/graph/muteThread'
export * as AppBskyGraphStarterpack from './types/app/bsky/graph/starterpack'
export * as AppBskyGraphUnmuteActor from './types/app/bsky/graph/unmuteActor'
export * as AppBskyGraphUnmuteActorList from './types/app/bsky/graph/unmuteActorList'
export * as AppBskyGraphUnmuteThread from './types/app/bsky/graph/unmuteThread'
export * as AppBskyLabelerDefs from './types/app/bsky/labeler/defs'
export * as AppBskyLabelerGetServices from './types/app/bsky/labeler/getServices'
export * as AppBskyLabelerService from './types/app/bsky/labeler/service'
export * as AppBskyNotificationGetUnreadCount from './types/app/bsky/notification/getUnreadCount'
export * as AppBskyNotificationListNotifications from './types/app/bsky/notification/listNotifications'
export * as AppBskyNotificationPushNotification from './types/app/bsky/notification/pushNotification'
export * as AppBskyNotificationPutPreferences from './types/app/bsky/notification/putPreferences'
export * as AppBskyNotificationRegisterPush from './types/app/bsky/notification/registerPush'
export * as AppBskyNotificationRegisterPushOt from './types/app/bsky/notification/registerPushOt'
export * as AppBskyNotificationUpdateSeen from './types/app/bsky/notification/updateSeen'
export * as AppBskyRichtextFacet from './types/app/bsky/richtext/facet'
export * as AppBskyUnspeccedDefs from './types/app/bsky/unspecced/defs'
export * as AppBskyUnspeccedGetPopularFeedGenerators from './types/app/bsky/unspecced/getPopularFeedGenerators'
export * as AppBskyUnspeccedGetSuggestionsSkeleton from './types/app/bsky/unspecced/getSuggestionsSkeleton'
export * as AppBskyUnspeccedGetTaggedSuggestions from './types/app/bsky/unspecced/getTaggedSuggestions'
export * as AppBskyUnspeccedSearchActorsSkeleton from './types/app/bsky/unspecced/searchActorsSkeleton'
export * as AppBskyUnspeccedSearchPostsSkeleton from './types/app/bsky/unspecced/searchPostsSkeleton'
export * as ChatBskyActorDeclaration from './types/chat/bsky/actor/declaration'
export * as ChatBskyActorDefs from './types/chat/bsky/actor/defs'
export * as ChatBskyActorDeleteAccount from './types/chat/bsky/actor/deleteAccount'
export * as ChatBskyActorExportAccountData from './types/chat/bsky/actor/exportAccountData'
export * as ChatBskyConvoDefs from './types/chat/bsky/convo/defs'
export * as ChatBskyConvoDeleteMessageForSelf from './types/chat/bsky/convo/deleteMessageForSelf'
export * as ChatBskyConvoGetConvo from './types/chat/bsky/convo/getConvo'
export * as ChatBskyConvoGetConvoForMembers from './types/chat/bsky/convo/getConvoForMembers'
export * as ChatBskyConvoGetLog from './types/chat/bsky/convo/getLog'
export * as ChatBskyConvoGetMessages from './types/chat/bsky/convo/getMessages'
export * as ChatBskyConvoLeaveConvo from './types/chat/bsky/convo/leaveConvo'
export * as ChatBskyConvoListConvos from './types/chat/bsky/convo/listConvos'
export * as ChatBskyConvoMuteConvo from './types/chat/bsky/convo/muteConvo'
export * as ChatBskyConvoSendMessage from './types/chat/bsky/convo/sendMessage'
export * as ChatBskyConvoSendMessageBatch from './types/chat/bsky/convo/sendMessageBatch'
export * as ChatBskyConvoUnmuteConvo from './types/chat/bsky/convo/unmuteConvo'
export * as ChatBskyConvoUpdateRead from './types/chat/bsky/convo/updateRead'
export * as ChatBskyModerationGetActorMetadata from './types/chat/bsky/moderation/getActorMetadata'
export * as ChatBskyModerationGetMessageContext from './types/chat/bsky/moderation/getMessageContext'
export * as ChatBskyModerationUpdateActorAccess from './types/chat/bsky/moderation/updateActorAccess'
export * as ToolsOzoneCommunicationCreateTemplate from './types/tools/ozone/communication/createTemplate'
export * as ToolsOzoneCommunicationDefs from './types/tools/ozone/communication/defs'
export * as ToolsOzoneCommunicationDeleteTemplate from './types/tools/ozone/communication/deleteTemplate'
export * as ToolsOzoneCommunicationListTemplates from './types/tools/ozone/communication/listTemplates'
export * as ToolsOzoneCommunicationUpdateTemplate from './types/tools/ozone/communication/updateTemplate'
export * as ToolsOzoneModerationDefs from './types/tools/ozone/moderation/defs'
export * as ToolsOzoneModerationEmitEvent from './types/tools/ozone/moderation/emitEvent'
export * as ToolsOzoneModerationGetEvent from './types/tools/ozone/moderation/getEvent'
export * as ToolsOzoneModerationGetRecord from './types/tools/ozone/moderation/getRecord'
export * as ToolsOzoneModerationGetRepo from './types/tools/ozone/moderation/getRepo'
export * as ToolsOzoneModerationQueryEvents from './types/tools/ozone/moderation/queryEvents'
export * as ToolsOzoneModerationQueryStatuses from './types/tools/ozone/moderation/queryStatuses'
export * as ToolsOzoneModerationSearchRepos from './types/tools/ozone/moderation/searchRepos'
export * as ToolsOzoneServerGetConfig from './types/tools/ozone/server/getConfig'
export * as ToolsOzoneTeamAddMember from './types/tools/ozone/team/addMember'
export * as ToolsOzoneTeamDefs from './types/tools/ozone/team/defs'
export * as ToolsOzoneTeamDeleteMember from './types/tools/ozone/team/deleteMember'
export * as ToolsOzoneTeamListMembers from './types/tools/ozone/team/listMembers'
export * as ToolsOzoneTeamUpdateMember from './types/tools/ozone/team/updateMember'

export const COM_ATPROTO_MODERATION = {
  DefsReasonSpam: 'com.atproto.moderation.defs#reasonSpam',
  DefsReasonViolation: 'com.atproto.moderation.defs#reasonViolation',
  DefsReasonMisleading: 'com.atproto.moderation.defs#reasonMisleading',
  DefsReasonSexual: 'com.atproto.moderation.defs#reasonSexual',
  DefsReasonRude: 'com.atproto.moderation.defs#reasonRude',
  DefsReasonOther: 'com.atproto.moderation.defs#reasonOther',
  DefsReasonAppeal: 'com.atproto.moderation.defs#reasonAppeal',
}
export const APP_BSKY_FEED = {
  DefsRequestLess: 'app.bsky.feed.defs#requestLess',
  DefsRequestMore: 'app.bsky.feed.defs#requestMore',
  DefsClickthroughItem: 'app.bsky.feed.defs#clickthroughItem',
  DefsClickthroughAuthor: 'app.bsky.feed.defs#clickthroughAuthor',
  DefsClickthroughReposter: 'app.bsky.feed.defs#clickthroughReposter',
  DefsClickthroughEmbed: 'app.bsky.feed.defs#clickthroughEmbed',
  DefsInteractionSeen: 'app.bsky.feed.defs#interactionSeen',
  DefsInteractionLike: 'app.bsky.feed.defs#interactionLike',
  DefsInteractionOppose: 'app.bsky.feed.defs#interactionOppose',
  DefsInteractionUnlock: 'app.bsky.feed.defs#interactionUnlock',
  DefsInteractionRepost: 'app.bsky.feed.defs#interactionRepost',
  DefsInteractionReply: 'app.bsky.feed.defs#interactionReply',
  DefsInteractionQuote: 'app.bsky.feed.defs#interactionQuote',
  DefsInteractionShare: 'app.bsky.feed.defs#interactionShare',
}
export const APP_BSKY_GRAPH = {
  DefsModlist: 'app.bsky.graph.defs#modlist',
  DefsCuratelist: 'app.bsky.graph.defs#curatelist',
  DefsReferencelist: 'app.bsky.graph.defs#referencelist',
}
export const TOOLS_OZONE_MODERATION = {
  DefsReviewOpen: 'tools.ozone.moderation.defs#reviewOpen',
  DefsReviewEscalated: 'tools.ozone.moderation.defs#reviewEscalated',
  DefsReviewClosed: 'tools.ozone.moderation.defs#reviewClosed',
  DefsReviewNone: 'tools.ozone.moderation.defs#reviewNone',
}
export const TOOLS_OZONE_TEAM = {
  DefsRoleAdmin: 'tools.ozone.team.defs#roleAdmin',
  DefsRoleModerator: 'tools.ozone.team.defs#roleModerator',
  DefsRoleTriage: 'tools.ozone.team.defs#roleTriage',
}

export class AtpBaseClient extends XrpcClient {
  com: ComNS
  app: AppNS
  chat: ChatNS
  tools: ToolsNS

  constructor(options: FetchHandler | FetchHandlerOptions) {
    super(options, schemas)
    this.com = new ComNS(this)
    this.app = new AppNS(this)
    this.chat = new ChatNS(this)
    this.tools = new ToolsNS(this)
  }

  /** @deprecated use `this` instead */
  get xrpc(): XrpcClient {
    return this
  }
}

export class ComNS {
  _client: XrpcClient
  atproto: ComAtprotoNS

  constructor(client: XrpcClient) {
    this._client = client
    this.atproto = new ComAtprotoNS(client)
  }
}

export class ComAtprotoNS {
  _client: XrpcClient
  admin: ComAtprotoAdminNS
  identity: ComAtprotoIdentityNS
  label: ComAtprotoLabelNS
  moderation: ComAtprotoModerationNS
  repo: ComAtprotoRepoNS
  server: ComAtprotoServerNS
  sync: ComAtprotoSyncNS
  temp: ComAtprotoTempNS

  constructor(client: XrpcClient) {
    this._client = client
    this.admin = new ComAtprotoAdminNS(client)
    this.identity = new ComAtprotoIdentityNS(client)
    this.label = new ComAtprotoLabelNS(client)
    this.moderation = new ComAtprotoModerationNS(client)
    this.repo = new ComAtprotoRepoNS(client)
    this.server = new ComAtprotoServerNS(client)
    this.sync = new ComAtprotoSyncNS(client)
    this.temp = new ComAtprotoTempNS(client)
  }
}

export class ComAtprotoAdminNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  createLabelTag(
    data?: ComAtprotoAdminCreateLabelTag.InputSchema,
    opts?: ComAtprotoAdminCreateLabelTag.CallOptions,
  ): Promise<ComAtprotoAdminCreateLabelTag.Response> {
    return this._client.call(
      'com.atproto.admin.createLabelTag',
      opts?.qp,
      data,
      opts,
    )
  }

  deleteAccount(
    data?: ComAtprotoAdminDeleteAccount.InputSchema,
    opts?: ComAtprotoAdminDeleteAccount.CallOptions,
  ): Promise<ComAtprotoAdminDeleteAccount.Response> {
    return this._client.call(
      'com.atproto.admin.deleteAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  deleteLabelTag(
    data?: ComAtprotoAdminDeleteLabelTag.InputSchema,
    opts?: ComAtprotoAdminDeleteLabelTag.CallOptions,
  ): Promise<ComAtprotoAdminDeleteLabelTag.Response> {
    return this._client.call(
      'com.atproto.admin.deleteLabelTag',
      opts?.qp,
      data,
      opts,
    )
  }

  disableAccountInvites(
    data?: ComAtprotoAdminDisableAccountInvites.InputSchema,
    opts?: ComAtprotoAdminDisableAccountInvites.CallOptions,
  ): Promise<ComAtprotoAdminDisableAccountInvites.Response> {
    return this._client.call(
      'com.atproto.admin.disableAccountInvites',
      opts?.qp,
      data,
      opts,
    )
  }

  disableInviteCodes(
    data?: ComAtprotoAdminDisableInviteCodes.InputSchema,
    opts?: ComAtprotoAdminDisableInviteCodes.CallOptions,
  ): Promise<ComAtprotoAdminDisableInviteCodes.Response> {
    return this._client.call(
      'com.atproto.admin.disableInviteCodes',
      opts?.qp,
      data,
      opts,
    )
  }

  enableAccountInvites(
    data?: ComAtprotoAdminEnableAccountInvites.InputSchema,
    opts?: ComAtprotoAdminEnableAccountInvites.CallOptions,
  ): Promise<ComAtprotoAdminEnableAccountInvites.Response> {
    return this._client.call(
      'com.atproto.admin.enableAccountInvites',
      opts?.qp,
      data,
      opts,
    )
  }

  getAccountInfo(
    params?: ComAtprotoAdminGetAccountInfo.QueryParams,
    opts?: ComAtprotoAdminGetAccountInfo.CallOptions,
  ): Promise<ComAtprotoAdminGetAccountInfo.Response> {
    return this._client.call(
      'com.atproto.admin.getAccountInfo',
      params,
      undefined,
      opts,
    )
  }

  getAccountInfos(
    params?: ComAtprotoAdminGetAccountInfos.QueryParams,
    opts?: ComAtprotoAdminGetAccountInfos.CallOptions,
  ): Promise<ComAtprotoAdminGetAccountInfos.Response> {
    return this._client.call(
      'com.atproto.admin.getAccountInfos',
      params,
      undefined,
      opts,
    )
  }

  getActorStatistics(
    params?: ComAtprotoAdminGetActorStatistics.QueryParams,
    opts?: ComAtprotoAdminGetActorStatistics.CallOptions,
  ): Promise<ComAtprotoAdminGetActorStatistics.Response> {
    return this._client.call(
      'com.atproto.admin.getActorStatistics',
      params,
      undefined,
      opts,
    )
  }

  getInviteCodes(
    params?: ComAtprotoAdminGetInviteCodes.QueryParams,
    opts?: ComAtprotoAdminGetInviteCodes.CallOptions,
  ): Promise<ComAtprotoAdminGetInviteCodes.Response> {
    return this._client.call(
      'com.atproto.admin.getInviteCodes',
      params,
      undefined,
      opts,
    )
  }

  getSubjectStatus(
    params?: ComAtprotoAdminGetSubjectStatus.QueryParams,
    opts?: ComAtprotoAdminGetSubjectStatus.CallOptions,
  ): Promise<ComAtprotoAdminGetSubjectStatus.Response> {
    return this._client.call(
      'com.atproto.admin.getSubjectStatus',
      params,
      undefined,
      opts,
    )
  }

  modifyLabelTagActive(
    data?: ComAtprotoAdminModifyLabelTagActive.InputSchema,
    opts?: ComAtprotoAdminModifyLabelTagActive.CallOptions,
  ): Promise<ComAtprotoAdminModifyLabelTagActive.Response> {
    return this._client.call(
      'com.atproto.admin.modifyLabelTagActive',
      opts?.qp,
      data,
      opts,
    )
  }

  modifyPostLabels(
    data?: ComAtprotoAdminModifyPostLabels.InputSchema,
    opts?: ComAtprotoAdminModifyPostLabels.CallOptions,
  ): Promise<ComAtprotoAdminModifyPostLabels.Response> {
    return this._client.call(
      'com.atproto.admin.modifyPostLabels',
      opts?.qp,
      data,
      opts,
    )
  }

  queryLabelTags(
    params?: ComAtprotoAdminQueryLabelTags.QueryParams,
    opts?: ComAtprotoAdminQueryLabelTags.CallOptions,
  ): Promise<ComAtprotoAdminQueryLabelTags.Response> {
    return this._client.call(
      'com.atproto.admin.queryLabelTags',
      params,
      undefined,
      opts,
    )
  }

  renameLabelTag(
    data?: ComAtprotoAdminRenameLabelTag.InputSchema,
    opts?: ComAtprotoAdminRenameLabelTag.CallOptions,
  ): Promise<ComAtprotoAdminRenameLabelTag.Response> {
    return this._client.call(
      'com.atproto.admin.renameLabelTag',
      opts?.qp,
      data,
      opts,
    )
  }

  saveActorStatistics(
    data?: ComAtprotoAdminSaveActorStatistics.InputSchema,
    opts?: ComAtprotoAdminSaveActorStatistics.CallOptions,
  ): Promise<ComAtprotoAdminSaveActorStatistics.Response> {
    return this._client.call(
      'com.atproto.admin.saveActorStatistics',
      opts?.qp,
      data,
      opts,
    )
  }

  saveAdminAddress(
    data?: ComAtprotoAdminSaveAdminAddress.InputSchema,
    opts?: ComAtprotoAdminSaveAdminAddress.CallOptions,
  ): Promise<ComAtprotoAdminSaveAdminAddress.Response> {
    return this._client.call(
      'com.atproto.admin.saveAdminAddress',
      opts?.qp,
      data,
      opts,
    )
  }

  searchAccounts(
    params?: ComAtprotoAdminSearchAccounts.QueryParams,
    opts?: ComAtprotoAdminSearchAccounts.CallOptions,
  ): Promise<ComAtprotoAdminSearchAccounts.Response> {
    return this._client.call(
      'com.atproto.admin.searchAccounts',
      params,
      undefined,
      opts,
    )
  }

  searchLabelTags(
    params?: ComAtprotoAdminSearchLabelTags.QueryParams,
    opts?: ComAtprotoAdminSearchLabelTags.CallOptions,
  ): Promise<ComAtprotoAdminSearchLabelTags.Response> {
    return this._client.call(
      'com.atproto.admin.searchLabelTags',
      params,
      undefined,
      opts,
    )
  }

  sendEmail(
    data?: ComAtprotoAdminSendEmail.InputSchema,
    opts?: ComAtprotoAdminSendEmail.CallOptions,
  ): Promise<ComAtprotoAdminSendEmail.Response> {
    return this._client.call(
      'com.atproto.admin.sendEmail',
      opts?.qp,
      data,
      opts,
    )
  }

  updateAccountEmail(
    data?: ComAtprotoAdminUpdateAccountEmail.InputSchema,
    opts?: ComAtprotoAdminUpdateAccountEmail.CallOptions,
  ): Promise<ComAtprotoAdminUpdateAccountEmail.Response> {
    return this._client.call(
      'com.atproto.admin.updateAccountEmail',
      opts?.qp,
      data,
      opts,
    )
  }

  updateAccountHandle(
    data?: ComAtprotoAdminUpdateAccountHandle.InputSchema,
    opts?: ComAtprotoAdminUpdateAccountHandle.CallOptions,
  ): Promise<ComAtprotoAdminUpdateAccountHandle.Response> {
    return this._client.call(
      'com.atproto.admin.updateAccountHandle',
      opts?.qp,
      data,
      opts,
    )
  }

  updateAccountPassword(
    data?: ComAtprotoAdminUpdateAccountPassword.InputSchema,
    opts?: ComAtprotoAdminUpdateAccountPassword.CallOptions,
  ): Promise<ComAtprotoAdminUpdateAccountPassword.Response> {
    return this._client.call(
      'com.atproto.admin.updateAccountPassword',
      opts?.qp,
      data,
      opts,
    )
  }

  updateSubjectStatus(
    data?: ComAtprotoAdminUpdateSubjectStatus.InputSchema,
    opts?: ComAtprotoAdminUpdateSubjectStatus.CallOptions,
  ): Promise<ComAtprotoAdminUpdateSubjectStatus.Response> {
    return this._client.call(
      'com.atproto.admin.updateSubjectStatus',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoIdentityNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getFavorites(
    params?: ComAtprotoIdentityGetFavorites.QueryParams,
    opts?: ComAtprotoIdentityGetFavorites.CallOptions,
  ): Promise<ComAtprotoIdentityGetFavorites.Response> {
    return this._client.call(
      'com.atproto.identity.getFavorites',
      params,
      undefined,
      opts,
    )
  }

  getRecommendedDidCredentials(
    params?: ComAtprotoIdentityGetRecommendedDidCredentials.QueryParams,
    opts?: ComAtprotoIdentityGetRecommendedDidCredentials.CallOptions,
  ): Promise<ComAtprotoIdentityGetRecommendedDidCredentials.Response> {
    return this._client.call(
      'com.atproto.identity.getRecommendedDidCredentials',
      params,
      undefined,
      opts,
    )
  }

  isFavorite(
    params?: ComAtprotoIdentityIsFavorite.QueryParams,
    opts?: ComAtprotoIdentityIsFavorite.CallOptions,
  ): Promise<ComAtprotoIdentityIsFavorite.Response> {
    return this._client.call(
      'com.atproto.identity.isFavorite',
      params,
      undefined,
      opts,
    )
  }

  removeFavorite(
    data?: ComAtprotoIdentityRemoveFavorite.InputSchema,
    opts?: ComAtprotoIdentityRemoveFavorite.CallOptions,
  ): Promise<ComAtprotoIdentityRemoveFavorite.Response> {
    return this._client.call(
      'com.atproto.identity.removeFavorite',
      opts?.qp,
      data,
      opts,
    )
  }

  requestPlcOperationSignature(
    data?: ComAtprotoIdentityRequestPlcOperationSignature.InputSchema,
    opts?: ComAtprotoIdentityRequestPlcOperationSignature.CallOptions,
  ): Promise<ComAtprotoIdentityRequestPlcOperationSignature.Response> {
    return this._client.call(
      'com.atproto.identity.requestPlcOperationSignature',
      opts?.qp,
      data,
      opts,
    )
  }

  resolveHandle(
    params?: ComAtprotoIdentityResolveHandle.QueryParams,
    opts?: ComAtprotoIdentityResolveHandle.CallOptions,
  ): Promise<ComAtprotoIdentityResolveHandle.Response> {
    return this._client.call(
      'com.atproto.identity.resolveHandle',
      params,
      undefined,
      opts,
    )
  }

  setFavorite(
    data?: ComAtprotoIdentitySetFavorite.InputSchema,
    opts?: ComAtprotoIdentitySetFavorite.CallOptions,
  ): Promise<ComAtprotoIdentitySetFavorite.Response> {
    return this._client.call(
      'com.atproto.identity.setFavorite',
      opts?.qp,
      data,
      opts,
    )
  }

  signPlcOperation(
    data?: ComAtprotoIdentitySignPlcOperation.InputSchema,
    opts?: ComAtprotoIdentitySignPlcOperation.CallOptions,
  ): Promise<ComAtprotoIdentitySignPlcOperation.Response> {
    return this._client.call(
      'com.atproto.identity.signPlcOperation',
      opts?.qp,
      data,
      opts,
    )
  }

  submitPlcOperation(
    data?: ComAtprotoIdentitySubmitPlcOperation.InputSchema,
    opts?: ComAtprotoIdentitySubmitPlcOperation.CallOptions,
  ): Promise<ComAtprotoIdentitySubmitPlcOperation.Response> {
    return this._client.call(
      'com.atproto.identity.submitPlcOperation',
      opts?.qp,
      data,
      opts,
    )
  }

  updateHandle(
    data?: ComAtprotoIdentityUpdateHandle.InputSchema,
    opts?: ComAtprotoIdentityUpdateHandle.CallOptions,
  ): Promise<ComAtprotoIdentityUpdateHandle.Response> {
    return this._client.call(
      'com.atproto.identity.updateHandle',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoLabelNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  queryLabels(
    params?: ComAtprotoLabelQueryLabels.QueryParams,
    opts?: ComAtprotoLabelQueryLabels.CallOptions,
  ): Promise<ComAtprotoLabelQueryLabels.Response> {
    return this._client.call(
      'com.atproto.label.queryLabels',
      params,
      undefined,
      opts,
    )
  }
}

export class ComAtprotoModerationNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  createReport(
    data?: ComAtprotoModerationCreateReport.InputSchema,
    opts?: ComAtprotoModerationCreateReport.CallOptions,
  ): Promise<ComAtprotoModerationCreateReport.Response> {
    return this._client.call(
      'com.atproto.moderation.createReport',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoRepoNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  applyWrites(
    data?: ComAtprotoRepoApplyWrites.InputSchema,
    opts?: ComAtprotoRepoApplyWrites.CallOptions,
  ): Promise<ComAtprotoRepoApplyWrites.Response> {
    return this._client
      .call('com.atproto.repo.applyWrites', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoRepoApplyWrites.toKnownErr(e)
      })
  }

  createRecord(
    data?: ComAtprotoRepoCreateRecord.InputSchema,
    opts?: ComAtprotoRepoCreateRecord.CallOptions,
  ): Promise<ComAtprotoRepoCreateRecord.Response> {
    return this._client
      .call('com.atproto.repo.createRecord', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoRepoCreateRecord.toKnownErr(e)
      })
  }

  createRecords(
    data?: ComAtprotoRepoCreateRecords.InputSchema,
    opts?: ComAtprotoRepoCreateRecords.CallOptions,
  ): Promise<ComAtprotoRepoCreateRecords.Response> {
    return this._client
      .call('com.atproto.repo.createRecords', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoRepoCreateRecords.toKnownErr(e)
      })
  }

  deleteRecord(
    data?: ComAtprotoRepoDeleteRecord.InputSchema,
    opts?: ComAtprotoRepoDeleteRecord.CallOptions,
  ): Promise<ComAtprotoRepoDeleteRecord.Response> {
    return this._client
      .call('com.atproto.repo.deleteRecord', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoRepoDeleteRecord.toKnownErr(e)
      })
  }

  describeRepo(
    params?: ComAtprotoRepoDescribeRepo.QueryParams,
    opts?: ComAtprotoRepoDescribeRepo.CallOptions,
  ): Promise<ComAtprotoRepoDescribeRepo.Response> {
    return this._client.call(
      'com.atproto.repo.describeRepo',
      params,
      undefined,
      opts,
    )
  }

  getRecord(
    params?: ComAtprotoRepoGetRecord.QueryParams,
    opts?: ComAtprotoRepoGetRecord.CallOptions,
  ): Promise<ComAtprotoRepoGetRecord.Response> {
    return this._client.call(
      'com.atproto.repo.getRecord',
      params,
      undefined,
      opts,
    )
  }

  importRepo(
    data?: ComAtprotoRepoImportRepo.InputSchema,
    opts?: ComAtprotoRepoImportRepo.CallOptions,
  ): Promise<ComAtprotoRepoImportRepo.Response> {
    return this._client.call(
      'com.atproto.repo.importRepo',
      opts?.qp,
      data,
      opts,
    )
  }

  listMissingBlobs(
    params?: ComAtprotoRepoListMissingBlobs.QueryParams,
    opts?: ComAtprotoRepoListMissingBlobs.CallOptions,
  ): Promise<ComAtprotoRepoListMissingBlobs.Response> {
    return this._client.call(
      'com.atproto.repo.listMissingBlobs',
      params,
      undefined,
      opts,
    )
  }

  listRecords(
    params?: ComAtprotoRepoListRecords.QueryParams,
    opts?: ComAtprotoRepoListRecords.CallOptions,
  ): Promise<ComAtprotoRepoListRecords.Response> {
    return this._client.call(
      'com.atproto.repo.listRecords',
      params,
      undefined,
      opts,
    )
  }

  putRecord(
    data?: ComAtprotoRepoPutRecord.InputSchema,
    opts?: ComAtprotoRepoPutRecord.CallOptions,
  ): Promise<ComAtprotoRepoPutRecord.Response> {
    return this._client
      .call('com.atproto.repo.putRecord', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoRepoPutRecord.toKnownErr(e)
      })
  }

  uploadBlob(
    data?: ComAtprotoRepoUploadBlob.InputSchema,
    opts?: ComAtprotoRepoUploadBlob.CallOptions,
  ): Promise<ComAtprotoRepoUploadBlob.Response> {
    return this._client.call(
      'com.atproto.repo.uploadBlob',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoServerNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  activateAccount(
    data?: ComAtprotoServerActivateAccount.InputSchema,
    opts?: ComAtprotoServerActivateAccount.CallOptions,
  ): Promise<ComAtprotoServerActivateAccount.Response> {
    return this._client.call(
      'com.atproto.server.activateAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  addInviteeFriend(
    data?: ComAtprotoServerAddInviteeFriend.InputSchema,
    opts?: ComAtprotoServerAddInviteeFriend.CallOptions,
  ): Promise<ComAtprotoServerAddInviteeFriend.Response> {
    return this._client.call(
      'com.atproto.server.addInviteeFriend',
      opts?.qp,
      data,
      opts,
    )
  }

  bindTg(
    data?: ComAtprotoServerBindTg.InputSchema,
    opts?: ComAtprotoServerBindTg.CallOptions,
  ): Promise<ComAtprotoServerBindTg.Response> {
    return this._client
      .call('com.atproto.server.bindTg', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerBindTg.toKnownErr(e)
      })
  }

  bindTwitter(
    data?: ComAtprotoServerBindTwitter.InputSchema,
    opts?: ComAtprotoServerBindTwitter.CallOptions,
  ): Promise<ComAtprotoServerBindTwitter.Response> {
    return this._client
      .call('com.atproto.server.bindTwitter', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerBindTwitter.toKnownErr(e)
      })
  }

  bindWalletAddress(
    data?: ComAtprotoServerBindWalletAddress.InputSchema,
    opts?: ComAtprotoServerBindWalletAddress.CallOptions,
  ): Promise<ComAtprotoServerBindWalletAddress.Response> {
    return this._client
      .call('com.atproto.server.bindWalletAddress', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerBindWalletAddress.toKnownErr(e)
      })
  }

  bindWalletAddressList(
    params?: ComAtprotoServerBindWalletAddressList.QueryParams,
    opts?: ComAtprotoServerBindWalletAddressList.CallOptions,
  ): Promise<ComAtprotoServerBindWalletAddressList.Response> {
    return this._client.call(
      'com.atproto.server.bindWalletAddressList',
      params,
      undefined,
      opts,
    )
  }

  checkAccountStatus(
    params?: ComAtprotoServerCheckAccountStatus.QueryParams,
    opts?: ComAtprotoServerCheckAccountStatus.CallOptions,
  ): Promise<ComAtprotoServerCheckAccountStatus.Response> {
    return this._client.call(
      'com.atproto.server.checkAccountStatus',
      params,
      undefined,
      opts,
    )
  }

  checkUserEmailCode(
    data?: ComAtprotoServerCheckUserEmailCode.InputSchema,
    opts?: ComAtprotoServerCheckUserEmailCode.CallOptions,
  ): Promise<ComAtprotoServerCheckUserEmailCode.Response> {
    return this._client.call(
      'com.atproto.server.checkUserEmailCode',
      opts?.qp,
      data,
      opts,
    )
  }

  circleAcceptInvitation(
    data?: ComAtprotoServerCircleAcceptInvitation.InputSchema,
    opts?: ComAtprotoServerCircleAcceptInvitation.CallOptions,
  ): Promise<ComAtprotoServerCircleAcceptInvitation.Response> {
    return this._client
      .call('com.atproto.server.circleAcceptInvitation', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCircleAcceptInvitation.toKnownErr(e)
      })
  }

  circleAgreeOrReject(
    data?: ComAtprotoServerCircleAgreeOrReject.InputSchema,
    opts?: ComAtprotoServerCircleAgreeOrReject.CallOptions,
  ): Promise<ComAtprotoServerCircleAgreeOrReject.Response> {
    return this._client
      .call('com.atproto.server.circleAgreeOrReject', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCircleAgreeOrReject.toKnownErr(e)
      })
  }

  circleApply(
    data?: ComAtprotoServerCircleApply.InputSchema,
    opts?: ComAtprotoServerCircleApply.CallOptions,
  ): Promise<ComAtprotoServerCircleApply.Response> {
    return this._client
      .call('com.atproto.server.circleApply', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCircleApply.toKnownErr(e)
      })
  }

  circleDelete(
    data?: ComAtprotoServerCircleDelete.InputSchema,
    opts?: ComAtprotoServerCircleDelete.CallOptions,
  ): Promise<ComAtprotoServerCircleDelete.Response> {
    return this._client.call(
      'com.atproto.server.circleDelete',
      opts?.qp,
      data,
      opts,
    )
  }

  circleInfoAdd(
    data?: ComAtprotoServerCircleInfoAdd.InputSchema,
    opts?: ComAtprotoServerCircleInfoAdd.CallOptions,
  ): Promise<ComAtprotoServerCircleInfoAdd.Response> {
    return this._client.call(
      'com.atproto.server.circleInfoAdd',
      opts?.qp,
      data,
      opts,
    )
  }

  circleInfoUpdate(
    data?: ComAtprotoServerCircleInfoUpdate.InputSchema,
    opts?: ComAtprotoServerCircleInfoUpdate.CallOptions,
  ): Promise<ComAtprotoServerCircleInfoUpdate.Response> {
    return this._client.call(
      'com.atproto.server.circleInfoUpdate',
      opts?.qp,
      data,
      opts,
    )
  }

  circleQueryApproveInfos(
    params?: ComAtprotoServerCircleQueryApproveInfos.QueryParams,
    opts?: ComAtprotoServerCircleQueryApproveInfos.CallOptions,
  ): Promise<ComAtprotoServerCircleQueryApproveInfos.Response> {
    return this._client.call(
      'com.atproto.server.circleQueryApproveInfos',
      params,
      undefined,
      opts,
    )
  }

  circleQueryApproveNum(
    params?: ComAtprotoServerCircleQueryApproveNum.QueryParams,
    opts?: ComAtprotoServerCircleQueryApproveNum.CallOptions,
  ): Promise<ComAtprotoServerCircleQueryApproveNum.Response> {
    return this._client.call(
      'com.atproto.server.circleQueryApproveNum',
      params,
      undefined,
      opts,
    )
  }

  circleQueryInviteInfo(
    params?: ComAtprotoServerCircleQueryInviteInfo.QueryParams,
    opts?: ComAtprotoServerCircleQueryInviteInfo.CallOptions,
  ): Promise<ComAtprotoServerCircleQueryInviteInfo.Response> {
    return this._client.call(
      'com.atproto.server.circleQueryInviteInfo',
      params,
      undefined,
      opts,
    )
  }

  circleUpdateUsersBackend(
    data?: ComAtprotoServerCircleUpdateUsersBackend.InputSchema,
    opts?: ComAtprotoServerCircleUpdateUsersBackend.CallOptions,
  ): Promise<ComAtprotoServerCircleUpdateUsersBackend.Response> {
    return this._client.call(
      'com.atproto.server.circleUpdateUsersBackend',
      opts?.qp,
      data,
      opts,
    )
  }

  circleUserDeleteBatch(
    data?: ComAtprotoServerCircleUserDeleteBatch.InputSchema,
    opts?: ComAtprotoServerCircleUserDeleteBatch.CallOptions,
  ): Promise<ComAtprotoServerCircleUserDeleteBatch.Response> {
    return this._client.call(
      'com.atproto.server.circleUserDeleteBatch',
      opts?.qp,
      data,
      opts,
    )
  }

  circleUserFromHandles(
    data?: ComAtprotoServerCircleUserFromHandles.InputSchema,
    opts?: ComAtprotoServerCircleUserFromHandles.CallOptions,
  ): Promise<ComAtprotoServerCircleUserFromHandles.Response> {
    return this._client.call(
      'com.atproto.server.circleUserFromHandles',
      opts?.qp,
      data,
      opts,
    )
  }

  circleUserFromTelegramGroup(
    data?: ComAtprotoServerCircleUserFromTelegramGroup.InputSchema,
    opts?: ComAtprotoServerCircleUserFromTelegramGroup.CallOptions,
  ): Promise<ComAtprotoServerCircleUserFromTelegramGroup.Response> {
    return this._client.call(
      'com.atproto.server.circleUserFromTelegramGroup',
      opts?.qp,
      data,
      opts,
    )
  }

  circleUsersBatch(
    data?: ComAtprotoServerCircleUsersBatch.InputSchema,
    opts?: ComAtprotoServerCircleUsersBatch.CallOptions,
  ): Promise<ComAtprotoServerCircleUsersBatch.Response> {
    return this._client.call(
      'com.atproto.server.circleUsersBatch',
      opts?.qp,
      data,
      opts,
    )
  }

  confirmBindTwitter(
    data?: ComAtprotoServerConfirmBindTwitter.InputSchema,
    opts?: ComAtprotoServerConfirmBindTwitter.CallOptions,
  ): Promise<ComAtprotoServerConfirmBindTwitter.Response> {
    return this._client
      .call('com.atproto.server.confirmBindTwitter', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerConfirmBindTwitter.toKnownErr(e)
      })
  }

  confirmEmail(
    data?: ComAtprotoServerConfirmEmail.InputSchema,
    opts?: ComAtprotoServerConfirmEmail.CallOptions,
  ): Promise<ComAtprotoServerConfirmEmail.Response> {
    return this._client
      .call('com.atproto.server.confirmEmail', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerConfirmEmail.toKnownErr(e)
      })
  }

  createAccount(
    data?: ComAtprotoServerCreateAccount.InputSchema,
    opts?: ComAtprotoServerCreateAccount.CallOptions,
  ): Promise<ComAtprotoServerCreateAccount.Response> {
    return this._client
      .call('com.atproto.server.createAccount', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCreateAccount.toKnownErr(e)
      })
  }

  createAppPassword(
    data?: ComAtprotoServerCreateAppPassword.InputSchema,
    opts?: ComAtprotoServerCreateAppPassword.CallOptions,
  ): Promise<ComAtprotoServerCreateAppPassword.Response> {
    return this._client
      .call('com.atproto.server.createAppPassword', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCreateAppPassword.toKnownErr(e)
      })
  }

  createInviteCode(
    data?: ComAtprotoServerCreateInviteCode.InputSchema,
    opts?: ComAtprotoServerCreateInviteCode.CallOptions,
  ): Promise<ComAtprotoServerCreateInviteCode.Response> {
    return this._client.call(
      'com.atproto.server.createInviteCode',
      opts?.qp,
      data,
      opts,
    )
  }

  createInviteCodes(
    data?: ComAtprotoServerCreateInviteCodes.InputSchema,
    opts?: ComAtprotoServerCreateInviteCodes.CallOptions,
  ): Promise<ComAtprotoServerCreateInviteCodes.Response> {
    return this._client.call(
      'com.atproto.server.createInviteCodes',
      opts?.qp,
      data,
      opts,
    )
  }

  createOrGetAnonAccount(
    data?: ComAtprotoServerCreateOrGetAnonAccount.InputSchema,
    opts?: ComAtprotoServerCreateOrGetAnonAccount.CallOptions,
  ): Promise<ComAtprotoServerCreateOrGetAnonAccount.Response> {
    return this._client.call(
      'com.atproto.server.createOrGetAnonAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  createPostShareImg(
    data?: ComAtprotoServerCreatePostShareImg.InputSchema,
    opts?: ComAtprotoServerCreatePostShareImg.CallOptions,
  ): Promise<ComAtprotoServerCreatePostShareImg.Response> {
    return this._client.call(
      'com.atproto.server.createPostShareImg',
      opts?.qp,
      data,
      opts,
    )
  }

  createSession(
    data?: ComAtprotoServerCreateSession.InputSchema,
    opts?: ComAtprotoServerCreateSession.CallOptions,
  ): Promise<ComAtprotoServerCreateSession.Response> {
    return this._client
      .call('com.atproto.server.createSession', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCreateSession.toKnownErr(e)
      })
  }

  createSubAccount(
    data?: ComAtprotoServerCreateSubAccount.InputSchema,
    opts?: ComAtprotoServerCreateSubAccount.CallOptions,
  ): Promise<ComAtprotoServerCreateSubAccount.Response> {
    return this._client
      .call('com.atproto.server.createSubAccount', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCreateSubAccount.toKnownErr(e)
      })
  }

  createSubAccountSession(
    data?: ComAtprotoServerCreateSubAccountSession.InputSchema,
    opts?: ComAtprotoServerCreateSubAccountSession.CallOptions,
  ): Promise<ComAtprotoServerCreateSubAccountSession.Response> {
    return this._client
      .call('com.atproto.server.createSubAccountSession', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerCreateSubAccountSession.toKnownErr(e)
      })
  }

  deactivateAccount(
    data?: ComAtprotoServerDeactivateAccount.InputSchema,
    opts?: ComAtprotoServerDeactivateAccount.CallOptions,
  ): Promise<ComAtprotoServerDeactivateAccount.Response> {
    return this._client.call(
      'com.atproto.server.deactivateAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  delBindWalletAddress(
    data?: ComAtprotoServerDelBindWalletAddress.InputSchema,
    opts?: ComAtprotoServerDelBindWalletAddress.CallOptions,
  ): Promise<ComAtprotoServerDelBindWalletAddress.Response> {
    return this._client
      .call('com.atproto.server.delBindWalletAddress', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerDelBindWalletAddress.toKnownErr(e)
      })
  }

  deleteAccount(
    data?: ComAtprotoServerDeleteAccount.InputSchema,
    opts?: ComAtprotoServerDeleteAccount.CallOptions,
  ): Promise<ComAtprotoServerDeleteAccount.Response> {
    return this._client
      .call('com.atproto.server.deleteAccount', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerDeleteAccount.toKnownErr(e)
      })
  }

  deleteSession(
    data?: ComAtprotoServerDeleteSession.InputSchema,
    opts?: ComAtprotoServerDeleteSession.CallOptions,
  ): Promise<ComAtprotoServerDeleteSession.Response> {
    return this._client.call(
      'com.atproto.server.deleteSession',
      opts?.qp,
      data,
      opts,
    )
  }

  deleteSubAccount(
    data?: ComAtprotoServerDeleteSubAccount.InputSchema,
    opts?: ComAtprotoServerDeleteSubAccount.CallOptions,
  ): Promise<ComAtprotoServerDeleteSubAccount.Response> {
    return this._client.call(
      'com.atproto.server.deleteSubAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  describeServer(
    params?: ComAtprotoServerDescribeServer.QueryParams,
    opts?: ComAtprotoServerDescribeServer.CallOptions,
  ): Promise<ComAtprotoServerDescribeServer.Response> {
    return this._client.call(
      'com.atproto.server.describeServer',
      params,
      undefined,
      opts,
    )
  }

  friendAddReq(
    data?: ComAtprotoServerFriendAddReq.InputSchema,
    opts?: ComAtprotoServerFriendAddReq.CallOptions,
  ): Promise<ComAtprotoServerFriendAddReq.Response> {
    return this._client.call(
      'com.atproto.server.friendAddReq',
      opts?.qp,
      data,
      opts,
    )
  }

  friendAgreeReq(
    data?: ComAtprotoServerFriendAgreeReq.InputSchema,
    opts?: ComAtprotoServerFriendAgreeReq.CallOptions,
  ): Promise<ComAtprotoServerFriendAgreeReq.Response> {
    return this._client.call(
      'com.atproto.server.friendAgreeReq',
      opts?.qp,
      data,
      opts,
    )
  }

  friendQueryIsFriend(
    params?: ComAtprotoServerFriendQueryIsFriend.QueryParams,
    opts?: ComAtprotoServerFriendQueryIsFriend.CallOptions,
  ): Promise<ComAtprotoServerFriendQueryIsFriend.Response> {
    return this._client.call(
      'com.atproto.server.friendQueryIsFriend',
      params,
      undefined,
      opts,
    )
  }

  friendQueryPendingReqNum(
    params?: ComAtprotoServerFriendQueryPendingReqNum.QueryParams,
    opts?: ComAtprotoServerFriendQueryPendingReqNum.CallOptions,
  ): Promise<ComAtprotoServerFriendQueryPendingReqNum.Response> {
    return this._client.call(
      'com.atproto.server.friendQueryPendingReqNum',
      params,
      undefined,
      opts,
    )
  }

  friendQueryRecommends(
    params?: ComAtprotoServerFriendQueryRecommends.QueryParams,
    opts?: ComAtprotoServerFriendQueryRecommends.CallOptions,
  ): Promise<ComAtprotoServerFriendQueryRecommends.Response> {
    return this._client.call(
      'com.atproto.server.friendQueryRecommends',
      params,
      undefined,
      opts,
    )
  }

  friendQueryReqs(
    params?: ComAtprotoServerFriendQueryReqs.QueryParams,
    opts?: ComAtprotoServerFriendQueryReqs.CallOptions,
  ): Promise<ComAtprotoServerFriendQueryReqs.Response> {
    return this._client.call(
      'com.atproto.server.friendQueryReqs',
      params,
      undefined,
      opts,
    )
  }

  getAccountInviteCodes(
    params?: ComAtprotoServerGetAccountInviteCodes.QueryParams,
    opts?: ComAtprotoServerGetAccountInviteCodes.CallOptions,
  ): Promise<ComAtprotoServerGetAccountInviteCodes.Response> {
    return this._client
      .call('com.atproto.server.getAccountInviteCodes', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoServerGetAccountInviteCodes.toKnownErr(e)
      })
  }

  getAccountList(
    params?: ComAtprotoServerGetAccountList.QueryParams,
    opts?: ComAtprotoServerGetAccountList.CallOptions,
  ): Promise<ComAtprotoServerGetAccountList.Response> {
    return this._client.call(
      'com.atproto.server.getAccountList',
      params,
      undefined,
      opts,
    )
  }

  getAccountSettings(
    params?: ComAtprotoServerGetAccountSettings.QueryParams,
    opts?: ComAtprotoServerGetAccountSettings.CallOptions,
  ): Promise<ComAtprotoServerGetAccountSettings.Response> {
    return this._client.call(
      'com.atproto.server.getAccountSettings',
      params,
      undefined,
      opts,
    )
  }

  getActorMagnification(
    params?: ComAtprotoServerGetActorMagnification.QueryParams,
    opts?: ComAtprotoServerGetActorMagnification.CallOptions,
  ): Promise<ComAtprotoServerGetActorMagnification.Response> {
    return this._client.call(
      'com.atproto.server.getActorMagnification',
      params,
      undefined,
      opts,
    )
  }

  getBetTransAmountDetails(
    params?: ComAtprotoServerGetBetTransAmountDetails.QueryParams,
    opts?: ComAtprotoServerGetBetTransAmountDetails.CallOptions,
  ): Promise<ComAtprotoServerGetBetTransAmountDetails.Response> {
    return this._client.call(
      'com.atproto.server.getBetTransAmountDetails',
      params,
      undefined,
      opts,
    )
  }

  getBetTransScoreDetails(
    params?: ComAtprotoServerGetBetTransScoreDetails.QueryParams,
    opts?: ComAtprotoServerGetBetTransScoreDetails.CallOptions,
  ): Promise<ComAtprotoServerGetBetTransScoreDetails.Response> {
    return this._client.call(
      'com.atproto.server.getBetTransScoreDetails',
      params,
      undefined,
      opts,
    )
  }

  getBetTransSummary(
    params?: ComAtprotoServerGetBetTransSummary.QueryParams,
    opts?: ComAtprotoServerGetBetTransSummary.CallOptions,
  ): Promise<ComAtprotoServerGetBetTransSummary.Response> {
    return this._client.call(
      'com.atproto.server.getBetTransSummary',
      params,
      undefined,
      opts,
    )
  }

  getBindTg(
    params?: ComAtprotoServerGetBindTg.QueryParams,
    opts?: ComAtprotoServerGetBindTg.CallOptions,
  ): Promise<ComAtprotoServerGetBindTg.Response> {
    return this._client.call(
      'com.atproto.server.getBindTg',
      params,
      undefined,
      opts,
    )
  }

  getBindTwitter(
    params?: ComAtprotoServerGetBindTwitter.QueryParams,
    opts?: ComAtprotoServerGetBindTwitter.CallOptions,
  ): Promise<ComAtprotoServerGetBindTwitter.Response> {
    return this._client.call(
      'com.atproto.server.getBindTwitter',
      params,
      undefined,
      opts,
    )
  }

  getBindWalletAddressMsg(
    params?: ComAtprotoServerGetBindWalletAddressMsg.QueryParams,
    opts?: ComAtprotoServerGetBindWalletAddressMsg.CallOptions,
  ): Promise<ComAtprotoServerGetBindWalletAddressMsg.Response> {
    return this._client.call(
      'com.atproto.server.getBindWalletAddressMsg',
      params,
      undefined,
      opts,
    )
  }

  getCircleInfo(
    params?: ComAtprotoServerGetCircleInfo.QueryParams,
    opts?: ComAtprotoServerGetCircleInfo.CallOptions,
  ): Promise<ComAtprotoServerGetCircleInfo.Response> {
    return this._client.call(
      'com.atproto.server.getCircleInfo',
      params,
      undefined,
      opts,
    )
  }

  getCircleList(
    params?: ComAtprotoServerGetCircleList.QueryParams,
    opts?: ComAtprotoServerGetCircleList.CallOptions,
  ): Promise<ComAtprotoServerGetCircleList.Response> {
    return this._client.call(
      'com.atproto.server.getCircleList',
      params,
      undefined,
      opts,
    )
  }

  getCircleUsers(
    params?: ComAtprotoServerGetCircleUsers.QueryParams,
    opts?: ComAtprotoServerGetCircleUsers.CallOptions,
  ): Promise<ComAtprotoServerGetCircleUsers.Response> {
    return this._client.call(
      'com.atproto.server.getCircleUsers',
      params,
      undefined,
      opts,
    )
  }

  getCirclesByName(
    params?: ComAtprotoServerGetCirclesByName.QueryParams,
    opts?: ComAtprotoServerGetCirclesByName.CallOptions,
  ): Promise<ComAtprotoServerGetCirclesByName.Response> {
    return this._client.call(
      'com.atproto.server.getCirclesByName',
      params,
      undefined,
      opts,
    )
  }

  getConnections(
    data?: ComAtprotoServerGetConnections.InputSchema,
    opts?: ComAtprotoServerGetConnections.CallOptions,
  ): Promise<ComAtprotoServerGetConnections.Response> {
    return this._client.call(
      'com.atproto.server.getConnections',
      opts?.qp,
      data,
      opts,
    )
  }

  getCredit(
    params?: ComAtprotoServerGetCredit.QueryParams,
    opts?: ComAtprotoServerGetCredit.CallOptions,
  ): Promise<ComAtprotoServerGetCredit.Response> {
    return this._client.call(
      'com.atproto.server.getCredit',
      params,
      undefined,
      opts,
    )
  }

  getCreditDetail(
    params?: ComAtprotoServerGetCreditDetail.QueryParams,
    opts?: ComAtprotoServerGetCreditDetail.CallOptions,
  ): Promise<ComAtprotoServerGetCreditDetail.Response> {
    return this._client.call(
      'com.atproto.server.getCreditDetail',
      params,
      undefined,
      opts,
    )
  }

  getDailyTask(
    params?: ComAtprotoServerGetDailyTask.QueryParams,
    opts?: ComAtprotoServerGetDailyTask.CallOptions,
  ): Promise<ComAtprotoServerGetDailyTask.Response> {
    return this._client.call(
      'com.atproto.server.getDailyTask',
      params,
      undefined,
      opts,
    )
  }

  getDailyTaskMoney(
    params?: ComAtprotoServerGetDailyTaskMoney.QueryParams,
    opts?: ComAtprotoServerGetDailyTaskMoney.CallOptions,
  ): Promise<ComAtprotoServerGetDailyTaskMoney.Response> {
    return this._client.call(
      'com.atproto.server.getDailyTaskMoney',
      params,
      undefined,
      opts,
    )
  }

  getDonateTransDetails(
    params?: ComAtprotoServerGetDonateTransDetails.QueryParams,
    opts?: ComAtprotoServerGetDonateTransDetails.CallOptions,
  ): Promise<ComAtprotoServerGetDonateTransDetails.Response> {
    return this._client.call(
      'com.atproto.server.getDonateTransDetails',
      params,
      undefined,
      opts,
    )
  }

  getDonateTransSummary(
    params?: ComAtprotoServerGetDonateTransSummary.QueryParams,
    opts?: ComAtprotoServerGetDonateTransSummary.CallOptions,
  ): Promise<ComAtprotoServerGetDonateTransSummary.Response> {
    return this._client.call(
      'com.atproto.server.getDonateTransSummary',
      params,
      undefined,
      opts,
    )
  }

  getLabelTagActive(
    params?: ComAtprotoServerGetLabelTagActive.QueryParams,
    opts?: ComAtprotoServerGetLabelTagActive.CallOptions,
  ): Promise<ComAtprotoServerGetLabelTagActive.Response> {
    return this._client.call(
      'com.atproto.server.getLabelTagActive',
      params,
      undefined,
      opts,
    )
  }

  getNotificationCount(
    params?: ComAtprotoServerGetNotificationCount.QueryParams,
    opts?: ComAtprotoServerGetNotificationCount.CallOptions,
  ): Promise<ComAtprotoServerGetNotificationCount.Response> {
    return this._client.call(
      'com.atproto.server.getNotificationCount',
      params,
      undefined,
      opts,
    )
  }

  getPaymentWalletAddress(
    params?: ComAtprotoServerGetPaymentWalletAddress.QueryParams,
    opts?: ComAtprotoServerGetPaymentWalletAddress.CallOptions,
  ): Promise<ComAtprotoServerGetPaymentWalletAddress.Response> {
    return this._client.call(
      'com.atproto.server.getPaymentWalletAddress',
      params,
      undefined,
      opts,
    )
  }

  getPostShareImg(
    params?: ComAtprotoServerGetPostShareImg.QueryParams,
    opts?: ComAtprotoServerGetPostShareImg.CallOptions,
  ): Promise<ComAtprotoServerGetPostShareImg.Response> {
    return this._client.call(
      'com.atproto.server.getPostShareImg',
      params,
      undefined,
      opts,
    )
  }

  getRandom(
    data?: ComAtprotoServerGetRandom.InputSchema,
    opts?: ComAtprotoServerGetRandom.CallOptions,
  ): Promise<ComAtprotoServerGetRandom.Response> {
    return this._client
      .call('com.atproto.server.getRandom', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerGetRandom.toKnownErr(e)
      })
  }

  getReceiveAddress(
    params?: ComAtprotoServerGetReceiveAddress.QueryParams,
    opts?: ComAtprotoServerGetReceiveAddress.CallOptions,
  ): Promise<ComAtprotoServerGetReceiveAddress.Response> {
    return this._client.call(
      'com.atproto.server.getReceiveAddress',
      params,
      undefined,
      opts,
    )
  }

  getRelationShips(
    data?: ComAtprotoServerGetRelationShips.InputSchema,
    opts?: ComAtprotoServerGetRelationShips.CallOptions,
  ): Promise<ComAtprotoServerGetRelationShips.Response> {
    return this._client.call(
      'com.atproto.server.getRelationShips',
      opts?.qp,
      data,
      opts,
    )
  }

  getServiceAuth(
    params?: ComAtprotoServerGetServiceAuth.QueryParams,
    opts?: ComAtprotoServerGetServiceAuth.CallOptions,
  ): Promise<ComAtprotoServerGetServiceAuth.Response> {
    return this._client
      .call('com.atproto.server.getServiceAuth', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoServerGetServiceAuth.toKnownErr(e)
      })
  }

  getSession(
    params?: ComAtprotoServerGetSession.QueryParams,
    opts?: ComAtprotoServerGetSession.CallOptions,
  ): Promise<ComAtprotoServerGetSession.Response> {
    return this._client.call(
      'com.atproto.server.getSession',
      params,
      undefined,
      opts,
    )
  }

  getSharedCircles(
    params?: ComAtprotoServerGetSharedCircles.QueryParams,
    opts?: ComAtprotoServerGetSharedCircles.CallOptions,
  ): Promise<ComAtprotoServerGetSharedCircles.Response> {
    return this._client.call(
      'com.atproto.server.getSharedCircles',
      params,
      undefined,
      opts,
    )
  }

  getSharedFriends(
    params?: ComAtprotoServerGetSharedFriends.QueryParams,
    opts?: ComAtprotoServerGetSharedFriends.CallOptions,
  ): Promise<ComAtprotoServerGetSharedFriends.Response> {
    return this._client.call(
      'com.atproto.server.getSharedFriends',
      params,
      undefined,
      opts,
    )
  }

  getSpizFriends(
    params?: ComAtprotoServerGetSpizFriends.QueryParams,
    opts?: ComAtprotoServerGetSpizFriends.CallOptions,
  ): Promise<ComAtprotoServerGetSpizFriends.Response> {
    return this._client.call(
      'com.atproto.server.getSpizFriends',
      params,
      undefined,
      opts,
    )
  }

  getStakeTransDetails(
    params?: ComAtprotoServerGetStakeTransDetails.QueryParams,
    opts?: ComAtprotoServerGetStakeTransDetails.CallOptions,
  ): Promise<ComAtprotoServerGetStakeTransDetails.Response> {
    return this._client.call(
      'com.atproto.server.getStakeTransDetails',
      params,
      undefined,
      opts,
    )
  }

  getStakeTransSummary(
    params?: ComAtprotoServerGetStakeTransSummary.QueryParams,
    opts?: ComAtprotoServerGetStakeTransSummary.CallOptions,
  ): Promise<ComAtprotoServerGetStakeTransSummary.Response> {
    return this._client.call(
      'com.atproto.server.getStakeTransSummary',
      params,
      undefined,
      opts,
    )
  }

  getSubAccountLIst(
    params?: ComAtprotoServerGetSubAccountLIst.QueryParams,
    opts?: ComAtprotoServerGetSubAccountLIst.CallOptions,
  ): Promise<ComAtprotoServerGetSubAccountLIst.Response> {
    return this._client.call(
      'com.atproto.server.getSubAccountLIst',
      params,
      undefined,
      opts,
    )
  }

  getSubscriptions(
    params?: ComAtprotoServerGetSubscriptions.QueryParams,
    opts?: ComAtprotoServerGetSubscriptions.CallOptions,
  ): Promise<ComAtprotoServerGetSubscriptions.Response> {
    return this._client.call(
      'com.atproto.server.getSubscriptions',
      params,
      undefined,
      opts,
    )
  }

  getTags(
    data?: ComAtprotoServerGetTags.InputSchema,
    opts?: ComAtprotoServerGetTags.CallOptions,
  ): Promise<ComAtprotoServerGetTags.Response> {
    return this._client.call('com.atproto.server.getTags', opts?.qp, data, opts)
  }

  getTelegramAvatarBlob(
    params?: ComAtprotoServerGetTelegramAvatarBlob.QueryParams,
    opts?: ComAtprotoServerGetTelegramAvatarBlob.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramAvatarBlob.Response> {
    return this._client
      .call('com.atproto.server.getTelegramAvatarBlob', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoServerGetTelegramAvatarBlob.toKnownErr(e)
      })
  }

  getTelegramContactsInfo(
    data?: ComAtprotoServerGetTelegramContactsInfo.InputSchema,
    opts?: ComAtprotoServerGetTelegramContactsInfo.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramContactsInfo.Response> {
    return this._client.call(
      'com.atproto.server.getTelegramContactsInfo',
      opts?.qp,
      data,
      opts,
    )
  }

  getTelegramContactsPage(
    params?: ComAtprotoServerGetTelegramContactsPage.QueryParams,
    opts?: ComAtprotoServerGetTelegramContactsPage.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramContactsPage.Response> {
    return this._client.call(
      'com.atproto.server.getTelegramContactsPage',
      params,
      undefined,
      opts,
    )
  }

  getTelegramContactsWithSpizPage(
    params?: ComAtprotoServerGetTelegramContactsWithSpizPage.QueryParams,
    opts?: ComAtprotoServerGetTelegramContactsWithSpizPage.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramContactsWithSpizPage.Response> {
    return this._client.call(
      'com.atproto.server.getTelegramContactsWithSpizPage',
      params,
      undefined,
      opts,
    )
  }

  getTelegramGroupCircles(
    data?: ComAtprotoServerGetTelegramGroupCircles.InputSchema,
    opts?: ComAtprotoServerGetTelegramGroupCircles.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramGroupCircles.Response> {
    return this._client.call(
      'com.atproto.server.getTelegramGroupCircles',
      opts?.qp,
      data,
      opts,
    )
  }

  getTelegramUserBasicInfo(
    params?: ComAtprotoServerGetTelegramUserBasicInfo.QueryParams,
    opts?: ComAtprotoServerGetTelegramUserBasicInfo.CallOptions,
  ): Promise<ComAtprotoServerGetTelegramUserBasicInfo.Response> {
    return this._client.call(
      'com.atproto.server.getTelegramUserBasicInfo',
      params,
      undefined,
      opts,
    )
  }

  getTopPerformers(
    params?: ComAtprotoServerGetTopPerformers.QueryParams,
    opts?: ComAtprotoServerGetTopPerformers.CallOptions,
  ): Promise<ComAtprotoServerGetTopPerformers.Response> {
    return this._client.call(
      'com.atproto.server.getTopPerformers',
      params,
      undefined,
      opts,
    )
  }

  getTransWalletAddress(
    params?: ComAtprotoServerGetTransWalletAddress.QueryParams,
    opts?: ComAtprotoServerGetTransWalletAddress.CallOptions,
  ): Promise<ComAtprotoServerGetTransWalletAddress.Response> {
    return this._client.call(
      'com.atproto.server.getTransWalletAddress',
      params,
      undefined,
      opts,
    )
  }

  getTranslation(
    params?: ComAtprotoServerGetTranslation.QueryParams,
    opts?: ComAtprotoServerGetTranslation.CallOptions,
  ): Promise<ComAtprotoServerGetTranslation.Response> {
    return this._client.call(
      'com.atproto.server.getTranslation',
      params,
      undefined,
      opts,
    )
  }

  getUserEmail(
    params?: ComAtprotoServerGetUserEmail.QueryParams,
    opts?: ComAtprotoServerGetUserEmail.CallOptions,
  ): Promise<ComAtprotoServerGetUserEmail.Response> {
    return this._client.call(
      'com.atproto.server.getUserEmail',
      params,
      undefined,
      opts,
    )
  }

  getUserEmails(
    params?: ComAtprotoServerGetUserEmails.QueryParams,
    opts?: ComAtprotoServerGetUserEmails.CallOptions,
  ): Promise<ComAtprotoServerGetUserEmails.Response> {
    return this._client.call(
      'com.atproto.server.getUserEmails',
      params,
      undefined,
      opts,
    )
  }

  getUserTags(
    data?: ComAtprotoServerGetUserTags.InputSchema,
    opts?: ComAtprotoServerGetUserTags.CallOptions,
  ): Promise<ComAtprotoServerGetUserTags.Response> {
    return this._client.call(
      'com.atproto.server.getUserTags',
      opts?.qp,
      data,
      opts,
    )
  }

  getUserTransRecords(
    params?: ComAtprotoServerGetUserTransRecords.QueryParams,
    opts?: ComAtprotoServerGetUserTransRecords.CallOptions,
  ): Promise<ComAtprotoServerGetUserTransRecords.Response> {
    return this._client.call(
      'com.atproto.server.getUserTransRecords',
      params,
      undefined,
      opts,
    )
  }

  getUsersByTags(
    data?: ComAtprotoServerGetUsersByTags.InputSchema,
    opts?: ComAtprotoServerGetUsersByTags.CallOptions,
  ): Promise<ComAtprotoServerGetUsersByTags.Response> {
    return this._client.call(
      'com.atproto.server.getUsersByTags',
      opts?.qp,
      data,
      opts,
    )
  }

  getWithdrawBalance(
    params?: ComAtprotoServerGetWithdrawBalance.QueryParams,
    opts?: ComAtprotoServerGetWithdrawBalance.CallOptions,
  ): Promise<ComAtprotoServerGetWithdrawBalance.Response> {
    return this._client.call(
      'com.atproto.server.getWithdrawBalance',
      params,
      undefined,
      opts,
    )
  }

  getWithdrawRecord(
    params?: ComAtprotoServerGetWithdrawRecord.QueryParams,
    opts?: ComAtprotoServerGetWithdrawRecord.CallOptions,
  ): Promise<ComAtprotoServerGetWithdrawRecord.Response> {
    return this._client.call(
      'com.atproto.server.getWithdrawRecord',
      params,
      undefined,
      opts,
    )
  }

  getWithdrawRecords(
    params?: ComAtprotoServerGetWithdrawRecords.QueryParams,
    opts?: ComAtprotoServerGetWithdrawRecords.CallOptions,
  ): Promise<ComAtprotoServerGetWithdrawRecords.Response> {
    return this._client.call(
      'com.atproto.server.getWithdrawRecords',
      params,
      undefined,
      opts,
    )
  }

  inviteTelegramUsers(
    data?: ComAtprotoServerInviteTelegramUsers.InputSchema,
    opts?: ComAtprotoServerInviteTelegramUsers.CallOptions,
  ): Promise<ComAtprotoServerInviteTelegramUsers.Response> {
    return this._client.call(
      'com.atproto.server.inviteTelegramUsers',
      opts?.qp,
      data,
      opts,
    )
  }

  listAppPasswords(
    params?: ComAtprotoServerListAppPasswords.QueryParams,
    opts?: ComAtprotoServerListAppPasswords.CallOptions,
  ): Promise<ComAtprotoServerListAppPasswords.Response> {
    return this._client
      .call('com.atproto.server.listAppPasswords', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoServerListAppPasswords.toKnownErr(e)
      })
  }

  login(
    data?: ComAtprotoServerLogin.InputSchema,
    opts?: ComAtprotoServerLogin.CallOptions,
  ): Promise<ComAtprotoServerLogin.Response> {
    return this._client
      .call('com.atproto.server.login', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerLogin.toKnownErr(e)
      })
  }

  loginUseTg(
    data?: ComAtprotoServerLoginUseTg.InputSchema,
    opts?: ComAtprotoServerLoginUseTg.CallOptions,
  ): Promise<ComAtprotoServerLoginUseTg.Response> {
    return this._client
      .call('com.atproto.server.loginUseTg', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerLoginUseTg.toKnownErr(e)
      })
  }

  postVisibilityControl(
    data?: ComAtprotoServerPostVisibilityControl.InputSchema,
    opts?: ComAtprotoServerPostVisibilityControl.CallOptions,
  ): Promise<ComAtprotoServerPostVisibilityControl.Response> {
    return this._client.call(
      'com.atproto.server.postVisibilityControl',
      opts?.qp,
      data,
      opts,
    )
  }

  prepareTransaction(
    data?: ComAtprotoServerPrepareTransaction.InputSchema,
    opts?: ComAtprotoServerPrepareTransaction.CallOptions,
  ): Promise<ComAtprotoServerPrepareTransaction.Response> {
    return this._client.call(
      'com.atproto.server.prepareTransaction',
      opts?.qp,
      data,
      opts,
    )
  }

  queryTgBindResult(
    data?: ComAtprotoServerQueryTgBindResult.InputSchema,
    opts?: ComAtprotoServerQueryTgBindResult.CallOptions,
  ): Promise<ComAtprotoServerQueryTgBindResult.Response> {
    return this._client.call(
      'com.atproto.server.queryTgBindResult',
      opts?.qp,
      data,
      opts,
    )
  }

  refreshSession(
    data?: ComAtprotoServerRefreshSession.InputSchema,
    opts?: ComAtprotoServerRefreshSession.CallOptions,
  ): Promise<ComAtprotoServerRefreshSession.Response> {
    return this._client
      .call('com.atproto.server.refreshSession', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerRefreshSession.toKnownErr(e)
      })
  }

  requestAccountDelete(
    data?: ComAtprotoServerRequestAccountDelete.InputSchema,
    opts?: ComAtprotoServerRequestAccountDelete.CallOptions,
  ): Promise<ComAtprotoServerRequestAccountDelete.Response> {
    return this._client.call(
      'com.atproto.server.requestAccountDelete',
      opts?.qp,
      data,
      opts,
    )
  }

  requestEmailConfirmation(
    data?: ComAtprotoServerRequestEmailConfirmation.InputSchema,
    opts?: ComAtprotoServerRequestEmailConfirmation.CallOptions,
  ): Promise<ComAtprotoServerRequestEmailConfirmation.Response> {
    return this._client.call(
      'com.atproto.server.requestEmailConfirmation',
      opts?.qp,
      data,
      opts,
    )
  }

  requestEmailUpdate(
    data?: ComAtprotoServerRequestEmailUpdate.InputSchema,
    opts?: ComAtprotoServerRequestEmailUpdate.CallOptions,
  ): Promise<ComAtprotoServerRequestEmailUpdate.Response> {
    return this._client.call(
      'com.atproto.server.requestEmailUpdate',
      opts?.qp,
      data,
      opts,
    )
  }

  requestPasswordReset(
    data?: ComAtprotoServerRequestPasswordReset.InputSchema,
    opts?: ComAtprotoServerRequestPasswordReset.CallOptions,
  ): Promise<ComAtprotoServerRequestPasswordReset.Response> {
    return this._client.call(
      'com.atproto.server.requestPasswordReset',
      opts?.qp,
      data,
      opts,
    )
  }

  reserveSigningKey(
    data?: ComAtprotoServerReserveSigningKey.InputSchema,
    opts?: ComAtprotoServerReserveSigningKey.CallOptions,
  ): Promise<ComAtprotoServerReserveSigningKey.Response> {
    return this._client.call(
      'com.atproto.server.reserveSigningKey',
      opts?.qp,
      data,
      opts,
    )
  }

  resetPassword(
    data?: ComAtprotoServerResetPassword.InputSchema,
    opts?: ComAtprotoServerResetPassword.CallOptions,
  ): Promise<ComAtprotoServerResetPassword.Response> {
    return this._client
      .call('com.atproto.server.resetPassword', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerResetPassword.toKnownErr(e)
      })
  }

  revokeAppPassword(
    data?: ComAtprotoServerRevokeAppPassword.InputSchema,
    opts?: ComAtprotoServerRevokeAppPassword.CallOptions,
  ): Promise<ComAtprotoServerRevokeAppPassword.Response> {
    return this._client.call(
      'com.atproto.server.revokeAppPassword',
      opts?.qp,
      data,
      opts,
    )
  }

  saveMaskWalletAddress(
    data?: ComAtprotoServerSaveMaskWalletAddress.InputSchema,
    opts?: ComAtprotoServerSaveMaskWalletAddress.CallOptions,
  ): Promise<ComAtprotoServerSaveMaskWalletAddress.Response> {
    return this._client
      .call('com.atproto.server.saveMaskWalletAddress', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerSaveMaskWalletAddress.toKnownErr(e)
      })
  }

  saveReceiveAddress(
    data?: ComAtprotoServerSaveReceiveAddress.InputSchema,
    opts?: ComAtprotoServerSaveReceiveAddress.CallOptions,
  ): Promise<ComAtprotoServerSaveReceiveAddress.Response> {
    return this._client.call(
      'com.atproto.server.saveReceiveAddress',
      opts?.qp,
      data,
      opts,
    )
  }

  saveTgLoginUser(
    data?: ComAtprotoServerSaveTgLoginUser.InputSchema,
    opts?: ComAtprotoServerSaveTgLoginUser.CallOptions,
  ): Promise<ComAtprotoServerSaveTgLoginUser.Response> {
    return this._client
      .call('com.atproto.server.saveTgLoginUser', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerSaveTgLoginUser.toKnownErr(e)
      })
  }

  sdnAppToken(
    data?: ComAtprotoServerSdnAppToken.InputSchema,
    opts?: ComAtprotoServerSdnAppToken.CallOptions,
  ): Promise<ComAtprotoServerSdnAppToken.Response> {
    return this._client
      .call('com.atproto.server.sdnAppToken', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerSdnAppToken.toKnownErr(e)
      })
  }

  sendBotCommMsg(
    data?: ComAtprotoServerSendBotCommMsg.InputSchema,
    opts?: ComAtprotoServerSendBotCommMsg.CallOptions,
  ): Promise<ComAtprotoServerSendBotCommMsg.Response> {
    return this._client.call(
      'com.atproto.server.sendBotCommMsg',
      opts?.qp,
      data,
      opts,
    )
  }

  sendEmailCodes(
    data?: ComAtprotoServerSendEmailCodes.InputSchema,
    opts?: ComAtprotoServerSendEmailCodes.CallOptions,
  ): Promise<ComAtprotoServerSendEmailCodes.Response> {
    return this._client.call(
      'com.atproto.server.sendEmailCodes',
      opts?.qp,
      data,
      opts,
    )
  }

  sendSipzBotNotification(
    data?: ComAtprotoServerSendSipzBotNotification.InputSchema,
    opts?: ComAtprotoServerSendSipzBotNotification.CallOptions,
  ): Promise<ComAtprotoServerSendSipzBotNotification.Response> {
    return this._client.call(
      'com.atproto.server.sendSipzBotNotification',
      opts?.qp,
      data,
      opts,
    )
  }

  sendUserEmailCode(
    data?: ComAtprotoServerSendUserEmailCode.InputSchema,
    opts?: ComAtprotoServerSendUserEmailCode.CallOptions,
  ): Promise<ComAtprotoServerSendUserEmailCode.Response> {
    return this._client.call(
      'com.atproto.server.sendUserEmailCode',
      opts?.qp,
      data,
      opts,
    )
  }

  setDefaultAccount(
    data?: ComAtprotoServerSetDefaultAccount.InputSchema,
    opts?: ComAtprotoServerSetDefaultAccount.CallOptions,
  ): Promise<ComAtprotoServerSetDefaultAccount.Response> {
    return this._client
      .call('com.atproto.server.setDefaultAccount', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerSetDefaultAccount.toKnownErr(e)
      })
  }

  setPaymentWallet(
    data?: ComAtprotoServerSetPaymentWallet.InputSchema,
    opts?: ComAtprotoServerSetPaymentWallet.CallOptions,
  ): Promise<ComAtprotoServerSetPaymentWallet.Response> {
    return this._client
      .call('com.atproto.server.setPaymentWallet', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerSetPaymentWallet.toKnownErr(e)
      })
  }

  storeAccountSettings(
    data?: ComAtprotoServerStoreAccountSettings.InputSchema,
    opts?: ComAtprotoServerStoreAccountSettings.CallOptions,
  ): Promise<ComAtprotoServerStoreAccountSettings.Response> {
    return this._client.call(
      'com.atproto.server.storeAccountSettings',
      opts?.qp,
      data,
      opts,
    )
  }

  storeCredit(
    data?: ComAtprotoServerStoreCredit.InputSchema,
    opts?: ComAtprotoServerStoreCredit.CallOptions,
  ): Promise<ComAtprotoServerStoreCredit.Response> {
    return this._client.call(
      'com.atproto.server.storeCredit',
      opts?.qp,
      data,
      opts,
    )
  }

  storeDailyTask(
    data?: ComAtprotoServerStoreDailyTask.InputSchema,
    opts?: ComAtprotoServerStoreDailyTask.CallOptions,
  ): Promise<ComAtprotoServerStoreDailyTask.Response> {
    return this._client.call(
      'com.atproto.server.storeDailyTask',
      opts?.qp,
      data,
      opts,
    )
  }

  storeDailyTaskStatus(
    data?: ComAtprotoServerStoreDailyTaskStatus.InputSchema,
    opts?: ComAtprotoServerStoreDailyTaskStatus.CallOptions,
  ): Promise<ComAtprotoServerStoreDailyTaskStatus.Response> {
    return this._client.call(
      'com.atproto.server.storeDailyTaskStatus',
      opts?.qp,
      data,
      opts,
    )
  }

  storeShareConnection(
    data?: ComAtprotoServerStoreShareConnection.InputSchema,
    opts?: ComAtprotoServerStoreShareConnection.CallOptions,
  ): Promise<ComAtprotoServerStoreShareConnection.Response> {
    return this._client.call(
      'com.atproto.server.storeShareConnection',
      opts?.qp,
      data,
      opts,
    )
  }

  storeShareCount(
    data?: ComAtprotoServerStoreShareCount.InputSchema,
    opts?: ComAtprotoServerStoreShareCount.CallOptions,
  ): Promise<ComAtprotoServerStoreShareCount.Response> {
    return this._client.call(
      'com.atproto.server.storeShareCount',
      opts?.qp,
      data,
      opts,
    )
  }

  storeTranslation(
    data?: ComAtprotoServerStoreTranslation.InputSchema,
    opts?: ComAtprotoServerStoreTranslation.CallOptions,
  ): Promise<ComAtprotoServerStoreTranslation.Response> {
    return this._client.call(
      'com.atproto.server.storeTranslation',
      opts?.qp,
      data,
      opts,
    )
  }

  syncParentId(
    data?: ComAtprotoServerSyncParentId.InputSchema,
    opts?: ComAtprotoServerSyncParentId.CallOptions,
  ): Promise<ComAtprotoServerSyncParentId.Response> {
    return this._client.call(
      'com.atproto.server.syncParentId',
      opts?.qp,
      data,
      opts,
    )
  }

  tagAdd(
    data?: ComAtprotoServerTagAdd.InputSchema,
    opts?: ComAtprotoServerTagAdd.CallOptions,
  ): Promise<ComAtprotoServerTagAdd.Response> {
    return this._client.call('com.atproto.server.tagAdd', opts?.qp, data, opts)
  }

  tagDelete(
    data?: ComAtprotoServerTagDelete.InputSchema,
    opts?: ComAtprotoServerTagDelete.CallOptions,
  ): Promise<ComAtprotoServerTagDelete.Response> {
    return this._client.call(
      'com.atproto.server.tagDelete',
      opts?.qp,
      data,
      opts,
    )
  }

  tagUpdate(
    data?: ComAtprotoServerTagUpdate.InputSchema,
    opts?: ComAtprotoServerTagUpdate.CallOptions,
  ): Promise<ComAtprotoServerTagUpdate.Response> {
    return this._client.call(
      'com.atproto.server.tagUpdate',
      opts?.qp,
      data,
      opts,
    )
  }

  tagUserAdd(
    data?: ComAtprotoServerTagUserAdd.InputSchema,
    opts?: ComAtprotoServerTagUserAdd.CallOptions,
  ): Promise<ComAtprotoServerTagUserAdd.Response> {
    return this._client.call(
      'com.atproto.server.tagUserAdd',
      opts?.qp,
      data,
      opts,
    )
  }

  tagUserAddTags(
    data?: ComAtprotoServerTagUserAddTags.InputSchema,
    opts?: ComAtprotoServerTagUserAddTags.CallOptions,
  ): Promise<ComAtprotoServerTagUserAddTags.Response> {
    return this._client.call(
      'com.atproto.server.tagUserAddTags',
      opts?.qp,
      data,
      opts,
    )
  }

  tagUsersAdd(
    data?: ComAtprotoServerTagUsersAdd.InputSchema,
    opts?: ComAtprotoServerTagUsersAdd.CallOptions,
  ): Promise<ComAtprotoServerTagUsersAdd.Response> {
    return this._client.call(
      'com.atproto.server.tagUsersAdd',
      opts?.qp,
      data,
      opts,
    )
  }

  tagUsersDelete(
    data?: ComAtprotoServerTagUsersDelete.InputSchema,
    opts?: ComAtprotoServerTagUsersDelete.CallOptions,
  ): Promise<ComAtprotoServerTagUsersDelete.Response> {
    return this._client.call(
      'com.atproto.server.tagUsersDelete',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramBind(
    data?: ComAtprotoServerTelegramBind.InputSchema,
    opts?: ComAtprotoServerTelegramBind.CallOptions,
  ): Promise<ComAtprotoServerTelegramBind.Response> {
    return this._client.call(
      'com.atproto.server.telegramBind',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramChannelUserForOperation(
    data?: ComAtprotoServerTelegramChannelUserForOperation.InputSchema,
    opts?: ComAtprotoServerTelegramChannelUserForOperation.CallOptions,
  ): Promise<ComAtprotoServerTelegramChannelUserForOperation.Response> {
    return this._client.call(
      'com.atproto.server.telegramChannelUserForOperation',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramChannels(
    data?: ComAtprotoServerTelegramChannels.InputSchema,
    opts?: ComAtprotoServerTelegramChannels.CallOptions,
  ): Promise<ComAtprotoServerTelegramChannels.Response> {
    return this._client.call(
      'com.atproto.server.telegramChannels',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramContacts(
    data?: ComAtprotoServerTelegramContacts.InputSchema,
    opts?: ComAtprotoServerTelegramContacts.CallOptions,
  ): Promise<ComAtprotoServerTelegramContacts.Response> {
    return this._client.call(
      'com.atproto.server.telegramContacts',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramMemberDetail(
    data?: ComAtprotoServerTelegramMemberDetail.InputSchema,
    opts?: ComAtprotoServerTelegramMemberDetail.CallOptions,
  ): Promise<ComAtprotoServerTelegramMemberDetail.Response> {
    return this._client.call(
      'com.atproto.server.telegramMemberDetail',
      opts?.qp,
      data,
      opts,
    )
  }

  telegramParticipants(
    data?: ComAtprotoServerTelegramParticipants.InputSchema,
    opts?: ComAtprotoServerTelegramParticipants.CallOptions,
  ): Promise<ComAtprotoServerTelegramParticipants.Response> {
    return this._client.call(
      'com.atproto.server.telegramParticipants',
      opts?.qp,
      data,
      opts,
    )
  }

  unlinkTg(
    data?: ComAtprotoServerUnlinkTg.InputSchema,
    opts?: ComAtprotoServerUnlinkTg.CallOptions,
  ): Promise<ComAtprotoServerUnlinkTg.Response> {
    return this._client.call(
      'com.atproto.server.unlinkTg',
      opts?.qp,
      data,
      opts,
    )
  }

  unlinkTwitter(
    data?: ComAtprotoServerUnlinkTwitter.InputSchema,
    opts?: ComAtprotoServerUnlinkTwitter.CallOptions,
  ): Promise<ComAtprotoServerUnlinkTwitter.Response> {
    return this._client.call(
      'com.atproto.server.unlinkTwitter',
      opts?.qp,
      data,
      opts,
    )
  }

  updateEmail(
    data?: ComAtprotoServerUpdateEmail.InputSchema,
    opts?: ComAtprotoServerUpdateEmail.CallOptions,
  ): Promise<ComAtprotoServerUpdateEmail.Response> {
    return this._client
      .call('com.atproto.server.updateEmail', opts?.qp, data, opts)
      .catch((e) => {
        throw ComAtprotoServerUpdateEmail.toKnownErr(e)
      })
  }

  updateEntityTagByAdmin(
    params?: ComAtprotoServerUpdateEntityTagByAdmin.QueryParams,
    opts?: ComAtprotoServerUpdateEntityTagByAdmin.CallOptions,
  ): Promise<ComAtprotoServerUpdateEntityTagByAdmin.Response> {
    return this._client.call(
      'com.atproto.server.updateEntityTagByAdmin',
      params,
      undefined,
      opts,
    )
  }

  updateUserEmailStatus(
    data?: ComAtprotoServerUpdateUserEmailStatus.InputSchema,
    opts?: ComAtprotoServerUpdateUserEmailStatus.CallOptions,
  ): Promise<ComAtprotoServerUpdateUserEmailStatus.Response> {
    return this._client.call(
      'com.atproto.server.updateUserEmailStatus',
      opts?.qp,
      data,
      opts,
    )
  }

  uploadBlobImage(
    data?: ComAtprotoServerUploadBlobImage.InputSchema,
    opts?: ComAtprotoServerUploadBlobImage.CallOptions,
  ): Promise<ComAtprotoServerUploadBlobImage.Response> {
    return this._client.call(
      'com.atproto.server.uploadBlobImage',
      opts?.qp,
      data,
      opts,
    )
  }

  validateHandle(
    params?: ComAtprotoServerValidateHandle.QueryParams,
    opts?: ComAtprotoServerValidateHandle.CallOptions,
  ): Promise<ComAtprotoServerValidateHandle.Response> {
    return this._client.call(
      'com.atproto.server.validateHandle',
      params,
      undefined,
      opts,
    )
  }

  withdrawReward(
    data?: ComAtprotoServerWithdrawReward.InputSchema,
    opts?: ComAtprotoServerWithdrawReward.CallOptions,
  ): Promise<ComAtprotoServerWithdrawReward.Response> {
    return this._client.call(
      'com.atproto.server.withdrawReward',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoSyncNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getBlob(
    params?: ComAtprotoSyncGetBlob.QueryParams,
    opts?: ComAtprotoSyncGetBlob.CallOptions,
  ): Promise<ComAtprotoSyncGetBlob.Response> {
    return this._client
      .call('com.atproto.sync.getBlob', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetBlob.toKnownErr(e)
      })
  }

  getBlocks(
    params?: ComAtprotoSyncGetBlocks.QueryParams,
    opts?: ComAtprotoSyncGetBlocks.CallOptions,
  ): Promise<ComAtprotoSyncGetBlocks.Response> {
    return this._client
      .call('com.atproto.sync.getBlocks', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetBlocks.toKnownErr(e)
      })
  }

  getCheckout(
    params?: ComAtprotoSyncGetCheckout.QueryParams,
    opts?: ComAtprotoSyncGetCheckout.CallOptions,
  ): Promise<ComAtprotoSyncGetCheckout.Response> {
    return this._client.call(
      'com.atproto.sync.getCheckout',
      params,
      undefined,
      opts,
    )
  }

  getHead(
    params?: ComAtprotoSyncGetHead.QueryParams,
    opts?: ComAtprotoSyncGetHead.CallOptions,
  ): Promise<ComAtprotoSyncGetHead.Response> {
    return this._client
      .call('com.atproto.sync.getHead', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetHead.toKnownErr(e)
      })
  }

  getLatestCommit(
    params?: ComAtprotoSyncGetLatestCommit.QueryParams,
    opts?: ComAtprotoSyncGetLatestCommit.CallOptions,
  ): Promise<ComAtprotoSyncGetLatestCommit.Response> {
    return this._client
      .call('com.atproto.sync.getLatestCommit', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetLatestCommit.toKnownErr(e)
      })
  }

  getRecord(
    params?: ComAtprotoSyncGetRecord.QueryParams,
    opts?: ComAtprotoSyncGetRecord.CallOptions,
  ): Promise<ComAtprotoSyncGetRecord.Response> {
    return this._client
      .call('com.atproto.sync.getRecord', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetRecord.toKnownErr(e)
      })
  }

  getRepo(
    params?: ComAtprotoSyncGetRepo.QueryParams,
    opts?: ComAtprotoSyncGetRepo.CallOptions,
  ): Promise<ComAtprotoSyncGetRepo.Response> {
    return this._client
      .call('com.atproto.sync.getRepo', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetRepo.toKnownErr(e)
      })
  }

  getRepoStatus(
    params?: ComAtprotoSyncGetRepoStatus.QueryParams,
    opts?: ComAtprotoSyncGetRepoStatus.CallOptions,
  ): Promise<ComAtprotoSyncGetRepoStatus.Response> {
    return this._client
      .call('com.atproto.sync.getRepoStatus', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncGetRepoStatus.toKnownErr(e)
      })
  }

  listBlobs(
    params?: ComAtprotoSyncListBlobs.QueryParams,
    opts?: ComAtprotoSyncListBlobs.CallOptions,
  ): Promise<ComAtprotoSyncListBlobs.Response> {
    return this._client
      .call('com.atproto.sync.listBlobs', params, undefined, opts)
      .catch((e) => {
        throw ComAtprotoSyncListBlobs.toKnownErr(e)
      })
  }

  listRepos(
    params?: ComAtprotoSyncListRepos.QueryParams,
    opts?: ComAtprotoSyncListRepos.CallOptions,
  ): Promise<ComAtprotoSyncListRepos.Response> {
    return this._client.call(
      'com.atproto.sync.listRepos',
      params,
      undefined,
      opts,
    )
  }

  notifyOfUpdate(
    data?: ComAtprotoSyncNotifyOfUpdate.InputSchema,
    opts?: ComAtprotoSyncNotifyOfUpdate.CallOptions,
  ): Promise<ComAtprotoSyncNotifyOfUpdate.Response> {
    return this._client.call(
      'com.atproto.sync.notifyOfUpdate',
      opts?.qp,
      data,
      opts,
    )
  }

  requestCrawl(
    data?: ComAtprotoSyncRequestCrawl.InputSchema,
    opts?: ComAtprotoSyncRequestCrawl.CallOptions,
  ): Promise<ComAtprotoSyncRequestCrawl.Response> {
    return this._client.call(
      'com.atproto.sync.requestCrawl',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ComAtprotoTempNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  checkSignupQueue(
    params?: ComAtprotoTempCheckSignupQueue.QueryParams,
    opts?: ComAtprotoTempCheckSignupQueue.CallOptions,
  ): Promise<ComAtprotoTempCheckSignupQueue.Response> {
    return this._client.call(
      'com.atproto.temp.checkSignupQueue',
      params,
      undefined,
      opts,
    )
  }

  fetchLabels(
    params?: ComAtprotoTempFetchLabels.QueryParams,
    opts?: ComAtprotoTempFetchLabels.CallOptions,
  ): Promise<ComAtprotoTempFetchLabels.Response> {
    return this._client.call(
      'com.atproto.temp.fetchLabels',
      params,
      undefined,
      opts,
    )
  }

  requestPhoneVerification(
    data?: ComAtprotoTempRequestPhoneVerification.InputSchema,
    opts?: ComAtprotoTempRequestPhoneVerification.CallOptions,
  ): Promise<ComAtprotoTempRequestPhoneVerification.Response> {
    return this._client.call(
      'com.atproto.temp.requestPhoneVerification',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class AppNS {
  _client: XrpcClient
  bsky: AppBskyNS

  constructor(client: XrpcClient) {
    this._client = client
    this.bsky = new AppBskyNS(client)
  }
}

export class AppBskyNS {
  _client: XrpcClient
  feed: AppBskyFeedNS
  actor: AppBskyActorNS
  embed: AppBskyEmbedNS
  graph: AppBskyGraphNS
  labeler: AppBskyLabelerNS
  notification: AppBskyNotificationNS
  richtext: AppBskyRichtextNS
  unspecced: AppBskyUnspeccedNS

  constructor(client: XrpcClient) {
    this._client = client
    this.feed = new AppBskyFeedNS(client)
    this.actor = new AppBskyActorNS(client)
    this.embed = new AppBskyEmbedNS(client)
    this.graph = new AppBskyGraphNS(client)
    this.labeler = new AppBskyLabelerNS(client)
    this.notification = new AppBskyNotificationNS(client)
    this.richtext = new AppBskyRichtextNS(client)
    this.unspecced = new AppBskyUnspeccedNS(client)
  }
}

export class AppBskyFeedNS {
  _client: XrpcClient
  bet: BetRecord
  donate: DonateRecord
  friendAgreeRequest: FriendAgreeRequestRecord
  friendRequest: FriendRequestRecord
  generator: GeneratorRecord
  like: LikeRecord
  oppose: OpposeRecord
  post: PostRecord
  repost: RepostRecord
  stake: StakeRecord
  tgFriendBind: TgFriendBindRecord
  threadgate: ThreadgateRecord
  unlock: UnlockRecord
  view: ViewRecord

  constructor(client: XrpcClient) {
    this._client = client
    this.bet = new BetRecord(client)
    this.donate = new DonateRecord(client)
    this.friendAgreeRequest = new FriendAgreeRequestRecord(client)
    this.friendRequest = new FriendRequestRecord(client)
    this.generator = new GeneratorRecord(client)
    this.like = new LikeRecord(client)
    this.oppose = new OpposeRecord(client)
    this.post = new PostRecord(client)
    this.repost = new RepostRecord(client)
    this.stake = new StakeRecord(client)
    this.tgFriendBind = new TgFriendBindRecord(client)
    this.threadgate = new ThreadgateRecord(client)
    this.unlock = new UnlockRecord(client)
    this.view = new ViewRecord(client)
  }

  getPostByTime(
    params?: AppBskyFeedGetPostByTime.QueryParams,
    opts?: AppBskyFeedGetPostByTime.CallOptions,
  ): Promise<AppBskyFeedGetPostByTime.Response> {
    return this._client
      .call('app.bsky.feed.getPostByTime', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetPostByTime.toKnownErr(e)
      })
  }

  queryPostByLabel(
    params?: AppBskyFeedQueryPostByLabel.QueryParams,
    opts?: AppBskyFeedQueryPostByLabel.CallOptions,
  ): Promise<AppBskyFeedQueryPostByLabel.Response> {
    return this._client
      .call('app.bsky.feed.queryPostByLabel', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedQueryPostByLabel.toKnownErr(e)
      })
  }

  describeFeedGenerator(
    params?: AppBskyFeedDescribeFeedGenerator.QueryParams,
    opts?: AppBskyFeedDescribeFeedGenerator.CallOptions,
  ): Promise<AppBskyFeedDescribeFeedGenerator.Response> {
    return this._client.call(
      'app.bsky.feed.describeFeedGenerator',
      params,
      undefined,
      opts,
    )
  }

  getActorEngagements(
    params?: AppBskyFeedGetActorEngagements.QueryParams,
    opts?: AppBskyFeedGetActorEngagements.CallOptions,
  ): Promise<AppBskyFeedGetActorEngagements.Response> {
    return this._client
      .call('app.bsky.feed.getActorEngagements', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetActorEngagements.toKnownErr(e)
      })
  }

  getActorFeeds(
    params?: AppBskyFeedGetActorFeeds.QueryParams,
    opts?: AppBskyFeedGetActorFeeds.CallOptions,
  ): Promise<AppBskyFeedGetActorFeeds.Response> {
    return this._client.call(
      'app.bsky.feed.getActorFeeds',
      params,
      undefined,
      opts,
    )
  }

  getActorLikes(
    params?: AppBskyFeedGetActorLikes.QueryParams,
    opts?: AppBskyFeedGetActorLikes.CallOptions,
  ): Promise<AppBskyFeedGetActorLikes.Response> {
    return this._client
      .call('app.bsky.feed.getActorLikes', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetActorLikes.toKnownErr(e)
      })
  }

  getActorOpposes(
    params?: AppBskyFeedGetActorOpposes.QueryParams,
    opts?: AppBskyFeedGetActorOpposes.CallOptions,
  ): Promise<AppBskyFeedGetActorOpposes.Response> {
    return this._client
      .call('app.bsky.feed.getActorOpposes', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetActorOpposes.toKnownErr(e)
      })
  }

  getAuthorFeed(
    params?: AppBskyFeedGetAuthorFeed.QueryParams,
    opts?: AppBskyFeedGetAuthorFeed.CallOptions,
  ): Promise<AppBskyFeedGetAuthorFeed.Response> {
    return this._client
      .call('app.bsky.feed.getAuthorFeed', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetAuthorFeed.toKnownErr(e)
      })
  }

  getFeed(
    params?: AppBskyFeedGetFeed.QueryParams,
    opts?: AppBskyFeedGetFeed.CallOptions,
  ): Promise<AppBskyFeedGetFeed.Response> {
    return this._client
      .call('app.bsky.feed.getFeed', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetFeed.toKnownErr(e)
      })
  }

  getFeedByTag(
    params?: AppBskyFeedGetFeedByTag.QueryParams,
    opts?: AppBskyFeedGetFeedByTag.CallOptions,
  ): Promise<AppBskyFeedGetFeedByTag.Response> {
    return this._client.call(
      'app.bsky.feed.getFeedByTag',
      params,
      undefined,
      opts,
    )
  }

  getFeedGenerator(
    params?: AppBskyFeedGetFeedGenerator.QueryParams,
    opts?: AppBskyFeedGetFeedGenerator.CallOptions,
  ): Promise<AppBskyFeedGetFeedGenerator.Response> {
    return this._client.call(
      'app.bsky.feed.getFeedGenerator',
      params,
      undefined,
      opts,
    )
  }

  getFeedGenerators(
    params?: AppBskyFeedGetFeedGenerators.QueryParams,
    opts?: AppBskyFeedGetFeedGenerators.CallOptions,
  ): Promise<AppBskyFeedGetFeedGenerators.Response> {
    return this._client.call(
      'app.bsky.feed.getFeedGenerators',
      params,
      undefined,
      opts,
    )
  }

  getFeedSkeleton(
    params?: AppBskyFeedGetFeedSkeleton.QueryParams,
    opts?: AppBskyFeedGetFeedSkeleton.CallOptions,
  ): Promise<AppBskyFeedGetFeedSkeleton.Response> {
    return this._client
      .call('app.bsky.feed.getFeedSkeleton', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetFeedSkeleton.toKnownErr(e)
      })
  }

  getLikes(
    params?: AppBskyFeedGetLikes.QueryParams,
    opts?: AppBskyFeedGetLikes.CallOptions,
  ): Promise<AppBskyFeedGetLikes.Response> {
    return this._client.call('app.bsky.feed.getLikes', params, undefined, opts)
  }

  getListFeed(
    params?: AppBskyFeedGetListFeed.QueryParams,
    opts?: AppBskyFeedGetListFeed.CallOptions,
  ): Promise<AppBskyFeedGetListFeed.Response> {
    return this._client
      .call('app.bsky.feed.getListFeed', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetListFeed.toKnownErr(e)
      })
  }

  getOpposes(
    params?: AppBskyFeedGetOpposes.QueryParams,
    opts?: AppBskyFeedGetOpposes.CallOptions,
  ): Promise<AppBskyFeedGetOpposes.Response> {
    return this._client.call(
      'app.bsky.feed.getOpposes',
      params,
      undefined,
      opts,
    )
  }

  getPostThread(
    params?: AppBskyFeedGetPostThread.QueryParams,
    opts?: AppBskyFeedGetPostThread.CallOptions,
  ): Promise<AppBskyFeedGetPostThread.Response> {
    return this._client
      .call('app.bsky.feed.getPostThread', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedGetPostThread.toKnownErr(e)
      })
  }

  getPosts(
    params?: AppBskyFeedGetPosts.QueryParams,
    opts?: AppBskyFeedGetPosts.CallOptions,
  ): Promise<AppBskyFeedGetPosts.Response> {
    return this._client.call('app.bsky.feed.getPosts', params, undefined, opts)
  }

  getRepostedBy(
    params?: AppBskyFeedGetRepostedBy.QueryParams,
    opts?: AppBskyFeedGetRepostedBy.CallOptions,
  ): Promise<AppBskyFeedGetRepostedBy.Response> {
    return this._client.call(
      'app.bsky.feed.getRepostedBy',
      params,
      undefined,
      opts,
    )
  }

  getSuggestedFeeds(
    params?: AppBskyFeedGetSuggestedFeeds.QueryParams,
    opts?: AppBskyFeedGetSuggestedFeeds.CallOptions,
  ): Promise<AppBskyFeedGetSuggestedFeeds.Response> {
    return this._client.call(
      'app.bsky.feed.getSuggestedFeeds',
      params,
      undefined,
      opts,
    )
  }

  getTimeline(
    params?: AppBskyFeedGetTimeline.QueryParams,
    opts?: AppBskyFeedGetTimeline.CallOptions,
  ): Promise<AppBskyFeedGetTimeline.Response> {
    return this._client.call(
      'app.bsky.feed.getTimeline',
      params,
      undefined,
      opts,
    )
  }

  getUnlock(
    params?: AppBskyFeedGetUnlock.QueryParams,
    opts?: AppBskyFeedGetUnlock.CallOptions,
  ): Promise<AppBskyFeedGetUnlock.Response> {
    return this._client.call('app.bsky.feed.getUnlock', params, undefined, opts)
  }

  searchPosts(
    params?: AppBskyFeedSearchPosts.QueryParams,
    opts?: AppBskyFeedSearchPosts.CallOptions,
  ): Promise<AppBskyFeedSearchPosts.Response> {
    return this._client
      .call('app.bsky.feed.searchPosts', params, undefined, opts)
      .catch((e) => {
        throw AppBskyFeedSearchPosts.toKnownErr(e)
      })
  }

  sendInteractions(
    data?: AppBskyFeedSendInteractions.InputSchema,
    opts?: AppBskyFeedSendInteractions.CallOptions,
  ): Promise<AppBskyFeedSendInteractions.Response> {
    return this._client.call(
      'app.bsky.feed.sendInteractions',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class BetRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedBet.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.bet',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedBet.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.bet',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedBet.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.bet'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.bet', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.bet', ...params },
      { headers },
    )
  }
}

export class DonateRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedDonate.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.donate',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedDonate.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.donate',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedDonate.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.donate'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.donate', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.donate', ...params },
      { headers },
    )
  }
}

export class FriendAgreeRequestRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedFriendAgreeRequest.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.friendAgreeRequest',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyFeedFriendAgreeRequest.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.friendAgreeRequest',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedFriendAgreeRequest.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.friendAgreeRequest'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.friendAgreeRequest', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.friendAgreeRequest', ...params },
      { headers },
    )
  }
}

export class FriendRequestRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedFriendRequest.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.friendRequest',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyFeedFriendRequest.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.friendRequest',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedFriendRequest.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.friendRequest'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.friendRequest', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.friendRequest', ...params },
      { headers },
    )
  }
}

export class GeneratorRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedGenerator.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.generator',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedGenerator.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.generator',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedGenerator.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.generator'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.generator', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.generator', ...params },
      { headers },
    )
  }
}

export class LikeRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedLike.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.like',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedLike.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.like',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedLike.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.like'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.like', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.like', ...params },
      { headers },
    )
  }
}

export class OpposeRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedOppose.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.oppose',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedOppose.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.oppose',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedOppose.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.oppose'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.oppose', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.oppose', ...params },
      { headers },
    )
  }
}

export class PostRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedPost.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.post',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedPost.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.post',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedPost.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.post'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.post', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.post', ...params },
      { headers },
    )
  }
}

export class RepostRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedRepost.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.repost',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedRepost.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.repost',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedRepost.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.repost'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.repost', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.repost', ...params },
      { headers },
    )
  }
}

export class StakeRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedStake.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.stake',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedStake.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.stake',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedStake.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.stake'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.stake', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.stake', ...params },
      { headers },
    )
  }
}

export class TgFriendBindRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedTgFriendBind.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.tgFriendBind',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyFeedTgFriendBind.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.tgFriendBind',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedTgFriendBind.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.tgFriendBind'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.tgFriendBind', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.tgFriendBind', ...params },
      { headers },
    )
  }
}

export class ThreadgateRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedThreadgate.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.threadgate',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyFeedThreadgate.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.threadgate',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedThreadgate.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.threadgate'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.threadgate', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.threadgate', ...params },
      { headers },
    )
  }
}

export class UnlockRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedUnlock.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.unlock',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedUnlock.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.unlock',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedUnlock.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.unlock'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.unlock', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.unlock', ...params },
      { headers },
    )
  }
}

export class ViewRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyFeedView.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.feed.view',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyFeedView.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.feed.view',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyFeedView.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.feed.view'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.feed.view', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.feed.view', ...params },
      { headers },
    )
  }
}

export class AppBskyActorNS {
  _client: XrpcClient
  profile: ProfileRecord

  constructor(client: XrpcClient) {
    this._client = client
    this.profile = new ProfileRecord(client)
  }

  getPreferences(
    params?: AppBskyActorGetPreferences.QueryParams,
    opts?: AppBskyActorGetPreferences.CallOptions,
  ): Promise<AppBskyActorGetPreferences.Response> {
    return this._client.call(
      'app.bsky.actor.getPreferences',
      params,
      undefined,
      opts,
    )
  }

  getProfile(
    params?: AppBskyActorGetProfile.QueryParams,
    opts?: AppBskyActorGetProfile.CallOptions,
  ): Promise<AppBskyActorGetProfile.Response> {
    return this._client.call(
      'app.bsky.actor.getProfile',
      params,
      undefined,
      opts,
    )
  }

  getProfiles(
    params?: AppBskyActorGetProfiles.QueryParams,
    opts?: AppBskyActorGetProfiles.CallOptions,
  ): Promise<AppBskyActorGetProfiles.Response> {
    return this._client.call(
      'app.bsky.actor.getProfiles',
      params,
      undefined,
      opts,
    )
  }

  getSuggestions(
    params?: AppBskyActorGetSuggestions.QueryParams,
    opts?: AppBskyActorGetSuggestions.CallOptions,
  ): Promise<AppBskyActorGetSuggestions.Response> {
    return this._client.call(
      'app.bsky.actor.getSuggestions',
      params,
      undefined,
      opts,
    )
  }

  putPreferences(
    data?: AppBskyActorPutPreferences.InputSchema,
    opts?: AppBskyActorPutPreferences.CallOptions,
  ): Promise<AppBskyActorPutPreferences.Response> {
    return this._client.call(
      'app.bsky.actor.putPreferences',
      opts?.qp,
      data,
      opts,
    )
  }

  searchActors(
    params?: AppBskyActorSearchActors.QueryParams,
    opts?: AppBskyActorSearchActors.CallOptions,
  ): Promise<AppBskyActorSearchActors.Response> {
    return this._client.call(
      'app.bsky.actor.searchActors',
      params,
      undefined,
      opts,
    )
  }

  searchActorsTypeahead(
    params?: AppBskyActorSearchActorsTypeahead.QueryParams,
    opts?: AppBskyActorSearchActorsTypeahead.CallOptions,
  ): Promise<AppBskyActorSearchActorsTypeahead.Response> {
    return this._client.call(
      'app.bsky.actor.searchActorsTypeahead',
      params,
      undefined,
      opts,
    )
  }

  searchTelegramHandle(
    params?: AppBskyActorSearchTelegramHandle.QueryParams,
    opts?: AppBskyActorSearchTelegramHandle.CallOptions,
  ): Promise<AppBskyActorSearchTelegramHandle.Response> {
    return this._client.call(
      'app.bsky.actor.searchTelegramHandle',
      params,
      undefined,
      opts,
    )
  }

  searchTelegrams(
    params?: AppBskyActorSearchTelegrams.QueryParams,
    opts?: AppBskyActorSearchTelegrams.CallOptions,
  ): Promise<AppBskyActorSearchTelegrams.Response> {
    return this._client.call(
      'app.bsky.actor.searchTelegrams',
      params,
      undefined,
      opts,
    )
  }
}

export class ProfileRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyActorProfile.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.actor.profile',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyActorProfile.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.actor.profile',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyActorProfile.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.actor.profile'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.actor.profile', rkey: 'self', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.actor.profile', ...params },
      { headers },
    )
  }
}

export class AppBskyEmbedNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }
}

export class AppBskyGraphNS {
  _client: XrpcClient
  block: BlockRecord
  follow: FollowRecord
  guarantee: GuaranteeRecord
  guaranteeJoin: GuaranteeJoinRecord
  guaranteeUpdate: GuaranteeUpdateRecord
  list: ListRecord
  listblock: ListblockRecord
  listitem: ListitemRecord
  starterpack: StarterpackRecord

  constructor(client: XrpcClient) {
    this._client = client
    this.block = new BlockRecord(client)
    this.follow = new FollowRecord(client)
    this.guarantee = new GuaranteeRecord(client)
    this.guaranteeJoin = new GuaranteeJoinRecord(client)
    this.guaranteeUpdate = new GuaranteeUpdateRecord(client)
    this.list = new ListRecord(client)
    this.listblock = new ListblockRecord(client)
    this.listitem = new ListitemRecord(client)
    this.starterpack = new StarterpackRecord(client)
  }

  getActorStarterPacks(
    params?: AppBskyGraphGetActorStarterPacks.QueryParams,
    opts?: AppBskyGraphGetActorStarterPacks.CallOptions,
  ): Promise<AppBskyGraphGetActorStarterPacks.Response> {
    return this._client.call(
      'app.bsky.graph.getActorStarterPacks',
      params,
      undefined,
      opts,
    )
  }

  getBlocks(
    params?: AppBskyGraphGetBlocks.QueryParams,
    opts?: AppBskyGraphGetBlocks.CallOptions,
  ): Promise<AppBskyGraphGetBlocks.Response> {
    return this._client.call(
      'app.bsky.graph.getBlocks',
      params,
      undefined,
      opts,
    )
  }

  getFollowers(
    params?: AppBskyGraphGetFollowers.QueryParams,
    opts?: AppBskyGraphGetFollowers.CallOptions,
  ): Promise<AppBskyGraphGetFollowers.Response> {
    return this._client.call(
      'app.bsky.graph.getFollowers',
      params,
      undefined,
      opts,
    )
  }

  getFollows(
    params?: AppBskyGraphGetFollows.QueryParams,
    opts?: AppBskyGraphGetFollows.CallOptions,
  ): Promise<AppBskyGraphGetFollows.Response> {
    return this._client.call(
      'app.bsky.graph.getFollows',
      params,
      undefined,
      opts,
    )
  }

  getGuarantee(
    params?: AppBskyGraphGetGuarantee.QueryParams,
    opts?: AppBskyGraphGetGuarantee.CallOptions,
  ): Promise<AppBskyGraphGetGuarantee.Response> {
    return this._client.call(
      'app.bsky.graph.getGuarantee',
      params,
      undefined,
      opts,
    )
  }

  getKnownFollowers(
    params?: AppBskyGraphGetKnownFollowers.QueryParams,
    opts?: AppBskyGraphGetKnownFollowers.CallOptions,
  ): Promise<AppBskyGraphGetKnownFollowers.Response> {
    return this._client.call(
      'app.bsky.graph.getKnownFollowers',
      params,
      undefined,
      opts,
    )
  }

  getList(
    params?: AppBskyGraphGetList.QueryParams,
    opts?: AppBskyGraphGetList.CallOptions,
  ): Promise<AppBskyGraphGetList.Response> {
    return this._client.call('app.bsky.graph.getList', params, undefined, opts)
  }

  getListBlocks(
    params?: AppBskyGraphGetListBlocks.QueryParams,
    opts?: AppBskyGraphGetListBlocks.CallOptions,
  ): Promise<AppBskyGraphGetListBlocks.Response> {
    return this._client.call(
      'app.bsky.graph.getListBlocks',
      params,
      undefined,
      opts,
    )
  }

  getListMutes(
    params?: AppBskyGraphGetListMutes.QueryParams,
    opts?: AppBskyGraphGetListMutes.CallOptions,
  ): Promise<AppBskyGraphGetListMutes.Response> {
    return this._client.call(
      'app.bsky.graph.getListMutes',
      params,
      undefined,
      opts,
    )
  }

  getLists(
    params?: AppBskyGraphGetLists.QueryParams,
    opts?: AppBskyGraphGetLists.CallOptions,
  ): Promise<AppBskyGraphGetLists.Response> {
    return this._client.call('app.bsky.graph.getLists', params, undefined, opts)
  }

  getMutes(
    params?: AppBskyGraphGetMutes.QueryParams,
    opts?: AppBskyGraphGetMutes.CallOptions,
  ): Promise<AppBskyGraphGetMutes.Response> {
    return this._client.call('app.bsky.graph.getMutes', params, undefined, opts)
  }

  getRelationships(
    params?: AppBskyGraphGetRelationships.QueryParams,
    opts?: AppBskyGraphGetRelationships.CallOptions,
  ): Promise<AppBskyGraphGetRelationships.Response> {
    return this._client
      .call('app.bsky.graph.getRelationships', params, undefined, opts)
      .catch((e) => {
        throw AppBskyGraphGetRelationships.toKnownErr(e)
      })
  }

  getStarterPack(
    params?: AppBskyGraphGetStarterPack.QueryParams,
    opts?: AppBskyGraphGetStarterPack.CallOptions,
  ): Promise<AppBskyGraphGetStarterPack.Response> {
    return this._client.call(
      'app.bsky.graph.getStarterPack',
      params,
      undefined,
      opts,
    )
  }

  getStarterPacks(
    params?: AppBskyGraphGetStarterPacks.QueryParams,
    opts?: AppBskyGraphGetStarterPacks.CallOptions,
  ): Promise<AppBskyGraphGetStarterPacks.Response> {
    return this._client.call(
      'app.bsky.graph.getStarterPacks',
      params,
      undefined,
      opts,
    )
  }

  getSuggestedFollowsByActor(
    params?: AppBskyGraphGetSuggestedFollowsByActor.QueryParams,
    opts?: AppBskyGraphGetSuggestedFollowsByActor.CallOptions,
  ): Promise<AppBskyGraphGetSuggestedFollowsByActor.Response> {
    return this._client.call(
      'app.bsky.graph.getSuggestedFollowsByActor',
      params,
      undefined,
      opts,
    )
  }

  muteActor(
    data?: AppBskyGraphMuteActor.InputSchema,
    opts?: AppBskyGraphMuteActor.CallOptions,
  ): Promise<AppBskyGraphMuteActor.Response> {
    return this._client.call('app.bsky.graph.muteActor', opts?.qp, data, opts)
  }

  muteActorList(
    data?: AppBskyGraphMuteActorList.InputSchema,
    opts?: AppBskyGraphMuteActorList.CallOptions,
  ): Promise<AppBskyGraphMuteActorList.Response> {
    return this._client.call(
      'app.bsky.graph.muteActorList',
      opts?.qp,
      data,
      opts,
    )
  }

  muteThread(
    data?: AppBskyGraphMuteThread.InputSchema,
    opts?: AppBskyGraphMuteThread.CallOptions,
  ): Promise<AppBskyGraphMuteThread.Response> {
    return this._client.call('app.bsky.graph.muteThread', opts?.qp, data, opts)
  }

  unmuteActor(
    data?: AppBskyGraphUnmuteActor.InputSchema,
    opts?: AppBskyGraphUnmuteActor.CallOptions,
  ): Promise<AppBskyGraphUnmuteActor.Response> {
    return this._client.call('app.bsky.graph.unmuteActor', opts?.qp, data, opts)
  }

  unmuteActorList(
    data?: AppBskyGraphUnmuteActorList.InputSchema,
    opts?: AppBskyGraphUnmuteActorList.CallOptions,
  ): Promise<AppBskyGraphUnmuteActorList.Response> {
    return this._client.call(
      'app.bsky.graph.unmuteActorList',
      opts?.qp,
      data,
      opts,
    )
  }

  unmuteThread(
    data?: AppBskyGraphUnmuteThread.InputSchema,
    opts?: AppBskyGraphUnmuteThread.CallOptions,
  ): Promise<AppBskyGraphUnmuteThread.Response> {
    return this._client.call(
      'app.bsky.graph.unmuteThread',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class BlockRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphBlock.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.block',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyGraphBlock.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.block',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphBlock.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.block'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.block', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.block', ...params },
      { headers },
    )
  }
}

export class FollowRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphFollow.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.follow',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyGraphFollow.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.follow',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphFollow.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.follow'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.follow', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.follow', ...params },
      { headers },
    )
  }
}

export class GuaranteeRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphGuarantee.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.guarantee',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyGraphGuarantee.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.guarantee',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphGuarantee.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.guarantee'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.guarantee', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.guarantee', ...params },
      { headers },
    )
  }
}

export class GuaranteeJoinRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphGuaranteeJoin.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.guaranteeJoin',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyGraphGuaranteeJoin.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.guaranteeJoin',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphGuaranteeJoin.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.guaranteeJoin'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.guaranteeJoin', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.guaranteeJoin', ...params },
      { headers },
    )
  }
}

export class GuaranteeUpdateRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphGuaranteeUpdate.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.guaranteeUpdate',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyGraphGuaranteeUpdate.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.guaranteeUpdate',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphGuaranteeUpdate.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.guaranteeUpdate'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.guaranteeUpdate', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.guaranteeUpdate', ...params },
      { headers },
    )
  }
}

export class ListRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphList.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.list',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyGraphList.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.list',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphList.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.list'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.list', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.list', ...params },
      { headers },
    )
  }
}

export class ListblockRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphListblock.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.listblock',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyGraphListblock.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.listblock',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphListblock.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.listblock'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.listblock', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.listblock', ...params },
      { headers },
    )
  }
}

export class ListitemRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphListitem.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.listitem',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{ uri: string; cid: string; value: AppBskyGraphListitem.Record }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.listitem',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphListitem.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.listitem'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.listitem', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.listitem', ...params },
      { headers },
    )
  }
}

export class StarterpackRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyGraphStarterpack.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.graph.starterpack',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyGraphStarterpack.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.graph.starterpack',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyGraphStarterpack.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.graph.starterpack'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      { collection: 'app.bsky.graph.starterpack', ...params, record },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.graph.starterpack', ...params },
      { headers },
    )
  }
}

export class AppBskyLabelerNS {
  _client: XrpcClient
  service: ServiceRecord

  constructor(client: XrpcClient) {
    this._client = client
    this.service = new ServiceRecord(client)
  }

  getServices(
    params?: AppBskyLabelerGetServices.QueryParams,
    opts?: AppBskyLabelerGetServices.CallOptions,
  ): Promise<AppBskyLabelerGetServices.Response> {
    return this._client.call(
      'app.bsky.labeler.getServices',
      params,
      undefined,
      opts,
    )
  }
}

export class ServiceRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: AppBskyLabelerService.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'app.bsky.labeler.service',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: AppBskyLabelerService.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'app.bsky.labeler.service',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: AppBskyLabelerService.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'app.bsky.labeler.service'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      {
        collection: 'app.bsky.labeler.service',
        rkey: 'self',
        ...params,
        record,
      },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'app.bsky.labeler.service', ...params },
      { headers },
    )
  }
}

export class AppBskyNotificationNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getUnreadCount(
    params?: AppBskyNotificationGetUnreadCount.QueryParams,
    opts?: AppBskyNotificationGetUnreadCount.CallOptions,
  ): Promise<AppBskyNotificationGetUnreadCount.Response> {
    return this._client.call(
      'app.bsky.notification.getUnreadCount',
      params,
      undefined,
      opts,
    )
  }

  listNotifications(
    params?: AppBskyNotificationListNotifications.QueryParams,
    opts?: AppBskyNotificationListNotifications.CallOptions,
  ): Promise<AppBskyNotificationListNotifications.Response> {
    return this._client.call(
      'app.bsky.notification.listNotifications',
      params,
      undefined,
      opts,
    )
  }

  pushNotification(
    data?: AppBskyNotificationPushNotification.InputSchema,
    opts?: AppBskyNotificationPushNotification.CallOptions,
  ): Promise<AppBskyNotificationPushNotification.Response> {
    return this._client.call(
      'app.bsky.notification.pushNotification',
      opts?.qp,
      data,
      opts,
    )
  }

  putPreferences(
    data?: AppBskyNotificationPutPreferences.InputSchema,
    opts?: AppBskyNotificationPutPreferences.CallOptions,
  ): Promise<AppBskyNotificationPutPreferences.Response> {
    return this._client.call(
      'app.bsky.notification.putPreferences',
      opts?.qp,
      data,
      opts,
    )
  }

  registerPush(
    data?: AppBskyNotificationRegisterPush.InputSchema,
    opts?: AppBskyNotificationRegisterPush.CallOptions,
  ): Promise<AppBskyNotificationRegisterPush.Response> {
    return this._client.call(
      'app.bsky.notification.registerPush',
      opts?.qp,
      data,
      opts,
    )
  }

  registerPushOt(
    data?: AppBskyNotificationRegisterPushOt.InputSchema,
    opts?: AppBskyNotificationRegisterPushOt.CallOptions,
  ): Promise<AppBskyNotificationRegisterPushOt.Response> {
    return this._client.call(
      'app.bsky.notification.registerPushOt',
      opts?.qp,
      data,
      opts,
    )
  }

  updateSeen(
    data?: AppBskyNotificationUpdateSeen.InputSchema,
    opts?: AppBskyNotificationUpdateSeen.CallOptions,
  ): Promise<AppBskyNotificationUpdateSeen.Response> {
    return this._client.call(
      'app.bsky.notification.updateSeen',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class AppBskyRichtextNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }
}

export class AppBskyUnspeccedNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getPopularFeedGenerators(
    params?: AppBskyUnspeccedGetPopularFeedGenerators.QueryParams,
    opts?: AppBskyUnspeccedGetPopularFeedGenerators.CallOptions,
  ): Promise<AppBskyUnspeccedGetPopularFeedGenerators.Response> {
    return this._client.call(
      'app.bsky.unspecced.getPopularFeedGenerators',
      params,
      undefined,
      opts,
    )
  }

  getSuggestionsSkeleton(
    params?: AppBskyUnspeccedGetSuggestionsSkeleton.QueryParams,
    opts?: AppBskyUnspeccedGetSuggestionsSkeleton.CallOptions,
  ): Promise<AppBskyUnspeccedGetSuggestionsSkeleton.Response> {
    return this._client.call(
      'app.bsky.unspecced.getSuggestionsSkeleton',
      params,
      undefined,
      opts,
    )
  }

  getTaggedSuggestions(
    params?: AppBskyUnspeccedGetTaggedSuggestions.QueryParams,
    opts?: AppBskyUnspeccedGetTaggedSuggestions.CallOptions,
  ): Promise<AppBskyUnspeccedGetTaggedSuggestions.Response> {
    return this._client.call(
      'app.bsky.unspecced.getTaggedSuggestions',
      params,
      undefined,
      opts,
    )
  }

  searchActorsSkeleton(
    params?: AppBskyUnspeccedSearchActorsSkeleton.QueryParams,
    opts?: AppBskyUnspeccedSearchActorsSkeleton.CallOptions,
  ): Promise<AppBskyUnspeccedSearchActorsSkeleton.Response> {
    return this._client
      .call('app.bsky.unspecced.searchActorsSkeleton', params, undefined, opts)
      .catch((e) => {
        throw AppBskyUnspeccedSearchActorsSkeleton.toKnownErr(e)
      })
  }

  searchPostsSkeleton(
    params?: AppBskyUnspeccedSearchPostsSkeleton.QueryParams,
    opts?: AppBskyUnspeccedSearchPostsSkeleton.CallOptions,
  ): Promise<AppBskyUnspeccedSearchPostsSkeleton.Response> {
    return this._client
      .call('app.bsky.unspecced.searchPostsSkeleton', params, undefined, opts)
      .catch((e) => {
        throw AppBskyUnspeccedSearchPostsSkeleton.toKnownErr(e)
      })
  }
}

export class ChatNS {
  _client: XrpcClient
  bsky: ChatBskyNS

  constructor(client: XrpcClient) {
    this._client = client
    this.bsky = new ChatBskyNS(client)
  }
}

export class ChatBskyNS {
  _client: XrpcClient
  actor: ChatBskyActorNS
  convo: ChatBskyConvoNS
  moderation: ChatBskyModerationNS

  constructor(client: XrpcClient) {
    this._client = client
    this.actor = new ChatBskyActorNS(client)
    this.convo = new ChatBskyConvoNS(client)
    this.moderation = new ChatBskyModerationNS(client)
  }
}

export class ChatBskyActorNS {
  _client: XrpcClient
  declaration: DeclarationRecord

  constructor(client: XrpcClient) {
    this._client = client
    this.declaration = new DeclarationRecord(client)
  }

  deleteAccount(
    data?: ChatBskyActorDeleteAccount.InputSchema,
    opts?: ChatBskyActorDeleteAccount.CallOptions,
  ): Promise<ChatBskyActorDeleteAccount.Response> {
    return this._client.call(
      'chat.bsky.actor.deleteAccount',
      opts?.qp,
      data,
      opts,
    )
  }

  exportAccountData(
    params?: ChatBskyActorExportAccountData.QueryParams,
    opts?: ChatBskyActorExportAccountData.CallOptions,
  ): Promise<ChatBskyActorExportAccountData.Response> {
    return this._client.call(
      'chat.bsky.actor.exportAccountData',
      params,
      undefined,
      opts,
    )
  }
}

export class DeclarationRecord {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  async list(
    params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>,
  ): Promise<{
    cursor?: string
    records: { uri: string; value: ChatBskyActorDeclaration.Record }[]
  }> {
    const res = await this._client.call('com.atproto.repo.listRecords', {
      collection: 'chat.bsky.actor.declaration',
      ...params,
    })
    return res.data
  }

  async get(
    params: Omit<ComAtprotoRepoGetRecord.QueryParams, 'collection'>,
  ): Promise<{
    uri: string
    cid: string
    value: ChatBskyActorDeclaration.Record
  }> {
    const res = await this._client.call('com.atproto.repo.getRecord', {
      collection: 'chat.bsky.actor.declaration',
      ...params,
    })
    return res.data
  }

  async create(
    params: Omit<
      ComAtprotoRepoCreateRecord.InputSchema,
      'collection' | 'record'
    >,
    record: ChatBskyActorDeclaration.Record,
    headers?: Record<string, string>,
  ): Promise<{ uri: string; cid: string }> {
    record.$type = 'chat.bsky.actor.declaration'
    const res = await this._client.call(
      'com.atproto.repo.createRecord',
      undefined,
      {
        collection: 'chat.bsky.actor.declaration',
        rkey: 'self',
        ...params,
        record,
      },
      { encoding: 'application/json', headers },
    )
    return res.data
  }

  async delete(
    params: Omit<ComAtprotoRepoDeleteRecord.InputSchema, 'collection'>,
    headers?: Record<string, string>,
  ): Promise<void> {
    await this._client.call(
      'com.atproto.repo.deleteRecord',
      undefined,
      { collection: 'chat.bsky.actor.declaration', ...params },
      { headers },
    )
  }
}

export class ChatBskyConvoNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  deleteMessageForSelf(
    data?: ChatBskyConvoDeleteMessageForSelf.InputSchema,
    opts?: ChatBskyConvoDeleteMessageForSelf.CallOptions,
  ): Promise<ChatBskyConvoDeleteMessageForSelf.Response> {
    return this._client.call(
      'chat.bsky.convo.deleteMessageForSelf',
      opts?.qp,
      data,
      opts,
    )
  }

  getConvo(
    params?: ChatBskyConvoGetConvo.QueryParams,
    opts?: ChatBskyConvoGetConvo.CallOptions,
  ): Promise<ChatBskyConvoGetConvo.Response> {
    return this._client.call(
      'chat.bsky.convo.getConvo',
      params,
      undefined,
      opts,
    )
  }

  getConvoForMembers(
    params?: ChatBskyConvoGetConvoForMembers.QueryParams,
    opts?: ChatBskyConvoGetConvoForMembers.CallOptions,
  ): Promise<ChatBskyConvoGetConvoForMembers.Response> {
    return this._client.call(
      'chat.bsky.convo.getConvoForMembers',
      params,
      undefined,
      opts,
    )
  }

  getLog(
    params?: ChatBskyConvoGetLog.QueryParams,
    opts?: ChatBskyConvoGetLog.CallOptions,
  ): Promise<ChatBskyConvoGetLog.Response> {
    return this._client.call('chat.bsky.convo.getLog', params, undefined, opts)
  }

  getMessages(
    params?: ChatBskyConvoGetMessages.QueryParams,
    opts?: ChatBskyConvoGetMessages.CallOptions,
  ): Promise<ChatBskyConvoGetMessages.Response> {
    return this._client.call(
      'chat.bsky.convo.getMessages',
      params,
      undefined,
      opts,
    )
  }

  leaveConvo(
    data?: ChatBskyConvoLeaveConvo.InputSchema,
    opts?: ChatBskyConvoLeaveConvo.CallOptions,
  ): Promise<ChatBskyConvoLeaveConvo.Response> {
    return this._client.call('chat.bsky.convo.leaveConvo', opts?.qp, data, opts)
  }

  listConvos(
    params?: ChatBskyConvoListConvos.QueryParams,
    opts?: ChatBskyConvoListConvos.CallOptions,
  ): Promise<ChatBskyConvoListConvos.Response> {
    return this._client.call(
      'chat.bsky.convo.listConvos',
      params,
      undefined,
      opts,
    )
  }

  muteConvo(
    data?: ChatBskyConvoMuteConvo.InputSchema,
    opts?: ChatBskyConvoMuteConvo.CallOptions,
  ): Promise<ChatBskyConvoMuteConvo.Response> {
    return this._client.call('chat.bsky.convo.muteConvo', opts?.qp, data, opts)
  }

  sendMessage(
    data?: ChatBskyConvoSendMessage.InputSchema,
    opts?: ChatBskyConvoSendMessage.CallOptions,
  ): Promise<ChatBskyConvoSendMessage.Response> {
    return this._client.call(
      'chat.bsky.convo.sendMessage',
      opts?.qp,
      data,
      opts,
    )
  }

  sendMessageBatch(
    data?: ChatBskyConvoSendMessageBatch.InputSchema,
    opts?: ChatBskyConvoSendMessageBatch.CallOptions,
  ): Promise<ChatBskyConvoSendMessageBatch.Response> {
    return this._client.call(
      'chat.bsky.convo.sendMessageBatch',
      opts?.qp,
      data,
      opts,
    )
  }

  unmuteConvo(
    data?: ChatBskyConvoUnmuteConvo.InputSchema,
    opts?: ChatBskyConvoUnmuteConvo.CallOptions,
  ): Promise<ChatBskyConvoUnmuteConvo.Response> {
    return this._client.call(
      'chat.bsky.convo.unmuteConvo',
      opts?.qp,
      data,
      opts,
    )
  }

  updateRead(
    data?: ChatBskyConvoUpdateRead.InputSchema,
    opts?: ChatBskyConvoUpdateRead.CallOptions,
  ): Promise<ChatBskyConvoUpdateRead.Response> {
    return this._client.call('chat.bsky.convo.updateRead', opts?.qp, data, opts)
  }
}

export class ChatBskyModerationNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getActorMetadata(
    params?: ChatBskyModerationGetActorMetadata.QueryParams,
    opts?: ChatBskyModerationGetActorMetadata.CallOptions,
  ): Promise<ChatBskyModerationGetActorMetadata.Response> {
    return this._client.call(
      'chat.bsky.moderation.getActorMetadata',
      params,
      undefined,
      opts,
    )
  }

  getMessageContext(
    params?: ChatBskyModerationGetMessageContext.QueryParams,
    opts?: ChatBskyModerationGetMessageContext.CallOptions,
  ): Promise<ChatBskyModerationGetMessageContext.Response> {
    return this._client.call(
      'chat.bsky.moderation.getMessageContext',
      params,
      undefined,
      opts,
    )
  }

  updateActorAccess(
    data?: ChatBskyModerationUpdateActorAccess.InputSchema,
    opts?: ChatBskyModerationUpdateActorAccess.CallOptions,
  ): Promise<ChatBskyModerationUpdateActorAccess.Response> {
    return this._client.call(
      'chat.bsky.moderation.updateActorAccess',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ToolsNS {
  _client: XrpcClient
  ozone: ToolsOzoneNS

  constructor(client: XrpcClient) {
    this._client = client
    this.ozone = new ToolsOzoneNS(client)
  }
}

export class ToolsOzoneNS {
  _client: XrpcClient
  communication: ToolsOzoneCommunicationNS
  moderation: ToolsOzoneModerationNS
  server: ToolsOzoneServerNS
  team: ToolsOzoneTeamNS

  constructor(client: XrpcClient) {
    this._client = client
    this.communication = new ToolsOzoneCommunicationNS(client)
    this.moderation = new ToolsOzoneModerationNS(client)
    this.server = new ToolsOzoneServerNS(client)
    this.team = new ToolsOzoneTeamNS(client)
  }
}

export class ToolsOzoneCommunicationNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  createTemplate(
    data?: ToolsOzoneCommunicationCreateTemplate.InputSchema,
    opts?: ToolsOzoneCommunicationCreateTemplate.CallOptions,
  ): Promise<ToolsOzoneCommunicationCreateTemplate.Response> {
    return this._client.call(
      'tools.ozone.communication.createTemplate',
      opts?.qp,
      data,
      opts,
    )
  }

  deleteTemplate(
    data?: ToolsOzoneCommunicationDeleteTemplate.InputSchema,
    opts?: ToolsOzoneCommunicationDeleteTemplate.CallOptions,
  ): Promise<ToolsOzoneCommunicationDeleteTemplate.Response> {
    return this._client.call(
      'tools.ozone.communication.deleteTemplate',
      opts?.qp,
      data,
      opts,
    )
  }

  listTemplates(
    params?: ToolsOzoneCommunicationListTemplates.QueryParams,
    opts?: ToolsOzoneCommunicationListTemplates.CallOptions,
  ): Promise<ToolsOzoneCommunicationListTemplates.Response> {
    return this._client.call(
      'tools.ozone.communication.listTemplates',
      params,
      undefined,
      opts,
    )
  }

  updateTemplate(
    data?: ToolsOzoneCommunicationUpdateTemplate.InputSchema,
    opts?: ToolsOzoneCommunicationUpdateTemplate.CallOptions,
  ): Promise<ToolsOzoneCommunicationUpdateTemplate.Response> {
    return this._client.call(
      'tools.ozone.communication.updateTemplate',
      opts?.qp,
      data,
      opts,
    )
  }
}

export class ToolsOzoneModerationNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  emitEvent(
    data?: ToolsOzoneModerationEmitEvent.InputSchema,
    opts?: ToolsOzoneModerationEmitEvent.CallOptions,
  ): Promise<ToolsOzoneModerationEmitEvent.Response> {
    return this._client
      .call('tools.ozone.moderation.emitEvent', opts?.qp, data, opts)
      .catch((e) => {
        throw ToolsOzoneModerationEmitEvent.toKnownErr(e)
      })
  }

  getEvent(
    params?: ToolsOzoneModerationGetEvent.QueryParams,
    opts?: ToolsOzoneModerationGetEvent.CallOptions,
  ): Promise<ToolsOzoneModerationGetEvent.Response> {
    return this._client.call(
      'tools.ozone.moderation.getEvent',
      params,
      undefined,
      opts,
    )
  }

  getRecord(
    params?: ToolsOzoneModerationGetRecord.QueryParams,
    opts?: ToolsOzoneModerationGetRecord.CallOptions,
  ): Promise<ToolsOzoneModerationGetRecord.Response> {
    return this._client
      .call('tools.ozone.moderation.getRecord', params, undefined, opts)
      .catch((e) => {
        throw ToolsOzoneModerationGetRecord.toKnownErr(e)
      })
  }

  getRepo(
    params?: ToolsOzoneModerationGetRepo.QueryParams,
    opts?: ToolsOzoneModerationGetRepo.CallOptions,
  ): Promise<ToolsOzoneModerationGetRepo.Response> {
    return this._client
      .call('tools.ozone.moderation.getRepo', params, undefined, opts)
      .catch((e) => {
        throw ToolsOzoneModerationGetRepo.toKnownErr(e)
      })
  }

  queryEvents(
    params?: ToolsOzoneModerationQueryEvents.QueryParams,
    opts?: ToolsOzoneModerationQueryEvents.CallOptions,
  ): Promise<ToolsOzoneModerationQueryEvents.Response> {
    return this._client.call(
      'tools.ozone.moderation.queryEvents',
      params,
      undefined,
      opts,
    )
  }

  queryStatuses(
    params?: ToolsOzoneModerationQueryStatuses.QueryParams,
    opts?: ToolsOzoneModerationQueryStatuses.CallOptions,
  ): Promise<ToolsOzoneModerationQueryStatuses.Response> {
    return this._client.call(
      'tools.ozone.moderation.queryStatuses',
      params,
      undefined,
      opts,
    )
  }

  searchRepos(
    params?: ToolsOzoneModerationSearchRepos.QueryParams,
    opts?: ToolsOzoneModerationSearchRepos.CallOptions,
  ): Promise<ToolsOzoneModerationSearchRepos.Response> {
    return this._client.call(
      'tools.ozone.moderation.searchRepos',
      params,
      undefined,
      opts,
    )
  }
}

export class ToolsOzoneServerNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  getConfig(
    params?: ToolsOzoneServerGetConfig.QueryParams,
    opts?: ToolsOzoneServerGetConfig.CallOptions,
  ): Promise<ToolsOzoneServerGetConfig.Response> {
    return this._client.call(
      'tools.ozone.server.getConfig',
      params,
      undefined,
      opts,
    )
  }
}

export class ToolsOzoneTeamNS {
  _client: XrpcClient

  constructor(client: XrpcClient) {
    this._client = client
  }

  addMember(
    data?: ToolsOzoneTeamAddMember.InputSchema,
    opts?: ToolsOzoneTeamAddMember.CallOptions,
  ): Promise<ToolsOzoneTeamAddMember.Response> {
    return this._client
      .call('tools.ozone.team.addMember', opts?.qp, data, opts)
      .catch((e) => {
        throw ToolsOzoneTeamAddMember.toKnownErr(e)
      })
  }

  deleteMember(
    data?: ToolsOzoneTeamDeleteMember.InputSchema,
    opts?: ToolsOzoneTeamDeleteMember.CallOptions,
  ): Promise<ToolsOzoneTeamDeleteMember.Response> {
    return this._client
      .call('tools.ozone.team.deleteMember', opts?.qp, data, opts)
      .catch((e) => {
        throw ToolsOzoneTeamDeleteMember.toKnownErr(e)
      })
  }

  listMembers(
    params?: ToolsOzoneTeamListMembers.QueryParams,
    opts?: ToolsOzoneTeamListMembers.CallOptions,
  ): Promise<ToolsOzoneTeamListMembers.Response> {
    return this._client.call(
      'tools.ozone.team.listMembers',
      params,
      undefined,
      opts,
    )
  }

  updateMember(
    data?: ToolsOzoneTeamUpdateMember.InputSchema,
    opts?: ToolsOzoneTeamUpdateMember.CallOptions,
  ): Promise<ToolsOzoneTeamUpdateMember.Response> {
    return this._client
      .call('tools.ozone.team.updateMember', opts?.qp, data, opts)
      .catch((e) => {
        throw ToolsOzoneTeamUpdateMember.toKnownErr(e)
      })
  }
}
