import React from 'react'
import {Image, Pressable, ScrollView, Text, View} from 'react-native'
import Swiper from 'react-native-web-swiper'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {
  CONNECTIONS_RQKEY,
  useFriendQueryPendingReqNum,
  useFriendQueryRecommendsQuery,
  useFriendsReqsQuery,
} from '#/state/queries/connections'
import {colors} from 'lib/styles'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {CirclesIcon, FriendsIcon} from '#/view/icons/ModalIcons'
import {useShowBottomBar} from '#/view/shell/bottom-bar/BottomBar'
import {atoms as a, useTheme} from '#/alf'
import {Loader} from '#/components/Loader'
import FriendRequestItem from './FriendRequestItem'
import RecommendItem from './RecommendItem'

const banner1 = require('../../../../assets/imgs/circle_banner_1.png')
const banner2 = require('../../../../assets/imgs/circle_banner_2.png')

export default function Connections() {
  const t = useTheme()
  const pal = usePalette('default')
  const navigation = useNavigation<NavigationProp>()
  const {isDesktop, isTabletOrDesktop, isWideList} = useWebMediaQueries()
  useShowBottomBar()

  const queryClient = useQueryClient()
  const {
    status: reqStatus,
    data: reqList = [],
    error: reqError,
  } = useFriendsReqsQuery({})
  const {
    status: recStatus,
    data: recList = [],
    error: recError,
  } = useFriendQueryRecommendsQuery({includeUnbindTgUser: true})
  const {data: reqNum} = useFriendQueryPendingReqNum()

  let requestContent
  if (reqStatus === 'pending') {
    requestContent = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (reqError) {
    requestContent = (
      <View style={[a.gap_md, a.justify_center, a.align_center, a.mb_md]}>
        <EmptyBox
          icon="heart"
          message="has no requests yet."
          viewStyles={{paddingTop: 10}}
        />
        <Pressable
          accessibilityRole="button"
          style={[
            a.px_lg,
            a.align_center,
            a.justify_center,
            a.rounded_lg,
            {backgroundColor: t.palette.primary, height: 32},
          ]}
          onPress={() => {
            queryClient.invalidateQueries({
              queryKey: [CONNECTIONS_RQKEY.requestList],
            })
          }}>
          <Text style={[a.text_sm, a.font_semibold]}>
            <Trans>Try again</Trans>
          </Text>
        </Pressable>
      </View>
    )
  } else if (reqList?.length === 0) {
    requestContent = (
      <EmptyBox
        icon="tea"
        message={'there is no requests'}
        viewStyles={{paddingVertical: 10}}
      />
    )
  } else {
    const requestSent = reqList?.filter(
      o => o?.status === 0 && o?.type === 'send',
    )
    const requestAccept = reqList?.filter(
      o => o?.status === 0 && o?.type === 'receive',
    )
    requestContent = (
      <View style={[a.py_sm, a.px_lg, a.gap_md]}>
        {requestSent?.slice(0, 1)?.map(item => {
          return <FriendRequestItem {...item} key={item?.id} />
        })}
        {requestAccept?.slice(0, requestSent?.length ? 2 : 3)?.map(item => {
          return <FriendRequestItem {...item} key={item?.id} />
        })}
      </View>
    )
  }

  let recommendContent
  if (recStatus === 'pending') {
    recommendContent = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (recError) {
    recommendContent = (
      <View style={[a.gap_md, a.justify_center, a.align_center]}>
        <EmptyBox
          icon="heart"
          message="There are no recommendations"
          viewStyles={{paddingTop: 10}}
        />
        <Pressable
          accessibilityRole="button"
          style={[
            a.px_lg,
            a.align_center,
            a.justify_center,
            a.rounded_lg,
            {backgroundColor: t.palette.primary, height: 32},
          ]}
          onPress={() => {
            queryClient.invalidateQueries({
              queryKey: [CONNECTIONS_RQKEY.recommendList],
            })
          }}>
          <Text style={[a.text_sm, a.font_semibold]}>
            <Trans>Try again</Trans>
          </Text>
        </Pressable>
      </View>
    )
  } else if (recList?.length === 0) {
    recommendContent = (
      <EmptyBox
        icon="tea"
        message={'there is no recommendations'}
        viewStyles={{paddingTop: 20, paddingBottom: 20}}
      />
    )
  } else {
    recommendContent = (
      <View>
        {recList?.map(item => (
          <RecommendItem {...item} key={item?.id} />
        ))}
      </View>
    )
  }

  return (
    <CenteredView style={{paddingBottom: !isDesktop ? 90 : 0}}>
      <ViewHeader
        title="Connections"
        style={{
          backgroundColor: t.atoms.bg_gray.backgroundColor,
        }}
        canGoBack={false}
        showHorizontalBorder={!isDesktop ? false : true}
        showOnDesktop
      />
      <ScrollView
        style={[
          pal.border,
          isDesktop && {borderLeftWidth: 1, borderRightWidth: 1},
        ]}>
        {/* Swiper */}
        <View
          style={[
            a.w_full,
            a.py_md,
            a.px_lg,
            isTabletOrDesktop
              ? {height: 205}
              : {height: isWideList ? 255 : 150},
          ]}>
          <Swiper
            from={0}
            loop={true}
            timeout={3}
            minDistanceForAction={0}
            // slideWrapperStyle={[a.px_md]}
            controlsProps={{
              dotsTouchable: true,
              prevPos: 'left',
              nextPos: 'right',
              nextTitle: '',
              prevTitle: '',
              prevTitleStyle: {fontSize: 24, fontWeight: '500'},
              nextTitleStyle: {fontSize: 24, fontWeight: '500'},
              // @ts-ignore
              // DotComponent: () => {
              //   return null
              // },
              // @ts-ignore
              DotComponent: ({isActive}: {isActive: boolean}) => {
                return (
                  <View
                    style={{
                      height: 4,
                      width: 16,
                      borderRadius: 2,
                      backgroundColor: 'white',
                      opacity: isActive ? 1 : 0.4,
                      marginTop: 18,
                      marginHorizontal: 4,
                    }}
                  />
                )
              },
            }}>
            <View style={[a.flex_1]}>
              <Pressable
                accessibilityRole="button"
                onPress={() => {
                  navigation.navigate('Task')
                }}
                style={[a.flex_1, a.w_full, a.h_full]}>
                <Image
                  source={banner1}
                  accessibilityIgnoresInvertColors
                  style={[a.flex_1, a.w_full, a.h_full, {borderRadius: 12}]}
                />
              </Pressable>
            </View>
            <View style={[a.flex_1]}>
              <Image
                source={banner2}
                accessibilityIgnoresInvertColors
                style={[a.flex_1, a.w_full, a.h_full, {borderRadius: 12}]}
                resizeMode="cover"
              />
            </View>
          </Swiper>
        </View>
        {/* Friends/Circle */}
        <View style={[a.gap_md, a.py_md, a.px_lg, a.flex_row, a.align_center]}>
          <Pressable
            accessibilityRole="button"
            style={[
              a.flex_1,
              a.flex_row,
              a.align_center,
              a.gap_md,
              a.px_md,
              {height: 80, borderRadius: 12, backgroundColor: '#FF7B1B'},
            ]}
            role="button"
            onPress={() => {
              navigation.navigate('Friend')
            }}>
            <View style={[a.flex_1]}>
              <Text
                style={[
                  !isDesktop ? a.mb_xs : a.mb_sm,
                  a.text_md,
                  a.font_semibold,
                  {color: t.palette.white},
                ]}>
                <Trans>My Friends</Trans>
              </Text>
              <Text style={[{color: t.palette.white}]}>
                <Trans>Share Moments and Stories</Trans>
              </Text>
            </View>
            <View style={[{marginBottom: !isDesktop ? 28 : 12}]}>
              <FriendsIcon size={32} color={t.palette.white} />
            </View>
          </Pressable>
          <Pressable
            accessibilityRole="button"
            style={[
              a.flex_1,
              a.flex_row,
              a.align_center,
              a.gap_md,
              a.px_md,
              {height: 80, borderRadius: 12, backgroundColor: '#8D47FF'},
            ]}
            role="button"
            onPress={() => {
              navigation.navigate('Circle')
            }}>
            <View style={[a.flex_1, a.flex_col, a.justify_between]}>
              <Text
                style={[
                  !isDesktop ? a.mb_xs : a.mb_sm,
                  a.text_md,
                  a.font_semibold,
                  {color: t.palette.white},
                ]}>
                <Trans>My Circle</Trans>
              </Text>
              <Text style={[{color: t.palette.white}]}>
                <Trans>Explore Secrets and Gossip</Trans>
              </Text>
            </View>
            <View style={[{marginBottom: !isDesktop ? 28 : 12}]}>
              <CirclesIcon color={t.palette.white} />
            </View>
          </Pressable>
        </View>
        {/* Requests */}
        <View>
          <View
            style={[
              a.w_full,
              a.py_md,
              a.px_lg,
              a.flex_row,
              a.align_center,
              a.justify_between,
            ]}>
            <View style={[a.flex_1]}>
              <Text style={[a.text_lg, a.font_bold, t.atoms.text]}>
                <Trans>Requests</Trans>
              </Text>
              {reqNum ? (
                <View
                  style={{
                    position: 'absolute',
                    top: 3,
                    left: 90,
                    paddingHorizontal: 6,
                    paddingVertical: 2,
                    borderRadius: 15,
                    backgroundColor: colors.bubble,
                  }}>
                  <Text
                    style={[
                      a.text_center,
                      {color: colors.white, fontSize: 12},
                    ]}>
                    {reqNum}
                  </Text>
                </View>
              ) : (
                ''
              )}
            </View>
            {reqList?.length > 0 && (
              <Pressable
                accessibilityRole="button"
                role="button"
                onPress={() => {
                  navigation.navigate('FriendRequestList')
                }}>
                <Text style={[a.text_sm, t.atoms.text, a.underline]}>
                  <Trans>See all</Trans>
                  {reqList?.length ? ` (${reqList?.length})` : ''}
                </Text>
              </Pressable>
            )}
          </View>
          {requestContent}
        </View>
        {/* Recommend */}
        <View style={[a.py_md, a.px_lg, a.mb_2xl]}>
          <View
            style={[a.flex_row, a.align_center, a.justify_between, a.mb_sm]}>
            <Text style={[a.text_lg, a.font_bold, t.atoms.text]}>
              <Trans>Recommend</Trans>
            </Text>
          </View>
          {recommendContent}
        </View>
      </ScrollView>
    </CenteredView>
  )
}
