import React from 'react'
import {StyleSheet, Text, View} from 'react-native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isNative} from '#/platform/detection'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {TeaLogo} from '#/view/icons/Logo'
import {CircleItemProps} from '#/view/screens/Circle'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {atoms as a, useTheme} from '#/alf'
import {FeedTabBar} from '../pager/FeedTabBar'
import {CenteredView} from '../util/Views'

export default function CircleTabs({
  selectTab,
  onSelectTab,
}: {
  selectTab: CircleItemProps
  onSelectTab: (value: CircleItemProps) => void
}) {
  const t = useTheme()
  const {isMobile} = useWebMediaQueries()
  const {circles} = usePostPreState()

  const tags: CircleItemProps[] = React.useMemo(() => {
    return [{id: '-1', name: 'All friends'}, ...circles]
  }, [circles])

  const renderItem = ({item}: {item: CircleItemProps}) => {
    const isSelected = selectTab?.id === item.id
    return (
      <View
        style={[
          a.flex_row,
          a.gap_xs,
          {
            backgroundColor: isSelected
              ? t.name === 'light'
                ? 'rgba(252, 208, 16, 0.2)'
                : '#322a03'
              : t.atoms.feed_tab_bg.backgroundColor,
            borderRadius: 8,
            padding: 10,
            borderWidth: 1,
            borderColor: isSelected ? '#FCD010' : 'transparent',
            marginRight: 10,
          },
        ]}>
        {Number(item?.id) <= 0 ? (
          <TeaLogo />
        ) : (
          <CircleAvatar
            size={20}
            circle={item}
            style={{borderRadius: 10}}
            showFlag={false}
          />
        )}
        <Text
          style={[
            styles.tabText,
            t.atoms.feed_tab_text,
            isSelected && {color: t.name === 'light' ? '#000000' : '#FCD010'},
            a.font_semibold,
          ]}>
          {item?.name}
        </Text>
      </View>
    )
  }

  return (
    <CenteredView
      isWidescreen={true}
      style={[styles.container, isMobile && !isNative && {marginTop: 85}]}>
      <FeedTabBar
        testID={'circles-tab-bar'}
        items={tags}
        selectedPage={tags?.findIndex(o => o?.id === selectTab?.id)}
        onSelect={index => {
          onSelectTab(tags[index])
        }}
        renderItem={renderItem}
        onPressSelected={index => {
          onSelectTab(tags[index])
        }}
      />
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 16,
    paddingHorizontal: isNative ? 18 : 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientBorder: {
    padding: 1,
    borderRadius: 8,
  },
  tabText: {
    fontSize: 14,
    fontWeight: '500',
  },
})
