import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from 'react'
import {AtUri} from '@atproto/api'

import {makeRecordUri} from '#/lib/strings/url-helpers'
import {emitter} from '#/state/events'
import {FeedItem} from '../../posts/Feed'
import {createStore} from './SdlStore'

interface ISwipeListStore {
  currentUri: string
  currentKey: string
  list: string[]
}

const store = createStore<ISwipeListStore>({
  currentUri: '',
  currentKey: '',
  list: [],
})

const {subscribe, getState, setState: setSwipeListStore} = store

export function useSwipeListStore() {
  const swipeListStore = useSyncExternalStore(subscribe, getState)

  const updateSwipeList = useCallback((list: any[]) => {
    setSwipeListStore(pre => {
      return {
        ...pre,
        list: [...list],
      }
    })
  }, [])

  const setCurrentSwipeUri = useCallback((uri: string) => {
    setSwipeListStore(pre => {
      return {
        ...pre,
        currentUri: uri,
      }
    })
  }, [])

  const swipeList = useMemo(() => {
    const list = swipeListStore.list
    if (!list.length) return [swipeListStore.currentUri]
    return list
  }, [swipeListStore])

  const currentKey = useMemo(() => {
    return swipeListStore.currentKey
  }, [swipeListStore.currentKey])

  const setCurrentKey = useCallback((key: string) => {
    setSwipeListStore(pre => ({
      ...pre,
      currentKey: key,
    }))
  }, [])

  return {
    updateSwipeList,
    setCurrentSwipeUri,
    swipeList,
    currentKey,
    setCurrentKey,
  }
}

export function useSwipeController(
  swipeKey: string,
  feedItems: FeedItem[],
  nextPage: () => void,
) {
  const {updateSwipeList, setCurrentKey} = useSwipeListStore()
  const [sourceUrl, setSourceUrl] = useState('')

  const swipeUrlList = useMemo(() => {
    return feedItems
      .filter(
        item => item.type === 'slice' && !item.slice.items[0].record.operation,
      )
      .map(post => {
        const urip = new AtUri(post.slice.items[0].uri)
        return makeRecordUri(
          post.slice.items[0].post.author.handle,
          'app.bsky.feed.post',
          urip.rkey,
        )
      })
  }, [feedItems])

  useEffect(() => {
    const startIndex = swipeUrlList.findIndex(item => item === sourceUrl)
    updateSwipeList([...swipeUrlList.slice(startIndex, swipeUrlList.length)])
  }, [sourceUrl, swipeUrlList, updateSwipeList])

  useEffect(() => {
    const setList = ({from, postUri}: {from: string; postUri: string}) => {
      if (from !== swipeKey) return
      setSourceUrl(postUri)
      setCurrentKey(from)
      const startIndex = swipeUrlList.findIndex(item => item === postUri)
      updateSwipeList([...swipeUrlList.slice(startIndex, swipeUrlList.length)])
    }
    const fetchMoreSwipe = (from: string) => {
      if (from !== swipeKey) return
      nextPage()
    }
    emitter.on('setSwipeList', setList)
    emitter.on('fetchMoreSwipe', fetchMoreSwipe)
    return () => {
      emitter.off('setSwipeList', setList)
      emitter.off('fetchMoreSwipe', fetchMoreSwipe)
    }
  }, [swipeUrlList, swipeKey, updateSwipeList, nextPage, setCurrentKey])
}
