import React from 'react'
import {Pressable, StyleSheet, Text, View, ViewStyle} from 'react-native'
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg'
import {LinearGradient} from 'expo-linear-gradient'
import {AppBskyFeedPost} from '@atproto/api'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {useComposerControls} from '#/state/shell'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {atoms as a, useTheme} from '#/alf'
import {TelegramBindStep} from '../modals/TelegramModal/config'
import {UserAvatar} from '../util/UserAvatar'
import {Bevel} from './MemoCard'

export default function MemoOperationCard({
  style,
  record,
}: {
  style?: ViewStyle
  record: AppBskyFeedPost.Record
}) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {openComposer} = useComposerControls()
  const {openModal} = useModalControls()

  const onPressSpill = React.useCallback(async () => {
    openComposer({
      placeholderText: record?.text,
    })
  }, [openComposer, record?.text])

  const onPressInvitation = React.useCallback(() => {
    openModal({name: 'telegram', currentStep: TelegramBindStep.SelectContact})
  }, [openModal])

  const onPressOperation = React.useCallback(() => {
    if (record.operationType === '1') {
      onPressSpill()
    } else if (record.operationType === '2') {
      onPressInvitation()
    } else if (record.operationType === '3') {
      navigation.navigate('CircleCreate')
    } else if (record.operationType === '4') {
      navigation.navigate('Rank')
    }
  }, [navigation, onPressInvitation, onPressSpill, record])

  const OPERATION_LIST = [
    {
      operationType: '1',
      title: 'Revealing insider information',
      text: 'You can reveal anything',
      operationText: 'Spill Tea',
      icon: <SpillQuestIcon />,
      colors: ['#FFDC42CC', '#FCD010E5'],
      bgColor: '#FCD010',
    },
    {
      operationType: '2',
      title: 'Discover secrets with friends',
      text: 'Share moments and stories',
      operationText: 'Invite friends',
      icon: <SpillInvationIcon />,
      colors: ['#51FFEB96', '#51FFEBF7'],
      bgColor: '#51FFEB',
    },
    {
      operationType: '3',
      title: 'Create your own circle',
      text: 'Explore secrets and gossip',
      operationText: 'Create Circle',
      icon: <SpillCircleIcon />,
      colors: ['#83F4578C', '#77EA4A'],
      bgColor: '#70D050',
    },
    {
      operationType: '4',
      title: 'Claim Your Achievements',
      text: 'Engage more, earn more!',
      operationText: 'View More',
      icon: <SpillRankIcon />,
      colors: ['#FFDC42CC', '#FCD010E5'],
      bgColor: 'white',
    },
  ]

  const operationRecord = OPERATION_LIST.find(
    o => o?.operationType === record?.operationType,
  )

  return operationRecord ? (
    <Pressable
      accessibilityRole="button"
      onPress={onPressOperation}
      style={[style, {paddingHorizontal: 6, marginTop: 12}]}>
      <View
        style={[
          a.relative,
          {borderRadius: 16, backgroundColor: operationRecord?.bgColor},
        ]}>
        <RandomImages operationType={record.operationType} />
        <LinearGradient
          colors={operationRecord?.colors ?? []}
          start={{x: 0.5, y: 0}}
          end={{x: 0.5, y: 1}}
          style={[styles.page, a.w_full]}>
          <Bevel />
          <View
            style={[
              a.flex_1,
              a.w_full,
              a.gap_md,
              a.flex_row,
              a.align_center,
              a.justify_between,
            ]}>
            <View style={[a.flex_1, a.h_full, a.gap_md, a.justify_between]}>
              <View style={[a.flex_1, a.gap_md]}>
                <Text
                  style={[a.text_xl, a.font_bold, {color: t.palette.black}]}>
                  {operationRecord?.title}
                </Text>
                <Text
                  style={[
                    a.text_sm,
                    a.font_semibold,
                    {color: t.palette.black},
                  ]}>
                  {operationRecord?.text}
                </Text>
              </View>
              <Pressable
                accessibilityRole="button"
                onPress={onPressOperation}
                style={[
                  a.align_center,
                  a.justify_center,
                  {
                    width: 150,
                    height: 44,
                    borderRadius: 100,
                    marginTop: 12,
                    backgroundColor: t.palette.black,
                  },
                ]}>
                <Text
                  style={[a.text_md, a.font_bold, {color: t.palette.white}]}>
                  {operationRecord?.operationText}
                </Text>
              </Pressable>
            </View>
            <View style={styles.dashCircle}>{operationRecord?.icon}</View>
          </View>
        </LinearGradient>
      </View>
    </Pressable>
  ) : null
}

const RandomImages = ({operationType}: {operationType?: string}) => {
  const {circles, teleContacts} = usePostPreState()

  const totalCount = 10

  const imageList = React.useMemo(() => {
    const spillImages = [
      {
        name: 'DEXX',
        avatar: 'https://public.rootdata.com/images/b12/1728005656400.jpg',
      },
      {
        name: 'Paradigm',
        avatar: 'https://public.rootdata.com/images/b11/1666594446821.jpg',
      },
      {
        name: 'ai16z',
        avatar: 'https://public.rootdata.com/images/b12/1730173739223.jpg',
      },
      {
        name: 'Polymarket',
        avatar: 'https://public.rootdata.com/images/b13/1666444790790.jpg',
      },
      {
        name: 'Vitalik Buterin',
        avatar: 'https://public.rootdata.com/images/b6/1669021107544.jpg',
      },
      {
        name: 'Pump.Fun',
        avatar: 'https://public.rootdata.com/images/b6/1712562719191.jpg',
      },
      {
        name: 'Elon Musk',
        avatar: 'https://public.rootdata.com/images/b6/1715140498236.jpg',
      },
      {
        name: 'Changpeng Zhao',
        avatar: 'https://public.rootdata.com/images/b12/1669630003987.jpg',
      },
      {
        name: 'PEPE',
        avatar: 'https://public.rootdata.com/images/b6/1683858659703.jpg',
      },
      {
        name: 'Pnut',
        avatar: 'https://public.rootdata.com/images/b12/1730948310342.jpg',
      },
      {
        name: 'Act I',
        avatar: 'https://public.rootdata.com/images/b12/1731305188437.jpg',
      },
      {
        name: 'Ethereum',
        avatar: 'https://public.rootdata.com/images/b6/1703249591096.jpg',
      },
    ]
    const listCopy: {avatar: string; size: number; name: string}[] = []
    const sourceImages =
      operationType === '1'
        ? spillImages
        : operationType === '2'
        ? teleContacts?.map(_ => {
            return {avatar: _.avatar, name: _.nickname}
          })
        : circles?.map(_ => {
            return {avatar: _.avatar, name: _.name}
          })
    sourceImages?.splice(0, totalCount).forEach((_, index) => {
      listCopy.push({
        avatar: _.avatar ?? '',
        size: index <= 2 ? 65 : index <= 4 ? 55 : index <= 6 ? 40 : 30,
        name: _.name ?? '',
      })
    })
    for (let i = listCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[listCopy[i], listCopy[j]] = [listCopy[j], listCopy[i]]
    }
    return listCopy
  }, [totalCount, teleContacts, circles, operationType])

  return (
    <View style={[a.absolute, a.w_full, a.h_full, styles.grid]}>
      {imageList.map((_, index) => {
        return (
          <View key={_.avatar + index} style={styles.item}>
            {operationType === '3' ? (
              <CircleAvatar
                size={_?.size}
                circle={{id: _.name, avatar: _.avatar}}
                style={{borderRadius: _?.size / 2}}
              />
            ) : (
              <UserAvatar
                size={_?.size}
                avatar={_.avatar}
                name={operationType !== '1' ? _.name : undefined}
              />
            )}
          </View>
        )
      })}
    </View>
  )
}

const SpillQuestIcon = () => {
  return (
    <Svg width="21" height="32" viewBox="0 0 21 32" fill="none">
      <Path
        d="M7.55461 21.104C6.70737 14.7699 15.3008 13.3578 15.3008 9.44434C15.3008 6.9833 13.3643 5.61158 10.177 5.61158C7.71599 5.61158 5.69875 6.74124 3.72185 8.79882L0.413574 5.77296C2.99564 2.78744 6.54599 0.810547 10.8629 0.810547C16.7129 0.810547 20.9894 3.554 20.9894 8.91986C20.9894 14.8102 12.1539 15.5364 12.7994 21.104H7.55461ZM10.2174 31.8761C8.11944 31.8761 6.62668 30.3833 6.62668 28.2854C6.62668 26.1874 8.15978 24.735 10.2174 24.735C12.2346 24.735 13.7677 26.1874 13.7677 28.2854C13.7677 30.3833 12.2346 31.8761 10.2174 31.8761Z"
        fill="black"
      />
    </Svg>
  )
}

const SpillInvationIcon = () => {
  return (
    <Svg width="36" height="27" viewBox="0 0 36 27" fill="none">
      <G clipPath="url(#clip0_2264_69057)">
        <Path
          d="M14.3794 13.5C18.0945 13.5 21.1036 10.4826 21.1036 6.7759C21.1036 3.06081 18.0945 0.0517578 14.3794 0.0517578C10.6643 0.0517579 7.65529 3.06081 7.65529 6.7759C7.65529 10.4826 10.6643 13.5 14.3794 13.5ZM14.3794 16.8621C9.89947 16.8621 0.931152 19.1063 0.931152 23.5862V26.9483H27.8277V23.5862C27.8277 19.1063 18.8594 16.8621 14.3794 16.8621Z"
          fill="black"
        />
        <Path
          d="M31.1899 4.53467V7.89674H34.5519V10.1381H31.1899V13.5002H28.9485V10.1381H25.5864V7.89674H28.9485V4.53467H31.1899Z"
          fill="black"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_2264_69057">
          <Rect
            width="34.7414"
            height="26.8966"
            fill="white"
            transform="translate(0.931152 0.0517578)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

const SpillCircleIcon = () => {
  return (
    <Svg width="37" height="37" viewBox="0 0 37 37" fill="none">
      <G clipPath="url(#clip0_2509_88796)">
        <Path
          d="M35.052 1.53076C35.0506 1.26962 35.0124 1.00997 34.9383 0.759544C33.6885 0.429021 32.0613 0.809397 30.6859 1.47692C30.4037 1.61352 29.7352 2.02032 28.7446 2.62353C28.3353 2.8728 27.9739 3.09314 27.8144 3.18388C27.7989 3.19285 27.7899 3.2093 27.776 3.21827C24.9906 1.511 21.7878 0.606306 18.5208 0.604004C8.66848 0.604004 0.653682 8.6203 0.653682 18.4712C0.655192 21.654 1.51344 24.7777 3.13834 27.5144C1.28531 30.372 0.366531 32.4344 0.606322 33.8317C0.656175 34.1179 1.14872 34.1638 1.41942 34.1638C6.94059 34.1638 19.5598 22.0496 21.1296 20.5042C28.0616 13.6853 35.0555 5.34897 35.052 1.53076ZM10.2254 22.407C9.59434 22.407 8.97748 22.2199 8.4528 21.8693C7.92811 21.5187 7.51917 21.0204 7.27768 20.4374C7.03619 19.8544 6.97301 19.2129 7.09612 18.594C7.21923 17.9751 7.5231 17.4066 7.96931 16.9604C8.41552 16.5142 8.98402 16.2103 9.60293 16.0872C10.2218 15.9641 10.8634 16.0273 11.4464 16.2688C12.0294 16.5103 12.5277 16.9192 12.8782 17.4439C13.2288 17.9686 13.4159 18.5854 13.4159 19.2165C13.415 20.0624 13.0786 20.8734 12.4804 21.4715C11.8823 22.0697 11.0713 22.4061 10.2254 22.407ZM15.8408 13.4734C15.0835 13.4734 14.3433 13.2489 13.7137 12.8282C13.0841 12.4075 12.5933 11.8095 12.3035 11.1099C12.0138 10.4103 11.9379 9.64052 12.0857 8.89783C12.2334 8.15514 12.598 7.47294 13.1335 6.93749C13.6689 6.40204 14.3511 6.03739 15.0938 5.88966C15.8365 5.74193 16.6063 5.81775 17.3059 6.10753C18.0055 6.39732 18.6035 6.88805 19.0242 7.51767C19.4449 8.14729 19.6695 8.88753 19.6695 9.64477C19.6681 10.6598 19.2643 11.6329 18.5466 12.3506C17.8289 13.0683 16.8558 13.4721 15.8408 13.4734Z"
          fill="black"
        />
        <Path
          d="M33.7415 9.16113C31.0649 13.1354 26.8574 17.9073 22.6598 22.0346C21.5177 23.1587 14.5926 29.8689 8.77734 33.4468C8.78156 33.4432 8.78626 33.4402 8.7913 33.4378C11.6829 35.3315 15.0645 36.3396 18.521 36.3383C28.3734 36.3383 36.3882 28.3235 36.3882 18.4711C36.3858 15.1825 35.4695 11.9592 33.7415 9.16113ZM23.5367 29.9571C23.0319 29.9571 22.5384 29.8074 22.1186 29.527C21.6989 29.2465 21.3717 28.8479 21.1785 28.3815C20.9854 27.9151 20.9348 27.4019 21.0333 26.9067C21.1318 26.4116 21.3749 25.9568 21.7318 25.5998C22.0888 25.2429 22.5436 24.9998 23.0387 24.9013C23.5339 24.8028 24.0471 24.8533 24.5135 25.0465C24.9799 25.2397 25.3785 25.5669 25.659 25.9866C25.9395 26.4064 26.0892 26.8999 26.0892 27.4047C26.0885 28.0814 25.8194 28.7303 25.3408 29.2088C24.8623 29.6874 24.2134 29.9565 23.5367 29.9571Z"
          fill="black"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_2509_88796">
          <Rect
            width="35.8621"
            height="35.8621"
            fill="white"
            transform="translate(0.568848 0.568848)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

const SpillRankIcon = () => {
  return (
    <Svg width="21" height="32" viewBox="0 0 21 32" fill="none">
      <Path
        d="M7.55485 21.1038C6.70761 14.7696 15.3011 13.3576 15.3011 9.44413C15.3011 6.98309 13.3645 5.61137 10.1773 5.61137C7.71623 5.61137 5.69899 6.74102 3.72209 8.79861L0.413818 5.77275C2.99589 2.78723 6.54623 0.810333 10.8631 0.810333C16.7131 0.810333 20.9897 3.55378 20.9897 8.91964C20.9897 14.81 12.1542 15.5362 12.7997 21.1038H7.55485ZM10.2176 31.8759C8.11968 31.8759 6.62692 30.3831 6.62692 28.2852C6.62692 26.1872 8.16003 24.7348 10.2176 24.7348C12.2349 24.7348 13.768 26.1872 13.768 28.2852C13.768 30.3831 12.2349 31.8759 10.2176 31.8759Z"
        fill="black"
      />
    </Svg>
  )
}

const styles = StyleSheet.create({
  page: {
    height: 178,
    padding: 20,
    borderRadius: 16,
    position: 'relative',
  },
  dashCircle: {
    width: 65,
    height: 65,
    borderRadius: 65,
    borderWidth: 2,
    borderColor: '#000000',
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: 15,
    overflow: 'hidden',
  },
  item: {
    width: 80,
    height: 65,
    marginBottom: 15,
  },
  image: {},
})
