import React, {useMemo} from 'react'
import {ImageBackground, Pressable, View} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import MasonryList from '@react-native-seoul/masonry-list'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {FeedPostSlice, FeedPostSliceItem} from '#/state/queries/post-feed'
import {NavigationProp} from 'lib/routes/types'
import {atoms as a, useTheme} from '#/alf'
import {Visibility} from '../../util/List.web'
import {ScrollView} from '../../util/ScrollView.web'
import {CenteredView} from '../../util/Views'
import {FeedItem} from '../Feed'
import {OperationFeedItem} from '../OperationFeedItem'
import {BigFigureCardItem} from './BigFigureCard'
import {useBigFigureFromHome, useIsMustSip} from './BigFigureProvider'

interface IExploreFeedList {
  data: FeedItem[]
  onEndReached: (data: {distanceFromEnd: number}) => void
  headerOffset?: number
  refreshing?: boolean
  onRefresh?: () => void
  feedParams?: string
  webFresh?: boolean
  renderEmptyState?: () => JSX.Element
}

const mustsipPng = require('../../../../../assets/imgs/mustSipTea.png')

export default function BigFigureFeedList({
  data,
  onEndReached,
  refreshing: _unsupportedRefreshing,
  onRefresh: _unsupportedOnRefresh = undefined,
  feedParams,
  webFresh,
  renderEmptyState,
}: IExploreFeedList) {
  const {isDesktop} = useWebMediaQueries()
  const pal = usePalette('default')
  const listColumn = isDesktop ? 2 : 1

  const t = useTheme()
  const isMustSip = useIsMustSip()
  const isFromHome = useBigFigureFromHome()

  const arrayData = useMemo((): FeedPostSliceItem[] => {
    const array = data.map(ele => {
      if (ele.type === 'slice') {
        return ele.slice.items
      } else {
        return []
      }
    })
    return array.flat()
  }, [data])

  const renderItem = ({item, i}: {item: FeedPostSliceItem; i: number}) => {
    if (item.record.operation) {
      return (
        <>
          {/* {!isMustSip && isFromHome && i === 2 && <MustSipDom />} */}
          <OperationFeedItem
            slice={{items: [item]} as FeedPostSlice}
            hideTopBorder={true}
            index={i}
          />
        </>
      )
    }
    return (
      <>
        {/* {!isMustSip && isFromHome && i === 2 && <MustSipDom />} */}
        <BigFigureCardItem
          key={item._reactKey}
          post={item.post}
          reason={undefined}
          feedContext={undefined}
          record={item.record}
          moderation={item.moderation}
          parentAuthor={item.parentAuthor}
          showReplyTo={i === 0}
          isThreadParent={
            arrayData?.length === 1 ? false : i < arrayData.length - 1
          }
          isThreadChild={arrayData.length === 1 ? false : i > 0}
          isThreadLastChild={
            (arrayData.length === 1 ? false : i > 0) &&
            arrayData.length === i + 1
          }
          isParentBlocked={item.isParentBlocked}
          hideTopBorder={i === 0}
          index={i}
        />
      </>
    )
  }

  if (data?.length > 0 && arrayData.length === 0 && renderEmptyState) {
    return renderEmptyState?.()
  }

  return arrayData?.length ? (
    <CenteredView
      isWidescreen={true}
      isClearWideLeft={!isFromHome}
      style={[
        {height: '100%'},
        isFromHome && [{borderLeftWidth: 1}, pal.border],
        isMustSip && t.name === 'light' && {borderColor: '#DAD4E2'},
      ]}>
      <ScrollView
        refreshing={_unsupportedRefreshing || false}
        handleRefresh={_unsupportedOnRefresh}
        scene={feedParams}
        webFresh={webFresh}>
        <MasonryList
          keyExtractor={item => item._reactKey}
          ListHeaderComponent={<View />}
          onEndReached={() => {
            console.log('onEndReached')
            // onEndReached({distanceFromEnd: headerOffset ?? 0})
          }}
          numColumns={listColumn}
          data={arrayData}
          // @ts-ignore
          renderItem={renderItem}
        />
        <View style={{width: 23, height: 90}} />
        <Visibility
          onVisibleChange={isVisible => {
            isVisible && onEndReached({distanceFromEnd: 0})
          }}
        />
      </ScrollView>
    </CenteredView>
  ) : null
}

export const MustSipDom = () => {
  const navigation = useNavigation<NavigationProp>()
  return (
    <Pressable
      accessibilityRole="button"
      role="button"
      onPress={() => {
        navigation.navigate('MustSipTea')
      }}
      style={[a.w_full, {marginTop: 16, paddingHorizontal: 16}]}>
      <ImageBackground
        source={mustsipPng}
        resizeMode="contain"
        style={{height: 64, borderRadius: 40, backgroundColor: '#FFEBB5'}}
      />
      <LinearGradient
        colors={['#CF781B', '#632400']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 1}}
        style={[
          a.absolute,
          a.align_center,
          a.justify_center,
          {borderRadius: 18, width: 36, height: 36, right: 32, top: 14},
        ]}>
        <FontAwesomeIcon
          icon={'arrow-right'}
          size={16}
          style={{color: 'white'}}
        />
      </LinearGradient>
    </Pressable>
  )
}
