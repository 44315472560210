/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  walletAddress: string
  limit?: number
  offset?: number
}

export type InputSchema = undefined

export interface OutputSchema {
  labelTagInfos: LabelTagInfo[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface LabelTagInfo {
  id: number
  val: string
  num: number
  active?: boolean
  [k: string]: unknown
}

export function isLabelTagInfo(v: unknown): v is LabelTagInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.admin.queryLabelTags#labelTagInfo'
  )
}

export function validateLabelTagInfo(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.admin.queryLabelTags#labelTagInfo', v)
}
