import {createMultiPathSVG, createSinglePathSVG} from './TEMPLATE'

export const FilterNormal_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M17.75 15.175h-3.875a.747.747 0 0 0-.75.75c0 .416.334.75.75.75h3.875c.417 0 .75-.334.75-.75a.747.747 0 0 0-.75-.75ZM6.125 9.75H2.25a.747.747 0 0 0-.75.75c0 .417.333.75.75.75h3.875c.408 0 .75-.333.75-.75a.747.747 0 0 0-.75-.75ZM17.75 4.325h-3.875a.747.747 0 0 0-.75.75c0 .417.334.75.75.75h3.875c.417 0 .75-.341.75-.75a.747.747 0 0 0-.75-.75ZM11.525 3.525a.747.747 0 0 0-.75-.75.747.747 0 0 0-.75.75v.8H2.25a.747.747 0 0 0-.75.75c0 .417.333.75.75.75h7.775v.8c0 .417.333.75.75.75s.75-.341.75-.75v-3.1ZM11.525 14.375a.747.747 0 0 0-.75-.75.747.747 0 0 0-.75.75v.8H2.25a.747.747 0 0 0-.75.75c0 .417.333.75.75.75h7.775v.8c0 .417.333.75.75.75s.75-.333.75-.75v-3.1ZM9.975 11.25h7.775c.417 0 .75-.333.75-.75a.747.747 0 0 0-.75-.75H9.975v-.8a.747.747 0 0 0-.75-.75.747.747 0 0 0-.75.75v3.1c0 .417.333.75.75.75s.75-.333.75-.75v-.8Z',
})

export const FilterNormal_Stroke2_Corner0_Rounded_1 = createMultiPathSVG({
  viewBox: '0 0 20 20',
  paths: [
    'M17.7501 14.6745L13.8751 14.6745C13.4585 14.6745 13.1251 15.0078 13.1251 15.4245C13.1251 15.8411 13.4585 16.1745 13.8751 16.1745L17.7501 16.1745C18.1668 16.1745 18.5001 15.8411 18.5001 15.4245C18.5001 15.0078 18.1668 14.6745 17.7501 14.6745Z',
    'M6.125 9.24968L2.25 9.24968C1.83333 9.24968 1.5 9.58301 1.5 9.99968C1.5 10.4163 1.83333 10.7497 2.25 10.7497L6.125 10.7497C6.53333 10.7497 6.875 10.4163 6.875 9.99968C6.875 9.58301 6.54167 9.24968 6.125 9.24968Z',
    'M17.7501 3.82487L13.8751 3.82487C13.4585 3.82487 13.1251 4.1582 13.1251 4.57487C13.1251 4.99154 13.4585 5.32487 13.8751 5.32487L17.7501 5.32487C18.1668 5.32487 18.5001 4.9832 18.5001 4.57487C18.5001 4.1582 18.1668 3.82487 17.7501 3.82487Z',
    'M11.525 3.02539C11.525 2.60872 11.1917 2.27539 10.775 2.27539C10.3584 2.27539 10.025 2.60872 10.025 3.02539L10.025 3.82539L2.25003 3.82539C1.83336 3.82539 1.50002 4.15872 1.50002 4.57539C1.50002 4.99206 1.83336 5.32539 2.25003 5.32539L10.025 5.32539L10.025 6.12539C10.025 6.54206 10.3584 6.87539 10.775 6.87539C11.1917 6.87539 11.525 6.53372 11.525 6.12539L11.525 3.02539Z',
    'M11.525 13.875C11.525 13.4583 11.1917 13.125 10.775 13.125C10.3584 13.125 10.025 13.4583 10.025 13.875L10.025 14.675L2.25003 14.675C1.83336 14.675 1.50002 15.0083 1.50002 15.425C1.50002 15.8417 1.83336 16.175 2.25003 16.175L10.025 16.175L10.025 16.975C10.025 17.3917 10.3584 17.725 10.775 17.725C11.1917 17.725 11.525 17.3917 11.525 16.975L11.525 13.875Z',
    'M9.97512 10.7502L17.7501 10.7502C18.1668 10.7502 18.5001 10.4169 18.5001 10.0002C18.5001 9.58353 18.1668 9.2502 17.7501 9.2502L9.97512 9.2502L9.97512 8.4502C9.97512 8.03353 9.64179 7.70019 9.22512 7.70019C8.80845 7.70019 8.47512 8.03353 8.47512 8.4502L8.47512 11.5502C8.47512 11.9669 8.80845 12.3002 9.22512 12.3002C9.64179 12.3002 9.97512 11.9669 9.97512 11.5502L9.97512 10.7502Z',
  ],
})
