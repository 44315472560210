import React, {createContext, useContext} from 'react'

import {useSubAccounts} from '#/lib/hooks/useIsMe'
import {PostAccountType} from '#/view/com/util/post-mask'
import {CircleItemProps, ContactItemProps} from '#/view/screens/Circle'
import {
  useCircleList,
  useTelegramContactList,
} from '#/view/screens/Circle/hooks'
import {formatHandler} from '#/screens/Onboarding/util'
import {useAgent, useSession} from '../session'

type StateContext = {
  accounts: PostAccountType[]
  delPostMaskAccounts: (did: string) => void
  updatePostMaskAccounts: (value: PostAccountType) => void
  circles: CircleItemProps[]
  teleContacts: ContactItemProps[]
}

export function Provider({children}: React.PropsWithChildren<{}>) {
  const {subAccounts} = useSubAccounts()
  const agent = useAgent()
  const {currentAccount} = useSession()
  const {data: circles = []} = useCircleList()
  const {data: teleContacts = []} = useTelegramContactList({
    circleId: -1,
    limit: 10,
  })

  const [accounts, setAccounts] = React.useState<PostAccountType[]>([])

  const getMaskNames = React.useCallback(async () => {
    let accountsTemp: PostAccountType[] = []
    if (currentAccount) {
      const dids = subAccounts?.reduce((acc, i) => {
        acc.push(i.did)
        return acc
      }, [] as string[])
      const isCurrentMask =
        subAccounts.findIndex(o => o?.did === currentAccount?.did) >= 0
      if (isCurrentMask && subAccounts?.[0]?.parentDid) {
        dids.unshift(subAccounts?.[0]?.parentDid)
      } else {
        dids.unshift(currentAccount.did)
      }
      const res = await agent.getProfiles({actors: dids})
      // console.log('getMaskNames', res.data.profiles);
      res?.data.profiles?.forEach(proItem => {
        accountsTemp.push({
          name:
            proItem?.displayName && proItem?.displayName !== ''
              ? proItem?.displayName
              : formatHandler(proItem?.handle),
          did: proItem?.did,
          handle: proItem?.handle,
          avatar: proItem?.avatar,
          parentDid: subAccounts.find(o => o?.did === proItem?.did)?.parentDid,
        })
      })

      setAccounts(accountsTemp)
    }
  }, [agent, subAccounts, currentAccount])

  const delPostMaskAccounts = (value: string) => {
    const index = accounts.findIndex(o => o.did === value)
    accounts.splice(index, 1)
    setAccounts(accounts.slice())
  }

  const updatePostMaskAccounts = (value: PostAccountType) => {
    const index = accounts.findIndex(o => o.did === value.did)
    if (index >= 0) {
      accounts[index] = {...accounts[index], ...value}
    } else {
      accounts.push(value)
    }
    setAccounts(accounts.slice())
  }

  React.useEffect(() => {
    getMaskNames()
  }, [subAccounts, getMaskNames])

  return (
    <PostMaskContext.Provider
      value={{
        accounts,
        delPostMaskAccounts,
        updatePostMaskAccounts,
        circles,
        teleContacts,
      }}>
      {children}
    </PostMaskContext.Provider>
  )
}

export const PostMaskContext = createContext<StateContext>({} as StateContext)

export function usePostPreState() {
  return useContext(PostMaskContext)
}
