import axios, {AxiosInstance} from 'axios'

import {ENTITY_URL} from '../../lib/constants'

// interface Person {
//   [key: string]: any
// }

const apiClient: AxiosInstance = axios.create({
  baseURL: ENTITY_URL,
})

apiClient.interceptors.response.use(
  res => {
    return res.data
  },
  err => {
    return err?.response?.data
  },
)

// const get = async <T>(
//   url: string,
//   params?: Record<string, any>,
// ): Promise<T> => {
//   try {
//     const {data}: AxiosResponse<T> = await apiClient.get<T>(url, {params})
//     return data
//   } catch (error) {
//     console.error(`Error fetching data from ${url}:`, error)
//     throw error
//   }
// }

// const tarListRequest = (name: string): Promise<any> => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let url = `get/all?query=${name}`
//       const data = await get<Person[]>(url)
//       if (data?.data) {
//         resolve(data.data)
//       } else if (data?.result) {
//         resolve([])
//       } else {
//         resolve(data)
//       }
//     } catch (error) {
//       reject(error.response.data.error)
//     }
//   })
// }

const tarListRequest = (name: string) => {
  return apiClient.request({
    url: 'get/all',
    params: {query: name},
  })
}

const getEntityDetail = (id: number, type: number) => {
  return apiClient.request({
    url: 'getEntityDetail',
    params: {id, type},
  })
}

export const entityAgent = {
  tarListRequest,
  getEntityDetail,
}
