import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isNative} from '#/platform/detection'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {LogoVisitorHorizontal} from '#/view/icons/LogoVisitorHorizontal'
import {atoms as a} from '#/alf'
import {DesktopSearch} from './desktop/Search'

export function TopNav() {
  const {isDesktop, isTablet, isMobile} = useWebMediaQueries()
  const {hasSession, currentAccount} = useSession()
  const {data: profile} = useProfileQuery({did: currentAccount?.did})
  const setDrawerOpen = useSetDrawerOpen()

  return (
    <View style={[a.w_full, a.flex_row]}>
      <View
        style={[
          a.w_full,
          a.flex_row,
          a.align_center,
          a.justify_between,
          a.relative,
          {paddingVertical: 5},
          isDesktop && styles.destopBar,
          isTablet && styles.tabletBar,
          isMobile && styles.mobileBar,
        ]}>
        {hasSession && isMobile && (
          <TouchableOpacity
            accessibilityRole="button"
            // style={styles.menu}
            onPress={() => setDrawerOpen(true)}>
            <UserAvatar
              size={32}
              avatar={profile?.avatar}
              usePlainRNImage={true}
              type={profile?.associated?.labeler ? 'labeler' : 'user'}
            />
          </TouchableOpacity>
        )}
        <View
          style={[
            isNative
              ? [a.absolute, styles.nativeLogo]
              : isMobile
              ? [
                  a.absolute,
                  a.align_center,
                  a.justify_center,
                  {
                    left: '50%',
                    transform: [{translateX: -47}, {translateY: 0}],
                  },
                ]
              : {},
          ]}>
          <LogoVisitorHorizontal
            style={[isMobile ? {transform: 'scale(0.8)'} : {marginTop: 5}]}
          />
        </View>
        <View style={[a.flex_row, a.gap_2xl]}>
          {false && <DesktopSearch />}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  destopBar: {
    // @ts-ignore Web only
    width: 844,
    paddingHorizontal: 30,
  },
  tabletBar: {
    paddingHorizontal: 30,
  },
  mobileBar: {
    paddingHorizontal: 16,
  },
  nativeLogo: {
    left: '50%',
    paddingTop: 5,
    transform: [{translateX: -27}, {translateY: 0}],
  },
})
