import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Image} from 'expo-image'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  RichText as RichTextAPI,
} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {isDev} from '#/lib/constants'
import {TranslateText} from '#/lib/hooks/Tools'
import {shortenLinks, stripInvalidMentions} from '#/lib/strings/rich-text-manip'
import {useLanguagePrefs} from '#/state/preferences'
import {useAgent, useSession} from '#/state/session'
import {NavigationProp} from 'lib/routes/types'
import * as Toast from '#/view/com/util/Toast'
import {useThemeName} from '#/alf/util/useColorModeTheme'

export function TranslationWidget({
  translate,
  isCircle,
  textStyle,
}: {
  translate: {
    displayOriginalText: () => void
    translatePost: () => void
    hasTranslated: boolean
    isLanguageConsistent: boolean
    fromMe: boolean
  }
  isCircle?: boolean
  textStyle?: StyleProp<TextStyle>
}) {
  const style = useMemo(() => {
    const tempStyle = {
      marginTop: -15,
      fontSize: 17,
      color: '#C28C00',
    }
    if (isCircle) {
      tempStyle.fontSize = 12
      tempStyle.textDecorationLine = 'underline'
      tempStyle.color = 'black'
      tempStyle.marginTop = -20
    }
    return tempStyle
  }, [isCircle])
  if (translate.fromMe) return null
  return (
    <>
      {!translate.isLanguageConsistent && (
        <View style={styles.page}>
          {!translate.hasTranslated ? (
            <TouchableOpacity
              accessibilityRole="button"
              onPress={translate.translatePost}>
              <Text style={[style, textStyle]}>
                <Trans>Translate</Trans>
              </Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              accessibilityRole="button"
              onPress={translate.displayOriginalText}>
              <Text style={[style, textStyle]}>
                <Trans>Translated by google</Trans>
              </Text>
            </TouchableOpacity>
          )}
        </View>
      )}
    </>
  )
}

export function AutoTranslate({style}: {style?: StyleProp<ViewStyle>}) {
  const navigation = useNavigation<NavigationProp>()
  const themeName = useThemeName()

  const onPressLanguageSettings = React.useCallback(() => {
    navigation.navigate('LanguageSettings')
  }, [navigation])

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={onPressLanguageSettings}
      style={[
        {
          position: 'absolute',
          right: 10,
          top: -2,
          zIndex: 999,
        },
        style,
      ]}>
      <Image
        style={[styles.image, {marginRight: 20}]}
        source={
          themeName === 'light'
            ? require('../../../../../assets/icons/auto_translate_w.png')
            : require('../../../../../assets/icons/auto_translate_b.png')
        }
        accessibilityIgnoresInvertColors={true}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  page: {
    paddingVertical: 10,
    marginTop: 10,
    marginBottom: -10,
  },
  autoTranslate: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    borderRadius: 100,
    backgroundColor: '#F5F5F5',
    paddingHorizontal: 4,
    paddingRight: 8,
  },
  image: {
    height: 20,
    width: 23,
  },
})

export function useTranslationWidget({
  record,
  shouldTrimArticle,
  post,
}: {
  record: AppBskyFeedPost.Record
  shouldTrimArticle?: boolean
  post: AppBskyFeedDefs.PostView
}) {
  const langPrefs = useLanguagePrefs()
  const {_} = useLingui()
  const [hasMore, setHasMore] = useState(false)
  const [showAutoTranslate, setShowAutoTranslate] = useState(false)
  const [richText, setRichText] = useState<RichTextAPI>(() => {
    let recordText = record.text
    if (shouldTrimArticle) {
      const {text, hasMoreText} = trimArticle(record.text)
      if (hasMoreText) {
        setHasMore(true)
        recordText = text
      }
    }
    return new RichTextAPI({
      text: recordText,
      facets: record.facets,
    })
  })
  const [translateTitle, setTranslateTitle] = useState('')
  const [hasTranslated, setHasTranslated] = useState(false)

  const agent = useAgent()

  const isLanguageConsistent = useMemo(() => {
    let temp = ['']
    if (record?.langs) {
      temp = record.langs.map(lan => {
        if (lan.includes('zh')) {
          return 'zh'
        }
        return lan
      })
    }
    return temp.includes(langPrefs.primaryLanguage)
  }, [langPrefs.primaryLanguage, record.langs])

  const originRichText = useMemo(
    () =>
      new RichTextAPI({
        text: record.text,
        facets: record.facets,
      }),
    [record],
  )

  const fromMe = useMemo(() => {
    return post.isSelfPost
  }, [post.isSelfPost])

  const FromOfficial = useMemo(() => {
    return record.operation
  }, [record.operation])

  const {hasSession} = useSession()

  const translatePost = useCallback(
    async (fromAutoTranslate?: boolean) => {
      if (!hasSession) return
      if (isLanguageConsistent || fromMe || FromOfficial) return
      let sourceText = '',
        title = ''

      agent.com.atproto.server
        .getTranslation({
          uri: post.uri,
          lang: langPrefs.primaryLanguage,
        })
        .then(sdlTranslate => {
          if (sdlTranslate.data.ok) {
            const resultTexts = sdlTranslate.data.translation?.split(
              '#Sipz_Split',
            ) || ['']
            sourceText = resultTexts[0]
            if (!sourceText) {
              googleTranslate()
            } else {
              title = resultTexts[1] || ''
              completeTranslate()
            }
          } else {
            googleTranslate()
          }
        })
        .catch(() => {
          googleTranslate()
        })

      function googleTranslate() {
        const transHandler = new TranslateHandler(record.text)
        TranslateText(
          [
            record.title || '',
            transHandler.sanitizeForTranslation(record.text),
          ],
          langPrefs.primaryLanguage,
        )
          .then(source => {
            sourceText = transHandler.restoreFromTranslation(source[1])
            title = source[0]
            if (sourceText) {
              agent.com.atproto.server
                .storeTranslation({
                  uri: post.uri,
                  cid: post.cid,
                  lang: langPrefs.primaryLanguage,
                  translation: [sourceText, title].join('#Sipz_Split'),
                  creator: post.author.did,
                })
                .catch(() => {})
            }
            if (shouldTrimArticle) {
              const {text, hasMoreText} = trimArticle(sourceText)
              if (hasMoreText) {
                setHasMore(true)
                sourceText = text
              }
            }
            completeTranslate()
          })
          .catch(() => {
            Toast.show(
              _(msg`Sorry! the translation request failed.`),
              'warning',
            )
          })
      }

      function completeTranslate() {
        let rt = new RichTextAPI(
          {text: sourceText},
          {
            cleanNewlines: true,
          },
        )
        rt.detectFacets(agent).then(() => {
          rt = shortenLinks(rt)
          setRichText(stripInvalidMentions(rt))
          setHasTranslated(true)
          setTranslateTitle(title)
        })
        if (fromAutoTranslate) {
          setShowAutoTranslate(true)
        }
      }
    },
    [
      FromOfficial,
      _,
      agent,
      fromMe,
      hasSession,
      isLanguageConsistent,
      langPrefs.primaryLanguage,
      post.author.did,
      post.cid,
      post.uri,
      record.text,
      record.title,
      shouldTrimArticle,
    ],
  )

  useEffect(() => {
    if (FromOfficial || isDev) {
      return
    }
    if (langPrefs.autoTranslate) {
      translatePost(true)
    } else if (showAutoTranslate) {
      displayOriginalText()
      setShowAutoTranslate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langPrefs])

  const displayOriginalText = useCallback(() => {
    setRichText(originRichText)
    setHasTranslated(false)
    setTranslateTitle('')
  }, [originRichText])

  return {
    translateTitle,
    hasTranslated,
    translatePost,
    displayOriginalText,
    richText,
    langPrefs,
    isLanguageConsistent,
    hasMore,
    fromMe,
    showAutoTranslate,
  }
}

export function trimArticle(text: string, maxLength?: number) {
  const MAX_LENGTH = maxLength ?? 280
  const {totalCount, result} = countCharacters(text, MAX_LENGTH)
  const hasMoreText = totalCount > MAX_LENGTH

  let trimmedText = hasMoreText ? result + '...' : text

  if (hasMoreText) {
    const lastSpaceIndex = trimmedText.lastIndexOf(' ')
    if (lastSpaceIndex >= 0 && MAX_LENGTH - lastSpaceIndex < 10) {
      trimmedText = trimmedText.slice(0, lastSpaceIndex) + '...'
    }
  }
  return {text: trimmedText, hasMoreText}
}

function countCharacters(str: string, maxLength?: number) {
  const MAX_LENGTH = maxLength ?? 280
  let totalCount = 0
  let result = ''
  for (const char of str) {
    if (/^[\u4e00-\u9fa5]$/.test(char)) {
      totalCount += 2
    } else {
      totalCount += 1
    }
    if (totalCount <= MAX_LENGTH) {
      result += char
    }
  }
  return {totalCount, result}
}

class TranslateHandler {
  private replacementMapping: Map<string, string>
  private restorationMapping: Map<string, string>

  constructor(text: string) {
    this.replacementMapping = new Map<string, string>()
    this.restorationMapping = new Map<string, string>()

    text.split(/\s+/).forEach((word, index) => {
      if (word.startsWith('#') || word.startsWith('@')) {
        this.replacementMapping.set(word, `#S_i_p_z${index}`)
        this.restorationMapping.set(`#S_i_p_z${index}`, word)
      }
    })
  }

  sanitizeForTranslation(text: string): string {
    return Array.from(this.replacementMapping.entries()).reduce(
      (acc, [key, value]) => {
        return acc.replace(key, value)
      },
      text,
    )
  }

  restoreFromTranslation(text: string): string {
    return Array.from(this.restorationMapping.entries()).reduce(
      (acc, [key, value]) => {
        return acc.replace(key, value)
      },
      text,
    )
  }
}
