import React from 'react'
import {ActivityIndicator, Pressable, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import dayjs from 'dayjs'

import {logger} from '#/logger'
import {useFriendAgreeReqMutation} from '#/state/queries/connections'
import * as Toast from '#/view/com/util/Toast'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {Telegram} from '#/components/icons/Telegram'
import {ContactItemProps} from '.'

export interface FriendRequestItemProps extends ContactItemProps {
  reason?: string
  status: number // 0: senting 1: accepted; 2: to accept;
  type?: 'send' | 'receive'
  source?: string
  createdAt: string
  isRecipientBandTg: boolean
}
export default function FriendRequestItem(props: FriendRequestItemProps) {
  const t = useTheme()
  const {_} = useLingui()
  const friendAgreeMutation = useFriendAgreeReqMutation()

  const [loading, setLoading] = React.useState<boolean>(false)

  const onAgree = async () => {
    if (!loading) {
      setLoading(true)
      friendAgreeMutation
        .mutateAsync({
          requestId: +props?.id,
          from: 'request',
        })
        .then(() => {
          Toast.show(_(msg`Friend Added Successfully.`))
        })
        .finally(() => {
          setLoading(false)
        })
        .catch(err => {
          logger.error(`agree friend request:`, {message: err})
          Toast.show(_(msg`An issue occurred, please try again.`), 'xmark')
        })
    }
  }

  return (
    <View
      style={[
        a.p_md,
        a.flex_row,
        a.justify_between,
        t.atoms.input_bg,
        a.rounded_md,
        a.gap_xs,
      ]}>
      <View style={[a.flex_row, a.flex_1, a.gap_md]}>
        <View style={[a.relative, {width: 48, height: 48}]}>
          <UserAvatar
            type="user"
            size={48}
            avatar={props?.avatar}
            style={[a.mt_xs]}
          />
          {props.isRecipientBandTg && (
            <IconCircle
              icon={Telegram}
              size="sm"
              iconStyle={[
                {color: '#fff', padding: 2, marginLeft: -1, marginBottom: -1},
              ]}
              style={[
                {
                  width: 20,
                  height: 20,
                  backgroundColor: '#25a3e1',
                  right: -4,
                  bottom: -4,
                  position: 'absolute',
                },
              ]}
            />
          )}
        </View>
        <View style={[a.flex_col, a.flex_1, a.gap_xs]}>
          <View style={[a.w_full, a.flex_row, a.align_center, a.gap_xs]}>
            <Text
              style={[a.text_md, a.font_bold, t.atoms.text]}
              numberOfLines={1}>
              {props?.nickname}
            </Text>
            <Text
              style={[a.text_sm, a.flex_1, t.atoms.text_sub]}
              numberOfLines={1}>
              @{formatHandler(props?.username ?? '')}
            </Text>
            {props.isMutual ? (
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <IconCircle
                  style={[{width: 16, height: 16, backgroundColor: '#2aabee'}]}
                  icon={Telegram}
                  iconStyle={{color: t.palette.white}}
                  size="xxs"
                />
                <Text style={[a.text_xs, t.atoms.text]}>
                  <Trans>Mutual</Trans>
                </Text>
              </View>
            ) : (
              ''
            )}
          </View>
          <Text
            style={[a.w_full, a.text_sm, t.atoms.text_sub_1, a.flex_wrap]}
            numberOfLines={1}>
            {props?.reason}
          </Text>
          <Text style={[a.w_full, a.text_sm, t.atoms.text_sub]}>
            {props?.type === 'send' ? '' : `From: ${props?.source}`}
          </Text>
        </View>
      </View>
      <View
        style={[
          {marginVertical: 2},
          a.flex_col,
          a.justify_between,
          a.align_end,
        ]}>
        <View>
          {props?.status === 0 && props?.type === 'send' && (
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <ArrowUpLeftIcon style={{marginTop: 2}} />
              <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                <Trans>Request sent</Trans>
              </Text>
            </View>
          )}
          {props?.status === 1 && props?.type === 'send' && (
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <ArrowUpLeftIcon style={{marginTop: 2}} />
              <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                <Trans>Added</Trans>
              </Text>
            </View>
          )}
          {props?.status === 1 && props?.type === 'receive' && (
            <View style={[a.flex_row, a.gap_sm]}>
              <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                <Trans>Accepted</Trans>
              </Text>
            </View>
          )}
          {props?.status === 0 && props?.type === 'receive' && (
            <Pressable
              accessibilityRole="button"
              role="button"
              style={[
                a.flex_row,
                a.align_center,
                a.justify_center,
                a.gap_xs,
                {
                  height: 32,
                  width: 70,
                  borderRadius: 32,
                  backgroundColor: t.palette.primary,
                },
              ]}
              onPress={onAgree}>
              {loading && <ActivityIndicator size={12} />}
              <Text style={[a.text_sm, a.font_semibold]}>
                <Trans>Accept</Trans>
              </Text>
            </Pressable>
          )}
        </View>
        <View>
          {props.createdAt && (
            <Text style={[t.atoms.text, t.atoms.text_sub_1]}>
              {dayjs(props.createdAt).format('MMM DD, HH:mm')}
            </Text>
          )}
        </View>
      </View>
    </View>
  )
}
