import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  Image as ImageRN,
  PixelRatio,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import ViewShot from 'react-native-view-shot'
import {LinearGradient} from 'expo-linear-gradient'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {makeProfileLink} from '#/lib/routes/links'
import {toShareUrl} from '#/lib/strings/url-helpers'
import {isNative} from '#/platform/detection'
import {IShareProfile, useModalControls} from '#/state/modals'
import {LogoHorizontal} from '#/view/icons/LogoHorizontal'
import {formatHandler} from '#/screens/Onboarding/util'
import {useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {UserAvatar} from '../../util/UserAvatar'
import {ShareQRCode} from './ShareQRCode'

export const snapPoints = ['fullscreen']

export function Component({
  profile: {handle, displayName, avatar, description},
}: IShareProfile) {
  const viewRef = useRef<ViewShot>(null)
  const {closeModal} = useModalControls()
  const t = useTheme()
  const pixelRatio = PixelRatio.get()
  const {isMobile} = useWebMediaQueries()
  const [shareImageUri, setShareImageUri] = useState<string | undefined>(
    undefined,
  )
  const [imageDimensions, setImageDimensions] = useState({width: 0, height: 0})
  const [isRendered, setIsRendered] = useState<boolean>(false)

  // const captureView = async () => {
  //   try {
  //     const uri = await captureRef(viewRef, {
  //       format: 'png',
  //       quality: 0.8,
  //     })
  //     await DownLoadImage(uri, displayName)
  //     closeModal()
  //   } catch (error) {
  //     console.error('Failed to capture view', error)
  //   }
  // }

  const captureView = useCallback(async () => {
    try {
      if (shareImageUri || !viewRef.current) return
      viewRef.current?.capture?.().then(async (uri: string) => {
        // const canvas = await getCanvas(uri)
        setShareImageUri(uri)
        ImageRN.getSize(uri, (width, height) => {
          setImageDimensions({
            width: width / pixelRatio,
            height: height / pixelRatio,
          })
        })
      })
      // const imgUri = await captureRef(viewRef, {format: 'png', quality: 1.0})
    } catch (error) {
      console.error('Failed to capture view', error)
    }
  }, [pixelRatio, shareImageUri])

  const url = useMemo(() => {
    return toShareUrl(
      makeProfileLink({
        did: '',
        handle: handle,
      }),
    )
  }, [handle])

  // const onPressLink = useCallback(() => {
  //   shareUrl(url)
  //   closeModal()
  // }, [url, closeModal])

  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!avatar) return
        const response = await fetch(avatar)
        const blob = await response.blob()
        const imgUrl = URL.createObjectURL(blob)
        setImageUrl(imgUrl)
      } catch (error) {
        console.error('Error fetching image:', error)
      } finally {
        setIsRendered(true)
      }
    }
    fetchImage()
  }, [avatar])

  useEffect(() => {
    if (viewRef?.current && isRendered) {
      captureView()
    }
  }, [viewRef, captureView, isRendered])

  return (
    <View style={styles.page}>
      <TouchableOpacity
        accessibilityRole="button"
        style={{
          position: 'absolute',
          right: 20,
          top: isNative ? 30 : -30,
        }}
        onPress={closeModal}>
        <IconCircle
          style={[
            {
              width: 20,
              height: 20,
              borderRadius: 10,
              backgroundColor: t.palette.white,
            },
          ]}
          icon={Close}
          iconStyle={{color: t.palette.black}}
          size="xs"
        />
      </TouchableOpacity>
      {/* <View style={{position: 'relative'}}>
        <Text style={[styles.title, t.atoms.text]}>
          <Trans>Share</Trans>
        </Text>
        <TouchableOpacity
          accessibilityRole="button"
          style={{position: 'absolute', left: -5, top: 0}}
          onPress={closeModal}>
          <CloseShareIcon color={t.palette.white} />
        </TouchableOpacity>
      </View> */}
      <View style={[styles.out, isNative && {marginTop: 70}]}>
        <ViewShot
          ref={viewRef}
          style={[styles.viewShot, shareImageUri ? {display: 'none'} : {}]}>
          <LinearGradient
            colors={['#DBFE0A', '#FCD010']}
            style={styles.outputBox}>
            <View style={{borderWidth: 3, borderRadius: 100, width: 100}}>
              <UserAvatar avatar={imageUrl} size={94} />
            </View>
            <View>
              <Text
                style={{
                  fontSize: 26,
                  fontWeight: 'bold',
                  color: '#000000',
                  paddingVertical: 10,
                }}>
                {displayName}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: '#000000',
                  textAlign: 'center',
                }}>{`@${formatHandler(handle)}`}</Text>
            </View>

            {description ? (
              <View style={styles.content}>
                <Text
                  numberOfLines={3}
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: '#000000',
                    fontStyle: 'italic',
                    textAlign: 'center',
                  }}>
                  {description}
                </Text>
              </View>
            ) : (
              <View style={{width: 2, height: 50}} />
            )}

            <View
              style={{
                width: '100%',
                gap: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View style={{flex: 1, paddingTop: 10}}>
                <Text
                  style={{
                    color: '#000000',
                    fontSize: 16,
                    marginTop: 12,
                    marginBottom: 9,
                    fontWeight: 'bold',
                    fontFamily: 'Damion',
                  }}>
                  <Trans>Sip and Spill together!</Trans>
                </Text>
                <LogoHorizontal />
                <Text style={{color: '#191916', fontSize: 12}}>
                  https://sipz.io
                </Text>
              </View>

              <View style={{marginTop: 20}}>
                <ShareQRCode url={url} />
              </View>
            </View>
          </LinearGradient>
        </ViewShot>
        <ImageRN
          style={{
            width: imageDimensions.width,
            height: imageDimensions.height,
            borderRadius: 16,
          }}
          source={{uri: shareImageUri}}
          accessibilityIgnoresInvertColors={true}
        />
      </View>

      <View style={styles.ctrl}>
        {!isNative && shareImageUri && (
          <Text style={{color: t.palette.white}}>
            {isMobile ? (
              <Trans>Long press to share the image</Trans>
            ) : (
              <Trans>Right-click to copy the image</Trans>
            )}
          </Text>
        )}
        {/* <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={captureView}>
          <DownLoadIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Save to Device</Trans>
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={onPressLink}>
          <ShareLinkIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Copy Link</Trans>
          </Text>
        </TouchableOpacity> */}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    // padding: 10,
    // backgroundColor: 'transparent',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  out: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  outputBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    padding: 24,
    paddingTop: 50,
    borderRadius: 16,
  },
  userInfo: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  content: {
    position: 'relative',
    marginTop: 10,
    paddingBottom: 10,
  },
  viewShot: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctrl: {
    padding: 20,
    flexDirection: 'row',
    gap: 50,
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    gap: 10,
  },
})

export async function DownLoadImage(uri: string, imgName: string) {
  const canvas = await getCanvas(uri)
  const imgHref = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream')

  const link = document.createElement('a')
  link.setAttribute('download', `${imgName.replaceAll(' ', '_')}.png`)
  link.setAttribute('href', imgHref)
  link.click()
}

export const getCanvas = (base64: string): Promise<HTMLCanvasElement> => {
  return new Promise(resolve => {
    const image = new Image()
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const ctx = canvas.getContext('2d')
      ctx?.drawImage(image, 0, 0)
      resolve(canvas)
    }
    image.src = base64
  })
}
