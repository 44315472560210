import React from 'react'
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {useCircleDeleteMutatation} from '#/view/screens/Circle/hooks'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import * as Prompt from '#/components/Prompt'
import {FROM_TYPE_OPTIONS} from '../../tags/TagFilter'
import {Button} from '../../util/forms/Button'

export interface CircleConfigMenuModalProps {
  menuType?: 'fromType' | 'circleConfig'
  circleId: string
  circleName?: string
  onCallBack?: (value?: any) => void
}

export const snapPoints = ['30%']
export function Component({
  menuType = 'circleConfig',
  circleId,
  // circleName,
  onCallBack,
}: CircleConfigMenuModalProps) {
  const t = useTheme()
  const {_} = useLingui()
  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const navigation = useNavigation<NavigationProp>()
  const prompt = useDialogControl()
  const deleteCircle = useCircleDeleteMutatation()

  const onRemoveCircle = async () => {
    if (!circleId) {
      Toast.show('Please select a circle to remove')
      return
    }
    await deleteCircle.mutateAsync({circleId: Number(circleId)})
    closeModal()
    onCallBack?.()
  }

  const fromTypeMenu = FROM_TYPE_OPTIONS?.map(item => {
    return {
      name: item?.name,
      action: () => {
        closeModal()
        onCallBack?.(item?.key)
      },
    }
  })

  const circleActionMenu = [
    {
      name: 'Edit Info',
      action: () => {
        navigation.navigate('CircleUpdate', {id: circleId})
        closeModal()
      },
    },
    {
      name: 'Delete',
      action: () => {
        prompt.open()
      },
    },
  ]

  return (
    <View style={[styles.page, isMobile && {paddingHorizontal: 18}]}>
      {(menuType === 'circleConfig' ? circleActionMenu : fromTypeMenu)?.map(
        item => {
          return (
            <TouchableWithoutFeedback
              accessibilityRole="button"
              key={item.name}
              onPress={() => {
                item?.action()
              }}>
              <View style={[styles.item]}>
                <Text style={[styles.itemLabel, t.atoms.text]}>
                  <Trans>{item?.name}</Trans>
                </Text>
              </View>
            </TouchableWithoutFeedback>
          )
        },
      )}

      <View
        style={[
          styles.ctrl,
          a.gap_md,
          isDesktop ? [a.flex_row_reverse, a.gap_md] : {},
        ]}>
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>

      <Prompt.Outer control={prompt}>
        <Prompt.TitleText>Delete Circle</Prompt.TitleText>
        <Prompt.DescriptionText>
          Are you sure you want to delete this circle?
        </Prompt.DescriptionText>
        <Prompt.Actions>
          <Prompt.Action
            cta="Delete"
            color="negative"
            onPress={onRemoveCircle}
          />
          <Prompt.Cancel />
        </Prompt.Actions>
      </Prompt.Outer>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 0,
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
  },
  item: {
    height: 56,
    borderWidth: 1,
    borderColor: '#97979733',
    borderRadius: 8,
    marginBottom: 12,
    padding: 10,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemLabel: {
    fontSize: 16,
    fontWeight: '500',
  },
  ctrl: {
    marginTop: 20,
  },
})
