import React, {useState} from 'react'
import {Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {useModalControls} from '#/state/modals'
import {useUpdateTransMutation} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {useTelegramLink} from '../../modals/TelegramModal/hooks'
import {Button} from '../../util/forms/Button'
import {useTwStore} from '../../util/sdlStore/TwStore'

export interface JoinGuarModalProps {
  rKey: string
  cid: string
  transPostAuthor: {
    did: string
  }
  onCallBack?: () => void
}
export const snapPoints = ['40%']
export const Component = function JoinGuarComponent({
  rKey,
  cid,
  transPostAuthor,
  onCallBack,
}: JoinGuarModalProps) {
  const {_} = useLingui()
  const t = useTheme()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {openModal, closeModal} = useModalControls()
  const {isBoundTw} = useTwStore()
  const {bind: hasBindTelegram} = useTelegramLink()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()

  const [pending, setPending] = useState<boolean>(false)

  async function onConfirm() {
    if (!currentAccount?.did) return
    if (!hasBindTelegram && !isBoundTw) {
      openModal({name: 'link-social'})
      return
    }
    setPending(true)
    const urIParam = makeRecordUri(
      transPostAuthor?.did,
      'app.bsky.feed.post',
      rKey,
    )
    try {
      const res = await updateTransMutation.mutateAsync({
        param: {
          repo: currentAccount?.did,
          collection: 'app.bsky.graph.guaranteeJoin',
          record: {
            guarantor: currentAccount?.did,
            subject: {
              uri: urIParam,
              cid,
            },
            createdAt: new Date().toISOString(),
            $type: 'app.bsky.graph.guaranteeJoin',
            status: 1,
          },
        },
      })
      if (res?.data?.uri) {
        Toast.show('You have successfully joined')
        closeModal()
        onCallBack?.()
      }
    } catch (error) {
    } finally {
      setPending(false)
    }
  }

  return (
    <View testID="joinGuarModal" style={[isMobile && {paddingHorizontal: 18}]}>
      <Text
        style={[
          a.text_xl,
          a.font_bold,
          a.flex,
          a.justify_center,
          a.text_center,
          t.atoms.text_title_1,
          a.mb_lg,
        ]}>
        <Trans>Join as a guarantor</Trans>
      </Text>
      <Text style={[a.text_sm, t.atoms.text_title_2]}>
        <Trans>
          It means that you have the same experience and demands as the spiller,
          or you completely trust the spiller to endorse it with your
          reputation.
        </Trans>
      </Text>
      <View style={[a.mt_5xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          disabled={pending}
          withLoading={true}
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint=""
          label={_(msg`Confirm`)}
          onPress={onConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}
