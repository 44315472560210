import React from 'react'
import {Pressable, View} from 'react-native'
import Animated from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {AppBskyFeedDefs, AppBskyFeedPost} from '@atproto/api'

import {useMinimalShellFooterTransform} from '#/lib/hooks/useMinimalShellTransform'
import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {clamp} from '#/lib/numbers'
import {POST_TOMBSTONE, usePostShadow} from '#/state/cache/post-shadow'
import {useShellLayout} from '#/state/shell/shell-layout'
import {useTranslationWidget} from '#/view/com/posts/TranslationWidget/TranslationWidget'
import {BigFigurePostCtrls} from '#/view/com/util/post-ctrls/BigFigurePostCtrls'
import {Text} from '#/view/com/util/text/Text'
import {atoms as a, useTheme} from '#/alf'
import {RelyIcon} from '#/components/icons/StakeIcons'

export function PostBottomBar({
  post,
  record,
  onPressReply,
  isCircle,
}: {
  post: AppBskyFeedDefs.PostView
  record: AppBskyFeedPost.Record
  onPressReply: () => void
  isCircle?: boolean
}) {
  const t = useTheme()
  const pal = usePalette('default')
  const safeAreaInsets = useSafeAreaInsets()
  const footerMinimalShellTransform = useMinimalShellFooterTransform()
  const {footerHeight} = useShellLayout()
  const {isMobile} = useWebMediaQueries()
  const postShadowed = usePostShadow(post)
  const {isDesktop} = useWebMediaQueries()

  const {richText} = useTranslationWidget({record, post})

  return (
    <Animated.View
      style={[
        a.w_full,
        a.justify_between,
        a.px_lg,
        pal.view,
        pal.border,
        {
          paddingVertical: clamp(safeAreaInsets.bottom, 15, 30),
          borderTopWidth: 1,
          backgroundColor: t.name === 'light' ? '#fff' : '#000',
        },
        isDesktop && {maxWidth: 598},
        footerMinimalShellTransform,
        a.flex_row,
        a.align_center,
      ]}
      onLayout={e => {
        footerHeight.value = e.nativeEvent.layout.height
      }}>
      <Pressable
        accessibilityRole="button"
        style={[
          a.flex_1,
          {height: 40},
          a.flex_row,
          a.align_center,
          a.justify_start,
          a.rounded_5xl,
          a.px_md,
          a.gap_sm,
          t.atoms.reply_btn_bg,
          t.atoms.input_border,
        ]}
        role="button"
        onPress={onPressReply}>
        <RelyIcon primaryColor={t.palette.gray_10} size={18} />
        <Text style={[a.text_sm, {color: t.palette.gray_10}]}>Reply</Text>
      </Pressable>
      {!isCircle && (
        <View style={[isMobile ? a.flex_2 : a.flex_1, a.pr_lg, a.pl_3xl]}>
          {postShadowed !== POST_TOMBSTONE && record && (
            <BigFigurePostCtrls
              post={postShadowed}
              record={record}
              richText={richText}
              onPressReply={onPressReply}
              logContext="Post"
              hideRely={true}
              big={true}
            />
          )}
        </View>
      )}
    </Animated.View>
  )
}
