import React, {useMemo} from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {colors} from '#/lib/styles'
import {isMobileWeb} from '#/platform/detection'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {useHideBottomBar} from '#/view/shell/bottom-bar/BottomBar'
import {atoms as a, useTheme} from '#/alf'
import {MessageNotif} from '#/components/icons/MessageNotif'
import {Loader} from '#/components/Loader'
import {CircleItemProps} from '.'
import {CircleList} from './CircleList'
import {useCircleApproveNumQuery, useCircleList} from './hooks'

type Props = {
  query?: string
}
export function CirclePanel(props: Props) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {data: circles, isLoading} = useCircleList()
  const {data: requestCount} = useCircleApproveNumQuery({})

  const [query, setQuery] = React.useState(props?.query ?? '')

  const handlerEdit = (circle: CircleItemProps) => {
    navigation.navigate('CircleUpdate', {id: circle.id})
  }
  const handlerInvite = (circle: CircleItemProps) => {
    navigation.navigate('CircleInvite', {id: circle.id})
  }

  const filter = useMemo(() => {
    if (query) {
      return circles?.filter(circle =>
        circle.name?.toLowerCase().includes(query.toLowerCase()),
      )
    }
    return circles
  }, [query, circles])

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const onSubmitQuery = () => {}

  const renderHeaderBtn = React.useCallback(() => {
    return (
      <View style={[a.gap_md, a.flex_row, a.align_center]}>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            navigation.navigate('CircleCreate')
          }}>
          <FontAwesomeIcon icon={'plus'} color={t.atoms.text.color} size={20} />
        </Pressable>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            navigation.navigate('CircleRequestList')
          }}>
          <View style={[a.flex_row, a.gap_md]}>
            <MessageNotif style={{color: t.atoms.text.color}} />
            {requestCount && requestCount > 0 ? (
              <View
                style={{
                  position: 'absolute',
                  top: -5,
                  right: -6,
                  backgroundColor: colors.bubble,
                  paddingHorizontal: 6,
                  paddingVertical: 2,
                  borderRadius: 15,
                }}>
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 10,
                  }}>
                  {requestCount}
                </Text>
              </View>
            ) : (
              ''
            )}
          </View>
        </Pressable>
      </View>
    )
  }, [navigation, requestCount, t.atoms.text.color])

  useHideBottomBar()

  return (
    <CenteredView
      style={[
        a.h_full,
        a.justify_between,
        {paddingBottom: isMobileWeb ? 90 : 0},
      ]}>
      <ViewHeader
        title={`My Circle`}
        canGoBack
        showOnDesktop
        showBorder={false}
        renderButton={renderHeaderBtn}
      />
      <View style={[a.my_md]}>
        <SearchInput
          query={query}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
          onSubmitQuery={onSubmitQuery}
          style={[
            a.border,
            t.atoms.input_bg,
            t.atoms.input_border,
            a.mx_lg,
            {borderRadius: 10},
          ]}
        />
      </View>
      <View style={[a.flex_1, a.px_lg]}>
        {isLoading && <Loader />}
        {!isLoading && (
          <CircleList
            data={filter}
            onEdit={handlerEdit}
            onInvite={handlerInvite}
          />
        )}
        {!filter?.length && (
          <EmptyBox icon="heart" message="There are no circle yet." />
        )}
      </View>
    </CenteredView>
  )
}
