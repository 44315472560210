import {useCallback, useSyncExternalStore} from 'react'

import {createStore} from './SdlStore'

const store = createStore({
  circle: [] as any[],
  public: [] as any[],
})

export const circleStoreUrls = new Set<string>()

export const publicStoreUrls = new Set<string>()

const {subscribe, getState, setState: setNewPostStore} = store

export function useNewPostStore() {
  const newPostStore = useSyncExternalStore(subscribe, getState)

  const updateLocalNewPost = (newPost: any, type: 'circle' | 'public') => {
    if (type === 'circle') {
      circleStoreUrls.add(newPost.slice.items[0].post.uri)
    } else {
      publicStoreUrls.add(newPost.slice.items[0].post.uri)
    }
    setNewPostStore(pre => {
      return {
        ...pre,
        [type]: [...pre[type], newPost],
      }
    })
  }

  const updateLocalPost = useCallback(
    ({uri, value}: {uri: string; value: any}) => {
      if (circleStoreUrls.has(uri)) {
        setNewPostStore(pre => {
          return {
            ...pre,
            circle: pre.circle.map(item => {
              if (item.slice.items[0].post.uri === uri) {
                item.slice.items[0]._reactKey =
                  item.slice.items[0]._reactKey + '1'
                const prePost = item.slice.items[0].post
                prePost.viewer.like = value.likeUri
                if (value.likeUri !== 'pending') {
                  prePost.likeCount = value.likeUri
                    ? prePost.likeCount + 1
                    : prePost.likeCount - 1
                }
              }
              return item
            }),
          }
        })
      } else if (publicStoreUrls.has(uri)) {
        setNewPostStore(pre => {
          return {
            ...pre,
            public: pre.public.map(item => {
              if (item.slice.items[0].post.uri === uri) {
                item.slice.items[0]._reactKey =
                  item.slice.items[0]._reactKey + '1'
                const prePost = item.slice.items[0].post
                prePost.viewer.like = value.likeUri
                if (value.likeUri !== 'pending') {
                  prePost.likeCount = value.likeUri
                    ? prePost.likeCount + 1
                    : prePost.likeCount - 1
                }
              }
              return item
            }),
          }
        })
      }
    },
    [],
  )

  return {
    newPostStore,
    updateLocalNewPost,
    updateLocalPost,
  }
}
