import React from 'react'
import {useCallback} from 'react'
import {
  ActivityIndicator,
  Keyboard,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {Trans} from '@lingui/macro'

import {useAnimatedScrollHandler} from '#/lib/hooks/useAnimatedScrollHandler_FIXED'
import {ComposerOptsQuote} from '#/state/shell/composer'
import {isAndroid, isWeb} from 'platform/detection'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {DialogControlProps} from '#/components/Dialog'
import {SwitchAccountIcon} from '#/components/icons/StakeIcons'
import {PostAccountType} from '../util/post-mask'

type ComposerTopBarProps = {
  sltAccount: PostAccountType | undefined
  markAsControl: DialogControlProps
  onPressCancel: () => void
  quote: ComposerOptsQuote | undefined
  visible: string
  isAnonymous: boolean
  isProcessing: boolean
  onPressPublish: () => void
  canPost: boolean
  isReply: boolean
}

export default function ComposerTopBar({
  sltAccount,
  markAsControl,
  onPressCancel,
  quote,
  visible,
  isAnonymous,
  isProcessing,
  onPressPublish,
  canPost,
  isReply,
}: ComposerTopBarProps) {
  const t = useTheme()
  const {topBarAnimatedStyle} = useAnimatedBorders()
  const insets = useSafeAreaInsets()

  return (
    <Animated.View style={[topBarAnimatedStyle]}>
      <TouchableWithoutFeedback
        accessibilityRole="combobox"
        onPress={() => {
          !isWeb && Keyboard.dismiss()
        }}>
        <View
          style={[
            a.w_full,
            styles.topbarInner,
            {
              marginTop: isAndroid ? insets.top : 0,
              borderBottomWidth: 1,
            },
            t.atoms.line,
            t.atoms.modalBg,
          ]}>
          <TouchableOpacity
            accessibilityRole="button"
            style={{}}
            onPress={onPressCancel}>
            <Text style={t.atoms.text}>
              <Trans>Cancel</Trans>
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            accessibilityRole="button"
            style={[a.flex_1, a.justify_center]}
            onPress={() => {
              Keyboard.dismiss()
              markAsControl.open()
            }}>
            {!isReply && (
              <Text
                style={[a.text_md, t.atoms.text, a.font_bold, a.text_center]}>
                <Trans>Tea</Trans>
              </Text>
            )}
            {sltAccount && !quote && (
              <View
                style={[
                  a.flex_row,
                  a.align_center,
                  a.justify_center,
                  a.px_md,
                  a.gap_xs,
                ]}>
                <Text style={[a.text_xs, {color: t.palette.gray_10}]}>
                  {isReply ? <Trans>Reply as</Trans> : <Trans>Spill as</Trans>}
                </Text>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[
                    a.text_xs,
                    a.font_bold,
                    {color: t.palette.primary_active},
                  ]}>
                  {visible !== 'Public' && isAnonymous
                    ? 'Anonymous'
                    : sltAccount?.name && sltAccount?.name !== ''
                    ? sltAccount?.name
                    : formatHandler(sltAccount?.handle)}
                </Text>
                <SwitchAccountIcon style={[a.ml_xs]} />
              </View>
            )}
          </TouchableOpacity>

          {isProcessing ? (
            <>
              {/* <Text style={pal.textLight}>{processingState}</Text> */}
              <View style={styles.postBtn}>
                <ActivityIndicator color={t.palette.primary} />
              </View>
            </>
          ) : (
            <TouchableOpacity
              accessibilityRole="button"
              style={{
                borderRadius: 25,
                backgroundColor: t.palette.primary,
                paddingHorizontal: 10,
                paddingVertical: canPost ? 5 : 7,
                opacity: canPost ? 1 : 0.5,
              }}
              onPress={() => onPressPublish()}>
              <Text style={[a.font_bold, {color: 'black'}]}>
                {isReply ? (
                  <Trans context="action">Reply</Trans>
                ) : (
                  <Trans context="action">Spill!</Trans>
                )}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </TouchableWithoutFeedback>
    </Animated.View>
  )
}

export function useAnimatedBorders() {
  const t = useTheme()
  const hasScrolledTop = useSharedValue(0)
  const hasScrolledBottom = useSharedValue(0)
  const contentOffset = useSharedValue(0)
  const scrollViewHeight = useSharedValue(Infinity)
  const contentHeight = useSharedValue(0)

  /**
   * Make sure to run this on the UI thread!
   */
  const showHideBottomBorder = useCallback(
    ({
      newContentHeight,
      newContentOffset,
      newScrollViewHeight,
    }: {
      newContentHeight?: number
      newContentOffset?: number
      newScrollViewHeight?: number
    }) => {
      'worklet'

      if (typeof newContentHeight === 'number')
        contentHeight.value = Math.floor(newContentHeight)
      if (typeof newContentOffset === 'number')
        contentOffset.value = Math.floor(newContentOffset)
      if (typeof newScrollViewHeight === 'number')
        scrollViewHeight.value = Math.floor(newScrollViewHeight)

      hasScrolledBottom.value = withTiming(
        contentHeight.value - contentOffset.value - 5 > scrollViewHeight.value
          ? 1
          : 0,
      )
    },
    [contentHeight, contentOffset, scrollViewHeight, hasScrolledBottom],
  )

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      'worklet'
      hasScrolledTop.value = withTiming(event.contentOffset.y > 0 ? 1 : 0)
      showHideBottomBorder({
        newContentOffset: event.contentOffset.y,
        newContentHeight: event.contentSize.height,
        newScrollViewHeight: event.layoutMeasurement.height,
      })
    },
  })

  const onScrollViewContentSizeChange = useCallback(
    (_width: number, height: number) => {
      'worklet'
      showHideBottomBorder({
        newContentHeight: height,
      })
    },
    [showHideBottomBorder],
  )

  const onScrollViewLayout = useCallback(
    (evt: LayoutChangeEvent) => {
      'worklet'
      showHideBottomBorder({
        newScrollViewHeight: evt.nativeEvent.layout.height,
      })
    },
    [showHideBottomBorder],
  )

  const topBarAnimatedStyle = useAnimatedStyle(() => {
    return {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: interpolateColor(
        hasScrolledTop.value,
        [0, 1],
        ['transparent', t.atoms.border_contrast_medium.borderColor],
      ),
    }
  })
  const bottomBarAnimatedStyle = useAnimatedStyle(() => {
    return {
      borderTopWidth: StyleSheet.hairlineWidth,
      borderColor: interpolateColor(
        hasScrolledBottom.value,
        [0, 1],
        ['transparent', t.atoms.border_contrast_medium.borderColor],
      ),
    }
  })

  return {
    scrollHandler,
    onScrollViewContentSizeChange,
    onScrollViewLayout,
    topBarAnimatedStyle,
    bottomBarAnimatedStyle,
  }
}

const styles = StyleSheet.create({
  topbarInner: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    height: 54,
    gap: 4,
  },
  postBtn: {
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 6,
    marginLeft: 12,
  },
})
