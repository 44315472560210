import React from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
// @ts-ignore
import ProgressCircle from 'react-native-progress/Circle'
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated'
import {RichText} from '@atproto/api'

import {isNative, isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {GalleryModel} from '#/state/models/media/gallery'
import {useSession} from '#/state/session'
import {ComposerOptsQuote} from '#/state/shell/composer'
import {State as VideoUploadState} from 'state/queries/video/video'
import {
  ComposerEmojiIcon,
  ComposerMentionIcon,
  ComposerSpillIcon,
} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {ArrowTriangleBottom_Stroke2_Corner1 as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
// import {SelectTarget} from '../modals/PostTarget'
import {getIsOpreration, getOperationName} from '../util/access/OperationUtil'
import {SelectPhotoBtn} from './photos/SelectPhotoBtn'
import {TextInputRef} from './text-input/TextInput'
import {textInputWebEmitter} from './text-input/TextInput.web'

type ComposerFooterProps = {
  videoUploadState: VideoUploadState
  canSelectImages: boolean
  gallery: GalleryModel
  quote: ComposerOptsQuote | undefined
  operation: string
  setOperation: (operation: string) => void
  textInput: React.RefObject<TextInputRef>
  isReply: boolean
  richtext: RichText
  setRichText: (value: RichText) => void
  openPicker?: (pos: DOMRect | undefined) => void
}

export default function ComposerFooter({
  videoUploadState,
  canSelectImages,
  gallery,
  quote,
  operation,
  setOperation,
  textInput,
  isReply,
  richtext,
  // setRichText,
  openPicker,
}: ComposerFooterProps) {
  const t = useTheme()
  const {currentAccount} = useSession()
  const isOpreration = getIsOpreration(currentAccount?.did || '')
  const {openModal} = useModalControls()

  return (
    <View
      style={[
        t.atoms.modalBg2,
        t.atoms.border_contrast_medium,
        styles.bottomBar,
      ]}>
      {videoUploadState.status !== 'idle' ? (
        <VideoUploadToolbar state={videoUploadState} />
      ) : (
        <ToolbarWrapper
          style={[a.w_full, a.flex_row, a.align_center, a.gap_md, a.px_md]}>
          <View style={[!canSelectImages && {opacity: 0.5}]}>
            <SelectPhotoBtn gallery={gallery} disabled={!canSelectImages} />
          </View>
          {!isReply && !quote && (
            <>
              {/* {!quote && (
                <View>
                  <SelectTarget init={() => textInput.current?.blur()} />
                </View>
              )} */}
              {isOpreration && (
                <View>
                  <TouchableOpacity
                    accessibilityRole="button"
                    onPress={() => {
                      openModal({name: 'operation', setOperation, operation})
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Text style={[t.atoms.text, a.ml_sm, a.mr_2xs]}>
                      Operation Type:
                    </Text>
                    <Text style={t.atoms.text}>
                      {getOperationName(operation)}
                    </Text>
                    <ArrowTriangleBottom
                      style={{
                        color: t.palette.contrast_975,
                        width: 16,
                        height: 16,
                        marginLeft: 6,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
              <View>
                <ComposerSpillBtn richtext={richtext} textInput={textInput} />
              </View>
              <View>
                <ComposerMentionBtn richtext={richtext} textInput={textInput} />
              </View>
              {!isNative && (
                <View>
                  <ComposerEmojiBtn
                    textInput={textInput}
                    openEmojiPicker={openPicker}
                  />
                </View>
              )}
            </>
          )}
        </ToolbarWrapper>
      )}
      <View style={a.flex_1} />
    </View>
  )
}

export function ToolbarWrapper({
  style,
  children,
}: {
  style: StyleProp<ViewStyle>
  children: React.ReactNode
}) {
  if (isWeb) return <View style={style}>{children}</View>
  return (
    <Animated.View
      style={style}
      entering={FadeIn.duration(400)}
      exiting={FadeOut.duration(400)}>
      {children}
    </Animated.View>
  )
}

export function VideoUploadToolbar({state}: {state: VideoUploadState}) {
  const t = useTheme()

  const progress =
    state.status === 'compressing' || state.status === 'uploading'
      ? state.progress
      : state.jobStatus?.progress ?? 100

  return (
    <ToolbarWrapper
      style={[a.gap_sm, a.flex_row, a.align_center, {paddingVertical: 5}]}>
      <ProgressCircle
        size={30}
        borderWidth={1}
        borderColor={t.atoms.border_contrast_low.borderColor}
        color={t.palette.primary_500}
        progress={progress}
      />
      <Text>{state.status}</Text>
    </ToolbarWrapper>
  )
}

const ComposerSpillBtn = ({
  richtext,
  textInput,
}: {
  richtext: RichText
  textInput: React.RefObject<TextInputRef>
}) => {
  const t = useTheme()

  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <TouchableOpacity
      accessibilityRole="button"
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => {
        if (isWeb) {
          textInputWebEmitter.emit('mention-inserted', '!')
        } else {
          const text =
            richtext.text.trim().length === 0 ? '' : richtext.text + ' '
          textInput.current?.onChangeText(text + '!')
        }
      }}
      style={[
        {borderRadius: 9, padding: 2, margin: 8},
        isHovered && t.atoms.bg_primary,
      ]}>
      <ComposerSpillIcon active={isHovered} />
    </TouchableOpacity>
  )
}

const ComposerMentionBtn = ({
  richtext,
  textInput,
}: {
  richtext: RichText
  textInput: React.RefObject<TextInputRef>
}) => {
  const t = useTheme()

  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <TouchableOpacity
      accessibilityRole="button"
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => {
        if (isWeb) {
          textInputWebEmitter.emit('mention-inserted', '@')
        } else {
          const text =
            richtext.text.trim().length === 0 ? '' : richtext.text + ' '
          textInput.current?.onChangeText(text + '@')
        }
      }}
      style={[
        {borderRadius: 9, padding: 2, margin: 8},
        isHovered && t.atoms.bg_primary,
      ]}>
      <ComposerMentionIcon active={isHovered} />
    </TouchableOpacity>
  )
}

const ComposerEmojiBtn = ({
  textInput,
  openEmojiPicker,
}: {
  textInput: React.RefObject<TextInputRef>
  openEmojiPicker?: (pos: DOMRect | undefined) => void
}) => {
  const t = useTheme()

  const [isHovered, setIsHovered] = React.useState(false)

  const onEmojiButtonPress = React.useCallback(() => {
    openEmojiPicker?.(textInput.current?.getCursorPosition())
    if (isWeb) {
      const elements =
        document && document?.querySelectorAll('[data-tippy-root]')
      elements.forEach(element => {
        element.remove()
      })
    }
  }, [openEmojiPicker, textInput])

  return (
    <TouchableOpacity
      accessibilityRole="button"
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={onEmojiButtonPress}
      style={[
        {borderRadius: 9, padding: 2, margin: 8},
        isHovered && t.atoms.bg_primary,
      ]}>
      <ComposerEmojiIcon active={isHovered} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  bottomBar: {
    flexDirection: 'row',
    paddingVertical: 4,
    // should be 8 but due to visual alignment we have to fudge it
    paddingLeft: 7,
    paddingRight: 16,
    alignItems: 'center',
    borderTopWidth: 0,
  },
})
