import * as React from 'react'
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg'

import {createSinglePathSVG} from './TEMPLATE'

export const Warning_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M11.14 4.494a.995.995 0 0 1 1.72 0l7.001 12.008a.996.996 0 0 1-.86 1.498H4.999a.996.996 0 0 1-.86-1.498L11.14 4.494Zm3.447-1.007c-1.155-1.983-4.019-1.983-5.174 0L2.41 15.494C1.247 17.491 2.686 20 4.998 20h14.004c2.312 0 3.751-2.509 2.587-4.506L14.587 3.487ZM13 9.019a1 1 0 1 0-2 0v2.994a1 1 0 1 0 2 0V9.02Zm-1 4.731a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z',
})

export const WarninCircle = () => (
  <Svg width={24} height={24} fill="none">
    <G clipPath="url(#a)">
      <Path fill="#FF453A" d="M24 12a12 12 0 1 0-24 0 12 12 0 0 0 24 0Z" />
      <Path
        fill="#fff"
        d="M10.8 7.2v3.6a1.2 1.2 0 0 0 2.4 0V7.2a1.2 1.2 0 1 0-2.4 0ZM10.727 16.873a1.8 1.8 0 1 0 2.546-2.546 1.8 1.8 0 0 0-2.546 2.546Z"
      />
    </G>
    <Defs>
      <ClipPath>
        <Path fill="#fff" d="M0 24h24V0H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)
