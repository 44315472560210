import React, {useCallback, useMemo} from 'react'
import {Pressable, ScrollView, Text, View} from 'react-native'
import {ComAtprotoServerGetCircleUsers} from '@atproto/api'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'

import {fuzzyMatch} from '#/lib/hooks/Tools'
import {
  CommonNavigatorParams,
  NativeStackScreenProps,
  NavigationProp,
} from '#/lib/routes/types'
import {isMobileWeb, isNative} from '#/platform/detection'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {AddFriendToCircleIcon} from '#/view/icons/ModalIcons'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {useDialogControl} from '#/components/Dialog'
import * as Prompt from '#/components/Prompt'
import {CircleFromType} from '.'
import {CircleMemberItem} from './CircleMemberItem'
import {
  useCircleAccount,
  useCircleDeleteMemberMutation,
  useCircleItem,
} from './hooks'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'CircleMembers'>
export default function CircleMembers({route}: Props) {
  const t = useTheme()

  const [query, setQuery] = React.useState('')
  const [selectedMember, setSelectedMember] = React.useState<
    ComAtprotoServerGetCircleUsers.User | undefined
  >()

  const {data} = useCircleAccount(Number(route.params.id))
  const deleteCircleMember = useCircleDeleteMemberMutation()
  const prompt = useDialogControl()
  const {data: currentRole} = useCircleItem(Number(route.params.id))
  const navigation = useNavigation<NavigationProp>()

  const checkIsOwner = useCallback(
    (arr: any[]) => {
      return arr.includes(currentRole?.creator)
    },
    [currentRole?.creator],
  )

  const ownerItem = useMemo(() => {
    return data
      ?.filter(item => {
        if (query) {
          return (
            fuzzyMatch(item.user_name || '', query) ||
            fuzzyMatch(item.source_user_id || '', query)
          )
        } else {
          return item
        }
      })
      .find(item => item?.sipz_dids?.includes(currentRole?.creator || ''))
  }, [currentRole?.creator, data, query])

  const contacts = useMemo(() => {
    if (!data) return []
    if (!query) {
      return data
    } else {
      return data.filter(item => {
        if (query) {
          return (
            fuzzyMatch(item.user_name || '', query) ||
            fuzzyMatch(item.source_user_id || '', query)
          )
        } else {
          return item
        }
      })
    }
  }, [data, query])

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const onRemoveCircleMember = async () => {
    if (!selectedMember) {
      Toast.show('Please select a member to remove')
      return
    }
    await deleteCircleMember.mutateAsync({
      circleId: +route.params.id,
      members: [selectedMember?.source_user_id],
      sourceType: selectedMember?.source_type as CircleFromType,
    })
  }

  return (
    <CenteredView
      style={[
        a.h_full,
        a.justify_between,
        {paddingBottom: isMobileWeb || isNative ? 90 : 0},
      ]}>
      <ViewHeader
        canGoBack
        showBorder={false}
        showHorizontalBorder={false}
        showOnDesktop
        title={
          currentRole
            ? `${currentRole?.name} (${currentRole?.memberCount})`
            : ''
        }
      />
      <View style={[a.px_lg, a.flex_1, a.justify_between]}>
        <View style={[a.mt_md, a.mb_sm, {height: 44}]}>
          <SearchInput
            query={query}
            onSubmitQuery={() => {}}
            onChangeQuery={setQuery}
            onPressCancelSearch={onPressCancelSearch}
            style={[
              a.border,
              t.atoms.input_bg,
              t.atoms.input_border,
              {borderRadius: 10},
            ]}
          />
        </View>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Pressable
            accessibilityRole="button"
            role="button"
            onPress={() => {
              navigation.navigate('CircleInvite', {id: route.params.id})
            }}
            style={[a.flex_row, a.align_center, a.gap_xs]}>
            <View
              style={[
                {width: 44, height: 44},
                a.align_center,
                a.justify_center,
              ]}>
              <AddFriendToCircleIcon color={t.palette.primary_active} />
            </View>
            <Text style={[{color: t.palette.primary_active}, a.font_semibold]}>
              <Trans>Add Members</Trans>
            </Text>
          </Pressable>
        </View>
        <ScrollView>
          <View style={[a.gap_md, a.mt_md, a.flex_1]}>
            {ownerItem && <CircleMemberItem item={ownerItem} isOwner={true} />}
            {contacts?.map(item => {
              const itemIsOwner = checkIsOwner(item?.sipz_dids || [])
              if (itemIsOwner) {
                return null
              }
              return (
                <CircleMemberItem
                  key={item.source_user_id}
                  item={item}
                  isOwner={itemIsOwner}
                  isCircleOwner={currentRole?.isOwner}
                  onDelete={value => {
                    setSelectedMember(value)
                    prompt.open()
                  }}
                />
              )
            })}
          </View>
        </ScrollView>
      </View>
      <Prompt.Outer control={prompt}>
        <Prompt.TitleText>Delete Member</Prompt.TitleText>
        <Prompt.DescriptionText>
          Remove{' '}
          {
            contacts.find(
              contact =>
                contact.source_user_id === selectedMember?.source_user_id,
            )?.user_name
          }{' '}
          from this circle? They will no longer be able to view Circle Tea.
        </Prompt.DescriptionText>
        <Prompt.Actions>
          <Prompt.Action
            cta="Delete"
            color="negative"
            onPress={onRemoveCircleMember}
          />
          <Prompt.Cancel />
        </Prompt.Actions>
      </Prompt.Outer>
    </CenteredView>
  )
}
