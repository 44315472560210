import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {Pressable, StyleSheet, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {PluginKey} from '@tiptap/pm/state'
import {ReactRenderer} from '@tiptap/react'
import {
  SuggestionKeyDownProps,
  SuggestionOptions,
  SuggestionProps,
} from '@tiptap/suggestion'
import tippy, {Instance as TippyInstance} from 'tippy.js'

import {ActorAutocompleteFn} from '#/state/queries/actor-autocomplete'
import {usePalette} from 'lib/hooks/usePalette'
import {useTgStore} from '#/view/com/util/sdlStore/TgStore'
import {degrees} from '#/view/screens/Telegram/ContactItem'
import {Text} from 'view/com/util/text/Text'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {useGrapheme} from '../hooks/useGrapheme'

interface MentionListRef {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean
}

export function createSuggestion({
  autocomplete,
  type,
}: {
  autocomplete: ActorAutocompleteFn
  type: string
}): Omit<SuggestionOptions, 'editor'> {
  return {
    async items({query}) {
      const suggestions = await autocomplete({query, type})
      return suggestions.slice(0, 10)
    },

    render: () => {
      let component: ReactRenderer<MentionListRef> | undefined
      let popup: TippyInstance[] | undefined

      return {
        onStart: props => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          // @ts-ignore getReferenceClientRect doesnt like that clientRect can return null -prf
          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component?.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup?.[0]?.setProps({
            // @ts-ignore getReferenceClientRect doesnt like that clientRect can return null -prf
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup?.[0]?.hide()

            return true
          }

          return component?.ref?.onKeyDown(props) || false
        },

        onExit() {
          popup?.[0]?.destroy()
          component?.destroy()
        },
      }
    },
    char: type === 'user' ? '@' : '!',
    pluginKey: new PluginKey(type),
    command({editor, range, props}) {
      editor
        .chain()
        .focus()
        .insertContentAt(range, {
          type: props?.type === 'entity' ? 'mentionEntity' : 'mention',
          attrs: {
            label: props.label,
            id: props.id,
            type: props.type,
          },
        })
        .run()
    },
  }
}

const MentionList = forwardRef<MentionListRef, SuggestionProps>(
  function MentionListImpl(props: SuggestionProps, ref) {
    const t = useTheme()
    const themeName = useThemeName()
    const pal = usePalette('default')
    const {bind: hasBindTelegram} = useTgStore()
    const firstChar = props?.text[0]
    const type = firstChar === '@' ? 'user' : 'entity'

    let selectTypeList: {name: string; value: string}[] = []
    if (firstChar === '@') {
      selectTypeList.push({name: 'Sipz', value: 'sipz'})
      if (hasBindTelegram) {
        selectTypeList.push({name: 'Telegram', value: 'telegram'})
      }
    } else if (firstChar === '!') {
      selectTypeList.push({name: 'Spill about', value: 'entity'})
    }

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedType, setSelectedType] = useState<string>(
      type === 'user' ? 'sipz' : 'entity',
    )

    const selectItem = (index: any) => {
      const item = props.items.filter(o => o?.sourceType === selectedType)[
        index
      ]

      if (item) {
        props.command({
          id: item.handle,
          label: formatHandler(item.handle) + ' ',
          type: item?.sourceType,
        })
      }
    }

    const upHandler = () => {
      const data = props.items.filter(o => o?.sourceType === selectedType)
      setSelectedIndex((selectedIndex + data.length - 1) % data.length)
    }

    const downHandler = () => {
      const data = props.items.filter(o => o?.sourceType === selectedType)
      setSelectedIndex((selectedIndex + 1) % data.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items, selectedType])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({event}) => {
        // console.log('xxx-1', event.key)
        if (event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (event.key === 'Enter' || event.key === 'Tab') {
          enterHandler()
          return true
        }

        return false
      },
    }))

    const items = props?.items.filter(o => o?.sourceType === selectedType)

    // console.log('xxx', selectedIndex)

    return (
      <div className="items">
        <View
          style={[
            t.atoms.input_border,
            pal.view,
            styles.container,
            {maxWidth: 340},
            // @ts-ignore
            themeName === 'light' && {boxShadow: '0px 4px 8px 0px #0000001A'},
          ]}>
          {/* SelectType Tab */}
          <View
            style={[
              t.atoms.input_border,
              a.w_full,
              a.flex_row,
              a.align_center,
              {borderBottomWidth: 1, height: 42},
            ]}>
            {selectTypeList?.map(item => {
              return (
                <SelectTypeItem
                  item={item}
                  key={item.value}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  count={selectTypeList?.length}
                />
              )
            })}
          </View>
          {/* SelectContent */}
          {items.length > 0 ? (
            items?.map((item, index) => {
              const isSelected = selectedIndex === index
              return (
                <SelectedContentItem
                  item={item}
                  key={item.handle}
                  isSelected={isSelected}
                  index={index}
                  selectItem={selectItem}
                />
              )
            })
          ) : (
            <Text type="sm" style={[t.atoms.text, styles.noResult]}>
              {type === 'user' ? (
                <Trans>Friends you want to remind...</Trans>
              ) : (
                <Trans>Projects, Investors or People...</Trans>
              )}
            </Text>
          )}
        </View>
      </div>
    )
  },
)

const SelectTypeItem = ({
  item,
  selectedType,
  setSelectedType,
  count,
}: {
  item: {
    name: string
    value: string
  }
  selectedType: string
  setSelectedType: (value: string) => void
  count: number
}) => {
  const t = useTheme()

  return (
    <Pressable
      style={[
        a.flex_1,
        a.h_full,
        count > 1 && a.align_center,
        a.justify_center,
        a.relative,
        {paddingHorizontal: 12},
      ]}
      onPress={() => setSelectedType(item.value)}
      accessibilityRole="button">
      <Text
        style={[
          t.atoms.text_sub,
          a.text_sm,
          selectedType === item.value && [t.atoms.text_title_1, a.font_bold],
        ]}>
        {item?.name}
      </Text>
      {selectedType === item.value && count > 1 && (
        <View
          style={[
            a.absolute,
            {
              width: 30,
              height: 2,
              backgroundColor: t.palette.primary,
              bottom: 0,
            },
          ]}
        />
      )}
    </Pressable>
  )
}

const SelectedContentItem = ({
  item,
  isSelected,
  index,
  selectItem,
}: {
  item: any
  isSelected: boolean
  index: number
  selectItem: (index: number) => void
}) => {
  const t = useTheme()
  const pal = usePalette('default')
  const {getGraphemeString} = useGrapheme()
  const {name: displayName} = getGraphemeString(
    item.displayName && item.displayName !== ''
      ? item.displayName
      : formatHandler(item.handle),
    30,
  )

  return (
    <Pressable
      key={item.handle}
      style={[
        isSelected ? pal.viewLight : undefined,
        pal.borderDark,
        styles.mentionContainer,
      ]}
      onPress={() => {
        selectItem(index)
      }}
      accessibilityRole="button">
      <UserAvatar
        avatar={item.avatar ?? null}
        size={34}
        type={item.associated?.labeler ? 'labeler' : 'user'}
      />
      <View style={[a.flex_1, a.flex_col]}>
        <View style={[a.flex_1, a.flex_row, a.align_center, a.gap_sm]}>
          <Text
            style={[a.text_sm, a.font_bold, t.atoms.text]}
            numberOfLines={1}>
            {displayName}
          </Text>
          {item?.sourceType !== 'entity' && (
            <>
              <Text
                type="xs"
                style={[a.text_xs, t.atoms.text_sub]}
                numberOfLines={1}>
                @{formatHandler(item.handle)}
              </Text>
              {item?.degree && degrees[item?.degree - 1] && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  {degrees[item?.degree - 1].icon}
                  {degrees[item?.degree - 1].name}
                </View>
              )}
            </>
          )}
        </View>
        {item?.sourceType === 'entity' && (
          <View style={[a.flex_1, a.flex_row, a.align_center, a.gap_sm]}>
            <Text style={[a.text_xs, t.atoms.text_sub]} numberOfLines={1}>
              {item?.desc}
            </Text>
          </View>
        )}
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 500,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    // padding: 4,
  },
  mentionContainer: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 8,
    gap: 4,
  },
  firstMention: {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
  lastMention: {
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  avatarAndDisplayName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  noResult: {
    height: 80,
    paddingHorizontal: 12,
    paddingVertical: 13,
    color: '#98989F',
  },
})
