import React, {useEffect} from 'react'
import {ScrollView, Text} from 'react-native'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {toNumber} from 'ethers'

import {uploadBlobImage} from '#/lib/api/upload-blob'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {compressIfNeeded} from '#/lib/media/manip'
import {CommonNavigatorParams, NativeStackScreenProps} from '#/lib/routes/types'
import {cleanError} from '#/lib/strings/errors'
import {validateCircleName} from '#/lib/strings/handles'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {useAgent} from '#/state/session'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import * as Toast from '#/view/com/util/Toast'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Loader} from '#/components/Loader'
import {ContactItemProps} from '.'
import {CreateForm} from './CreateForm'
import {
  useCircleItem,
  useCircleMembers,
  useCircleMembersUpdateMutation,
  useCircleUpdateMutation,
} from './hooks'
import {SelectFriend} from './SelectFriend'
type Props = NativeStackScreenProps<
  CommonNavigatorParams,
  'CircleUpdate' | 'CircleCreate' | 'CircleInvite'
>

// const totalSteps = 2
export function CircleEdit({route, navigation}: Props) {
  const {isMobile} = useWebMediaQueries()
  const {_} = useLingui()

  const [circleId, setCircleId] = React.useState<number>(() => {
    return route.params?.id ? +route.params?.id : -1
  })

  const [stepNumber, setStepNumber] = React.useState(() => {
    if (route.name === 'CircleCreate' || route.name === 'CircleUpdate') {
      return 1
    }
    if (route.name === 'CircleInvite') {
      return 2
    }
    return route.params?.id ? 2 : 1
  })

  const {openModal} = useModalControls()

  const {data: circle} = useCircleItem(circleId)
  const {data: contacts} = useCircleMembers(circleId)
  const circleUpdate = useCircleUpdateMutation()
  const circleMemberUpdate = useCircleMembersUpdateMutation()

  const t = useTheme()
  const [bio, setBio] = React.useState(circle?.bio)
  const [name, setName] = React.useState(circle?.name)
  const [members, setMembers] = React.useState<ContactItemProps[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [historyMembers, setHistoryMembers] =
    React.useState<ContactItemProps[]>()
  const agent = useAgent()
  const [isPrivate, setIsPrivate] = React.useState(circle?.private ?? true)
  const [newCircleBanner, setNewCircleBanner] = React.useState<
    RNImage | undefined
  >()

  useEffect(() => {
    if (contacts && route.name === 'CircleInvite') {
      // setMembers(contacts)
      setHistoryMembers(contacts)
    }
  }, [contacts, circle, route.name])

  const [circleBgImageUri, setCircleBgImageUri] = React.useState<
    string | undefined
  >(circle?.avatar)
  const [_imageError, setImageError] = React.useState<string>('')
  const title = React.useMemo(() => {
    if (route.name === 'CircleCreate') {
      return 'Create Circle'
    }
    if (route.name === 'CircleInvite') {
      return 'Add Members'
    }
    return 'Edit Circle'
  }, [route.name])

  const isCreate = circleId === -1

  useEffect(() => {
    if (circle) {
      setBio(circle.bio)
      setName(circle.name)
      setIsPrivate(!!circle.private)
      setCircleBgImageUri(circle.avatar)
    }
  }, [circle])

  const onSelectBanner = React.useCallback(async (img: RNImage | null) => {
    setImageError('')
    if (!img) {
      setNewCircleBanner(undefined)
      setCircleBgImageUri(undefined)
      return
    }
    //   track('EditProfile:BannerSelected')
    try {
      const finalImg = await compressIfNeeded(img, 1000000)
      setNewCircleBanner(finalImg)
      setCircleBgImageUri(finalImg.path)
    } catch (e: any) {
      setImageError(cleanError(e))
    }
  }, [])

  // const subtitle = React.useMemo(() => {
  //   return (
  //     <View style={[a.flex_1, a.align_center, a.justify_center]}>
  //       <Text>
  //         <Trans>Step</Trans> {stepNumber + ' '}
  //         <Trans>of</Trans>
  //         {' ' + totalSteps}
  //       </Text>
  //     </View>
  //   )
  // }, [stepNumber])

  const onCreate = async () => {
    setIsLoading(true)
    try {
      let newCircleAvatarPromise
      if (newCircleBanner) {
        newCircleAvatarPromise = uploadBlobImage(
          agent,
          newCircleBanner.path,
          newCircleBanner.mime,
        )
      }
      const avatarRes = await newCircleAvatarPromise

      const res = await agent.com.atproto.server.circleInfoAdd({
        circle_name: name,
        description: bio,
        is_public: !isPrivate,
        photo: avatarRes?.data?.blob,
      })
      if (res.data.circle_id) {
        setCircleId(toNumber(res.data.circle_id))
        setStepNumber(2)
      }
    } catch (error: any) {
      logger.error(JSON.stringify(error))
      Toast.show(
        error?.errorMessage ||
          error?.message ||
          _(msg`cause some error, please try again later.`),
        'xmark',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onUpdate = async () => {
    await circleUpdate.mutateAsync({
      circle: {
        id: circleId.toString(),
        name: name,
        bio: bio,
        private: isPrivate,
      },
      newCircleAvatar: newCircleBanner,
    })
  }

  const onBindMembers = async () => {
    if (!(members.length > 0)) {
      Toast.show('Please select at least one member')
      return
    }
    await circleMemberUpdate.mutateAsync({
      circleId: circleId.toString(),
      members: members?.map(item => {
        return {
          id: item.id,
          from: item?.from ?? 'telegram',
        }
      }),
    })
    if (members?.filter(o => o?.from === 'telegram').length > 0) {
      openModal({
        name: 'telegram',
        currentStep: TelegramBindStep.SendMessage,
        userIds: members
          ?.filter(o => o?.from === 'telegram')
          ?.map(item => item.id),
        onCallBack: () => {
          navigation.navigate('Circle')
        },
        circleId: circleId.toString(),
      })
    } else {
      navigation.navigate('Circle')
    }
  }

  const validCheck = React.useMemo(() => {
    if (name) {
      return validateCircleName(name ?? '')
    }
    return {
      frontLength: false,
      handleChars: false,
      hyphenStartOrEnd: false,
      overall: false,
      totalLength: false,
    }
  }, [name])

  const handleCreateUpdate = async () => {
    if (!name) {
      Toast.show('Circle name is required', 'xmark')
      return
    }
    if (
      !validCheck.frontLength ||
      !validCheck.handleChars ||
      !validCheck.hyphenStartOrEnd ||
      !validCheck.totalLength
    ) {
      return
    }

    if (!name) {
      return Toast.show('Please enter a name for the circle')
    }
    if (name.length > 20) {
      return Toast.show('Name should be less than 20 characters')
    }
    if (isCreate) {
      await onCreate()
      setStepNumber(2)
    } else {
      await onUpdate()
      navigation.navigate('CircleDetail', {id: circleId + ''})
    }
  }

  const renderButton = () => {
    if (stepNumber === 2) {
      return (
        <Button
          color={members.length > 0 ? 'primary' : 'secondary'}
          style={[{right: 16}, a.absolute]}
          variant="solid"
          size="xsmall"
          label="Add"
          onPress={onBindMembers}>
          {circleMemberUpdate.isPending ? (
            <Loader />
          ) : (
            <Text style={[{color: t.palette.black}, a.font_bold]}>
              <Trans>Add</Trans>
            </Text>
          )}
        </Button>
      )
    }
    if (stepNumber === 1 && isMobile) {
      return (
        <Button
          color={isCreate || circleId ? 'primary' : 'secondary'}
          style={[{right: 0}, a.absolute]}
          variant="solid"
          size="xsmall"
          label="Done"
          onPress={handleCreateUpdate}>
          {isLoading || circleUpdate.isPending ? (
            <Loader />
          ) : (
            <Text style={[{color: t.palette.black}, a.font_bold]}>
              <Trans>Done</Trans>
            </Text>
          )}
        </Button>
      )
    }
    return null
  }

  return (
    <CenteredView
      style={!isMobile && [a.h_full, a.flex_col, a.justify_between]}>
      <ViewHeader
        canGoBack
        showBorder={false}
        showHorizontalBorder={false}
        showOnDesktop
        title={title}
        // subtitle={subtitle}
        renderButton={renderButton}
      />
      {stepNumber === 1 && (
        <CreateForm
          isCreate={isCreate}
          bio={bio}
          name={name}
          isPrivate={isPrivate}
          isPending={isLoading || circleUpdate.isPending}
          avatarUri={circleBgImageUri}
          onChangeCircleName={setName}
          onChangeCircleBio={setBio}
          onChangePrivate={setIsPrivate}
          onSelectBanner={onSelectBanner}
          onNext={async () => {
            if (!name) {
              return Toast.show('Please enter a name for the circle')
            }
            if (name.length > 20) {
              return Toast.show('Name should be less than 20 characters')
            }
            if (isCreate) {
              await onCreate()
            } else {
              await onUpdate()
              navigation.navigate('CircleDetail', {id: circleId + ''})
            }
          }}
        />
      )}
      {stepNumber === 2 && circleId > 0 && (
        <>
          <ScrollView>
            <SelectFriend
              circleId={circleId}
              selected={members}
              historyMembers={historyMembers}
              onSelected={(list: ContactItemProps[]) => {
                setMembers(list)
              }}
            />
          </ScrollView>
        </>
      )}
    </CenteredView>
  )
}
